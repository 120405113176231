import * as Three from 'three';
import { IKConstraint } from '../types';
import IKJoint from '../IKJoint';
import IKChain from '../IKChain';

export type AxisConfig = {
  targetAxis: string;
  multiplier?: number;
  addDegrees?: number;
  parentMaxDegrees?: number;
  parentMinDegrees?: number;
};

export type AxesType = {
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  zAxis?: AxisConfig;
};

export type IKTargetRotationConstraintOptions = {
  axes?: AxesType;
};

type Coords = 'x' | 'y' | 'z';

const nameToCoordsMap = {
  xAxis: 'x',
  yAxis: 'y',
  zAxis: 'z',
};

export default class IKTargetRotationConstraint implements IKConstraint {
  public axesConfig: AxesType;

  constructor(options: IKTargetRotationConstraintOptions) {
    this.axesConfig = options.axes || {};
  }

  applyLazy(joint: IKJoint, chain: IKChain): boolean {
    if (!this.axesConfig) return false;
    let target = chain.target?.parent;
    // For debug
    if (target instanceof Three.Scene) target = chain.target;
    if (!target) return false;
    const { rotation } = joint.bone;
    Object.keys(this.axesConfig).forEach((axisName) => {
      const axisConfig = this.axesConfig[axisName as keyof AxesType];
      if (!axisConfig || !target) return;
      const axis: Coords = nameToCoordsMap[axisName as keyof AxesType] as Coords;
      const targetAxis: Coords = axisConfig.targetAxis as Coords;
      const multiplier = axisConfig.multiplier || 1;
      const deg = axisConfig.addDegrees || 0;

      rotation[axis] = multiplier * target.rotation[targetAxis] + Three.MathUtils.degToRad(deg);
    });
    joint.bone.updateMatrix();
    return true;
  }
}
