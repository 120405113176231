import { Component } from '../../../engine/Component';
import PresentationComponent from '../Presentation.component';

export default class PresentationMenuComponent extends Component {
  public enabled = false;

  public currentActionComponent: PresentationComponent | null = null;

  public isLoading = false;

  static get code(): string {
    return 'presentation_menu';
  }
}
