import { NetworkId } from '../types';
import { SyncVariable } from '../SyncVariable';
import Payload from './Payload';
import { IDType } from '../services/ID.service';
import Generated, { google } from '../../../generated';
import Any = google.protobuf.Any;
import IAny = google.protobuf.IAny;

export type SyncVariableSerialized<ValueType = any> = {
  type: string;
  value: ValueType | null;
  id: IDType;
  roomId: NetworkId;
  name: string;
  netObjectId: IDType;
  interpolated: boolean;
  writerId: NetworkId;
};

export class VariablePayload<ValueType = any> extends Payload<SyncVariable<ValueType>, SyncVariableSerialized<ValueType>> {
  static fromProto(buffer: Uint8Array): SyncVariableSerialized {
    const variable = Generated.engine.network.payloads.VariablePayload.decode(new Uint8Array(buffer));
    return {
      id: Number(variable?.id),
      roomId: variable.roomId,
      type: variable?.type ?? '',
      name: variable?.name ?? '',
      writerId: Number(variable?.writerId),
      netObjectId: Number(variable?.netObjectId),
      interpolated: Boolean(variable?.interpolated),
      value: variable.payload?.value ? this.payloadFromProto(variable.payload) : null,
    };
  }

  // todo: swypse: return type
  // noinspection JSUnusedLocalSymbols
  static payloadFromProto(payload: IAny): any | null {
    // should be implemented in a child class
    return null;
  }

  get targetType(): string {
    return (<typeof SyncVariable> this.target.constructor).type ?? '';
  }

  toProto(): Uint8Array {
    if (!this.target.id) {
      throw new Error('Variable without iD');
    }
    const proto = Generated.engine.network.payloads.VariablePayload.create({
      type: this.targetType,
      id: this.target.id,
      name: this.target.name,
      interpolated: this.target.interpolated,
      netObjectId: this.target.netObjectId,
      writerId: this.target.writerId,
      roomId: this.target.roomId,
      payload: this.payloadToProto(),
    });
    return Generated.engine.network.payloads.VariablePayload.encode(proto).finish();
  }

  payloadToProto(): Any | null {
    return null;
  }

  toJson(): SyncVariableSerialized<ValueType> {
    if (!this.target.id) throw Error('Variable without iD');
    return {
      type: (this.target.constructor as any).type,
      value: this.valueToJson(),
      id: this.target.id,
      name: this.target.name,
      writerId: this.target.writerId,
      interpolated: this.target.interpolated,
      netObjectId: this.target.netObjectId,
      roomId: this.target.roomId ?? 0,
    };
  }

  valueToJson(): ValueType | null {
    return this.target.value;
  }

  fromJson(data: SyncVariableSerialized<ValueType>) {
    this.target.id = data.id;
    if (data.roomId) {
      this.target.roomId = data.roomId;
    }
    this.target.writerId = data.writerId;
    this.target.name = data.name;
    this.target.netObjectId = data.netObjectId;
    this.target.interpolated = data.interpolated;
    this.valueFromJson(data.value);
  }

  valueFromJson(value: ValueType | null) {
    this.target.value = value;
  }
}
