import * as THREE from 'three';
import IKJoint from '../IKJoint';
import IKBaseConstraint from './IKBaseConstraint';
import IKChain from '../IKChain';

export type IKMinMaxRotationConstraintOrder = 'XYZ' | 'YZX' | 'ZXY' | 'XZY' | 'YXZ' | 'ZYX';

export type IKMinMaxRotationConstraintOptions = {
  order?: IKMinMaxRotationConstraintOrder;
  rotationMin: THREE.Vector3;
  rotationMax: THREE.Vector3;
};

export default class IKMinMaxRotationConstraint extends IKBaseConstraint {
  public order: IKMinMaxRotationConstraintOrder = 'XYZ';

  public rotationMin: THREE.Vector3;

  public rotationMax: THREE.Vector3;

  protected _vector = new THREE.Vector3();

  constructor(options: IKMinMaxRotationConstraintOptions) {
    super();
    this.order = options.order || this.order;
    this.rotationMin = options.rotationMin.clone();
    this.rotationMax = options.rotationMax.clone();
  }

  apply(joint: IKJoint, chain: IKChain): boolean {
    joint.bone.rotation.setFromVector3(
      this._vector.set(
        joint.bone.rotation.x,
        joint.bone.rotation.y,
        joint.bone.rotation.z,
      ).max(this.rotationMin).min(this.rotationMax), this.order,
    );
    return true;
  }
}
