import { LocaleSettingsInterface } from './LocaleSettingsInterface';
import { ShareButtonCodes } from '../../domain/ui/enum/ShareButtonCodes';
import LogoIcon from '../../app/assets/Loader/universeLogo.png';

export const RuLocalSettings: LocaleSettingsInterface = {
  mainSite: 'http://xn--80adja3ana1a6k.xn--80asehdb/',
  logoUrl: 'assets/ui/icons/logo-ru.png',
  poweredIcon: LogoIcon,
  poweredIconSecondary: LogoIcon,
  ui: {
    letterSpacing: 0.0,
    fontMain: {
      texture: 'assets/fonts/Roboto/Roboto.png',
      json: 'assets/fonts/Roboto/Roboto-msdf.json',
    },
    fontMainBold: {
      texture: 'assets/fonts/Roboto/Roboto-Bold.png',
      json: 'assets/fonts/Roboto/Roboto-Bold-msdf.json',
    },
    mainMenu: {
      captionFontSize: 21,
      shareLinks: [ShareButtonCodes.VK, ShareButtonCodes.TELEGRAM],
    },
    welcomeMenu: {
      VRControlsUrl: 'assets/ui/welcome/vr-ru.jpg',
    },
  },
};
