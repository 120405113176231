import * as Three from 'three';
import { System } from '../../engine/System';
import { ActionIconComponent } from '../components/ActionIcon.component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import { UIDocumentElementState, UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import AvatarHelpers from '../services/AvatarHelpers';

export class ActionIconSystem extends System {
  onUpdate(ts: number) {
    const components = this.app.componentManager.getComponentsByType(ActionIconComponent);
    components.forEach((component) => {
      this.handleActive(component);
      if (!component.enabled) return;
      this.handleRadius(component);
      this.handleLootAt(component);
    });
  }

  protected handleRadius(component: ActionIconComponent) {
    if (!component.showRadius) return;
    const head = AvatarHelpers.getHead(this.app);
    if (!head) return;
    const uiComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    if (!uiComponent.root) return;
    uiComponent.root.visible = component.entity.getWorldPosition(new Three.Vector3())
      .distanceTo(head.getWorldPosition(new Three.Vector3())) < component.showRadius;
  }

  protected handleLootAt(component: ActionIconComponent) {
    if (!component.lookToCamera) return;
    if (!this.app.camera) return;
    component.getUiElement().lookAt(this.app.camera.getWorldPosition(new Three.Vector3()));
  }

  protected handleActive(component: ActionIconComponent) {
    const uiComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const uiSystem = this.app.getSystemOrFail(UIDocumentSystem);
    if (uiSystem.isAnyDocumentHovered([uiComponent])) return;
    component.isActiveInCurrentFrame = uiComponent.elementStateDataList[component.uiId].state === UIDocumentElementState.Active;
  }
}
