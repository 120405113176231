import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

export const Send: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon"
      >
        <path
          id="Vector"
          d="M20.35 12.7L4.05 19.55C3.8 19.65 3.5625 19.6292 3.3375 19.4875C3.1125 19.3458 3 19.1417 3 18.875V5.12499C3 4.85833 3.1125 4.65416 3.3375 4.51249C3.5625 4.37083 3.8 4.34999 4.05 4.44999L20.35 11.3C20.65 11.4333 20.8 11.6667 20.8 12C20.8 12.3333 20.65 12.5667 20.35 12.7ZM4.5 17.675L18.1 12L4.5 6.24999V10.45L10.55 12L4.5 13.5V17.675Z"
        />
      </g>
    </svg>
  );
});

export default Send;
