import { engine, google } from '../../../generated';
import { VariablePayload } from './VariablePayload';
import IAny = google.protobuf.IAny;
import Any = google.protobuf.Any;

export type AnimatorVariableValueType = {
  actionName: string;
  parameters: number[];
};
export default class AnimatorVariablePayload extends VariablePayload<AnimatorVariableValueType> {
  public static payloadFromProto(payload: IAny): AnimatorVariableValueType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = engine.network.payloads.AnimatorVariablePayload.decode(payload.value);
    return {
      actionName: decoded.actionName,
      parameters: decoded.parameters,
    };
  }

  public payloadToProto(): Any | null {
    const payload = engine.network.payloads.AnimatorVariablePayload.create({
      actionName: this.target?.value?.actionName,
      parameters: Object.values(this.target?.value?.parameters ?? {}),
    });
    return Any.create({
      type_url: this.targetType,
      value: engine.network.payloads.AnimatorVariablePayload.encode(payload).finish(),
    });
  }
}
