import * as Three from 'three';
import { AdditionalPhonemeInfo } from '@inworld/web-sdk';
import { getVisemeData } from './PhonemDataToVisemeDataConverter';
import { DefaultVisemeDataOptions, VisemeDataOptions } from './types';

export function phonemesToMesh(
  phonemeData: AdditionalPhonemeInfo[],
  meshes: Three.Mesh[],
  startingIndex: number,
  delta: number,
  visemeOffset: number,
  opts: VisemeDataOptions = { ...DefaultVisemeDataOptions },
) {
  let visemeOffsetS = visemeOffset;
  if (phonemeData.length && startingIndex !== -1) {
    visemeOffsetS += delta;
    const data = getVisemeData(visemeOffsetS, phonemeData);

    if (!data) {
      return { offset: 0 };
    }
    for (let i = 0; i < opts.AMOUNT; i++) {
      meshes.forEach((smesh) => {
        smesh.morphTargetInfluences![startingIndex + i] = data[i];
      });
    }
  } else {
    meshes.forEach((smesh) => {
      smesh!.morphTargetInfluences![startingIndex] = 1; // then every other morph would be cancelled.
    });
    for (let i = 1; i < opts.AMOUNT; i++) {
      meshes.forEach((smesh) => {
        smesh!.morphTargetInfluences![startingIndex + i] = 0;
      });
    }
  }
  return { offset: visemeOffsetS };
}
