import * as Three from 'three';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
import { Component as EnginComponent, ComponentOptions } from '../../engine/Component';

export type FlyControlsComponentOptions = ComponentOptions & {
  data?: {
    camera: Three.Camera;
    domElement: HTMLElement;
  };
};

export default class FlyControlsComponent extends EnginComponent {
  public camera: Three.Camera | null = null;

  public controls: FlyControls | null = null;

  public enabled = false;

  static get code(): string {
    return 'fly_controls';
  }

  constructor(options: FlyControlsComponentOptions) {
    super(options);
    this.camera = options.data?.camera ?? null;
    if (this.camera) {
      this.controls = new FlyControls(this.camera, options.data?.domElement);
      this.controls.dragToLook = true;
      this.controls.movementSpeed = 5;
      this.controls.rollSpeed = Math.PI / 4;
      this.controls.autoForward = false;
    }
  }
}
