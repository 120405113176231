import * as Three from 'three';
import { AbstractScene } from './AbstractScene';
import { Application } from '../Application';

export type SceneManagerOptions = {
  app: Application;
};

export class SceneManager {
  protected app: Application;

  public currentScene: AbstractScene | null = null;

  public sceneIsLoaded = false;

  public sceneIsLoading = false;

  constructor(options: SceneManagerOptions) {
    this.app = options.app;
  }

  public get currentThreeScene(): Three.Scene | null {
    return this.currentScene?.threeScene || null;
  }

  public loadScene<D>(scene: AbstractScene, sceneData: D): Promise<void> {
    return this.destroyCurrentScene().then(() => {
      this.currentScene = scene;
      return scene.prepareConfig().then(() => {
        this.sceneIsLoading = true;
        return scene.load(this.app, sceneData)
          .then(() => {
            this.sceneIsLoaded = true;
          })
          .finally(() => {
            this.sceneIsLoading = false;
          });
      });
    });
  }

  public destroyCurrentScene(): Promise<void> {
    this.sceneIsLoaded = false;

    if (!this.currentScene) return Promise.resolve();

    return this.currentScene.destroy(this.app);
  }
}
