import { System } from '../../../engine/System';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import ActionsMenuComponent from '../../components/UI/ActionsMenu.component';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import { ContainerId } from '../../ui/enum/ContainerId';
import { ButtonId } from '../../ui/enum/ButtonId';
import { PlayerControlsComponent } from '../../components/PlayerControls.component';
import { InputSystem } from '../../../engine/systems/InputSystem';
import { PlayerControlsSystem } from '../PlayerControls.system';
import { ActionsMenuAdaptive } from '../../ui/adaptive';
import ShopifyMenuComponent from '../../components/UI/ShopifyMenu.component';
import { XRInputSystem } from '../../../engine/systems/XRInputSystem';
import CurrentFrameValueContainer from '../../services/CurrentFrameValueContainer';
import AvatarHelpers from '../../services/AvatarHelpers';
import BaseScene from '../../scenes/BaseScene';

export default class ActionsMenuSystem extends System {
  protected XButtonPressInCurrentFrame = new CurrentFrameValueContainer<string>('default', 'pressed');

  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(ActionsMenuComponent).forEach((component) => {
      this.handleInput();
      this.handleWelcomeMode(component);
      this.handleOverScreenMode(component);
      this.handleEnabled(component);
      if (!component.enabled) return;
      this.handleSeatButtonVisible(component);
      this.resetSelectedState(component);
      this.handleActiveButtons(component);
    });
  }

  public handleOverScreenMode(component: ActionsMenuComponent) {
    const overScreenComponents: (WelcomeScreenComponent | ShopifyMenuComponent)[] = [
      ...this.app.componentManager.getComponentsByType(WelcomeScreenComponent),
    ];
    overScreenComponents.push(...this.app.componentManager.getComponentsByType(ShopifyMenuComponent));
    const overScreenEnabled = overScreenComponents.some((cp) => cp.enabled);
    const adaptive = new ActionsMenuAdaptive(this.app);
    if (component.enabled && overScreenEnabled && adaptive.isMobile) component.enabled = false;
  }

  public handleEnabled(component: ActionsMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
    if (uiDocument.root) {
      uiDocument.enabled = component.enabled;
      uiDocument.root.visible = component.enabled;
    }
  }

  public handleSeatButtonVisible(component: ActionsMenuComponent) {
    const uiDoc = component.entity.getComponentOrFail(UIDocumentComponent);
    const welcomeComponent = this.app.componentManager.getComponentsByType(WelcomeScreenComponent)[0];
    const seatButton = uiDoc.getElementById(ButtonId.SeatButton);
    if (!seatButton) return;
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const controlsComponent = characterEntity.getComponentOrFail(PlayerControlsComponent);
    seatButton.visible = controlsComponent.sitIsActive || welcomeComponent.enabled;
  }

  public handleWelcomeMode(component: ActionsMenuComponent) {
    const welcomeComponent = this.app.componentManager.getComponentsByType(WelcomeScreenComponent)[0];
    if (!welcomeComponent) return;
    const uiDoc = component.entity.getComponentOrFail(UIDocumentComponent);
    const root = uiDoc.getElementById(ContainerId.RootContainer);
    const tooltips = uiDoc.getElementById(ContainerId.TooltipsContainer);
    const seatButton = uiDoc.getElementById(ButtonId.SeatButton);
    if (!root || !tooltips || !seatButton) return;
    const adaptive = new ActionsMenuAdaptive(this.app);

    component.enabled = !(welcomeComponent.enabled && adaptive.isMobile);

    if (welcomeComponent.enabled && component.enabled && !adaptive.isMobile) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      root.set({ backgroundOpacity: 0.9 });
      tooltips.visible = true;
      seatButton.visible = true;
    }
    if (!welcomeComponent.enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      root.set({ backgroundOpacity: 0.0 });
      tooltips.visible = false;
      seatButton.visible = false;
    }
    // component.enabled = !welcomeComponent.enabled;
  }

  public handleInput() {
    const characterEntity = AvatarHelpers.getCharacterEntity(this.app);
    if (!characterEntity) return;
    const controlsComponent = characterEntity.getComponentOrFail(PlayerControlsComponent);
    const xrSystem = this.app.getSystemOrFail(XRInputSystem);
    this.XButtonPressInCurrentFrame.update(xrSystem.getXButton().state);
    if (this.XButtonPressInCurrentFrame.checkValueInCurrentFrame()) {
      controlsComponent.danceIsActive = !controlsComponent.danceIsActive;
    }
  }

  public handleActiveButtons(component: ActionsMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const welcomeComponent = this.app.componentManager.getComponentsByType(WelcomeScreenComponent)[0];
    if (welcomeComponent.enabled) return;
    const states = uIDocumentComponent.elementStateDataList;
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const controlsComponent = characterEntity.getComponentOrFail(PlayerControlsComponent);
    const sprintButton = uIDocumentComponent.getElementById(ButtonId.SprintButton);
    const danceButton = uIDocumentComponent.getElementById(ButtonId.DanceButton);

    if (states[ButtonId.SeatButton] && states[ButtonId.SeatButton].state === UIDocumentElementState.Active) {
      // TODO: tmp solution
      const inputSystem = this.app.getSystemOrFail(InputSystem);
      inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame = true;
    }

    if (states[ButtonId.SprintButton]
      && states[ButtonId.SprintButton].state === UIDocumentElementState.Active
      && sprintButton
    ) {
      controlsComponent.sprintIsActive = !controlsComponent.sprintIsActive;
    }

    if (controlsComponent.sprintIsActive && sprintButton) {
      sprintButton.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }

    if (states[ButtonId.DanceButton]
      && states[ButtonId.DanceButton].state === UIDocumentElementState.Active
      && danceButton
    ) {
      controlsComponent.danceIsActive = !controlsComponent.danceIsActive;
    }

    if (controlsComponent.danceIsActive && danceButton) {
      danceButton.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }

    if (states[ButtonId.PlayerControllerSwitchButton]
      && states[ButtonId.PlayerControllerSwitchButton].state === UIDocumentElementState.Active
    ) {
      this.app.getSystemOrFail(PlayerControlsSystem).toggleCharacterController(controlsComponent);
    }
  }

  public resetSelectedState(component: ActionsMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);

    uiDocument.root?.traverse((element) => {
      if (element?.userData?.uiData?.defaultState) {
        element.userData.uiData.defaultState = UIDocumentElementState.Default;
      }
    });
  }
}
