import { System } from '../../engine/System';
import NetworkIKComponent from '../components/NetworkIK.component';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import VrmIKComponent from '../components/VrmIKComponent';
import IKVariable, { IKVariableValue } from '../network/variables/IKVariable';

export default class NetworkIKSystem extends System {
  onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(NetworkIKComponent).forEach((networkIKComponent) => {
      const netComponent = networkIKComponent.entity.getComponentFromParents(NetworkObjectComponent);
      if (!netComponent) return;
      if (!networkIKComponent.enabled) return;
      const variable = (
        networkIKComponent.variable
        || netComponent.netObject?.getVariableByName<IKVariable>(networkIKComponent.variableName)
      ) as IKVariable;
      if (!variable) return;

      const ikComponent = networkIKComponent.entity.getComponentFromParents(VrmIKComponent);
      if (ikComponent && networkIKComponent.isSourceType) {
        variable.set(networkIKComponent.getState(ikComponent.getAllBones(), ikComponent.enabled));
      }
      if (networkIKComponent.isTargetType && variable.value) {
        const value = variable.getInterpolationValue(
          this.app.networkManager ? this.app.networkManager.getShiftedTime(variable.required) : Date.now(),
          dt * 1000,
        );
        networkIKComponent.setState(value);
      }
    });
  }
}
