import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { System } from '../../../engine/System';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { PanelId } from '../../ui/enum/PanelId';
import { TPControllerComponent } from '../../components/TPController.component';
import { FPControllerComponent } from '../../components/FPController.component';
import { XRFPControllerComponent } from '../../components/XRFPController.component';
import { InputSystem } from '../../../engine/systems/InputSystem';
import VideoComponent from '../../components/VideoComponent';
import { XRInputSystem } from '../../../engine/systems/XRInputSystem';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import { TabsId } from '../../ui/enum/TabsId';
import BaseScene from '../../scenes/BaseScene';

export default class WelcomeScreenSystem extends System {
  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((component) => {
      component.enabledInCurrentFrame.update(component.enabled);
      this.handleActiveButtons(component);
      this.handleComponentEnabled(component);
      this.handleInput(component);
      this.handleVRModeEnabled(component);
    });
  }

  public handleInput(component: WelcomeScreenComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
    if (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame) {
      this.closeMenu(component);
    }
    if (xrInputSystem.getBButton().state === 'pressed') {
      this.openMenu(component);
    }
  }

  public handleActiveButtons(component: WelcomeScreenComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    if (
      states[ButtonId.CloseButton]?.state === UIDocumentElementState.Active
      || states[ButtonId.StartButton]?.state === UIDocumentElementState.Active
    ) {
      this.closeMenu(component);
      this.app.componentManager.getComponentsByType(VideoComponent).forEach((video) => {
        // if (video.source) video.setSource(video.source);
      });
    }
    if (states[ButtonId.Sound]?.state === UIDocumentElementState.Active) {
      console.log('sound');
    }
  }

  public handleVRModeEnabled(component: WelcomeScreenComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;

    const selectedElement = uIDocumentComponent.getElementById(this.app.renderer.xr.isPresenting ? TabsId.VR : TabsId.PC);
    if (selectedElement) {
      selectedElement.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }
    if (states[TabsId.PC]?.state === UIDocumentElementState.Active) {
      this.app.vrSession.endSession();
    }
    if (states[TabsId.VR]?.state === UIDocumentElementState.Active) {
      this.app.vrSession.startSession();
    }
  }

  public closeMenu(component: WelcomeScreenComponent) {
    // TODO: remove layers mask == optimize raycast
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = false;
    uIDocumentComponent.enabled = false;
  }

  public openMenu(component: WelcomeScreenComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = true;
    uIDocumentComponent.enabled = true;
  }

  public handleComponentEnabled(component: WelcomeScreenComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const panel = uIDocumentComponent.getElementById(PanelId.WelcomePanel);
    uIDocumentComponent.enabled = component.enabled;
    if (panel) {
      panel.visible = component.enabled;
    }
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const fpController = characterEntity.getComponentOrFail(FPControllerComponent);
    const tpController = characterEntity.getComponentOrFail(TPControllerComponent);
    const xrController = characterEntity.getComponentOrFail(XRFPControllerComponent);

    if (component.enabled) {
      this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((mainMenuComponent) => {
        mainMenuComponent.enabled = false;
      });
    }

    if (!component.enabledInCurrentFrame.changedInCurrentFrame()) return;

    if (tpController.isInitialized) {
      // tpController.enabled = !component.enabled;
      tpController.isBlocked = component.enabled;
    }
    if (fpController.isInitialized) {
      // fpController.enabled = !component.enabled;
      fpController.isBlocked = component.enabled;
    }
    if (xrController.isInitialized) {
      xrController.isBlocked = component.enabled;
    }
  }
}
