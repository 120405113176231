import { Object3D } from 'three/src/core/Object3D';
import * as Three from 'three';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import NetworkTransformComponent, {
  NetworkTransformComponentTypes,
} from '../../../engine/components/NetworkTransform.component';
import { Application } from '../../../engine/Application';
import NetworkManager from '../../../engine/network/NetworkManager';
import TransformVariable from '../../../engine/network/variables/TransformVariable';
import { Entity } from '../../../engine/Entity';
import SystemObject from '../../../engine/network/SystemObject';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import {
  AnimationFilterOptions,
  AnimationFilterType,
  AnimatorComponent,
} from '../../../engine/components/Animator.component';
import { TPControllerComponent } from '../../components/TPController.component';
import { FPControllerComponent } from '../../components/FPController.component';
import { XRFPControllerComponent } from '../../components/XRFPController.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import { PlayerControlsComponent } from '../../components/PlayerControls.component';
import {
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
} from '../../../engine/components/RigidBody.component';
import { PrimitiveComponent } from '../../../engine/components/Primitive.component';
import RaycastComponent from '../../components/Raycast.component';
import AnimatorVariable from '../../../engine/network/variables/AnimatorVariable';
import NetworkAnimatorComponent, {
  NetworkAnimatorComponentTypes,
} from '../../../engine/components/NetworkAnimator.component';
import AvatarComponent from '../../components/Avatar.component';
import NetworkIKComponent, { NetworkIKComponentTypes } from '../../components/NetworkIK.component';
import { defaultIKConfig } from '../../services/VrmIK/DefaultVrmConfig';
import VrmIKComponent from '../../components/VrmIKComponent';
import { UIBuilderSystem } from '../../systems/UIBuilder.system';
import FlyControlsComponent from '../../components/FlyControls.component';
import { CameraComponent } from '../../../engine/components/Camera.component';
import MeshLoaderComponent from '../../components/MeshLoader.component';
import { AvatarAssetsType } from '../../assets/types';

export default class CharacterObject extends SystemObject {
  public static type = 'character';

  public static avatarAssets: AvatarAssetsType | null = null;

  static register(manager: NetworkManager) {
    manager.objectsTypes[CharacterObject.type] = CharacterObject;
  }

  static buildNetworkObject(app: Application): CharacterObject | null {
    if (!app.networkManager) {
      return null;
    }
    const netObj = app.networkManager?.buildNetObject<CharacterObject>(CharacterObject.type);
    netObj.app = app;
    netObj.addVariables();
    return netObj;
  }

  static setAssetsData(data: AvatarAssetsType) {
    CharacterObject.avatarAssets = data;
  }

  public get avatarsData() {
    return CharacterObject.avatarAssets;
  }

  protected subscribeToSessionUpdate() {
    this.manager.sessionStore.usersVariable?.events.on('anyUpdate', () => {
      const { avatarEntity } = this;
      const user = this.manager.sessionStore.getUser(this.ownerId);
      if (!avatarEntity || !user?.avatarName) return;
      const localAvatarName = avatarEntity.getComponentOrFail(AvatarComponent).avatarName;
      if (user.avatarName !== localAvatarName) this.changeAvatar(user.avatarName);
    });
  }

  public get avatarEntity(): Entity | undefined {
    const { component } = this;
    return component?.entity.children[0] as Entity;
  }

  public get avatarName(): string {
    return this.manager.sessionStore.getUser(this.ownerId)?.avatarName ?? '';
  }

  public set avatarName(name: string) {
    const user = this.manager.sessionStore.getUser(this.ownerId);
    if (!user) return;
    user.avatarName = name;
    this.manager.sessionStore.tryToAddUser(user);
  }

  public addVariables() {
    this.addVariable(new TransformVariable());
    this.addVariable(new AnimatorVariable());
    // this.addVariable(new IKVariable());
  }

  public get component(): NetworkObjectComponent | undefined {
    return this.app?.componentManager.getComponentsByType(NetworkObjectComponent).find((component) => {
      return component.netObject === this;
    });
  }

  static changeModel(avatarEntity: Entity, avatarName: string) {
    return new Promise((resolve) => {
      if (!this.avatarAssets) return resolve(null);
      const loader = avatarEntity.getObjectByName('loader') as Entity;
      if (loader) loader.getComponentOrFail(MeshLoaderComponent).enabled = true;
      const charEntity = avatarEntity.parent as Entity;
      const xrControls = charEntity.getComponent(XRFPControllerComponent);
      const fpControls = charEntity.getComponent(FPControllerComponent);
      if (fpControls) fpControls.isBlocked = true;
      if (xrControls) xrControls.isBlocked = true;
      const avatarConfig = this.avatarAssets.avatars[avatarName];
      const animator = avatarEntity.getComponentOrFail(AnimatorComponent);
      const meshComponent = avatarEntity.getComponentOrFail(MeshRendererComponent);
      meshComponent.clear();
      animator.cleanAnimation();
      animator.animationSourcesData = CharacterObject.getAvatarAnimationSources(avatarName);
      meshComponent.changeSource({
        url: this.avatarAssets.avatarsModels[avatarConfig.model],
        type: AssetSourceType.VRM,
      });
      new Promise<Three.Object3D>((loaded) => {
        meshComponent.events.once('contentAdded', ({ content }) => {
          content.visible = false;
          loaded(content);
        });
      })
        .then((content) => { return animator.initAnimationSource().then(() => content); })
        .then((content) => {
          content.visible = true;
          meshComponent.getMeshes().forEach((mesh) => {
            mesh.castShadow = true;
          });
          if (xrControls) xrControls.isBlocked = false;
          if (fpControls) fpControls.isBlocked = false;
          const ikComponent = avatarEntity.getComponent(VrmIKComponent);
          if (ikComponent) {
            ikComponent.setVrm(meshComponent.getVRM());
            ikComponent.attached = false;
          }
          // save local change name (can be compared with network name)
          avatarEntity.getComponentOrFail(AvatarComponent).avatarName = avatarName;
          // this trigger changes in network
          const netObject = charEntity.getComponent(NetworkObjectComponent)?.netObject as CharacterObject;
          if (netObject && netObject.avatarName !== avatarName) {
            netObject.avatarName = avatarName;
          }
          resolve(content);
          if (loader) loader.getComponentOrFail(MeshLoaderComponent).enabled = false;
        });
    });
  }

  public changeAvatar(name: string) {
    if (!this.avatarEntity) return;
    return CharacterObject.changeModel(this.avatarEntity, name);
  }

  public attachToEntity(
    entity: Entity,
    avatarEntity: Entity,
    transformType = NetworkTransformComponentTypes.Source,
    animatorType = NetworkAnimatorComponentTypes.Source,
    ikType: NetworkIKComponentTypes = NetworkIKComponentTypes.Source,
  ) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
      // special fo reconnect
      removeTimeout: 100,
    });
    entity.addComponent(NetworkTransformComponent, {
      variableName: 'transform',
      type: transformType,
    });
    entity.addComponent(NetworkAnimatorComponent, {
      variableName: 'animator',
      type: animatorType,
      avatarEntity,
    });
    avatarEntity.addComponent(NetworkIKComponent, {
      variableName: 'ik',
      type: ikType,
    });

    this.subscribeToSessionUpdate();
  }

  static createCharacterEntity(app: Application) {
    const characterEntity = app.entityManager.makeEntity();
    // window.c = characterEntity;
    characterEntity.rotation.order = 'YXZ';
    characterEntity.rotation.y = Math.PI / 2;
    characterEntity.position.set(0, 1, 0);
    characterEntity.name = 'CharacterEntity';
    return characterEntity;
  }

  static getAvatarAnimationSources(avatarName = this.avatarAssets?.defaultAvatar) {
    if (!avatarName || !this.avatarAssets) return [];
    const avatarConfig = this.avatarAssets.avatars[avatarName];
    const defaultSources = { ...this.avatarAssets.avatarAnimations.default };
    const avatarSources = { ...this.avatarAssets.avatarAnimations[avatarConfig.animations] };
    Object.keys(avatarSources).forEach((name) => {
      defaultSources[name] = { ...avatarSources[name] };
    });
    return Object.values(defaultSources);
  }

  static buildAvatarEntity(
    app: Application,
    avatarName: string,
    isGhost = false,
  ): [Entity, Promise<void>] {
    if (!this.avatarAssets) throw Error('CharacterObject: no avatars assets');
    const avatarEntity = app.entityManager.makeEntity();
    avatarEntity.name = 'avatarEntity';
    const avatarConfig = this.avatarAssets.avatars[avatarName];

    const loaderDummy = app.componentManager.getComponentsByType(MeshLoaderComponent)[0];
    if (loaderDummy) {
      const loaderComponent = loaderDummy.clone();
      loaderComponent.entity.name = 'loader';
      avatarEntity.add(loaderComponent.entity);
      loaderComponent.entity.position.setY(1.2);
      loaderComponent.entity.scale.set(0.6, 0.6, 0.6);
    }

    const avatarComponent = avatarEntity.addComponent(AvatarComponent);
    avatarComponent.avatarName = avatarName;
    avatarComponent.isGhost = isGhost;
    avatarComponent.idleAnimation = 'idle';
    avatarComponent.walkAnimations = ['walk'];

    const meshComponent = avatarEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.VRM,
        url: this.avatarAssets.avatarsModels[avatarConfig.model],
      },
      needFirstPersonSetup: !isGhost,
    });

    let IKComponent: VrmIKComponent;
    const promise = new Promise<void>((resolve) => {
      meshComponent.events.once('contentAdded', () => {
        meshComponent.getMeshes().forEach((mesh) => {
          mesh.castShadow = true;
        });

        const vrm = meshComponent.getVRM();

        if (!vrm || !vrm.humanoid) return new Error('VRM not loaded properly');
        vrm.scene.traverse((mesh) => { mesh.frustumCulled = false; });

        // TODO: choose system
        const ikConfig = { ...defaultIKConfig };
        // const ikSystem = ikConfig.system;
        const bones: Three.Bone[] = [];
        ikConfig.ikSimpleChainConfigs.forEach((chain) => chain.jointConfigs.forEach((joint) => {
          const bone = vrm.humanoid?.getRawBoneNode(joint.boneName);
          if (bone) bones.push(bone as Three.Bone);
        }));

        const skeletonRotations: Record<string, Three.Quaternion> = {};

        IKComponent = avatarEntity.addComponent(VrmIKComponent, { vrm, ikConfig });
        IKComponent.disable();

        const animationFilters: AnimationFilterOptions[] = [{
          name: 'armRotation',
          type: AnimationFilterType.Rotation,
          enabled: true,
          rotation: skeletonRotations,
        }];
        animationFilters.push({
          name: 'ik',
          type: AnimationFilterType.Bones,
          bones, // IKComponent.getAllBones(),
          enabled: false,
          bindings: {
            enabled: 'ikFilterEnabled',
          },
        });

        const avatarWalkDuration = this.avatarAssets?.avatarWalkDuration ?? 0;

        const walkClipData = (name: string) => [
          {
            name,
            clipName: name,
            // resizeTo: avatarWalkDuration,
            startAt: avatarWalkDuration / 2,
            bindings: {
              activeWeight: 'forwardWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'back',
            clipName: 'back',
            // resizeTo: avatarWalkDuration,
            startAt: avatarWalkDuration / 2,
            bindings: {
              activeWeight: 'backwardWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'left',
            clipName: 'left',
            // resizeTo: avatarWalkDuration,
            startAt: avatarWalkDuration / 2,
            bindings: {
              activeWeight: 'leftStrafeWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'right',
            clipName: 'right',
            // resizeTo: avatarWalkDuration,
            bindings: {
              activeWeight: 'rightStrafeWeight',
              speedMultiplier: 'speed',
            },
          },
          {
            name: 'leftBack',
            clipName: 'left',
            // resizeTo: avatarWalkDuration,
            startAt: avatarWalkDuration / 2,
            bindings: {
              activeWeight: 'leftBackStrafeWeight',
              speedMultiplier: 'backStrafeSpeed',
            },
          },
          {
            name: 'rightBack',
            clipName: 'right',
            // resizeTo: avatarWalkDuration,
            bindings: {
              activeWeight: 'rightBackStrafeWeight',
              speedMultiplier: 'backStrafeSpeed',
            },
          },
        ];

        const animator = avatarEntity.addComponent(AnimatorComponent, {
          initialActionName: 'idle',
          animationSources: CharacterObject.getAvatarAnimationSources(avatarName),
          animationFilters,
          actions: [
            {
              name: 'walk',
              filters: ['ik', 'armRotation'],
              clipsData: walkClipData('walk'),
            },
            // {
            //   name: 'run',
            //   filters: ['ik', 'armRotation'],
            //   clipsData: walkClipData('run'),
            // },
            {
              name: 'idle',
              filters: ['ik', 'armRotation'],
              clipsData: [{
                name: 'idle',
                clipName: 'idle',
              }],
            },
            {
              name: 'sit',
              filters: ['ik', 'armRotation'],
              clipsData: [{
                name: 'sit',
                clipName: 'sit',
                bindings: {
                  activeWeight: 'sitWeight',
                },
              }],
            },
            {
              name: 'dance',
              filters: ['ik', 'armRotation'],
              clipsData: [{
                name: 'dance',
                clipName: 'dance',
                bindings: {
                  activeWeight: 'danceWeight',
                },
              }],
            },
          ],
          parameters: {
            speed: 1,
            backStrafeSpeed: 1,
            forwardWeight: 1,
            backwardWeight: 1,
            leftStrafeWeight: 1,
            rightStrafeWeight: 1,
            leftBackStrafeWeight: 1,
            rightBackStrafeWeight: 1,
            sitWeight: 1,
            danceWeight: 1,
            runWeight: 1,
            ikFilterEnabled: 1,
          },
        });
        // animator.enabled = false;

        animator.events.once('actionsCreated', () => resolve());
      });
    });

    avatarEntity.position.y = -1;

    return [avatarEntity, promise];
  }

  static buildEntity(app: Application, characterEntity: Entity, cameraEntity: Entity) {
    if (!this.avatarAssets) throw Error('CharacterObject: no avatars assets');
    const user = app.networkManager?.sessionStore.getUser(app.networkManager?.networkId);
    const initAvatar = user?.avatarName || this.avatarAssets.defaultAvatar;
    const [avatarEntity, promise] = CharacterObject.buildAvatarEntity(app, initAvatar, false);
    // avatarEntity.rotation.y = Math.PI / 2;
    const tpControllerComponent = characterEntity.addComponent(TPControllerComponent);
    tpControllerComponent.cameraEntity = cameraEntity;
    tpControllerComponent.avatarEntity = avatarEntity;
    tpControllerComponent.collisionEntity = app.sceneManager.currentThreeScene?.getObjectByName('spaceCollider') as Entity;

    tpControllerComponent.lookAtEntity = app.entityManager.makeEntity();
    tpControllerComponent.lookAtEntity.position.y = 0.72;
    tpControllerComponent.lookAtEntity.position.z = -0.05;
    const { width, height } = app.renderer.getSize(new Three.Vector2());
    tpControllerComponent.lookAtEntity.position.x = width > height ? 0.72 : 0.3;

    const fpControllerComponent = characterEntity.addComponent(FPControllerComponent);
    fpControllerComponent.cameraEntity = cameraEntity;
    fpControllerComponent.avatarEntity = avatarEntity;
    fpControllerComponent.enabled = false;

    const xRFPControllerComponent = characterEntity.addComponent(XRFPControllerComponent);
    xRFPControllerComponent.cameraEntity = cameraEntity;
    xRFPControllerComponent.avatarEntity = avatarEntity;
    xRFPControllerComponent.enabled = false;
    characterEntity.addComponent(PlayerControlsComponent);
    characterEntity.add(avatarEntity);

    characterEntity.addComponent(
      FlyControlsComponent,
      {
        camera: cameraEntity.getComponentOrFail(CameraComponent).threeCamera,
        domElement: app.renderer.domElement,
      },
    );

    // characterEntity.addComponent(GenderComponent);
    characterEntity.add(tpControllerComponent.lookAtEntity);
    characterEntity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.Capsule,
        radius: 0.3,
        height: 1.4,
      },
    });
    characterEntity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 1,
      activationState: RigidBodyActivationState.AlwaysActive,
      // mask: CollisionFilters.StaticFilter,
      // group: CollisionFilters.CharacterFilter,
    }).applyEntityWorldMatrix();

    // add network
    const netObject = CharacterObject.buildNetworkObject(app);
    if (netObject) {
      if (netObject.avatarName !== initAvatar) netObject.avatarName = initAvatar;
      netObject.isNeedSpawn = false;
      netObject.attachToEntity(characterEntity, avatarEntity);
    }

    return promise;
  }

  public spawnEntity(): Object3D | undefined {
    if (!this.app) return;
    if (!this.avatarsData) return;
    if (!this.getVariableByName('animator')) return;
    const transformVariable = this.getVariableByName<TransformVariable>('transform');
    if (!transformVariable || (transformVariable.value?.position.y || 0) < 0.2) return;
    const entity = this.app.entityManager.makeEntity();
    const avatarName = this.avatarName || this.avatarsData.defaultAvatar;
    const [avatarEntity, promise] = CharacterObject.buildAvatarEntity(this.app, avatarName, true);
    const meshComponent = avatarEntity.getComponentOrFail(MeshRendererComponent);
    meshComponent.enabled = false;
    entity.add(avatarEntity);
    const primitiveComponent = entity.addComponent(PrimitiveComponent);
    entity.addComponent(RaycastComponent, { target: primitiveComponent.data });
    this.app.getSystemOrFail(UIBuilderSystem).setupAvatarNamePanel(entity).then((nameEntity) => {
    });
    // FIXME: T-pose to idle visible
    entity.visible = false;

    promise.then(() => {
      setTimeout(() => {
        entity.visible = true;
        meshComponent.enabled = true;
      }, 1000);
    });

    this.attachToEntity(
      entity,
      avatarEntity,
      NetworkTransformComponentTypes.Target,
      NetworkAnimatorComponentTypes.Target,
      // NetworkIKComponentTypes.Target,
    );
    entity.position.set(0, 1, 0);

    return entity;
  }
}
