import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';

export type SelectedObjectComponentOptions = ComponentOptions & {
  data?: {
    distance?: number;
    hoverColor?: Three.Color;
    defaultColor?: Three.Color;
  };
};

export default class SelectedObjectComponent extends EngineComponent {
  public isActive = false;

  public distance = 20;

  public hoverColor = new Three.Color();

  public defaultColor = new Three.Color();

  constructor(options: SelectedObjectComponentOptions) {
    super(options);
    this.distance = options.data?.distance ?? this.distance;
    this.hoverColor = options.data?.hoverColor ?? this.hoverColor;
    this.defaultColor = options.data?.defaultColor ?? ('material' in this.entity ? (this.entity.material as Three.MeshBasicMaterial).color : this.defaultColor);
  }

  static get code(): string {
    return 'selected_object';
  }
}
