import SpawnService from '../services/Spawn.service';
import { AbstractSpaceGenerator } from '../services/SpaceZoneGenerator/AbstractSpaceGenerator';
import BaseScene from './BaseScene';
import { Application } from '../../engine/Application';
import ApiSpaceGenerator from '../services/SpaceZoneGenerator/ApiSpaceGenerator';

export type ApiControlledSceneOptions = {
  configUrl: string;
};

export class ApiControllerScene extends BaseScene<ApiControlledSceneOptions> {
  private generator?: ApiSpaceGenerator;

  private readonly sceneConfigUrl: string;

  constructor(name = '', options?: ApiControlledSceneOptions) {
    super(name, options);
    if (!options?.configUrl) {
      throw new Error('No scene config url found');
    }
    this.sceneConfigUrl = options.configUrl;
  }

  public getSpaceGeneratorService(app: Application): AbstractSpaceGenerator {
    if (!this.generator) {
      this.generator = new ApiSpaceGenerator(this.sceneConfigUrl, { app, spawnService: this.spawnService });
    }
    return this.generator;
  }

  public getSpawnService(app: Application): SpawnService {
    return new SpawnService({ app });
  }

  public isSame(name: string, options?: ApiControlledSceneOptions): boolean {
    return this.name === name && this.options?.configUrl === options?.configUrl;
  }
}
