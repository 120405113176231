import * as Three from 'three';
import { System } from '../../engine/System';
import { TeleportComponent } from '../components/Teleport.component';
import { TPControllerComponent } from '../components/TPController.component';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { FPControllerComponent } from '../components/FPController.component';
import { XRFPControllerComponent } from '../components/XRFPController.component';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import BaseScene, { BaseSceneLoadData } from '../scenes/BaseScene';
import ScenesRegistry from '../scenes/ScenesRegistry';
import { InputSystem } from '../../engine/systems/InputSystem';
import { ColliderComponent } from '../../engine/components/Collider.component';

export class TeleportSystem extends System {
  static get code(): string {
    return 'teleport';
  }

  onUpdate() {
    // this.handleInput();
    this.componentManager.getComponentsByType(TeleportComponent).forEach((teleportComponent) => {
      if (!this.app.sceneManager.sceneIsLoaded) return;

      this.handleTriggerPosition(teleportComponent);
      this.handleTriggerCollider(teleportComponent);

      if (teleportComponent.activatedInCurrentFrame) {
        teleportComponent.activatedInCurrentFrame = false;
        this.updateNetwork(teleportComponent);
        if (!teleportComponent.isActive) {
          this.deinitControllers(teleportComponent);
        }
      }

      if (teleportComponent.isActive) {
        teleportComponent.isLoading = true;
        this.teleportTo(teleportComponent).then(() => {
          teleportComponent.isLoading = false;
        });
        teleportComponent.isActive = false;
        teleportComponent.activatedInCurrentFrame = true;
      }
    });
  }

  protected handleTriggerPosition(component: TeleportComponent) {
    if (!component.triggerPosition) return;
    if (component.isActive) return;
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const distance = component.triggerPosition.distanceTo(characterEntity.getWorldPosition(new Three.Vector3()));
    // console.log(distance);
    if (distance < component.triggerPositionDistance) { // TODO: move 1 to triggerDistance parameter in component
      component.isActive = true;
    }
  }

  protected handleTriggerCollider(component: TeleportComponent) {
    if (!this.app.sceneManager.sceneIsLoaded) return;
    if (component.isActive) return;
    const colliderComponent = component.entity.getComponent(ColliderComponent);
    if (!colliderComponent) return;
    const overlaps = colliderComponent.getOverlaps();
    const characterEntityOverlapped = overlaps.find((overlap) => {
      // todo: bad search method, think about it
      return overlap.colliderComponent.entity.getComponent(TPControllerComponent);
    });
    if (characterEntityOverlapped) {
      component.isActive = true;
    }
  }

  protected handleInput() {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    const keysNamesMap: Record<string, string> = {
      Digit3: 'pitchSpace',
      Digit4: 'ownSpace',
    };
    Object.keys(keysNamesMap).forEach((key) => {
      if (inputSystem.keyboard.getKeyByCode(key).wasPressedThisFrame) {
        const entity = this.app.entityManager.makeEntity();
        // spawn point of scene?
        entity.position.set(0, 1, 0);
        const teleport = entity.addComponent(TeleportComponent, {
          spaceName: keysNamesMap[key],
        });
        teleport.isActive = true;
      }
    });
  }

  private buildTeleportClone(component: TeleportComponent): TeleportComponent {
    const entity = this.app.entityManager.makeEntity();
    // spawn point of scene?
    entity.position.copy(component.entity.position);
    return entity.addComponent(TeleportComponent, {
      placeId: component.placeId,
      toggleRigidAfterTeleport: component.toggleRigidAfterTeleport,
    });
  }

  teleportTo(component: TeleportComponent) {
    const { currentScene } = this.app.sceneManager;
    if (!currentScene) return Promise.resolve();
    const { spawnService } = currentScene as BaseScene;
    if (!spawnService) return Promise.resolve();
    return this.loadScene(component).then((isNewSceneLoaded) => {
      let teleportComponent = component;
      if (isNewSceneLoaded) {
        const findTeleportByPlace = this.app.componentManager
          .getComponentsByType(TeleportComponent).find((cm) => cm.placeId === component.placeId);
        teleportComponent = findTeleportByPlace ?? this.buildTeleportClone(component);
      }
      // maybe need named character entity
      this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerComponent) => {
        const transform = spawnService.getEntityTransform(teleportComponent.entity);
        const characterEntity = playerComponent.entity;
        spawnService.setTransform(characterEntity, transform);
        const rb = characterEntity.getComponentOrFail(RigidBodyComponent);
        if (teleportComponent.toggleRigidAfterTeleport) {
          rb.enable();
        }
        rb.applyEntityWorldMatrix();
        this.deinitControllers(component);

        // TODO: think about it
        setTimeout(() => {
          this.activateControllers(teleportComponent);
        }, 1000);
      });
    });
  }

  protected loadScene(component: TeleportComponent) {
    if (!component.spaceName) return Promise.resolve(false);
    const SceneClass = ScenesRegistry.getScene(component.spaceName);
    if (!SceneClass) throw Error('Scene not found');
    if ((this.app.sceneManager.currentScene as BaseScene).isSame(component.spaceName, component.sceneParameters)) {
      return Promise.resolve(false);
    }
    const scene = new SceneClass(component.spaceName, component.sceneParameters);
    // scene.userInteractionComplete = true;
    // scene.userInteractionComplete = (this.app.sceneManager.currentScene as BaseScene).userInteractionComplete;
    scene.createSpaceGeneratorService(this.app);
    return this.app.sceneManager.loadScene<BaseSceneLoadData>(
      scene, { initSpace: false, inVR: this.app.isInVR },
    ).then(() => true);
  }

  protected updateNetwork(component: TeleportComponent) {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const networkObject = characterEntity.getComponent(NetworkObjectComponent)?.netObject;
    if (networkObject) {
      networkObject.broadcastVariables([], true);
    }
  }

  private deinitControllers(component: TeleportComponent) {
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerComponent) => {
      const characterEntity = playerComponent.entity;
      const fp = characterEntity.getComponentOrFail(FPControllerComponent);
      const tp = characterEntity.getComponentOrFail(TPControllerComponent);
      const xr = characterEntity.getComponentOrFail(XRFPControllerComponent);
      fp.disableRigidOnIdle = !component.toggleRigidAfterTeleport;
      fp.isInitialized = false;
      tp.disableRigidOnIdle = !component.toggleRigidAfterTeleport;
      tp.isInitialized = false;
      xr.disableRigidOnIdle = !component.toggleRigidAfterTeleport;
      xr.isInitialized = false;
    });
  }

  private activateControllers(component: TeleportComponent) {
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerComponent) => {
      const characterEntity = playerComponent.entity;
      const fp = characterEntity.getComponentOrFail(FPControllerComponent);
      const tp = characterEntity.getComponentOrFail(TPControllerComponent);
      const xr = characterEntity.getComponentOrFail(XRFPControllerComponent);
      fp.disableRigidOnIdle = true;
      tp.disableRigidOnIdle = true;
      xr.disableRigidOnIdle = true;
    });
  }
}
