import ThreeMeshUI from 'three-mesh-ui';
import { normalizeColor } from '../helpers';

export type TextParams = {
  fontName: string;
  content: string;
  color?: string;
  fontOpacity?: number;
  fontSize?: number;
  fontKerning?: number;
  letterSpacing?: number;
  hiddenOverflow?: boolean;
  whiteSpace?: string;
};
export function Text(params: TextParams) {
  return new ThreeMeshUI.Text({
    fontFamily: params.fontName,
    fontTexture: params.fontName,
    content: params.content,
    fontColor: normalizeColor(params.color ?? '#FFFFFF'),
    fontOpacity: params.fontOpacity ?? 1,
    fontSize: params.fontSize ?? 24,
    fontKerning: params.fontKerning,
    letterSpacing: params.letterSpacing,
    hiddenOverflow: params.hiddenOverflow,
    whiteSpace: params.whiteSpace,
  });
}
