import ThreeMeshUI from 'three-mesh-ui';
import { TextParams, Text } from './Text';
import { Container, ContainerParams } from './Container';

export type TextBlockParams = {
  text: TextParams;
  container: ContainerParams;
};
export function TextBlock(params: TextBlockParams): ThreeMeshUI.Block {
  return Container(params.container, [
    Text(params.text),
  ]);
}
