import * as Three from 'three';
import IKJoint from './IKJoint';

export type IKChainOptions = {
  name: string;
};

export default class IKChain {
  public name: string;

  public isIKChain = true;

  public joints: IKJoint[] = [];

  public target: Three.Object3D | null = null;

  public camera: Three.Object3D | null = null;

  public effector: IKJoint | null = null;

  constructor(options: IKChainOptions) {
    this.name = options.name;
  }

  setTarget(target: Three.Object3D) {
    this.target = target;
  }

  setCamera(camera: Three.Object3D) {
    this.camera = camera;
  }

  addEffector(joint: IKJoint, target: Three.Object3D | null = null) {
    if (target) this.target = target;
    this.addJoint(joint);
    this.effector = joint;
    return this;
  }

  addJoint(joint: IKJoint) {
    this.joints.push(joint);
    return this;
  }
}
