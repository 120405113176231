import * as Three from 'three';

export async function JsonAnimationLoader(url: string) {
  const response = await fetch(url);
  const json = await response.json();
  let animationClip: Three.AnimationClip | undefined;
  const jsonAnimation = json[0];
  const tracks = jsonAnimation.tracks.filter((track: Three.KeyframeTrack) => {
    // return track;
    const name = track.name.toLowerCase();
    return !name.includes('.position')
      && !name.includes('index')
      && !name.includes('middle')
      && !name.includes('pinky')
      && !name.includes('ring')
      && !name.includes('thumb');
  });
  if (tracks.length > 0) {
    jsonAnimation.tracks = tracks;
    animationClip = Three.AnimationClip.parse(jsonAnimation);
  }
  return animationClip;
}
