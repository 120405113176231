import * as Three from 'three';

export function getMorphIndex(morphName: string, mesh: Three.Mesh) {
  let nResult = -1;
  if (mesh) {
    for (let i = 0; i < mesh?.userData.targetNames.length; i++) {
      if (mesh?.userData.targetNames[i] === morphName) {
        nResult = i;
        break;
      }
    }
  }
  return nResult;
}
