import { Component, ComponentOptions } from '../../../engine/Component';

export type PlaceMenuItemComponentOptions = ComponentOptions & {
  data: {
    placeId: string;
    text: string;
    imageUrl: string;
    sortOrder: number;
  };
};

/**
 * Component for one item of Places menu
 *
 * Every such component attached to any entity will be displayed as sing menu item in Places menu. It will force
 * an avatar to teleport to object on scene with ID `placeId`
 */
export default class PlaceMenuItemComponent extends Component {
  /**
   * ID of object ot teleport to
   */
  public placeId: string;

  /**
   * Menu item text
   */
  public text: string;

  /**
   * Menu item icon url
   */
  public imageUrl: string;

  /**
   * Sorting order for menu item (lower means higher)
   */
  public sortOrder: number;

  static get code(): string {
    return 'place_menu_item';
  }

  constructor(options: PlaceMenuItemComponentOptions) {
    super(options);
    this.placeId = options.data.placeId;
    this.text = options.data.text;
    this.imageUrl = options.data.imageUrl;
    this.sortOrder = options.data.sortOrder;
  }
}
