import { AnimationSourceType as AnimatorSourceType } from '../../engine/components/Animator.component';
import { AvatarAnimations, AvatarList, AvatarsMenuListType, AvatarsModels } from './types';

export const avatarsIconBasePath = 'assets/avatars/icons';

export const defaultAvatarsMenu: AvatarsMenuListType = [
  { id: 'Avatar_yuki', imgUrl: `${avatarsIconBasePath}/yuki.png` },
  { id: 'Avatar_yuki_hacker', imgUrl: `${avatarsIconBasePath}/yuki_hacker.png` },
  { id: 'Avatar_mira', imgUrl: `${avatarsIconBasePath}/mira.png` },
  { id: 'Avatar_mira_space', imgUrl: `${avatarsIconBasePath}/mira_space.png` },
];

export const defaultAvatarsList: AvatarList = {
  yuki: {
    model: 'yuki',
    animations: 'yuki',
  },
  yuki_hacker: {
    model: 'yuki_hacker',
    animations: 'yuki',
  },
  mira: {
    model: 'mira',
    animations: 'mira',
  },
  mira_space: {
    model: 'mira_space',
    animations: 'mira',
  },
};

export const defaultAvatarName = 'yuki';

export const defaultAvatarsModels: AvatarsModels = {
  yuki: 'assets/avatars/VRM/yuki.vrm',
  yuki_hacker: 'assets/avatars/VRM/yuki_hacker.vrm',
  mira: 'assets/avatars/VRM/mira.vrm',
  mira_space: 'assets/avatars/VRM/mira_space.vrm',
};

export const defaultAvatarWalkDuration = 1.1833332777023315;

export const defaultAvatarAnimations: AvatarAnimations = {
  default: {
    walk: {
      url: 'assets/avatars/Animations/fbx/walk.fbx',
      clipName: 'walk',
      type: AnimatorSourceType.FBX,
    },
    run: {
      url: 'assets/avatars/Animations/fbx/walk.fbx',
      clipName: 'run',
      type: AnimatorSourceType.FBX,
    },
    idle: {
      url: 'assets/avatars/Animations/fbx/idle.fbx',
      clipName: 'idle',
      type: AnimatorSourceType.FBX,
    },
    back: {
      url: 'assets/avatars/Animations/glb/Back.glb',
      clipName: 'back',
      type: AnimatorSourceType.GLB,
    },
    left: {
      url: 'assets/avatars/Animations/glb/Left.glb',
      clipName: 'left',
      type: AnimatorSourceType.GLB,
    },
    right: {
      url: 'assets/avatars/Animations/glb/Right.glb',
      clipName: 'right',
      type: AnimatorSourceType.GLB,
    },
    sit: {
      url: 'assets/avatars/Animations/fbx/seat.fbx',
      clipName: 'sit',
      type: AnimatorSourceType.FBX,
    },
    dance: {
      url: 'assets/avatars/Animations/fbx/cheer.fbx',
      clipName: 'dance',
      type: AnimatorSourceType.FBX,
    },
  },
  yuki: {
    idle: {
      url: 'assets/avatars/Animations/glb/YukiIdle.glb',
      clipName: 'idle',
      type: AnimatorSourceType.GLB,
    },
    walk: {
      url: 'assets/avatars/Animations/glb/YukiWalk.glb',
      clipName: 'walk',
      type: AnimatorSourceType.GLB,
    },
    run: {
      url: 'assets/avatars/Animations/glb/YukiRun.glb',
      clipName: 'run',
      type: AnimatorSourceType.GLB,
    },
    dance: {
      url: 'assets/avatars/Animations/glb/YukiDance.glb',
      clipName: 'dance',
      type: AnimatorSourceType.GLB,
    },
    sit: {
      url: 'assets/avatars/Animations/glb/YukiSit.glb',
      clipName: 'sit',
      type: AnimatorSourceType.GLB,
    },
  },
  mira: {
    idle: {
      url: 'assets/avatars/Animations/glb/MiraIdle.glb',
      clipName: 'idle',
      type: AnimatorSourceType.GLB,
    },
    walk: {
      url: 'assets/avatars/Animations/glb/MiraWalk.glb',
      clipName: 'walk',
      type: AnimatorSourceType.GLB,
    },
    run: {
      url: 'assets/avatars/Animations/glb/MiraRun.glb',
      clipName: 'run',
      type: AnimatorSourceType.GLB,
    },
    dance: {
      url: 'assets/avatars/Animations/glb/MiraDance.glb',
      clipName: 'dance',
      type: AnimatorSourceType.GLB,
    },
    sit: {
      url: 'assets/avatars/Animations/glb/MiraSit.glb',
      clipName: 'sit',
      type: AnimatorSourceType.GLB,
    },
  },
};
