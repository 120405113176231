import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const SlashSound: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4011 12.0427L20.7235 12.6357L19.0293 14.3299C18.8122 15.3124 18.281 15.8263 19.3681 16.4476C19.5375 16.5323 19.707 16.617 19.9611 16.617C20.3846 16.617 20.8082 16.3629 20.9776 16.024C21.6928 14.3299 21.5471 13.5028 21.4011 12.0427Z"
        fill={fill}
      />
      <path
        d="M22.4195 13.4829C22.4195 12.8736 22.3324 12.265 22.1588 11.6575L22.1051 11.4957L23.633 9.96777L23.785 10.3224C24.1196 11.4115 24.3688 12.4947 24.4525 13.5741C24.4508 15.8974 23.5378 18.0546 21.8761 19.7163C21.7631 19.8294 21.6555 19.8995 21.5433 19.9427C21.4305 19.9861 21.3041 20.0055 21.1488 20.0055C20.9936 20.0055 20.8672 19.9861 20.7544 19.9427C20.6422 19.8995 20.5346 19.8294 20.4216 19.7163C19.9871 19.2818 20.0563 18.627 20.4216 18.2617C21.7233 16.96 22.4195 15.3077 22.4195 13.4829Z"
        fill={fill}
      />
      <path
        d="M14.9633 5.60496C14.9633 5.60496 14.9636 5.60496 14.9636 5.77438L14.9633 5.60496Z"
        fill={fill}
      />
      <path
        d="M15.133 5.77438L15.1332 5.60496C15.1332 5.60496 15.133 5.60496 15.133 5.77438Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2198 4.28471L13.1396 4.3649L8.94234 9.24744H7.67864C5.82391 9.24744 4.375 10.6963 4.375 12.5511V14.4994C4.375 16.3541 5.82391 17.803 7.67864 17.803H8.94927L9.34239 18.3272L5.59601 22.0736C5.15392 22.5156 5.15392 23.1708 5.59601 23.6128C6.0381 24.0549 6.6935 24.0546 7.13559 23.6125L24.4951 6.33774C24.8598 5.89354 24.8598 5.14702 24.4951 4.70281C24.0531 4.26617 23.4016 4.26799 22.9613 4.70825L17.5896 10.08V6.11322C17.5896 5.08783 16.9594 4.20865 15.989 3.72348C15.0521 3.255 13.9429 3.56161 13.2198 4.28471ZM10.0684 16.1846L10.0807 16.2092L10.8129 16.9414L15.5566 12.1977V6.11322C15.5566 6.05652 15.5494 6.00719 15.5355 5.96293C15.5212 5.91788 15.502 5.88475 15.4857 5.86041L15.4718 5.83943V5.78891L15.4222 5.73929C15.3592 5.67624 15.2933 5.64216 15.2395 5.62425C15.213 5.61539 15.1897 5.61059 15.1715 5.60799C15.1624 5.60669 15.1545 5.60594 15.1481 5.6055L15.1394 5.60507L15.1359 5.60499L15.1344 5.60497L15.1332 5.60496L14.9633 5.60496L14.9622 5.60497L14.9607 5.60499L14.9572 5.60507L14.9485 5.6055C14.9421 5.60594 14.9342 5.60669 14.9251 5.60799C14.9069 5.61059 14.8837 5.61539 14.8571 5.62425C14.8033 5.64216 14.7374 5.67624 14.6744 5.73929L14.6697 5.74397L10.1803 10.9958C9.95054 11.2235 9.65468 11.3652 9.37282 11.3652H7.67864C6.99211 11.3652 6.40801 11.9493 6.40801 12.6358V14.5841C6.40801 15.2706 6.99211 15.8547 7.67864 15.8547H9.37282C9.69156 15.8547 9.94313 15.9341 10.0684 16.1846Z"
        fill={fill}
      />
      <path
        d="M15.3247 21.6177L15.4448 21.4977C15.5787 21.3637 15.6447 21.2106 15.6447 21.022V18.1636L17.1741 16.6342L17.5874 16.2622V21.022C17.5874 22.0103 16.9807 22.8537 16.0511 23.241L16.0457 23.2435L16.0352 23.2487C15.7361 23.3983 15.3526 23.4757 15.0489 23.4757C14.4348 23.4757 13.8239 23.2455 13.367 22.7886L13.2862 22.7079L12.3667 21.7048L12.2539 21.5543L13.6089 20.1994L13.7585 20.349L14.6424 21.3936C14.6533 21.4242 14.6691 21.4544 14.6912 21.4828C14.741 21.5468 14.804 21.5782 14.8512 21.594C14.9248 21.6185 15.0067 21.618 15.0413 21.6178L15.0489 21.6177H15.3247Z"
        fill={fill}
      />
    </svg>

  );
});

export default SlashSound;
