import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { AudioController, AudioSourceType } from '../services/AudioController';
import AudioVariable from '../network/variables/AudioVariable';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import { AudioVariableValueType } from '../network/payloads/AudioVariablePayload';
import BaseScene from '../scenes/BaseScene';

// TODO: merge Audio & Video to Media Component

export type AudioComponentOptions = ComponentOptions & {
  data?: {
    source?: AudioSourceType;
    colliderObjects?: Three.Object3D[];
    distanceVolume?: number;
    rayCastFar?: number;
    recursiveIntersection?: boolean;
  };
};

export default class AudioComponent extends EngineComponent {
  public controller: AudioController;

  public source?: AudioSourceType;

  public prevState?: AudioVariableValueType;

  public enabled = true;

  public distanceVolume = 12;

  public colliderObjects: Three.Object3D[] = [];

  public rayCastFar = 15; // depends on the size of the 3D entities that are the audio component

  public recursiveIntersection = false; // use this in raycast.intersectObjects with colliderObjects

  static get code(): string {
    return 'audio';
  }

  constructor(options: AudioComponentOptions) {
    super(options);
    this.controller = new AudioController();
    if (options.data?.source) this.source = { ...options.data?.source };
    // this.prevVideoState = this.videoState;
    this.recursiveIntersection = options.data?.recursiveIntersection ?? this.recursiveIntersection;
    this.distanceVolume = options.data?.distanceVolume ?? this.distanceVolume;
    this.rayCastFar = options.data?.rayCastFar ?? this.rayCastFar;
    this.rayCastFar = Math.max(this.rayCastFar, this.distanceVolume);
    this.colliderObjects = options.data?.colliderObjects ?? this.colliderObjects;
    // TODO: think about it
    if ((this.entity.app.sceneManager.currentScene as BaseScene)?.userInteractionComplete) {
      this.init();
    }
    return this;
  }

  public init() {
    return this.controller.createElement().then(() => {
      if (this.source) return this.setSource(this.source);
    });
  }

  protected get timestamp() {
    return Math.floor((this.entity.app.networkManager ? this.entity.app.networkManager.getCorrectTime(true) : Date.now()) / 1000);
  }

  public rewind(delta: number) {
    if (!this.controller.element) return;
    const { time, duration } = this.controller;
    let setTime = (time + delta);
    if (setTime < 0) setTime = duration - setTime;
    setTime %= duration;
    this.controller.setTime(setTime);
    this.variable?.setNeedUpdateFromLocal();
  }

  public get state() : AudioVariableValueType {
    return {
      isPlaying: this.controller.isPlaying(),
      source: this.source,
      audioTime: this.controller.time,
      localTime: this.timestamp,
    };
  }

  public calculateTime(state: AudioVariableValueType): number {
    let time = state.audioTime + (state.isPlaying ? Math.max(0, this.timestamp - (state.localTime || 0)) : 0);
    const { duration } = this.controller;
    if (duration > 0) {
      time %= duration;
    }
    return time;
  }

  // TODO: move to variable ?
  public setState(state: AudioVariableValueType) {
    let promise = Promise.resolve();
    if (state.source && (this.source?.mp3 !== state.source.mp3)) {
      promise = this.setSource(state.source);
    }
    return promise
      .then(() => {
        return this.controller.toggle(state.isPlaying);
      })
      .then(() => {
        if (state.audioTime <= 0) return;
        const audioTime = this.calculateTime(state);
        this.controller.setTime(audioTime);
      })
      .then(() => {
        this.prevState = state;
      });
  }

  public get variable(): AudioVariable | undefined {
    return this.entity.getComponent(NetworkObjectComponent)?.netObject?.getVariableByName('audio') as AudioVariable;
  }

  public play() : Promise<void> {
    return this.controller.toggle(true).then(() => {
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public pause() {
    return this.controller.toggle(false).then(() => {
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public toggleSound() {
    this.controller.toggleMute();
  }

  public toggle() {
    return this.controller.isPlaying() ? this.pause() : this.play();
  }

  public setSource(source: AudioSourceType) {
    this.enabled = false;
    this.source = { ...source };
    const promise = this.controller.setSource(source);
    return (promise || Promise.resolve()).then(() => {
      this.enabled = true;
    });
  }

  public changeVolume(distance: number, blocked: boolean) {
    if (!Number.isFinite(distance)) return;
    if (/* blocked || */ distance > this.distanceVolume) {
      return this.controller.setVolume(0);
    }
    this.controller.setVolume(1 - (distance / this.distanceVolume));
  }

  public destroy(): void {
    this.controller.destroy();
  }
}
