import { Container } from './Container';
import { TabButton } from './Buttons/TabButton';
import { TabsId } from '../enum/TabsId';
import * as Theme from '../Theme';

export type RadioSwitchParams = {
  width: number;
  height: number;
  padding?: number;
  options: { id: string; text: string }[];
};

export function RadioSwitch(params: RadioSwitchParams) {
  const padding = params.padding ?? 6;
  return Container({
    width: params.width,
    height: params.height,
    padding,
    contentDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#272A44',
    backgroundOpacity: 0.5,
    borderRadius: Theme.bgBorderRadius,
  }, params.options.map(({ id, text }) => {
    return TabButton({
      id,
      text,
      height: params.height - 2 * padding,
      width: (params.width - 2 * padding) / 2 - padding,
      fontSize: 24,
    });
  }));
}
