import { SyncVariable } from '../../SyncVariable';
import { VariablePayload } from '../../payloads/VariablePayload';
import SessionSpacesPayload, { SessionSpacesVariableValueType } from '../payloads/SessionSpacesPayload';

export class SessionSpacesVariable extends SyncVariable<SessionSpacesVariableValueType> {
  public static type = 'sessionSpaces';

  public static payloadType: typeof VariablePayload<SessionSpacesVariableValueType> = SessionSpacesPayload;

  public required = true;

  constructor(name = 'sessionSpaces') {
    super(name);
  }
}
