import { VariablePayload } from '../../../engine/network/payloads/VariablePayload';
import { AudioSourceType } from '../../services/AudioController';
import { domain, google } from '../../../generated';
import IAny = google.protobuf.IAny;

export type AudioVariableValueType = {
  source?: AudioSourceType;
  isPlaying: boolean;
  audioTime: number;
  localTime: number;
};

export default class AudioVariablePayload extends VariablePayload<AudioVariableValueType> {
  public static payloadFromProto(payload: IAny): AudioVariableValueType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = domain.network.payloads.AudioVariablePayload.decode(payload.value);
    return {
      audioTime: decoded.audioTime as number,
      localTime: decoded.localTime as number,
      isPlaying: Boolean(decoded.isPlaying),
      source: {
        mp3: decoded.source?.mp3 as string,
      },
    };
  }

  public payloadToProto(): google.protobuf.Any | null {
    const payload = domain.network.payloads.AudioVariablePayload.create({
      source: domain.network.payloads.AudioVariablePayload.AudioSource.create({
        mp3: this.target?.value?.source?.mp3,
      }),
      isPlaying: this.target?.value?.isPlaying,
      audioTime: this.target?.value?.audioTime,
      localTime: this.target?.value?.localTime,
    });
    return google.protobuf.Any.create({
      type_url: this.targetType,
      value: domain.network.payloads.AudioVariablePayload.encode(payload).finish(),
    });
  }
}
