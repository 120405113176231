import { Object3D, Vector3 } from 'three';
import * as Three from 'three';
import SystemObject from '../../../engine/network/SystemObject';
import { NetworkObjectLifeTimeTypes } from '../../../engine/network/NetworkObject';
import NetworkManager from '../../../engine/network/NetworkManager';
import { Application } from '../../../engine/Application';
import VideoVariable from '../variables/VideoVariable';
import { Entity } from '../../../engine/Entity';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import VideoComponent from '../../components/VideoComponent';
import { VideoSourceType } from '../../services/VideoController';
import { replaceObjectByEntity } from '../../services/replaceObjectByEntity';
import RaycastComponent from '../../components/Raycast.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import PresentationComponent from '../../components/Presentation.component';
import YoutubeComponent from '../../components/Youtube.component';
import { VideoBannersType } from '../../assets/types';

export interface VideoTimelineFrameInterface {
  name: string;
  timeStamp: number;
}

export enum VideoType {
  Presentation = 'Presentation',
  Video = 'Video',
}

export interface VideoInterface {
  title?: string;
  mp4: string;
  ogv?: string;
  previewUrl?: string;
}

export type VideoTimelineInterface = VideoTimelineFrameInterface[];

export default class VideoObject extends SystemObject {
  public static type = 'videoobject';

  public static videoBanners: VideoBannersType = {};

  public lifeTimeType = NetworkObjectLifeTimeTypes.Shared;

  static register(manager: NetworkManager) {
    manager.objectsTypes[VideoObject.type] = VideoObject;
  }

  static build(app: Application, code: string): VideoObject | null {
    if (!app.networkManager) return null;
    if (!app.networkManager.isActiveRoomHost) return null;

    const netObj = app.networkManager?.buildSharedObject<VideoObject>(VideoObject.type, code);
    netObj.setApplication(app);
    netObj.addVariables();
    return netObj;
  }

  static setAssetsData(data: VideoBannersType) {
    VideoObject.videoBanners = data;
  }

  public static createEntityPrefab(
    app: Application,
    scene: Three.Object3D | null,
    objectName: string,
    type: VideoType,
    source?: VideoSourceType,
    timeline?: VideoTimelineInterface,
    videos: VideoInterface[] = [],
    colliderObjects: Three.Object3D[] = [],
  ): Entity | undefined {
    if (!scene) return;
    const object = scene.getObjectByName(objectName);
    if (!object) return;

    const entity = app.entityManager.makeEntity();

    // replace object by entity
    // TODO: move to EntityManager
    replaceObjectByEntity(object, entity);

    entity.addComponent(VideoComponent, {
      targetMesh: object as Three.Mesh,
      source,
      colliderObjects,
      exactTime: type === VideoType.Presentation,
    });

    entity.getComponentOrFail(VideoComponent).chapters = timeline ?? [];
    // TODO: think about only once collision on colliders
    entity.addComponent(RaycastComponent).colliderObjects = colliderObjects;
    entity.addComponent(SelectedObjectComponent, {
      hoverColor: new Three.Color(0.5, 0.5, 0.5),
    });
    if (type === VideoType.Presentation) {
      entity.addComponent(PresentationComponent);
    }
    if (type === VideoType.Video) {
      entity.addComponent(YoutubeComponent, {
        tracks: videos.map((video, index) => ({
          title: video.title,
          src: video,
          id: `track_${index}`,
          previewUrl: video.previewUrl,
        })),
        mediaComponent: entity.getComponentOrFail(VideoComponent),
      });
    }

    return entity;
  }

  public addVariables() {
    this.addVariable(new VideoVariable());
  }

  public attachToEntity(entity: Entity) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
    });
  }

  public spawnEntity(
    position: Vector3 | undefined = undefined,
  ): Object3D | undefined {
    if (!this.app) return;
    const scene = this.app.sceneManager.currentThreeScene;
    const videoVar = this.getVariableByName('video');
    if (!videoVar || !scene) return;
    const videoBanner = VideoObject.videoBanners[this.code];
    const collider = scene.getObjectByName('spaceCollider');
    const colliders = collider ? [collider] : [];
    const entity = VideoObject.createEntityPrefab(
      this.app,
      scene,
      this.code,
      String(videoBanner.type) as VideoType,
      videoVar.value ? videoVar.value.source : undefined,
      videoBanner.timeline,
      videoBanner.videos,
      colliders,
    );
    if (entity) {
      entity.getComponentOrFail(VideoComponent).autoplay = !!videoBanner.autoplay;
      this.attachToEntity(entity);
      // entity.getComponent(VideoTextureComponent)?.setState(videoVar.value);
      videoVar.setNeedUpdateFromNetwork();
    }
    return entity;
  }
}
