import * as Three from 'three';
import { Component, ComponentOptions } from '../../engine/Component';

export type TeleportComponentOptions = ComponentOptions & {
  data?: {
    placeId?: string;
    toggleRigidAfterTeleport?: boolean;
    spaceName?: string;
    sceneParameters?: Record<string, unknown>;
    triggerPosition?: Three.Vector3;
    triggerPositionDistance?: number;
  };
};

export class TeleportComponent extends Component {
  public placeId: string;

  public spaceName: string;

  public sceneParameters?: Record<string, unknown>;

  public triggerPosition?: Three.Vector3;

  public triggerPositionDistance = 1;

  public isActive = false;

  public activatedInCurrentFrame = false;

  public toggleRigidAfterTeleport = false;

  public isLoading = false;

  constructor(options: TeleportComponentOptions) {
    super(options);
    this.placeId = options.data?.placeId ?? '';
    this.spaceName = options.data?.spaceName ?? '';
    this.sceneParameters = options.data?.sceneParameters;
    this.toggleRigidAfterTeleport = options.data?.toggleRigidAfterTeleport ?? false;
    this.triggerPosition = options.data?.triggerPosition;
    this.triggerPositionDistance = options.data?.triggerPositionDistance ?? 1;
  }

  static get code(): string {
    return 'teleport';
  }
}
