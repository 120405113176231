export enum AmmoDebugDrawMode {
  NoDebug = 0,
  DrawWireframe = 1,
  DrawAabb = 2,
  DrawFeaturesText = 4,
  DrawContactPoints = 8,
  NoDeactivation = 16,
  NoHelpText = 32,
  DrawText = 64,
  ProfileTimings = 128,
  EnableSatComparison = 256,
  DisableBulletLCP = 512,
  EnableCCD = 1024,
  DrawConstraints = 2048,
  DrawConstraintLimits = 4096,
  FastWireframe = 8192,
  DrawNormals = 16384,
  DrawOnTop = 32768,
  MAX_DEBUG_DRAW_MODE = 0xffffffff,
}
