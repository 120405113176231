import { google, engine } from '../../../../generated';
import { NetworkId } from '../../types';
import { VariablePayload } from '../../payloads/VariablePayload';
import payloads = engine.network.sessionStore.payloads;
import Any = google.protobuf.Any;
import IAny = google.protobuf.IAny;

export type SessionUserType = {
  id: NetworkId;
  name: string;
  activeRoomID: NetworkId;
  avatarName: string;
};

export type SessionUsersVariableValueType = {
  users: SessionUserType[];
};

export default class SessionUsersPayload extends VariablePayload<SessionUsersVariableValueType> {
  static payloadFromProto(payload: IAny): SessionUsersVariableValueType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = payloads.SessionUsersPayload.decode(payload.value);
    return {
      users: decoded.users.map((x) => ({
        id: x.id as number,
        name: x.name as string,
        activeRoomID: x.activeRoomId as number,
        avatarName: x.avatarName as string,
      })),
    };
  }

  get isEmpty() {
    return !this.target.value || !this.target.value.users.length;
  }

  payloadToProto(): Any | null {
    if (this.isEmpty) {
      return null;
    }
    const payload = payloads.SessionUsersPayload.create({
      users: (this.target.value?.users ?? []).map((x) => {
        return payloads.SessionUsersPayload.SessionUserPayload.create({
          id: x.id,
          name: x.name,
          activeRoomId: x.activeRoomID,
          avatarName: x.avatarName,
        });
      }),
    });
    return Any.create({
      type_url: this.targetType,
      value: payloads.SessionUsersPayload.encode(payload).finish(),
    });
  }
}
