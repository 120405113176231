import * as Three from 'three';
import { Container } from '../elements';
import { PanelId } from '../enum/PanelId';
import * as Theme from '../Theme';
import { TextBlock } from '../elements/TextBlock';
import { DialogButton } from '../elements/Buttons/DialogButton';
import { ButtonId } from '../enum/ButtonId';
import { PlayerButton } from '../elements/Buttons/PlayerButton';
import { ContainerId } from '../enum/ContainerId';
import { Component } from '../../../engine/Component';
import { Adaptive, PresentationMenuAdaptive } from '../adaptive';
import { i18n } from '../../../i18n/config';

export function PresentationMenuTemplate(component: Component, adaptive: Adaptive) {
  const { width, height } = adaptive;
  const {
    padding,
  } = adaptive as PresentationMenuAdaptive;
  const percentWidth = (value: number) => (value / 100) * width;
  const percentHeight = (value: number) => (value / 100) * height;
  const br = 16;
  const rowHeight = ((height - 2 * padding) / 2) * 0.94;

  return Container({
    id: PanelId.PresentationPanel,
    interactive: true,
    width,
    height,
    fontName: Theme.fontMain.name,
    justifyContent: 'space-between',
    alignItems: 'start',
    contentDirection: 'column',
    backgroundOpacity: Theme.bgOpacity,
    backgroundColor: Theme.bgColor,
    borderRadius: [br, br, br, br],
    padding,
    visible: component.enabled,
  }, [
    Container({
      width: width - 2 * padding,
      height: rowHeight,
      justifyContent: 'space-between',
      alignItems: 'start',
      contentDirection: 'row',
    }, [
      TextBlock({
        container: {
          id: ContainerId.ChapterName,
          width: (width - 2 * padding) * 0.77,
          height: rowHeight,
          padding,
          backgroundColor: '#171A30',
          backgroundOpacity: 0.5,
          borderRadius: [br, br, br, br],
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'left',
        },
        text: {
          letterSpacing: Theme.letterSpacing,
          content: i18n.t('canvas.presentationMenu.text'),
          fontName: Theme.fontMainBold.name,
          fontSize: 22,
        },
      }),
      DialogButton({
        id: ButtonId.CloseButton,
        imageUrl: 'assets/ui/icons/close.png',
        width: (width - 2 * padding) * 0.2,
        height: rowHeight,
        iconWidth: 32,
        iconHeight: 32,
      }),
    ]),
    Container({
      width: width - 2 * padding,
      height: rowHeight,
      backgroundColor: '#171A30',
      backgroundOpacity: 0.5,
      borderRadius: [br, br, br, br],
      justifyContent: 'space-between',
      alignItems: 'start',
      contentDirection: 'row',
      padding,
    }, [
      PlayerButton({
        id: ButtonId.PrevButton,
        imageUrl: 'assets/ui/icons/prev.png',
      }),
      PlayerButton({
        id: ButtonId.PlayButton,
        imageUrl: 'assets/ui/icons/play.png',
        selectedImg: 'assets/ui/icons/pause.png',
        selectedColor: '#D4FF00',
        selectedOpacity: 1,
      }),
      PlayerButton({
        id: ButtonId.NextButton,
        imageUrl: 'assets/ui/icons/next.png',
      }),
      Container({
        width: 8 * padding,
        height: 2 * padding,
      }, []),
      PlayerButton({
        id: ButtonId.CamButton,
        imageUrl: 'assets/ui/icons/share.png',
        selectedImg: 'assets/ui/icons/unshare.png',
        selectedColor: '#5156D1',
        selected: true,
      }),
    ]),
  ]);
}
