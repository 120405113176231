import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const Sound: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2779 21.348C15.2833 21.3475 15.2884 21.3473 15.2932 21.3473C15.2932 21.314 15.319 21.2808 15.3506 21.2426C15.3527 21.2401 15.3548 21.2375 15.357 21.2349"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67992 9.28571H8.95592L13.2801 4.2697C14.0839 3.46904 15.1196 3.31088 16.0829 3.71226C17.0456 4.11341 17.6029 4.99209 17.6029 5.94953V21.2545C17.5204 22.2164 16.96 23.0145 16.0778 23.4155L16.0722 23.4184C15.7564 23.5762 15.3554 23.6569 15.0366 23.6569C14.3946 23.6569 13.7547 23.4162 13.2756 22.9371L13.1947 22.8562L8.95606 17.9255H7.67992C5.80693 17.9255 4.34375 16.4624 4.34375 14.5894V12.6219C4.34375 10.7489 5.80693 9.28571 7.67992 9.28571ZM14.789 5.62542L14.7586 5.64061L10.2066 10.8797C9.97461 11.1099 9.67562 11.2532 9.39078 11.2532H7.67992C6.97847 11.2532 6.39678 11.8514 6.39678 12.6219V14.5894C6.39678 15.2827 6.98664 15.8725 7.67992 15.8725H9.39078C9.69564 15.8725 9.99585 15.9463 10.2998 16.1695L14.8254 21.4635L14.8301 21.4682C14.9381 21.5762 15.0831 21.5593 15.1503 21.5481C15.1816 21.5429 15.2139 21.5353 15.2381 21.5297L15.2536 21.5261C15.2808 21.5199 15.2913 21.5186 15.2932 21.5183H15.4643V21.3741C15.4698 21.3668 15.4754 21.3601 15.483 21.351C15.4904 21.3422 15.4996 21.3311 15.5124 21.3151C15.5602 21.2553 15.6354 21.1519 15.6354 21.0051V6.03508C15.6354 5.89586 15.5682 5.77277 15.4995 5.69031C15.4639 5.6475 15.4225 5.60909 15.3788 5.57996C15.3399 5.55406 15.279 5.52182 15.2077 5.52182L15.2049 5.52182C15.1622 5.52181 15.1053 5.5218 15.0351 5.53584C14.9636 5.55015 14.8849 5.57749 14.789 5.62542Z"
        fill={fill}
      />
      <path
        d="M20.7888 11.2526L20.7941 11.2618L20.8005 11.2704C21.0373 11.5861 21.4506 12.3206 21.4506 13.5629C21.4506 14.5637 21.1435 15.2483 20.8913 15.8103C20.8533 15.8951 20.8165 15.977 20.7822 16.0569C20.6412 16.334 20.2779 16.5569 19.9109 16.5569C19.6978 16.5569 19.5583 16.4888 19.3886 16.4039L19.3718 16.3955L19.3536 16.3909C19.0962 16.3266 18.9564 16.1398 18.8792 15.8311C18.7983 15.5073 18.8096 15.2239 18.9412 15.0265L18.9471 15.0176L18.9525 15.0068C19.1263 14.6592 19.3121 14.2876 19.3121 13.6485C19.3121 12.9354 19.1333 12.5609 18.9473 12.2796C18.873 12.1289 18.8391 12.0036 18.8305 11.8826C18.8217 11.7596 18.8383 11.6312 18.8776 11.4726C18.9471 11.272 19.1621 11.0467 19.402 10.8857C19.921 10.5922 20.4965 10.741 20.7888 11.2526Z"
        fill={fill}
      />
      <path
        d="M20.4654 8.8283C20.0919 8.37447 20.1055 7.71446 20.4543 7.35912C20.9104 6.98402 21.5747 6.99976 21.9286 7.35369C23.6076 9.0327 24.5303 11.2134 24.5303 13.6484C24.5303 15.997 23.6082 18.178 21.9286 19.8576C21.8144 19.9718 21.7057 20.0427 21.5925 20.0862C21.4785 20.1301 21.3509 20.1497 21.1941 20.1497C21.0373 20.1497 20.9097 20.1301 20.7957 20.0862C20.6825 20.0427 20.5739 19.9718 20.4597 19.8576C20.021 19.4189 20.0908 18.7576 20.4597 18.3887C21.7742 17.0742 22.4773 15.4056 22.4773 13.5629C22.4773 11.807 21.7754 10.1411 20.4654 8.8283Z"
        fill={fill}
      />
    </svg>
  );
});

export default Sound;
