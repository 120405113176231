import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { SnackbarProvider } from 'notistack';
import SpaceProvider from './app/provider/SpaceProvider';
import Space from './app/containers/Space/Space';
import './i18n/config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <SpaceProvider>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={3000}
    >
      <Space />
    </SnackbarProvider>
  </SpaceProvider>,
);
