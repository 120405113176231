import { makeAutoObservable } from 'mobx';

class ControlsStore {
  public showControls = true;

  public userName = '';

  public microActive = true;

  public cameraActive = true;

  public soundActive = true;

  public isValidateError = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setUserName(name: string): void {
    this.userName = name;
  }

  public setIsErrorValidate(isError: boolean): void {
    this.isValidateError = isError;
  }

  public setShowControls(showControls: boolean): void {
    this.showControls = showControls;
  }

  public setMicroActive(microActive: boolean): void {
    this.microActive = microActive;
  }

  public setCameraActive(cameraActive: boolean): void {
    this.cameraActive = cameraActive;
  }

  public setSoundActive(soundActive: boolean): void {
    this.soundActive = soundActive;
  }
}

export default ControlsStore;
