import { System } from '../../engine/System';
import SceneLightMapsComponent from '../components/SceneLightMaps.component';
import { LightMapsService } from '../services/LightMaps.service';

export class SceneLightMapsSystem extends System {
  protected service = new LightMapsService();

  public onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(SceneLightMapsComponent).forEach((component: SceneLightMapsComponent) => {
      component.isInitializedInCurrentFrame.update(component.isInitialized);
      this.handleState(component);
    });
  }

  protected handleState(component: SceneLightMapsComponent) {
    if (component.isInitialized || component.isLoading) return;
    component.isLoading = true;
    setTimeout(() => {
      this.service.setAllObjectsNames(component.objectsNames);
      this.service.toggleObjects(component.content, component.presetObjects);
      this.service.setupLightMaps(component.content, component.preset.lightMaps).then(() => {
        component.isInitialized = true;
        component.isLoading = false;
      });
    }, component.timeout);
  }
}
