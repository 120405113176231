import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { IconButton } from '../IconButton';
import { addStatesToIconElement } from '../../helpers/addStatesToIconElement';

export type DialogButtonParams = {
  id: string;
  imageUrl: string;
  height?: number;
  width?: number;
  iconWidth?: number;
  iconHeight?: number;
  absolutePosition?: Three.Vector2;
  altImageUrl?: string;
  color?: string;
  opacity?: number;
  visible?: boolean;
  selected?: boolean;
};

export function DialogButton(params: DialogButtonParams) {
  const button = IconButton({
    id: params.id,
    width: params.width ?? 48,
    height: params.height ?? 48,
    imageUrl: params.imageUrl,
    iconWidth: params.iconWidth,
    iconHeight: params.iconHeight,
    color: params.color ?? '#171A30',
    opacity: params.opacity ?? 0.9,
    hoverColor: '#D7DAD0',
    hoverOpacity: 0.3,
    activeColor: '#5156D1',
    absolutePosition: params.absolutePosition,
    selected: params.selected,
  });

  if (typeof params.visible !== 'undefined') {
    button.visible = params.visible;
  }
  addStatesToIconElement(button, {
    imageUrl: params.imageUrl,
    altImg: params.altImageUrl,
    altState: 'alt',
  });
  return button;
}
