import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ruDictionary } from './ru';
import { enDictionary } from './en';

i18n
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_LANG || 'en',
    defaultNS: 'translation',
    resources: {
      en: {
        translation: enDictionary,
      },
      ru: {
        translation: ruDictionary,
      },
    },
  });

export { i18n };
