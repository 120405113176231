import EventEmitter from 'eventemitter3';

export enum ProtocolType {
  DC = 'webrtc-datachannel',
  WS = 'websocket',
}

export type TimeInfo = {
  offset: number;
  rtt: number;
};

export type PayloadType = ArrayBuffer;
export type Body = ArrayBuffer;
export type NetworkId = number;
export type MessageId = bigint;
export type QueueId = number;
export type Timestamp = bigint;

export type RequestHeader = {
  route: number;
  queueId: QueueId;
  senderId: NetworkId;
  messageId: MessageId;
  clientSentTimestamp: Timestamp;
};

export type ResponseHeader = {
  route: number;
  queueId: QueueId;
  senderId: NetworkId;
  messageId: MessageId;
  clientSentTimestamp: Timestamp;
  serverReceivedTimestamp: Timestamp;
  serverSentTimestamp: Timestamp;
  clientReceivedTimestamp: Timestamp;
};

export type MessageOptions = { queueId?: QueueId; messageId?: MessageId };

export type OnMessagePayload = { message: Body; header: ResponseHeader; protocol: ProtocolType };
export type OnWSMessagePayload = { message: Body; header: ResponseHeader };
export type OnDCMessagePayload = { message: Body; header: ResponseHeader };
export type OnListRoomConnectionsPayload = { roomId: NetworkId; connectionIds: NetworkId[] };
export type OnJoinedRoomPayload = { roomId: NetworkId; clientId: NetworkId };
export type OnRoomCreatedPayload = { roomId: NetworkId };
export type OnLeftRoomPayload = { roomId: NetworkId; connectionId: NetworkId };
export type OnRoomClosedPayload = { roomId: NetworkId };
export type OnCloseConnection = { connectionId: NetworkId };

export type TransportEventTypes = {
  // roomJoined: (payload: { networkId: string; roomId: string; participants: string[] }) => void;
  // roomLeft: (payload: { networkId: string }) => void;

  onError: (payload: { header: ResponseHeader; message: string }) => void;
  onMessage: (payload: OnMessagePayload) => void;
  onDCMessage: (payload: OnDCMessagePayload) => void;
  onWSMessage: (payload: OnWSMessagePayload) => void;
  onJoinedRoom: (payload: OnJoinedRoomPayload) => void;
  onLeftRoom: (payload: OnLeftRoomPayload) => void;
  oncloseConnection: (payload: OnCloseConnection) => void;
};

export interface Transport {
  networkId: NetworkId;
  get events(): EventEmitter<TransportEventTypes>;
}
