import { forwardRef, PropsWithChildren } from 'react';
import classnames from 'classnames';
import classNames from './IconButton.module.css';

export interface IconButtonProps {
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  className?: string;
}

const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<IconButtonProps>>((props, ref) => {
  const {
    disabled = false,
    active = false,
    onClick = () => undefined,
    onMouseEnter = () => undefined,
    className,
    children,
  } = props;

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={classnames(className, classNames.root, { [classNames.active]: active })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      { children }
    </button>
  );
});

export default IconButton;
