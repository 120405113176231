import { google, engine } from '../../../../generated';
import { NetworkId } from '../../types';
import { VariablePayload } from '../../payloads/VariablePayload';
import payloads = engine.network.sessionStore.payloads;
import Any = google.protobuf.Any;
import IAny = google.protobuf.IAny;

export type SessionSpaceType = {
  spaceName: string;
  roomId: NetworkId;
};

export type SessionSpacesVariableValueType = {
  spaces: SessionSpaceType[];
};

export default class SessionSpacesPayload extends VariablePayload<SessionSpacesVariableValueType> {
  static payloadFromProto(payload: IAny): SessionSpacesVariableValueType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = payloads.SessionSpacesPayload.decode(payload.value);
    return {
      spaces: decoded.spaces.map((x) => ({
        roomId: x.roomId as number,
        spaceName: x.spaceName as string,
      })),
    };
  }

  get isEmpty() {
    return !this.target.value || !this.target.value.spaces.length;
  }

  payloadToProto(): Any | null {
    if (this.isEmpty) {
      return null;
    }
    const payload = payloads.SessionSpacesPayload.create({
      spaces: (this.target.value?.spaces ?? []).map((x) => {
        return payloads.SessionSpacesPayload.SessionSpacePayload.create({
          roomId: x.roomId, spaceName: x.spaceName,
        });
      }),
    });
    return Any.create({
      type_url: this.targetType,
      value: payloads.SessionSpacesPayload.encode(payload).finish(),
    });
  }
}
