import IKChain from './IKChain';

export type IKSystemOptions = {
  iterations?: number;
  tolerance?: number;
};

export class IKSystem {
  public chains: IKChain[] = [];

  protected needsRecalculated = true;

  public isIKSystem = true;

  public iterations = 1;

  public tolerance = 0.02;

  /**
   * An array of root chains for this IK system, each containing
   * an array of all subchains, including the root chain, for that
   * root chain, in descending-depth order.
   */
  protected orderedChains: IKChain[][] | null = null;

  constructor(options: IKSystemOptions = {}) {
    this.iterations = options.iterations || this.iterations;
    this.tolerance = options.tolerance || this.tolerance;
  }

  /**
   * Adds an IKChain to the IK system.
   */
  add(chain: IKChain) {
    if (!chain.isIKChain) {
      throw new Error('Argument is not an IKChain.');
    }
    this.chains.push(chain);
  }

  /**
   * Called if there's been any changes to an IK structure.
   */
  recalculate() {
    this.orderedChains = [];

    for (let i = 0; i < this.chains.length; i++) {
      const rootChain = this.chains[i];
      const orderedChains: IKChain[] = [];
      this.orderedChains.push(orderedChains);

      const chainsToSave: IKChain[] = [rootChain];
      while (chainsToSave.length) {
        const chain = chainsToSave.shift();
        if (!chain) break;
        orderedChains.push(chain);
        if (chain.chains) {
          chain.chains.forEach((subChains) => {
            subChains.forEach((subChain) => {
              if (chainsToSave.indexOf(subChain) !== -1) {
                throw new Error('Recursive chain structure detected.');
              }
              chainsToSave.push(subChain);
            });
          });
        }
      }
    }
    this.needsRecalculated = false;
  }

  /**
   * Performs the IK solution and updates bones.
   */
  solve() {
    // If we don't have a depth-sorted array of chains, generate it.
    // This is from the first `update()` call after creating.
    if (!this.orderedChains || this.needsRecalculated) {
      this.recalculate();
    }

    if (!this.orderedChains) return;

    for (let i = 0; i < this.orderedChains.length; i++) {
      const subChains = this.orderedChains[i];
      // Hardcode to one for now
      let { iterations } = this;

      while (iterations > 0) {
        for (let j = subChains.length - 1; j >= 0; j--) {
          subChains[j].updateJointWorldPositions();
        }

        // Run the chain's forward step starting with the deepest chains.
        for (let j = subChains.length - 1; j >= 0; j--) {
          subChains[j].forward();
        }

        // Run the chain's backward step starting with the root chain.
        let withinTolerance = true;
        for (let j = 0; j < subChains.length; j++) {
          const distanceFromTarget = subChains[j].backward();
          if (distanceFromTarget > this.tolerance) {
            withinTolerance = false;
          }
        }

        if (withinTolerance) {
          break;
        }

        iterations--;

        // Get the root chain's base and randomize the rotation, maybe
        // we'll get a better change at reaching our goal
        // @TODO
        if (iterations > 0) {
          // subChains[subChains.length - 1]._randomizeRootRotation();
        }
      }
    }

    for (let i = 0; i < this.orderedChains.length; i++) {
      const subChains = this.orderedChains[i];
      for (let j = 0; j < subChains.length; j++) {
        subChains[j].joints.forEach((joint) => joint.applyConstraints(subChains[j], 'applyLazy'));
      }
    }
  }

  /**
   * Returns the root bone of this structure. Currently
   * only returns the first root chain's bone.
   */
  getRootBone() {
    return this.chains && this.chains.length > 0 ? this.chains[0].base?.bone : undefined;
  }
}
