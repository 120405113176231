import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';

export function setIconState(doc: UIDocumentComponent, btnId: string, state: string | null, iconName = 'icon') {
  const btnElement = doc.getElementById(btnId);
  if (btnElement) {
    const iconElement = doc.getChildElementByName(btnElement, iconName);
    if (state === null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iconElement?.setState(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !iconElement?.currentState || iconElement?.currentState === UIDocumentElementState.Default
          ? 'alt' : UIDocumentElementState.Default,
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (iconElement?.states[state]) iconElement?.setState(state);
  }
}
