import * as Three from 'three';
import { XRControllerModel, XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { XRGripSpace, XRTargetRaySpace } from 'three/src/renderers/webxr/WebXRController';
import { MotionController, Component as ControllerComponent } from '@webxr-input-profiles/motion-controllers';
import { System, SystemOptions } from '../System';

export enum ControllerName {
  Left = 'left',
  Right = 'right',
  None = 'none',
}

type ControllerComponentValues = {
  state: string;
  button: number;
  xAxis: number;
  yAxis: number;
};

export type XRInputSystemOptions = SystemOptions & {
  drawLayers?: number[];
};

// todo: refactor, think about profiles and gamepad api
// todo: move to input system, xr device?
export class XRInputSystem extends System {
  public gripSpaces: XRGripSpace[] = [];

  public raySpaces: XRTargetRaySpace[] = [];

  public controllerModels: XRControllerModel[] = [];

  public motionControllers: MotionController[] = [];

  protected xRControllerModelFactory = new XRControllerModelFactory();

  protected lastXrStandardTriggerStates: [string, string] = [
    'default',
    'default',
  ];

  protected xrStandardTriggersPressedInCurrentFrameValues: [boolean, boolean] = [false, false];

  protected xrStandardTriggersPressedInCurrentFrameProcessed = false;

  protected drawLayers: number[] = [];

  static get code(): string {
    return 'x_r_input';
  }

  constructor(options: XRInputSystemOptions) {
    super(options);
    this.drawLayers = options.drawLayers ?? this.drawLayers;

    [0, 1].forEach((controllerIndex) => {
      this.buildController(controllerIndex);
    });
  }

  onUpdate(ts: number) {
    this.xrStandardTriggersPressedInCurrentFrameProcessed = false;
    this.controllerModels.forEach((model, modelIndex) => {
      if (!model.motionController) return;

      this.motionControllers[modelIndex] = model.motionController;
    });

    this.motionControllers.forEach((motionController) => motionController.updateFromGamepad());
  }

  public getAButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'a-button');
  }

  public getBButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'b-button');
  }

  public getXButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'x-button');
  }

  public getYButton(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'y-button');
  }

  public getRightXrStandardThumbstick(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-thumbstick');
  }

  public getLeftXrStandardThumbstick(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-thumbstick');
  }

  public getRightXrStandardTrigger(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-trigger');
  }

  public getLeftXrStandardTrigger(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-trigger');
  }

  public getLeftXrStandardSqueeze(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Left, 'xr-standard-squeeze');
  }

  public getRightXrStandardSqueeze(): ControllerComponentValues {
    return this.getComponentRequiredValues(ControllerName.Right, 'xr-standard-squeeze');
  }

  public getComponent(controllerName: string, componentName: string): ControllerComponent | undefined {
    return this.getController(controllerName)?.components[componentName];
  }

  public getController(controllerName: string): MotionController | undefined {
    return this.motionControllers.find((controller) => {
      return controller && (controller.xrInputSource as XRInputSource)?.handedness === controllerName;
    });
  }

  public getRaySpace(controllerName: string): XRTargetRaySpace | undefined {
    return this.raySpaces[this.getControllerIndex(controllerName)];
  }

  public getGripSpace(controllerName: string): XRGripSpace | undefined {
    return this.gripSpaces[this.getControllerIndex(controllerName)];
  }

  public getComponentRequiredValues(controllerIndex: string, componentName: string): ControllerComponentValues {
    const component = this.getComponent(controllerIndex, componentName);

    if (!component) return this.getFallbackValues();

    return this.makeRequiredValues(component.values);
  }

  public getControllerNameByIndex(controllerIndex: number): ControllerName | undefined {
    const controller = this.motionControllers[controllerIndex];
    const controllerName = (controller?.xrInputSource as XRInputSource)?.handedness;

    if (!controllerName) return;
    if (!Object.values(ControllerName).includes(controllerName as ControllerName)) return;

    return controllerName as ControllerName;
  }

  public getFallbackValues(): ControllerComponentValues {
    return {
      state: 'default',
      button: 0,
      xAxis: 0,
      yAxis: 0,
    };
  }

  public makeRequiredValues(values: ControllerComponent['values']): ControllerComponentValues {
    return {
      state: values.state,
      button: values.button ?? 0,
      xAxis: values.xAxis ?? 0,
      yAxis: values.yAxis ?? 0,
    };
  }

  protected buildController(controllerIndex: number): void {
    const gripSpace = this.app.renderer.xr.getControllerGrip(controllerIndex);
    const raySpace = this.app.renderer.xr.getController(controllerIndex);
    this.gripSpaces.push(gripSpace);
    this.raySpaces.push(raySpace);
    raySpace.add(this.buildRay());
    const controllerModel = this.xRControllerModelFactory.createControllerModel(gripSpace);
    this.controllerModels.push(controllerModel);
    gripSpace.add(controllerModel);

    raySpace.addEventListener('connected', (event) => {
      raySpace.add(this.buildRay());
    });
  }

  public setRaysLength(length = 3) {
    this.raySpaces.forEach((raySpace) => {
      raySpace.traverse((obj) => {
        if (obj instanceof Three.Line) {
          obj.scale.z = length;
        }
      });
    });
  }

  protected buildRay(): Three.Line {
    const geometry = new Three.BufferGeometry();
    geometry.setAttribute('position', new Three.Float32BufferAttribute([0, 0, 0, 0, 0, -1], 3));
    geometry.setAttribute('color', new Three.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

    const material = new Three.LineBasicMaterial({
      vertexColors: true,
      blending: Three.AdditiveBlending,
      // depthTest: false,
      // depthWrite: false,
    });

    const mesh = new Three.Line(geometry, material);
    this.drawLayers.forEach((layer, index) => {
      if (index === 0) mesh.layers.set(layer);
      else mesh.layers.enable(layer);
    });

    return mesh;
  }

  protected getControllerIndex(controllerName: string): number {
    return this.motionControllers.findIndex((controller) => {
      if (!controller) return;
      return (controller.xrInputSource as XRInputSource).handedness === controllerName;
    });
  }

  public xrStandardTriggersPressedInCurrentFrame(): [boolean, boolean] {
    if (this.xrStandardTriggersPressedInCurrentFrameProcessed) return this.xrStandardTriggersPressedInCurrentFrameValues;
    const currentStates = [
      this.app.getSystemOrFail(XRInputSystem).getLeftXrStandardTrigger().state,
      this.app.getSystemOrFail(XRInputSystem).getRightXrStandardTrigger().state,
    ];

    this.xrStandardTriggersPressedInCurrentFrameValues = this.lastXrStandardTriggerStates.map((prevState, stateIndex) => {
      this.lastXrStandardTriggerStates[stateIndex] = currentStates[stateIndex];
      if (currentStates[stateIndex] === 'touched') {
        return false;
      }
      if (currentStates[stateIndex] === prevState) return false;

      return currentStates[stateIndex] === 'pressed';
    }) as [boolean, boolean];
    this.xrStandardTriggersPressedInCurrentFrameProcessed = true;
    return this.xrStandardTriggersPressedInCurrentFrameValues;
  }
}
