import React, { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { HistoryItem, InworldPacket } from '@inworld/web-sdk';
import classNames from './AssistantChat.module.css';
import { InworldService } from '../../../../../domain/services/Assistent/Inworld/InworldService';
import Input from '../../../../components/Input/Input';
import Icon from '../../../../components/Icons/Icon';
import IconButton from '../../../../components/IconButton/IconButton';
import { History } from './components/History';
import { EmotionsMap } from '../../../../../domain/services/Assistent/Inworld/types';
import { AvatarIcon } from './components/AvatarIcon/AvatarIcon';
import AvatarButton from './components/AvatarButton/AvatarButton';

export interface AssistantChatProps {
  service: InworldService;
}

export const AssistantChat: FC<AssistantChatProps> = observer((pops) => {
  const { service } = pops;
  const [text, setText] = useState('');
  const [chatHistory, setChatHistory] = useState<HistoryItem[]>([]);
  const [emotions, setEmotions] = useState<EmotionsMap>({});
  const [isPlaybackMuted, setIsPlaybackMuted] = useState(
    service.getMute() ?? false,
  );
  const [isRecording, setIsRecording] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  service.events.on('HistoryChange', (history: HistoryItem[]) => {
    // console.log(history);
    setChatHistory(history);
  });

  service.events.on('Message', (inworldPacket: InworldPacket) => {
    if (
      inworldPacket.isEmotion()
      && inworldPacket.packetId?.interactionId
    ) {
      // setEmotionEvent(inworldPacket.emotions);
      setEmotions((currentState) => ({
        ...currentState,
        [inworldPacket.packetId.interactionId]: inworldPacket.emotions,
      }));
    }
  });

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleSend = useCallback(() => {
    if (text) {
      service.sendText(text);
      setText('');
    }
  }, [text]);

  const handleTextKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSend();
      }
    },
    [handleSend],
  );

  const handleMutePlayback = useCallback(async () => {
    await service.mute(!isPlaybackMuted);
    setIsPlaybackMuted(!isPlaybackMuted);
  }, [service, isPlaybackMuted]);

  const stopRecording = useCallback(async () => {
    await service.stopRecording();
    setIsRecording(false);
  }, [service]);

  const startRecording = useCallback(async () => {
    try {
      setIsRecording(true);
      await service.startRecording();
    } catch (e) {
      console.error(e);
    }
  }, [service]);

  const handleSpeakClick = useCallback(async () => {
    if (isRecording) {
      return stopRecording();
    }

    return startRecording();
  }, [
    service,
    isRecording,
    startRecording,
    stopRecording,
  ]);

  const handleCloseChat = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  return (
    <>
      {!isOpened && <AvatarButton
        onClick={() => handleCloseChat()}
        className={classNames.openButton}
      /> }
      {isOpened
      && <div className={classNames.chatRoot}>
        <div className={classNames.chatHeader}>
          <AvatarIcon />
          <div className={classNames.avatarName}>Petra</div>
          <IconButton
            className={classNames.chatIcon}
            onClick={() => handleMutePlayback()}
          >
            <Icon name={isPlaybackMuted ? 'chatSoundOff' : 'chatSound'} />
          </IconButton>
          <IconButton
            className={classNames.chatIcon}
            onClick={() => handleCloseChat()}
          >
            <Icon name="close" />
          </IconButton>
        </div>
        <div className={classNames.chatBody}>
          <History
            history={chatHistory}
            emotions={emotions}
            service={service}
          />
          {!isRecording && <div className={classNames.inputFiled}>
            <Input
              // width="300px"
              error={false}
              placeholder="Ask me anything"
              onChange={(e) => {
                handleChangeText(e);
              }}
              onKeyPress={handleTextKeyPress}
              value={text}
            />
            <div className={classNames.actionButtons}>
              {text
                && <IconButton onClick={() => handleSend()}>
                  <Icon name="send" />
                </IconButton>}
              {!text && <IconButton onClick={() => handleSpeakClick()}>
                <Icon name="chatMic" />
              </IconButton>}
            </div>
          </div>}
          {isRecording && <div className={classNames.speakerBlock}>
            <div className={classNames.speakerText}>Speak, please</div>
            <div className={classNames.speakerMic}>
              <Icon name="chatMic" />
            </div>
            <IconButton
              className={classNames.speakerClose}
              onClick={() => handleSpeakClick()}
            >
              <Icon name="close" />
            </IconButton>
          </div>}
        </div>
      </div>}
    </>
  );
});
