import { Component, ComponentOptions } from '../Component';
import NetworkObject from '../network/NetworkObject';

export type NetworkObjectComponentOptions = ComponentOptions & {
  data?: {
    netObject: NetworkObject;
    removeTimeout?: number;
  };
};

export default class NetworkObjectComponent extends Component {
  public netObject: NetworkObject | null;

  public removeTimeout = 0;

  static get code(): string {
    return 'networkobject';
  }

  constructor(options: NetworkObjectComponentOptions) {
    super(options);
    this.netObject = options.data?.netObject || null;
    this.removeTimeout = options.data?.removeTimeout || this.removeTimeout;
    if (this.netObject) {
      this.netObject.isCreated = true;
    }
  }
}
