import { Entity, EntityOptions } from './Entity';
import { Application } from './Application';

export type EntityManagerOptions = {
  app: Application;
};

export class EntityManager {
  protected app: Application;

  constructor(options: EntityManagerOptions) {
    this.app = options.app;
  }

  public makeEntity(data?: EntityOptions['data']): Entity {
    return new Entity({ app: this.app, data });
  }
}
