import { System } from '../../engine/System';
import FlyControlsComponent from '../components/FlyControls.component';

export class FlyControlsSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);
    this.app.componentManager.getComponentsByType(FlyControlsComponent).forEach((component) => {
      if (!component.enabled || !component.controls) return;
      component.controls.update(dt);
    });
  }
}
