import * as Three from 'three';
import { Application } from '../../engine/Application';
import { Component } from '../../engine/Component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import BaseScene from '../scenes/BaseScene';
import { TPControllerComponent } from '../components/TPController.component';
import { FPControllerComponent } from '../components/FPController.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { UIDocumentElementState, UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import { TabsId } from '../ui/enum/TabsId';

export function handleUIScroll(app: Application, component: Component, containerId: string) {
  const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
  const states = uIDocumentComponent.elementStateDataList;
  const characterEntity = (app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
  const tpController = characterEntity.getComponentOrFail(TPControllerComponent);
  const fpController = characterEntity.getComponentOrFail(FPControllerComponent);
  const inputSystem = app.getSystemOrFail(InputSystem);
  const xrInputSystem = app.getSystemOrFail(XRInputSystem);
  const UIDocSystem = app.getSystemOrFail(UIDocumentSystem);
  const { touchscreen, mouse } = inputSystem;
  const { primaryTouch } = touchscreen;
  const [leftIsPressed, rightIsPressed] = app.getSystemOrFail(XRInputSystem).xrStandardTriggersPressedInCurrentFrame();

  const container = uIDocumentComponent.getElementById(containerId);
  if (!container) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const containerChildren: Three.Object3D[] = container.childrenBoxes;
  if (containerChildren.length <= 0) return;
  const firstElement = containerChildren[0];
  const lastElement = containerChildren[containerChildren.length - 1];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const topLimitPosition = container.size.y / 2 - container.padding - firstElement.size.y / 2;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const bottomLimitPosition = -container.size.y / 2 + container.padding + lastElement.size.y / 2;

  if (states[containerId]
    && (
      states[containerId].state === UIDocumentElementState.Hovered
      || states[containerId].state === UIDocumentElementState.Active
    )
  ) {
    const wheelScrollAmount = Math.sin(-mouse.wheelDelta.y) * 35;
    const mouseScrollAmount = mouse.leftButton.isPressed && !mouse.leftButton.wasPressedThisFrame
      ? Math.sin(-mouse.delta.threeVector2.y * 100) * 10 : 0;
    const touchScrollAmount = primaryTouch.press.isPressed && !primaryTouch.press.wasPressedThisFrame
      ? Math.sin(-primaryTouch.delta.threeVector2.y * 100) * 10 : 0;
    const vrScrollAmount = !rightIsPressed && xrInputSystem.getRightXrStandardTrigger().state === 'pressed'
      ? Math.sin(-UIDocSystem.vrCursorDelta.y * 100) * 15 : 0;
    const scrollAmount = mouseScrollAmount || wheelScrollAmount || touchScrollAmount || vrScrollAmount;
    if (
      (scrollAmount > 0 && lastElement.position.y < bottomLimitPosition)
      || (scrollAmount < 0 && firstElement.position.y > topLimitPosition)
    ) {
      containerChildren.forEach((obj) => {
        obj.position.y += scrollAmount;
      });
    }
    tpController.enableZoom = false;
    tpController.enableCameraRotation = false;
    fpController.enableCameraRotation = false;
  } else {
    tpController.enableZoom = true;
    tpController.enableCameraRotation = true;
    fpController.enableCameraRotation = true;
  }
}
