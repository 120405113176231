import { Component } from '../../../engine/Component';
import ShopifyComponent from '../Shopify.component';

export default class ShopifyMenuComponent extends Component {
  static get code(): string {
    return 'shopify_menu';
  }

  public currentActionComponent: ShopifyComponent | null = null;

  public isInfoUpdated = false;

  public isImageLoaded = false;
}
