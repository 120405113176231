import { ButtonControl } from '../controls/ButtonControl';

export class ButtonSensor {
  public isPressed = false;

  public sleep = false;

  public applyToControls(control: ButtonControl): void {
    const isPressed = this.sleep ? false : this.isPressed;

    control.wasPressedThisFrame = isPressed && !control.isPressed;
    control.wasReleasedThisFrame = !isPressed && control.isPressed;
    control.isPressed = isPressed;
  }
}
