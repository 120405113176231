import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

export const ChatSoundOff: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Volume off 1"
        fill={fill}
      >
        <path
          id="Union (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 3.37061C12.25 2.3004 10.9927 1.72507 10.1828 2.42468L6.85346 5.30077C6.35394 5.73229 5.71587 5.96972 5.05577 5.96973L3.49999 5.96974C2.53349 5.96975 1.75 6.75325 1.75 7.71974V11.0127C1.75 11.9792 2.5335 12.7627 3.5 12.7627H5.80003V14.2627H3.5C1.70507 14.2627 0.25 12.8076 0.25 11.0127V7.71974C0.25 5.92483 1.70506 4.46976 3.49998 4.46974L5.05576 4.46973C5.3558 4.46973 5.64583 4.3618 5.87289 4.16566L9.20228 1.28956C10.984 -0.249575 13.75 1.01615 13.75 3.37061V6H12.25V3.37061ZM13 9.25C13.4142 9.25 13.75 9.58579 13.75 10V15.3618C13.75 17.7163 10.984 18.982 9.20228 17.4428L8.92012 17.1991L7.53083 16.0851C7.20767 15.826 7.15575 15.354 7.41487 15.0308C7.67399 14.7077 8.14601 14.6558 8.46917 14.9149L9.8799 16.046L10.1828 16.3077C10.9927 17.0073 12.25 16.432 12.25 15.3618V10C12.25 9.58579 12.5858 9.25 13 9.25Z"
          // fill="#111111"
        />
        <path
          id="Line (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.46967 17.5303C1.76256 17.8232 2.23744 17.8232 2.53033 17.5303L17.5303 2.53034C17.8232 2.23745 17.8232 1.76257 17.5303 1.46968C17.2374 1.17679 16.7626 1.17679 16.4697 1.46968L1.46967 16.4697C1.17678 16.7626 1.17678 17.2374 1.46967 17.5303Z"
          // fill="#111111"
        />
      </g>
    </svg>
  );
});

export default ChatSoundOff;
