import AudioComponent from '../../components/AudioComponent';
import AudioVariablePayload, { AudioVariableValueType } from '../payloads/AudioVariablePayload';
import EngineVariable from './EngineVariable';
import { VariablePayload } from '../../../engine/network/payloads/VariablePayload';

export default class AudioVariable extends EngineVariable<AudioVariableValueType> {
  public static type = 'audio';

  public static payloadType: typeof VariablePayload<AudioVariableValueType> = AudioVariablePayload;

  public required = true;

  public autoSync = true;

  constructor(name = 'audio') {
    super(name);
  }

  public reset() {
    const component = this.getComponent(AudioVariable.app, AudioComponent);
    if (component) {
      this.set(component.state);
    }
  }

  // then local changed --> update variable == send state to network
  public updateFromLocal(): boolean {
    const { app } = AudioVariable;
    if (!app) return false;
    const component = this.getComponent(app, AudioComponent);
    if (!component || !component.enabled) return false;

    this.set(component.state);
    return true;
  }

  // then receive changed from network -> get variable state & update local values
  public updateFromNetwork(): boolean {
    const { app } = AudioVariable;
    if (!app) return false;
    const component = this.getComponent(app, AudioComponent);
    if (!component || !component.enabled) return false;
    if (!this.value) return false;

    component.setState(this.value);
    return true;
  }
}
