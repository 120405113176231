import { SyncVariable } from '../../SyncVariable';
import SessionUsersPayload, { SessionUsersVariableValueType } from '../payloads/SessionUsersPayload';
import { VariablePayload } from '../../payloads/VariablePayload';

export class SessionUsersVariable extends SyncVariable<SessionUsersVariableValueType> {
  public static type = 'sessionUsers';

  public static payloadType: typeof VariablePayload<SessionUsersVariableValueType> = SessionUsersPayload;

  public required = true;

  constructor(name = 'sessionUsers') {
    super(name);
  }
}
