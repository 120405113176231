import * as THREE from 'three';
import { Component, ComponentOptions } from '../../engine/Component';
import IKVariable, { IKVariableValue } from '../network/variables/IKVariable';

export type NetworkIKComponentOptions = ComponentOptions & {
  data?: {
    variable?: IKVariable;
    variableName?: string;
    type?: NetworkIKComponentTypes;
  };
};

export enum NetworkIKComponentTypes {
  Target = 'Target',
  Source = 'Source',
}

export default class NetworkIKComponent extends Component {
  public variable: IKVariable | null;

  public type: NetworkIKComponentTypes;

  public variableName: string;

  public enabled = true;

  static get code(): string {
    return 'netik';
  }

  constructor(options: NetworkIKComponentOptions) {
    super(options);
    this.variable = options.data?.variable || null;
    this.variableName = options.data?.variableName || '';
    this.type = options.data?.type || NetworkIKComponentTypes.Target;
  }

  public getState(bones: THREE.Object3D[], enabled: boolean): IKVariableValue {
    return {
      enabled,
      bones: bones.map((bone) => ({
        name: bone.name,
        quaternion: bone.quaternion.clone(),
        position: bone.position.clone(),
      })),
    };
  }

  public setState(value: IKVariableValue) {
    if (!value.enabled) return;
    value.bones.forEach((boneData) => {
      const bone = this.entity.getObjectByName(boneData.name);
      if (!bone) return;
      bone.position.copy(boneData.position);
      bone.quaternion.copy(boneData.quaternion);
    });
  }

  public setSourceType() {
    this.type = NetworkIKComponentTypes.Source;
  }

  public setTargetType() {
    this.type = NetworkIKComponentTypes.Target;
  }

  public get isSourceType() {
    return this.type === NetworkIKComponentTypes.Source;
  }

  public get isTargetType() {
    return this.type === NetworkIKComponentTypes.Target;
  }

  public disable() {
    this.enabled = false;
  }

  public enable() {
    this.enabled = true;
  }
}
