import { cloneDeep } from 'lodash';
import { AbstractSpaceGenerator, PlaceMenuItem, SpaceGeneratorServiceOptions } from './AbstractSpaceGenerator';
import {
  AudioPlacesType,
  AvatarAssetsType,
  AvatarList, AvatarsMenuItemType,
  AvatarsMenuListType,
  AvatarsModels,
  LightObjectsInterface,
  LightStateInterface,
  MaterialType,
  MirrorObject,
  PlaceObjectsType,
  SettingsType,
  ShopifyAssetsType,
  TeleportType,
  VideoBannersType,
} from '../../assets/types';
import { Entity } from '../../../engine/Entity';
import {
  AvatarConfig,
  CustomMenuConfig,
  NewAvatarsConfig,
  PreDefinedAnimationsPacks,
  PreDefinedAvatarCode,
  PreDefinedAvatarConfig,
  SpaceConfig,
} from './SpaceConfig';
import { defaultAvatarAnimations, defaultAvatarWalkDuration } from '../../assets/DefaultAvatars';
import { extendedDefaultAvatars, extendedDefaultAvatarModels, extendedDefaultMenuItems } from '../../assets/apiController/avatars';

export default class ApiSpaceGenerator extends AbstractSpaceGenerator {
  private _config?: SpaceConfig;

  constructor(private readonly configUrl: string, options: SpaceGeneratorServiceOptions) {
    super(options);
  }

  async prepareConfig(): Promise<void> {
    if (this._config) return;
    const configResponse = await fetch(this.configUrl);
    this._config = await configResponse.json() as SpaceConfig; // TODO: validate
    this.setupMeta();
  }

  protected setupMeta() {
    if (!this.config.meta) return;
    document.title = this.config.meta.title;
    document.querySelector('meta[name="description"]')?.setAttribute(
      'content',
      this.config.meta.description,
    );
    document.querySelector('link[rel~=\'icon\']')?.setAttribute(
      'href',
      this.config.settings.baseAssetsUrl + this.config.meta.favicoUrl,
    );
  }

  public generateEntities(): Promise<Entity>[] {
    return [this.buildSpace()];
  }

  private get config(): SpaceConfig {
    if (!this._config) this.ensureConfigLoaded();
    return this._config;
  }

  public get settings(): SettingsType {
    const { settings } = this.config;
    return {
      ...settings,
      background: settings.background ? {
        mobile: `${settings.baseAssetsUrl}${settings.background.mobile.replace(settings.baseAssetsUrl, '')}`,
        desktop: `${settings.baseAssetsUrl}${settings.background.desktop.replace(settings.baseAssetsUrl, '')}`,
      } : undefined,
    };
  }

  get colliderModelUrl(): string {
    return this.config.colliderModelUrl;
  }

  get spaceModelUrl(): string {
    return this.config.spaceModelUrl;
  }

  public get avatarsAssets(): AvatarAssetsType {
    const avatarsConfig = this.config.avatars as unknown as NewAvatarsConfig;
    const avatarsMenu: AvatarsMenuListType = [];
    const avatars: AvatarList = {};
    const models: AvatarsModels = {};
    let defaultAvatar = '';

    avatarsConfig.forEach((avatar) => {
      if (!this.isPredefined(avatar)) {
        models[avatar.code] = this.getSpacePath(avatar.model);
        avatars[avatar.code] = {
          model: avatar.code,
          animations: avatar.animations === PreDefinedAnimationsPacks.MALE ? 'yuki' : 'mira',
        };
      } else {
        const foundAvatar = this._getDefaultAvatarByCode(avatar.code);
        if (foundAvatar) {
          avatars[avatar.code] = (foundAvatar);
        }
      }
      if (!avatar.hideFromMenu) {
        const menuItem = this.getAvatarMenuItem(avatar);
        if (menuItem) {
          avatarsMenu.push(menuItem);
        }
      }
      if (avatar.isDefault || !defaultAvatar) {
        defaultAvatar = avatar.code;
      }
    });

    return {
      avatars,
      defaultAvatar,
      avatarsModels: { ...models, ...extendedDefaultAvatarModels },
      avatarAnimations: defaultAvatarAnimations,
      avatarWalkDuration: defaultAvatarWalkDuration,
      avatarsMenu,
    };
  }

  private getAvatarMenuItem(avatar: AvatarConfig): AvatarsMenuItemType | undefined {
    if (avatar.menu) {
      return this.buildMenuItem(avatar, avatar.menu);
    }
    return this.isPredefined(avatar) ? this._getMenuItemByCode(avatar.code) : undefined;
  }

  private buildMenuItem(avatar: AvatarConfig, menu: CustomMenuConfig): AvatarsMenuItemType {
    return {
      id: `Avatar_${avatar.code}`,
      imgUrl: menu.imgUrl,
    };
  }

  private isPredefined(avatar: AvatarConfig): avatar is PreDefinedAvatarConfig {
    return Object.values(PreDefinedAvatarCode).includes(avatar.code as PreDefinedAvatarCode);
  }

  public get lightStates(): LightStateInterface | undefined {
    return cloneDeep(this.config.lightStates);
  }

  public get lightObjects(): LightObjectsInterface | undefined {
    return cloneDeep(this.config.lightObjects);
  }

  public get videoBanners(): VideoBannersType | undefined {
    return cloneDeep(this.config.videoBanners);
  }

  public get audioPlaces(): AudioPlacesType | undefined {
    return cloneDeep(this.config.audioPlaces);
  }

  public get shopifyAssets(): ShopifyAssetsType | undefined {
    return cloneDeep(this.config.shopifyAssets);
  }

  public get shadowReceiveObjects(): string[] {
    return this.config.shadowReceiveObjects ? cloneDeep(this.config.shadowReceiveObjects) : [];
  }

  public get placeObjects(): PlaceObjectsType | undefined {
    return undefined;
  }

  public get placesMenuObjects(): PlaceMenuItem[] {
    return cloneDeep(this.config.placesMenuObjects) || [];
  }

  public get teleports(): TeleportType[] {
    return this.config.teleports ? cloneDeep(this.config.teleports) : [];
  }

  public get mirrorsConfigs(): MirrorObject[] {
    return this.config.mirrorsConfigs ? cloneDeep(this.config.mirrorsConfigs) : [];
  }

  public get wireframeObjects(): string[] {
    return this.config.wireframeObjects ? cloneDeep(this.config.wireframeObjects) : [];
  }

  public get materials(): MaterialType[] {
    return this.config.materials ? cloneDeep(this.config.materials) : [];
  }

  private ensureConfigLoaded(): never {
    throw new Error(
      `No space config exists. It's either generateEntities method was'n call
        before reading space settings, either loaded config is not correct`,
    );
  }

  private _getDefaultAvatarByCode(avatarCode: PreDefinedAvatarCode) {
    switch (avatarCode) {
      case PreDefinedAvatarCode.YUKI:
        return extendedDefaultAvatars.yuki;
      case PreDefinedAvatarCode.YUKI_HACKER:
        return extendedDefaultAvatars.yuki_hacker;
      case PreDefinedAvatarCode.MIRA:
        return extendedDefaultAvatars.mira;
      case PreDefinedAvatarCode.MIRA_SPACE:
        return extendedDefaultAvatars.mira_space;
      case PreDefinedAvatarCode.BASE_MALE:
        return extendedDefaultAvatars.base_male;
      case PreDefinedAvatarCode.BASE_FEMALE:
        return extendedDefaultAvatars.base_female;
      case PreDefinedAvatarCode.UNO_BASE_MALE:
        return extendedDefaultAvatars.uno_base_male;
      case PreDefinedAvatarCode.UNO_BASE_FEMALE:
        return extendedDefaultAvatars.uno_base_female;
      default:
        return undefined;
    }
  }

  private _getMenuItemByCode(avatarCode: `${PreDefinedAvatarCode}`) {
    switch (avatarCode) {
      case PreDefinedAvatarCode.YUKI:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_yuki');
      case PreDefinedAvatarCode.YUKI_HACKER:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_yuki_hacker');
      case PreDefinedAvatarCode.MIRA:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_mira');
      case PreDefinedAvatarCode.MIRA_SPACE:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_mira_space');
      case PreDefinedAvatarCode.BASE_MALE:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_base_male');
      case PreDefinedAvatarCode.BASE_FEMALE:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_base_female');
      case PreDefinedAvatarCode.UNO_BASE_MALE:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_uno_base_male');
      case PreDefinedAvatarCode.UNO_BASE_FEMALE:
        return extendedDefaultMenuItems.find((a) => a.id === 'Avatar_uno_base_female');
      default:
        return undefined;
    }
  }
}
