import * as Three from 'three';

export function getObjectCenter(object: Three.Object3D) {
  const centers: Three.Vector3[] = [];
  object.traverse((mesh) => {
    if (!(mesh instanceof Three.Mesh)) return;
    const { geometry } = mesh;
    geometry.computeBoundingBox();
    const center = new Three.Vector3();
    geometry.boundingBox?.getCenter(center);
    mesh.localToWorld(center);
    centers.push(center);
  });
  const avgCenter = new Three.Vector3(0, 0, 0);
  centers.forEach((center) => avgCenter.add(center));
  avgCenter.multiplyScalar(centers.length);
  return avgCenter;
}
