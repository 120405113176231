import { MathUtils, Vector3 } from 'three';
import { IKConstraint } from '../types';
import IKJoint from '../IKJoint';
import IKChain from '../IKChain';

export default class IKTestConstraint implements IKConstraint {
  apply(joint: IKJoint, chain: IKChain): boolean {
    const direction = new Vector3().copy(joint.getDirection());
    const jointIndex = chain.joints.findIndex((cj) => cj === joint);
    const parentJoint = jointIndex > 0 ? chain.joints[jointIndex - 1] : null;
    if (!parentJoint) return false;
    const localDirection = joint.worldToLocalDirection(direction);
    localDirection.normalize();
    // localDirection.x = Math.max(Math.min(localDirection.x, 120 * MathUtils.RAD2DEG), -120 * MathUtils.RAD2DEG);
    // localDirection.y = Math.max(Math.min(localDirection.x, 0.5), -0.5);
    // localDirection.z = Math.max(Math.min(localDirection.x, 0.5), -0.5);
    // console.log(localDirection);
    const worldDirection = joint.localToWorldDirection(localDirection);
    joint.setDirection(worldDirection);
    // console.log(worldDirection);
    return true;
  }
}
