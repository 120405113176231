import { Application } from '../../../engine/Application';
import { Entity } from '../../../engine/Entity';
import { XRInputSystem } from '../../../engine/systems/XRInputSystem';

// todo: make component for attach spaces
export function setupControllers(app: Application, cameraEntity: Entity): void {
  const xRInputSystem = app.getSystem(XRInputSystem);

  if (!xRInputSystem) return;

  xRInputSystem.gripSpaces.forEach((gripSpace) => {
    cameraEntity.add(gripSpace);
  });

  xRInputSystem.raySpaces.forEach((raySpace) => {
    cameraEntity.add(raySpace);
  });
}
