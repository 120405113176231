import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PoweredOwnImage from '../../assets/Loader/ownverseLogo.png';
import PoweredOwnImageSecondary from '../../assets/Loader/ownverseLogoSecondary.png';
import classNames from './PoweredOwn.module.css';
import { MAINSITE } from '../../../domain/constants/redirectPages';
import { Adaptive } from '../../../domain/ui/adaptive';
import { getLocaleSettings } from '../../../i18n/settings';

export interface PoweredOwnProps {
  loading: boolean;
  show: boolean;
}

const PoweredOwn: FC<PoweredOwnProps> = (props) => {
  const { loading, show } = props;

  const device = Adaptive.isTouchDevice() ? 'Mobile' : 'Desktop';

  const poweredIcon = getLocaleSettings().poweredIcon ?? PoweredOwnImage;
  const poweredIconSecondary = getLocaleSettings().poweredIconSecondary ?? PoweredOwnImageSecondary;

  const redirectLandingPageHandler = useCallback(() => {
    window.open(MAINSITE, '_blank');
  }, []);

  const { t } = useTranslation();

  return (
    <div
      onClick={redirectLandingPageHandler}
      className={classnames(
        classNames.powered,
        { [classNames.poweredTop]: !loading },
        { [classNames[`powered${device}`]]: !loading },
        { [classNames.poweredTablet]: !loading },
      )}
    >
      <div
        className={classnames(
          classNames.text,
          classNames.text_desktop,
          { [classNames.textDarkOrWhite]: !loading },
        )}
      >
        {t('components.poweredOwn.powered')}
        <div>
          {t('components.poweredOwn.by')}
&nbsp;
          <span className={classNames.powered_name}>{t('components.poweredOwn.ownverse')}</span>
        </div>
      </div>
      <div className={classnames(classNames.text, classNames.text_mobile, { [classNames.textDarkOrWhite]: !loading })}>
        {t('components.poweredOwn.powered')}
        {t('components.poweredOwn.by')}
&nbsp;
        <span className={classNames.powered_name}>{t('components.poweredOwn.ownverse')}</span>
      </div>
      <img
        className={classnames({ [classNames.image]: !loading })}
        alt="own"
        src={loading ? poweredIcon : poweredIconSecondary}
      />
    </div>
  );
};

export default PoweredOwn;
