import { System } from '../../../engine/System';
import { InworldAssistantComponent } from '../../components/Assistant/InworldAssistant.component';
import { phonemesToMesh } from '../../services/Assistent/Inworld/Phonemes/PhonemesToMesh';
import { FacialToMesh } from '../../services/Assistent/Inworld/Facial/FacialToMesh';
import { BlinkToMesh } from '../../services/Assistent/Inworld/Facial/BlinkToMesh';

export class InworldAssistantSystem extends System {
  onUpdate(dt: number) {
    const components = this.app.componentManager.getComponentsByType(InworldAssistantComponent);
    components.forEach((component) => {
      if (!component.enabled) return;
      this.handleBlink(component, dt);
      this.handlePhonemes(component, dt);
      this.handleFacial(component, dt);
      this.handleAnimation(component, dt);
    });
  }

  protected handleBlink(component: InworldAssistantComponent, dt: number) {
    component.elapsdTime += dt;
    BlinkToMesh(component.meshes, {
      elapsdTime: component.elapsdTime,
      eyesClosedIndex: component.eyesClosedIndex,
    });
  }

  protected handlePhonemes(component: InworldAssistantComponent, dt: number) {
    if (component.phonemes.length <= 0 || component.visemeStartIndex < 0) return;
    const { offset } = phonemesToMesh(
      component.phonemes,
      component.meshes,
      component.visemeStartIndex,
      dt,
      component.visemeOffsetS,
    );
    component.visemeOffsetS = offset;
    if (offset === 0) component.setPhonemes([]);
  }

  protected handleFacial(component: InworldAssistantComponent, dt: number) {
    const { lastEmo, currMorph } = FacialToMesh(component.meshes, {
      dt,
      LastEmo: component.lastEmo,
      CurrEmo: component.currEmo,
      CurrMorph: component.currMorph,
    });
    component.currMorph = currMorph;
    component.lastEmo = lastEmo;
  }

  protected handleAnimation(component: InworldAssistantComponent, dt: number) {
    component.updateAnimation(dt);
  }
}
