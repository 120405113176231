import VideoComponent from '../../components/VideoComponent';
import EngineVariable from './EngineVariable';
import VideoVariablePayload, { VideoVariableValueType } from '../payloads/VideoVariablePayload';
import { VariablePayload } from '../../../engine/network/payloads/VariablePayload';

export default class VideoVariable extends EngineVariable<VideoVariableValueType> {
  public static type = 'video';

  public static payloadType: typeof VariablePayload<VideoVariableValueType> = VideoVariablePayload;

  public required = true;

  public autoSync = true;

  constructor(name = 'video') {
    super(name);
  }

  public reset() {
    const component = this.getComponent(VideoVariable.app, VideoComponent);
    if (component) {
      this.set(component.videoState);
    }
  }

  // then local changed --> update variable == send state to network
  public updateFromLocal(): boolean {
    const { app } = VideoVariable;
    if (!app) return false;
    const component = this.getComponent(app, VideoComponent);
    if (!component || !component.enabled) return false;

    const state = component.videoState;
    this.set(state);
    return true;
  }

  // then receive changed from network -> get variable state & update local values
  public updateFromNetwork(): boolean {
    const { app } = VideoVariable;
    if (!app) return false;
    const component = this.getComponent(app, VideoComponent);
    if (!component || !component.enabled) return false;
    if (!this.value) return false;
    component.setState(this.value);
    return true;
  }
}
