import { Button } from '../Button';
import { ButtonId } from '../../enum/ButtonId';
import * as Theme from '../../Theme';

export type TextButtonParams = {
  id: string;
  text: string;
  width?: number;
  height?: number;
  titleWidth?: number;
};
export function TextButton(params: TextButtonParams) {
  const width = params.width ?? 120;
  return Button({
    id: params.id,
    width: params.width ?? 115,
    titleWidth: params.titleWidth ?? width * 0.8,
    height: params.height ?? 48,
    title: params.text,
    fontName: Theme.fontMainBold.name,
    letterSpacing: Theme.letterSpacing,
    titleFontSize: 24,
    // backgroundColor: '#D4FF00',
    // hoverColor: '#E7FF72',
    // activeColor: '#AACD00',
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0.1,
    hoverColor: '#FFFFFF',
    hoverOpacity: 0.3,
    activeColor: '#5156D1',
    fontColor: '#FFFFFF',
    selectedColor: '#D4FF00',
    fontColorSelected: '#3D0F99',
    selected: true,
  });
}
