import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class TopMenuAdaptive extends Adaptive {
  get isMobile() {
    return this.app && this.elementSize.width < 868;
  }

  get width() {
    return 5 * this.buttonSize + 5 * this.padding - (!this.isMobile ? 2 * this.padding : 0);
  }

  get height() {
    return 1.5 * this.buttonSize;
  }

  get scale() {
    const base = 0.0004;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.6 : 1;
    return kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return this.isMobile ? 8 : 32;
  }

  get buttonSize() {
    return this.isMobile ? 48 : 64;
  }

  get position() {
    const margin = this.isMobile ? 0.03 : 0;
    return new Three.Vector3(
      this.right(this.width / 2),
      (this.top(this.height / 2)) - margin,
      -this.z,
    );
  }
}
