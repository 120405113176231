import * as Three from 'three';
import { Component, ComponentOptions } from '../../engine/Component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import { Icon } from '../ui/elements';
import { addInteractivityToElement } from '../ui/helpers/addInteractivityToElement';

export type ActionIconComponentOptions = ComponentOptions & {
  data?: {
    texture: Three.Texture;
    hoverTexture: Three.Texture;
    activeTexture: Three.Texture;
    width?: number;
    height?: number;
    lookToCamera?: boolean;
    showRadius?: number;
    uiDocument: UIDocumentComponent;
  };
};

export class ActionIconComponent extends Component {
  public texture?: Three.Texture;

  public hoverTexture?: Three.Texture;

  public activeTexture?: Three.Texture;

  public isActiveInCurrentFrame = false;

  public enabled = true;

  public width = 0.25;

  public height = 0.25;

  public lookToCamera = true;

  public showRadius = 0;

  public uiId = 'Action';

  static get code(): string {
    return 'action_icon_';
  }

  constructor(options: ActionIconComponentOptions) {
    super(options);
    this.texture = options.data?.texture;
    this.hoverTexture = options.data?.hoverTexture;
    this.activeTexture = options.data?.activeTexture;
    this.width = options.data?.width ?? this.width;
    this.height = options.data?.height ?? this.height;
    this.showRadius = options.data?.showRadius ?? this.showRadius;
    this.lookToCamera = typeof options.data?.lookToCamera !== 'undefined' ? options.data?.lookToCamera : this.lookToCamera;
    options.data?.uiDocument.setRoot(this.makeUITemplate());
  }

  public destroy() {
    this.texture?.dispose();
    this.activeTexture?.dispose();
    this.hoverTexture?.dispose();
  }

  protected makeUITemplate() {
    const icon = Icon({
      width: this.width,
      height: this.height,
    });
    icon.name = 'ActionIcon';
    // ((icon.children[0] as Three.Mesh).material as Three.Material).depthWrite = false;
    // ((icon.children[0] as Three.Mesh).material as Three.Material).blending = Three.AdditiveBlending;
    addInteractivityToElement({
      id: this.uiId,
      element: icon,
      defaultAttributes: { backgroundTexture: this.texture },
      hoveredAttributes: { backgroundTexture: this.hoverTexture },
      activeAttributes: { backgroundTexture: this.activeTexture || this.hoverTexture },
    });
    icon.userData.uiData = icon.userData.uiData || {};
    icon.userData.uiData.depth = true;
    return icon;
  }

  public getUiElement() {
    const el = this.entity.getComponentOrFail(UIDocumentComponent).root;
    if (!el) throw new Error('Not found root ui element in ActionActionComponent');
    return el;
  }

  public hide() {
    const uiElement = this.getUiElement();
    if (uiElement) uiElement.visible = false;
  }

  public show() {
    const uiElement = this.getUiElement();
    if (uiElement) uiElement.visible = true;
  }

  public switch(value: boolean) {
    if (value) this.show();
    else this.hide();
  }
}
