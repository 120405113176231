import { engine, google } from '../../../generated';
import { VariablePayload } from './VariablePayload';
import payloads = engine.network.payloads;
import Any = google.protobuf.Any;
import IAny = google.protobuf.IAny;

export type TransformVariableType = {
  position: { x: number; y: number; z: number };
  quaternion: { x: number; y: number; z: number; w: number };
};

export class TransformVariablePayload extends VariablePayload<TransformVariableType> {
  public static payloadFromProto(payload: IAny): TransformVariableType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = payloads.TransformVariablePayload.decode(payload.value);
    return {
      position: {
        x: decoded?.position?.x as number,
        y: decoded?.position?.y as number,
        z: decoded?.position?.z as number,
      },
      quaternion: {
        x: decoded?.quaternion?.x as number,
        y: decoded?.quaternion?.y as number,
        z: decoded?.quaternion?.z as number,
        w: decoded?.quaternion?.w as number,
      },
    };
  }

  public payloadToProto(): Any | null {
    const payload = payloads.TransformVariablePayload.create({
      quaternion: {
        x: this.target.value?.quaternion.x,
        y: this.target.value?.quaternion.y,
        z: this.target.value?.quaternion.z,
        w: this.target.value?.quaternion.w,
      },
      position: {
        x: this.target.value?.position.x,
        y: this.target.value?.position.y,
        z: this.target.value?.position.z,
      },
    });
    return Any.create({
      type_url: this.targetType,
      value: payloads.TransformVariablePayload.encode(payload).finish(),
    });
  }
}
