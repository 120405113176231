import React, { FC } from 'react';
import classNames from '../EnterScreen.module.css';
import IconButton from '../../../../../components/IconButton/IconButton';
import Icon from '../../../../../components/Icons/Icon';

export interface ControllerProps {
  onChangeMicrophone: () => void;
  microActive: boolean;
  onChangeCamera: () => void;
  cameraActive: boolean;
  onChangeSound: () => void;
  soundActive: boolean;
}

const Controller: FC<ControllerProps> = (props) => {
  const {
    onChangeMicrophone = () => undefined,
    microActive = false,
    onChangeCamera = () => undefined,
    cameraActive = false,
    onChangeSound = () => undefined,
    soundActive = false,
  } = props;

  return (
    <div className={classNames.buttons}>
      <IconButton
        onClick={() => onChangeMicrophone()}
      >
        <Icon name={microActive ? 'microphone' : 'slashMicrophone'} />
      </IconButton>
      <IconButton
        onClick={() => onChangeCamera()}
      >
        <Icon name={cameraActive ? 'camera' : 'slashCamera'} />
      </IconButton>
      <IconButton
        onClick={() => onChangeSound()}
      >
        <Icon name={soundActive ? 'sound' : 'slashSound'} />
      </IconButton>
    </div>
  );
};

export default Controller;
