export default abstract class Payload<TargetType, DumpType> {
  protected target: TargetType;

  constructor(target: TargetType) {
    this.target = target;
  }

  public abstract toJson(): DumpType;

  public abstract fromJson(data: DumpType): void;

  public abstract toProto(): Uint8Array;
}
