import * as Three from 'three';
import Ammo from 'ammo.js';
import { ConvexHull } from 'three/examples/jsm/math/ConvexHull';

export default class ThreeToAmmoConvexHullConverter {
  public static makeConvexHullShape(mesh: Three.Mesh): Ammo.btConvexHullShape {
    const geometry = ThreeToAmmoConvexHullConverter.createConvexGeom(mesh);
    const coords = geometry.attributes.position.array;
    const tempVec = new Ammo.btVector3(0, 0, 0);
    const shape = new Ammo.btConvexHullShape();
    for (let i = 0, il = coords.length; i < il; i += 3) {
      tempVec.setValue(coords[i], coords[i + 1], coords[i + 2]);
      const lastOne = (i >= (il - 3));
      shape.addPoint(tempVec, lastOne);
    }
    return shape;
  }

  public static createConvexGeom(object: Three.Mesh) {
    // Compute the 3D convex hull.
    const hull = new ConvexHull().setFromObject(object);
    const { faces } = hull;
    const vertices = [];
    const normals = [];

    for (let i = 0; i < faces.length; i++) {
      const face = faces[i];
      let { edge } = face;
      do {
        const { point } = edge.head();
        vertices.push(point.x, point.y, point.z);
        normals.push(face.normal.x, face.normal.y, face.normal.z);
        edge = edge.next;
      } while (edge !== face.edge);
    }

    const geom = new Three.BufferGeometry();
    geom.setAttribute('position', new Three.Float32BufferAttribute(vertices, 3));
    geom.setAttribute('normal', new Three.Float32BufferAttribute(normals, 3));

    return geom;
  }
}
