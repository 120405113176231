import * as Three from 'three';
import * as ThreeMeshUI from 'three-mesh-ui';
import { System, SystemOptions } from '../System';
import { UIDocumentComponent } from '../components/UIDocument.component';
import { InputSystem } from './InputSystem';
import { ControllerName, XRInputSystem } from './XRInputSystem';

export enum UIDocumentElementState {
  Default = 'Default',
  Active = 'Active',
  Hovered = 'Hovered',
  User = 'User',
  Selected = 'Selected',
}

export type UIDocumentElementData = {
  id?: string;
  interactive?: boolean;
  defaultState?: UIDocumentElementState;
};

export type UIDocumentSystemOptions = SystemOptions & {
  raycastLayers?: number[];
  drawLayers?: number[];
  disableDepth?: boolean;
  dotInVR?: Three.Mesh;
};

export class UIDocumentSystem extends System {
  protected rayCaster: Three.Raycaster = new Three.Raycaster();

  protected drawLayers?: number[];

  protected disableDepth = true;

  protected cursorInVR?: Three.Mesh;

  public vrCursorDelta = new Three.Vector2(0, 0);

  static get code(): string {
    return 'u_i_document';
  }

  static buildDefaultCursorInVR(layer = 0, color = 0xffffff, size = 0.005) {
    const mesh = new Three.Mesh(
      new Three.SphereGeometry(size), new Three.MeshBasicMaterial({
        color,
        depthWrite: false,
        depthTest: false,
        transparent: false,
      }),
    );
    mesh.layers.set(layer);
    return mesh;
  }

  constructor(options: UIDocumentSystemOptions) {
    super(options);
    if (options.raycastLayers) {
      options.raycastLayers.forEach((layer, index) => {
        if (index === 0) this.rayCaster.layers.set(layer);
        else this.rayCaster.layers.enable(layer);
      });
    }
    this.disableDepth = options.disableDepth ?? this.disableDepth;
    this.drawLayers = options.drawLayers;
    this.cursorInVR = options.dotInVR;
    if (this.cursorInVR) this.app.sceneManager.currentThreeScene?.add(this.cursorInVR);
  }

  public onUpdate(ts: number) {
    const components = this.componentManager.getComponentsByType(UIDocumentComponent);
    this.resetBrowserCursor(components);
    this.resetXRCursor(components);
    components.forEach((component) => {
      this.updateState(component, false);
      this.updateLayers(component);
      this.updateDepth(component);
    });
    this.handleBrowserCursor(components);
    this.handleXRCursor(components);
    ThreeMeshUI.update();
  }

  public resetXRCursor(components: UIDocumentComponent[]) {
    if (this.cursorInVR) {
      this.cursorInVR.visible = false;
    }
    if (this.app.renderer.xr.isPresenting) {
      this.vrCursorDelta.set(0, 0);
      const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
      xrInputSystem.setRaysLength();
    }
    components.forEach((cm) => {
      cm.intersectDistance = 0;
    });
  }

  public handleXRCursor(components: UIDocumentComponent[]) {
    if (!this.app.renderer.xr.isPresenting || !this.cursorInVR) return;
    let closestComponent: UIDocumentComponent | null = null;
    let closeDistance = 10000000;
    components.forEach((cm) => {
      if (cm.intersectDistance > 0 && cm.intersectDistance < closeDistance) {
        closestComponent = cm;
        closeDistance = cm.intersectDistance;
      }
    });
    if (closestComponent) {
      const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
      this.cursorInVR.visible = true;
      const oldPosition = this.cursorInVR.position;
      const position = (closestComponent as UIDocumentComponent).intersectPoint;
      this.vrCursorDelta.set(-position.x + oldPosition.x, -position.y + oldPosition.y);
      this.cursorInVR.position.copy(position);
      xrInputSystem.setRaysLength((closestComponent as UIDocumentComponent).intersectDistance - 0.1);
    }
  }

  public updateDepth(component: UIDocumentComponent) {
    if (!component.root || !this.disableDepth) return;
    component.root.traverse((ch) => {
      // TODO: think about this
      if ((ch as Three.Mesh).material) {
        const uiData = this.getUIData(ch);
        // if (uiData && uiData.depth) return;
        ((ch as Three.Mesh).material as Three.MeshStandardMaterial).depthTest = uiData.depth;
        // ((ch as Three.Mesh).material as Three.MeshStandardMaterial).depthWrite = uiData.depth;
      }
    });
  }

  protected getUIData(obj: Three.Object3D) {
    const parents = [];
    let currentParent = obj.parent;
    while (currentParent) {
      parents.push(currentParent);
      currentParent = currentParent.parent;
    }
    const element = parents.find((el) => el.userData.uiData);
    if (element) return element.userData.uiData;
    return null;
  }

  public updateLayers(component: UIDocumentComponent) {
    if (!component.root) return;
    if (!this.drawLayers) return;
    component.root.traverse((ch) => {
      const uiData = this.getUIData(ch);
      const layers = uiData?.layer ? [uiData.layer] : this.drawLayers;
      if (!layers || layers.length === 0) return;
      for (let i = 0; i < layers.length; i++) {
        if (i === 0) ch.layers.set(layers[i]);
        else ch.layers.enable(layers[i]);
      }
    });
  }

  public updateState(component: UIDocumentComponent, recalculate = true): void {
    this.resetElementState(component);
    if (component.enabled) {
      if (this.app.renderer.xr.isPresenting) {
        this.handleXRHover(component);
        this.handleXRActive(component);
      } else {
        this.handleBrowserHover(component);
        this.handleBrowserActive(component);
      }
    }
    if (recalculate) ThreeMeshUI.update();
  }

  public resetBrowserCursor(components: UIDocumentComponent[]): void {
    this.app.renderer.domElement.style.cursor = 'default'; // TODO: to settings
    components.forEach((component) => {
      component.hovered = false;
    });
  }

  public handleBrowserCursor(components: UIDocumentComponent[]): void {
    components.forEach((component) => {
      if (component.hovered && component.enabled) {
        this.app.renderer.domElement.style.cursor = component.hoveredCursor;
      }
    });
  }

  protected handleBrowserHover(component: UIDocumentComponent): void {
    if (!component.root || !this.app.camera) return;

    const intersections = this.getElementsBrowserIntersections(component.root, this.app.camera);
    this.handleHoverInBrowserIntersections(component, intersections);
  }

  protected handleXRHover(component: UIDocumentComponent): void {
    if (!component.root) return;

    const intersections = this.getElementsXRIntersections(component.root);
    this.handleHoverInXRIntersections(component, intersections);
  }

  protected handleXRActive(component: UIDocumentComponent): void {
    const [leftIsPressed, rightIsPressed] = this.app.getSystemOrFail(XRInputSystem).xrStandardTriggersPressedInCurrentFrame();

    Object.keys(component.elementStateDataList).forEach((id) => {
      const stateData = component.elementStateDataList[id];

      if (stateData.state !== UIDocumentElementState.Hovered) return;

      const isLeft = stateData.source?.controllerName === ControllerName.Left;
      const isRight = stateData.source?.controllerName === ControllerName.Right;

      if ((isLeft && leftIsPressed) || (isRight && rightIsPressed)) {
        component.elementStateDataList[id].state = UIDocumentElementState.Active;
        const element = component.getElementById(id);
        if (element) this.setElementState(element, UIDocumentElementState.Active);
      }
    });
  }

  protected handleBrowserActive(component: UIDocumentComponent): void {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    if (
      (inputSystem.touchscreen.isPresent && inputSystem.touchscreen.primaryTouch.press.wasPressedThisFrame)
      || inputSystem.mouse.leftButton.isPressed || inputSystem.mouse.leftButton.wasReleasedThisFrame) {
      Object.keys(component.elementStateDataList).forEach((id) => {
        if (component.elementStateDataList[id].state === UIDocumentElementState.Hovered) {
          if (inputSystem.mouse.leftButton.wasReleasedThisFrame
                || inputSystem.touchscreen.primaryTouch.press.wasPressedThisFrame
          ) {
            component.elementStateDataList[id].state = UIDocumentElementState.Active;
          }
          const element = component.getElementById(id);
          if (element) {
            this.setElementState(element, UIDocumentElementState.Active);
          }
        }
      });
    }
  }

  protected getElementStates(element: ThreeMeshUI.Block): string[] {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return element.states || [];
  }

  protected setElementState(element: ThreeMeshUI.Block, state: UIDocumentElementState): void {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element.setState(state);
  }

  protected getElementFromFrame(frame: Three.Object3D): ThreeMeshUI.Block | undefined {
    if (!frame.parent) return;
    if (!(frame.parent instanceof ThreeMeshUI.Block)) return;

    return frame.parent;
  }

  public isAnyDocumentHovered(exclude: UIDocumentComponent[] = []): boolean {
    let hovered = false;
    this.app.componentManager.getComponentsByType(UIDocumentComponent)
      .filter((component) => component.enabled && !exclude.includes(component))
      .forEach((component) => {
        if (hovered) return;
        const states = component.elementStateDataList;
        Object.keys(states).forEach((name) => {
          if (hovered) return;
          hovered = states[name].state === UIDocumentElementState.Hovered
            || states[name].state === UIDocumentElementState.Active;
        });
      });
    return hovered;
  }

  protected getElementData(element: ThreeMeshUI.Block): UIDocumentElementData {
    return element.userData?.uiData || {};
  }

  protected getElementsXRIntersections(rootElement: ThreeMeshUI.Block): Three.Intersection[][] {
    const xRInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const raySpaces = [
      xRInputSystem.getRaySpace(ControllerName.Left),
      xRInputSystem.getRaySpace(ControllerName.Right),
    ];

    return raySpaces.map((raySpace) => {
      if (!raySpace) return [];
      const rotationMatrix = new Three.Matrix4();
      rotationMatrix.extractRotation(raySpace.matrixWorld);
      this.rayCaster.ray.origin.setFromMatrixPosition(raySpace.matrixWorld);
      this.rayCaster.ray.direction.set(0, 0, -1).applyMatrix4(rotationMatrix);

      return this.rayCaster.intersectObject(rootElement, true);
    }, []);
  }

  protected getElementsBrowserIntersections(rootElement: ThreeMeshUI.Block, camera: Three.Camera): Three.Intersection[] {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    if (inputSystem.touchscreen.isPresent && !inputSystem.touchscreen.primaryTouch.press.isPressed) return [];
    this.rayCaster.setFromCamera(
      inputSystem.touchscreen.isPresent && inputSystem.touchscreen.primaryTouch.press.isPressed
        ? inputSystem.touchscreen.primaryTouch.position
        : inputSystem.mouse.position, camera,
    );

    return this.rayCaster.intersectObject(rootElement, true);
  }

  protected handleHoverInXRIntersections(component: UIDocumentComponent, totalIntersections: Three.Intersection[][]): void {
    const controllerNames = [ControllerName.Left, ControllerName.Right];
    const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);

    totalIntersections.forEach((intersections, intersectionsIndex) => {
      intersections.forEach((intersect) => {
        const element = this.getElementFromFrame(intersect.object);

        if (!element || !element.visible) return;
        if (intersect.distance > component.availableOnDistance) return;

        component.intersectPoint.copy(intersect.point);
        component.intersectDistance = intersect.distance;

        const elementData = this.getElementData(element);

        if (!elementData.id || !elementData.interactive) return;

        this.setElementState(element, UIDocumentElementState.Hovered);

        component.elementStateDataList[elementData.id] = {
          state: UIDocumentElementState.Hovered,
          source: {
            type: 'xr',
            controllerName: controllerNames[intersectionsIndex],
          },
        };
      });
    });
  }

  protected handleHoverInBrowserIntersections(component: UIDocumentComponent, intersections: Three.Intersection[]): void {
    intersections.forEach((intersect) => {
      const element = this.getElementFromFrame(intersect.object);

      if (!element || !element.visible) return;
      if (intersect.distance > component.availableOnDistance) return;

      const elementData = this.getElementData(element);

      if (!elementData.id || !elementData.interactive) return;

      this.setElementState(element, UIDocumentElementState.Hovered);
      component.hovered = true;

      component.elementStateDataList[elementData.id] = {
        state: UIDocumentElementState.Hovered,
        source: {
          type: 'browser',
          controllerName: 'mouse',
        },
      };
    });
  }

  protected resetElementState(component: UIDocumentComponent): void {
    component.elementStateDataList = {};

    if (!component.root) return;

    component.root.traverse((element) => {
      if (!(element instanceof ThreeMeshUI.Block)) return;
      const data = this.getElementData(element);

      if (!data.interactive || !data.id) return;

      const defaultState = data.defaultState ?? UIDocumentElementState.Default;
      component.elementStateDataList[data.id] = { state: defaultState };
      this.setElementState(element, defaultState);
    });
  }
}
