import { FC } from 'react';
import classnames from 'classnames';
import { AvatarIcon } from '../AvatarIcon/AvatarIcon';
import classNames from './AvatarButton.module.css';

export type AvatarButtonProps = {
  onClick?: () => void;
  className?: string;
};
const AvatarButton: FC<AvatarButtonProps> = (props: AvatarButtonProps) => {
  const {
    onClick = () => undefined,
    className,
  } = props;
  return (
    <div
      onClick={onClick}
      className={classnames(className, classNames.root)}
    >
      <AvatarIcon />
    </div>
  );
};

export default AvatarButton;
