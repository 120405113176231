import { CHAT_HISTORY_TYPE, HistoryItem } from '@inworld/web-sdk';
import { CombinedHistoryItem, EmotionsMap } from '../types';
import { getEmoji } from './emoji';

export class InworldMessages {
  // TODO: create messages service and move this to this service

  public getCombinedHistoryItems(history: HistoryItem[]): {
    records: CombinedHistoryItem[]; currentRecord?: CombinedHistoryItem;
  } {
    let currentRecord: CombinedHistoryItem | undefined;
    const mergedRecords: CombinedHistoryItem[] = [];
    for (let i = 0; i < history.length; i++) {
      const item = history[i];
      switch (item.type) {
        case CHAT_HISTORY_TYPE.ACTOR:
        case CHAT_HISTORY_TYPE.NARRATED_ACTION:
          currentRecord = mergedRecords.find(
            (r) => r.interactionId === item.interactionId
              && [
                CHAT_HISTORY_TYPE.ACTOR,
                CHAT_HISTORY_TYPE.NARRATED_ACTION,
              ].includes(r.messages?.[0]?.type)
              && r.type === CHAT_HISTORY_TYPE.ACTOR
              && r.source.name === item.source.name,
          ) as CombinedHistoryItem;

          if (currentRecord) {
            currentRecord.messages.push(item);
          } else {
            currentRecord = {
              interactionId: item.interactionId,
              messages: [item],
              source: item.source,
              type: CHAT_HISTORY_TYPE.ACTOR,
            } as CombinedHistoryItem;
            mergedRecords.push(currentRecord);
          }
          break;
        default:
          break;
        // case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
        //   mergedRecords.push({
        //     interactionId: item.interactionId!,
        //     messages: [item],
        //     source: item.source,
        //     type: item.type,
        //   });
        //   break;
      }
    }
    return {
      records: mergedRecords,
      currentRecord,
    };
  }

  public isInteractionEnd(history: HistoryItem[], currentRecord?: CombinedHistoryItem): boolean {
    const hasActors = history.find(
      (record: HistoryItem) => record.type === CHAT_HISTORY_TYPE.ACTOR,
    );
    const withoutTriggerEvents = history.filter(
      (record: HistoryItem) => [CHAT_HISTORY_TYPE.ACTOR, CHAT_HISTORY_TYPE.INTERACTION_END].includes(
        record.type,
      ),
    );
    // Interaction is considered ended
    // when there is no actor action yet (chat is not started)
    // or last received message is INTERACTION_END.
    const lastInteractionId = withoutTriggerEvents[withoutTriggerEvents.length - 1]?.interactionId;

    const interactionEnd = withoutTriggerEvents.find(
      (event) => event.interactionId === lastInteractionId
        && event.type === CHAT_HISTORY_TYPE.INTERACTION_END,
    );
    return !hasActors || (!!currentRecord && !!interactionEnd);
  }

  public extractMessage(item: CombinedHistoryItem, emotions: EmotionsMap) {
    let emoji: string | null = null;
    const { messages } = item;
    let actorSource = 'AGENT';
    const message = item.messages?.[0];

    const title = item.type === CHAT_HISTORY_TYPE.ACTOR
    || item.type === CHAT_HISTORY_TYPE.TRIGGER_EVENT
      ? `${this.dateWithMilliseconds(message.date)} (${item.interactionId})`
      : '';

    if (item.type === CHAT_HISTORY_TYPE.ACTOR) {
      actorSource = item.source.isCharacter ? 'AGENT' : 'PLAYER';

      if (item.source.isCharacter) {
        const emotion = emotions[item.interactionId!];
        if (emotion?.behavior) {
          emoji = getEmoji(emotion.behavior);
        }
      }
    }
    return {
      title,
      emoji,
      actorSource,
      messages,
    };
  }

  private dateWithMilliseconds(date: Date) {
    return `${date.toLocaleString()}.${date.getMilliseconds()}`;
  }
}
