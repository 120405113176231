import * as Three from 'three';
import { System } from '../../engine/System';
import SelectedObjectComponent from '../components/SelectedObject.component';
import RaycastComponent, { RaycastComponentState } from '../components/Raycast.component';
import { ControllerName, XRInputSystem } from '../../engine/systems/XRInputSystem';
import { InputSystem } from '../../engine/systems/InputSystem';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import AvatarHelpers from '../services/AvatarHelpers';
import { getObjectCenter } from '../services/getObjectCenter';

export default class SelectedObjectSystem extends System {
  public onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(SelectedObjectComponent).forEach((component) => {
      this.handleHover(component);
      this.handleSelection(component);
    });
  }

  protected handleHover(component: SelectedObjectComponent) {
    const uiSystem = this.app.getSystem(UIDocumentSystem);
    const raycast = component.entity.getComponentOrFail(RaycastComponent);
    let isRaycastActive = this.isRaycastActive(component, raycast);
    if (uiSystem && uiSystem.isAnyDocumentHovered()) isRaycastActive = false;
    component.entity.traverse((obj) => {
      if (!(obj instanceof Three.Mesh)) return;
      if (isRaycastActive /* && !component.isActive */) {
        obj.material.color.copy(component.hoverColor);
      } else {
        obj.material.color.copy(component.defaultColor);
      }
    });
  }

  protected handleSelection(component: SelectedObjectComponent) {
    const uiSystem = this.app.getSystemOrFail(UIDocumentSystem);
    if (uiSystem.isAnyDocumentHovered()) return;
    const raycast = component.entity.getComponentOrFail(RaycastComponent);
    const isRaycast = this.isRaycastActive(component, raycast);
    const isClicked = this.isClicked(raycast.state);
    if (isRaycast && isClicked) {
      component.isActive = true;
    }
    // click outside
    if (!raycast.isActive && isClicked) {
      component.isActive = false;
    }
    if (!this.checkCameraDistance(component)) {
      component.isActive = false;
    }
  }

  public checkCameraDistance(component: SelectedObjectComponent) {
    const head = AvatarHelpers.getHead(this.app);
    return head && (
      head?.getWorldPosition(new Three.Vector3())
        .distanceTo(getObjectCenter(component.entity)) < component.distance);
  }

  public getDistanceRaycastComponent(raycastComponent: RaycastComponent): number {
    if (raycastComponent.state.intersections && raycastComponent.state.intersections[0]) {
      const { distance } = raycastComponent.state.intersections[0];
      return distance;
    }
    return 0;
  }

  public isRaycastActive(component: SelectedObjectComponent, raycastComponent: RaycastComponent) {
    return raycastComponent.isActive && this.getDistanceRaycastComponent(raycastComponent) < component.distance
      && this.checkCameraDistance(component);
  }

  public isClicked(state: RaycastComponentState): boolean {
    if (this.app.renderer.xr.isPresenting) {
      const xrInput = this.app.getSystemOrFail(XRInputSystem);
      const isLeft = state.source?.controllerName === ControllerName.Left;
      const isRight = state.source?.controllerName === ControllerName.Right;
      const [leftClick, rightClick] = xrInput.xrStandardTriggersPressedInCurrentFrame();
      return (isLeft && leftClick) || (isRight && rightClick);
    }
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    return inputSystem.touchscreen.primaryTouch.press.wasPressedThisFrame
      || inputSystem.mouse.leftButton.wasReleasedThisFrame;
  }
}
