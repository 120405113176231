import { Container } from '../Container';
import { Icon } from '../Icon';
import { TextBlock } from '../TextBlock';
import * as Theme from '../../Theme';

export type SmallCardParams = {
  width?: number;
  height?: number;
  iconWidth?: number;
  iconHeight?: number;
  imageUrl: string;
  text: string;
  fontSize?: number;
  margin?: number;
};
export function SmallCard(params: SmallCardParams) {
  const options = {
    width: 140,
    height: 140,
    iconWidth: 64,
    iconHeight: 48,
    ...params,
  };
  return Container({
    width: options.width,
    height: options.height,
    contentDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    margin: params.margin ?? 0,
    // backgroundOpacity: 1,
  }, [
    Icon({
      width: options.iconWidth,
      height: options.iconHeight,
      imageUrl: options.imageUrl,
    }),
    TextBlock({
      container: {
        width: options.width,
        height: options.height - options.iconHeight,
        margin: 10,
      },
      text: {
        content: options.text,
        fontName: Theme.fontMain.name,
        fontOpacity: 1,
        fontSize: params.fontSize ?? 22,
        color: '#FFFFFF',
        letterSpacing: -0.05,
      },
    }),
  ]);
}
