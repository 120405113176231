import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class ShopifyMenuAdaptive extends Adaptive {
  get isMobile() {
    return this.elementSize.width < 550;
  }

  get width() {
    return this.isMobile ? 328 : 616;
  }

  get height() {
    return this.isMobile ? 677 : 320;
  }

  get scale() {
    const base = 0.00045;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    const kfVR = this.isVRSession ? 1.7 : 1;
    return kfVR * kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return 16;
  }

  get imagePanelHeight() {
    return this.isMobile ? 345 : this.height;
  }

  get imagePanelWidth() {
    return this.isMobile ? this.width : this.width * 0.4;
  }

  get imageHeight() {
    return this.isMobile ? 312 : 222;
  }

  get buttonWithImage() {
    return !this.isMobile;
  }

  get textPanelWidth() {
    return this.isMobile ? this.width : this.width * 0.58;
  }

  get textPanelHeight() {
    return this.isMobile ? 326 : this.height;
  }

  get titleHeight() {
    return this.isMobile ? 34 : 54;
  }

  get titleSpace() {
    return this.isMobile ? 6 : 12;
  }

  get descriptionHeight() {
    const height = this.textPanelHeight - this.titleHeight - this.padding * 2 - this.titleSpace;
    return this.isMobile ? height - 45 : height;
  }

  get borderRadius(): [number, number, number, number] {
    return [24, 24, 24, 24];
  }

  get contentDirection() {
    return this.isMobile ? 'column' : 'row';
  }

  get position() {
    let x = 0.13;
    const { width } = this.elementSize;
    if (width < 915) x = 0;
    return new Three.Vector3(
      x,
      this.isVRSession ? this.headPositionY - 0.2 : 0,
      -this.z,
    );
  }
}
