import { Component } from '../../../engine/Component';
import AvatarVideoComponent from './AvatarVideo.component';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';

export default class AvatarNamePanelComponent extends Component {
  public get videoPanelComponent() {
    const userId = this.entity.getComponentFromParents(NetworkObjectComponent)?.netObject?.ownerId;
    return this.entity.app.componentManager.getComponentsByType(AvatarVideoComponent).find((cm) => cm.userId === userId);
  }

  static get code(): string {
    return 'avatar_panel';
  }
}
