import * as Three from 'three';
import { Application } from '../../../engine/Application';

// todo: refactor to components
export function setupSceneSettings(app: Application, threeScene: Three.Scene): void {
  app.renderer.outputEncoding = Three.sRGBEncoding;
  // app.renderer.toneMapping = Three.NoToneMapping;
  // app.renderer.toneMappingExposure = 1;
  app.renderer.toneMapping = Three.LinearToneMapping;
  app.renderer.toneMappingExposure = 1.1;
  app.renderer.shadowMap.enabled = true;
  // app.renderer.shadowMap.type = Three.BasicShadowMap;
  app.renderer.shadowMap.type = Three.PCFSoftShadowMap;
  // app.renderer.sortObjects = false;
}
