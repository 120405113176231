import { System } from '../../engine/System';
import VrmIKComponent from '../components/VrmIKComponent';
import { InputSystem } from '../../engine/systems/InputSystem';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';

export default class VrmIKSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);
    this.app.componentManager.getComponentsByType(VrmIKComponent).forEach((component) => {
      // component.enabled = !!this.app.renderer.xr.isPresenting;
      if (!component.enabled) return;
      component.saveGoalsPosition();
      component.ikSolver?.update();
      if (this.activeToggleDebug(component)) {
        component.toggleDebug();
      }
      if (this.activeToggleTransforms(component)) {
        component.toggleTransformsMode();
      }
    });
  }

  public activeToggleDebug(component: VrmIKComponent) {
    // TODO: fix avatar & character entity structure
    const netObject = component.entity.getComponentFromParents(NetworkObjectComponent)?.netObject;
    if (netObject && !netObject.isOwner()) return false;
    const input = this.app.getSystemOrFail(InputSystem);
    return input.keyboard.getKeyByCode('KeyI').wasPressedThisFrame;
  }

  public activeToggleTransforms(component: VrmIKComponent) {
    const netObject = component.entity.getComponentFromParents(NetworkObjectComponent)?.netObject;
    if (netObject && !netObject.isOwner()) return false;
    const input = this.app.getSystemOrFail(InputSystem);
    return input.keyboard.getKeyByCode('KeyT').wasPressedThisFrame;
  }
}
