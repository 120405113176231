import BaseScene from './BaseScene';

export type SceneClassType = new (name?: string, options?: any) => BaseScene;

export default class ScenesRegistry {
  protected static scenes: Record<string, SceneClassType> = {};

  public static addScene(name: string, scene: SceneClassType) {
    this.scenes[name] = scene;
  }

  public static getScene(name: string) {
    return this.scenes[name];
  }
}
