import { Object3D, Vector3 } from 'three';
import { SyncVariable } from '../SyncVariable';
import AnimatorBuffer from '../interpolation/AnimatorBuffer';
import config from '../config';
import { SyncVariableSerialized, VariablePayload } from '../payloads/VariablePayload';
import AnimatorVariablePayload, { AnimatorVariableValueType } from '../payloads/AnimatorVariablePayload';

export default class AnimatorVariable extends SyncVariable<AnimatorVariableValueType> {
  public static type = 'animator';

  public static payloadType: typeof VariablePayload<AnimatorVariableValueType> = AnimatorVariablePayload;

  public buffer: AnimatorBuffer = new AnimatorBuffer();

  public required = false;

  public interpolated = true;

  constructor(name = 'animator') {
    super(name);
  }

  saveValueFromNetwork(data: SyncVariableSerialized<AnimatorVariableValueType>, sendTime: number, receiveTime: number) {
    super.saveValueFromNetwork(data, sendTime, receiveTime);
    if (this.value) {
      this.buffer.setValue(this.value, receiveTime);
    }
  }

  getInterpolatedValue(t: number, ts: number): AnimatorVariableValueType {
    if (!this.interpolated && this.value) return this.value;
    // return this.buffer.buffer[0];
    return this.buffer.getValue(t - config.interpolate - ts) as AnimatorVariableValueType;
  }
}
