import * as ThreeMeshUI from 'three-mesh-ui';
import { Container, ContainerParams } from '../Container';
import { PanelId } from '../../enum/PanelId';

export type TracksRowParams = Pick<ContainerParams, 'contentDirection'> & {
  width?: number;
  height?: number;
};

export function TracksRow(params: TracksRowParams, children: ThreeMeshUI.Block[]) {
  return Container({
    id: `${PanelId.LibraryPanel}_row`,
    width: params.width ?? 519,
    height: params.height ?? 160,
    justifyContent: 'space-between',
    alignItems: 'center',
    contentDirection: params.contentDirection ?? 'row',
  }, children);
}
