import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';

export function setAbsolutePosition(parent: ThreeMeshUI.Block, child: ThreeMeshUI.Block, position?: Three.Vector2): void {
  child.position.set(
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    -(parent.getWidth() / 2) + (child.getWidth() / 2) + (position?.x || 0),
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (parent.getHeight() / 2) - (child.getHeight() / 2) - (position?.y || 0),
    child.position.z,
  );
}
