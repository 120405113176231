import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import VideoComponent from '../components/VideoComponent';
import { Application } from '../../engine/Application';
import { VideoServiceInterface } from '../../engine/network/services/chats/types';

export default class UIHelper {
  static setIconState(doc: UIDocumentComponent, btnId: string, state: string, iconName = 'icon') {
    const btnElement = doc.getElementById(btnId);
    if (btnElement) {
      const iconElement = doc.getChildElementByName(btnElement, iconName);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (iconElement?.states[state]) iconElement?.setState(state);
    }
    return btnElement;
  }

  static toggleScreenShare(app: Application, videoComponent: VideoComponent, videoService: VideoServiceInterface) {
    if (!videoComponent.canShareScreen()) {
      console.log('Already in share');
    } else {
      videoService.toggleScreenShare()
        .then(() => {
          return videoComponent.setScreenShareUser(videoService.isScreenShareEnabled
            ? app.networkManager?.networkId : undefined);
        });
    }
  }
}
