import * as ThreeMeshUI from 'three-mesh-ui';
import { Container, ContainerParams } from './Container';

export type GridRowsParams = ContainerParams & {
  inRow?: number;
  countByRows?: number[];
  gap?: number;
  itemHeight?: number;
};
export function GridRows(params: GridRowsParams, children: ThreeMeshUI.Block[]): ThreeMeshUI.Block[] {
  const rows: ThreeMeshUI.Block[] = [];
  const inRows = params.inRow ?? children.length;
  const numOfRows = params.countByRows && params.countByRows.length
    ? params.countByRows.length : Math.ceil(children.length / inRows);
  let toSkip = 0;
  if (params.height) params.height = (params.height / numOfRows) - (params.gap ?? 0);
  if (params.itemHeight) params.height = params.itemHeight;
  for (let i = 0; i < numOfRows; i++) {
    const count = params.countByRows && params.countByRows.length ? params.countByRows[i] : inRows;
    rows.push(Container(params, children.slice(toSkip, toSkip + count)));
    toSkip += count;
  }
  return rows;
}
