import ThreeMeshUI from 'three-mesh-ui';
import { System } from '../../../engine/System';
import PresentationMenuComponent from '../../components/UI/PresentationMenu.component';
import { InputSystem } from '../../../engine/systems/InputSystem';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { PanelId } from '../../ui/enum/PanelId';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import PresentationComponent from '../../components/Presentation.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import VideoComponent from '../../components/VideoComponent';
import { ContainerId } from '../../ui/enum/ContainerId';
import UIHelper from '../../services/UIHelper';

export default class PresentationMenuSystem extends System {
  onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(PresentationMenuComponent).forEach((component) => {
      this.handleInput(component);
      this.handleComponentActivation(component);
      this.handleComponentEnabled(component);
      if (!component.enabled) return;
      this.handleActiveButtons(component);
    });
  }

  public handleInput(component: PresentationMenuComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    if (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame) {
      this.closeMenu(component);
    }
  }

  public closeMenu(component: PresentationMenuComponent) {
    // TODO: remove layers mask == optimize raycast
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = false;
    uIDocumentComponent.enabled = false;
    if (component.currentActionComponent) {
      component.currentActionComponent.entity.getComponentOrFail(SelectedObjectComponent).isActive = false;
    }
    component.currentActionComponent = null;
  }

  public isHovered(component: PresentationMenuComponent): boolean {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    return states[PanelId.PresentationPanel]?.state === UIDocumentElementState.Hovered
      || states[PanelId.PresentationPanel]?.state === UIDocumentElementState.Active;
  }

  public resolveOtherMenu(component: PresentationMenuComponent, enabledInLastFrame: boolean) {
    this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
    this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
  }

  // TODO: refactoring this hell
  public handleComponentActivation(component: PresentationMenuComponent): void {
    const enabledInLastFrame = component.enabled;
    // if (!this.isHovered(component)) {
    component.currentActionComponent = null;
    component.enabled = false;
    // }
    this.app.componentManager.getComponentsByType(PresentationComponent).forEach((presentationComponent) => {
      const selectedComponent = presentationComponent.entity.getComponentOrFail(SelectedObjectComponent);
      if (selectedComponent.isActive) {
        component.enabled = true;
        this.resolveOtherMenu(component, enabledInLastFrame);
        component.currentActionComponent = presentationComponent;
      }
    });
    if (component.currentActionComponent) {
      (component.currentActionComponent as PresentationComponent)
        .entity.getComponentOrFail(SelectedObjectComponent).isActive = component.enabled;
    }
  }

  public handleComponentEnabled(component: PresentationMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const panel = uIDocumentComponent.getElementById(PanelId.PresentationPanel);
    uIDocumentComponent.enabled = component.enabled;
    if (panel) {
      panel.visible = component.enabled;
    }
  }

  public handleActiveButtons(component: PresentationMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const { videoService } = this.app.chatsService || {};

    if (states[ButtonId.CloseButton]?.state === UIDocumentElementState.Active) {
      this.closeMenu(component);
    }

    if (!component.currentActionComponent) return;
    this.updatePlayButton(component);
    this.updateChapterName(component);
    if (component.isLoading) return;
    const videoComponent = component.currentActionComponent.entity.getComponentOrFail(VideoComponent);

    const shareButton = uIDocumentComponent.getElementById(ButtonId.CamButton);
    if (shareButton) shareButton.visible = videoComponent.enableSharing;

    if (states[ButtonId.PlayButton]?.state === UIDocumentElementState.Active) {
      component.isLoading = true;
      videoComponent.toggle().then(() => { component.isLoading = false; });
    }

    if (states[ButtonId.NextButton]?.state === UIDocumentElementState.Active) {
      videoComponent.playNextChapter();
    }

    if (states[ButtonId.PrevButton]?.state === UIDocumentElementState.Active) {
      videoComponent.playPrevChapter();
    }

    if (states[ButtonId.CamButton]?.state === UIDocumentElementState.Active && videoService) {
      UIHelper.toggleScreenShare(this.app, videoComponent, videoService);
    }

    UIHelper.setIconState(uIDocumentComponent, ButtonId.CamButton, videoComponent.shareScreenUserId
      ? UIDocumentElementState.Selected : UIDocumentElementState.Default);

    if (shareButton) {
      shareButton.userData.uiData.defaultState = videoComponent.shareScreenUserId
        ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    }
  }

  protected updatePlayButton(component: PresentationMenuComponent) {
    if (!component.currentActionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const videoComponent = component.currentActionComponent.entity.getComponentOrFail(VideoComponent);
    const isPlaying = videoComponent.controller.isPlaying();
    const playButton = uIDocumentComponent.getElementById(ButtonId.PlayButton);
    if (playButton) {
      const playButtonIcon = uIDocumentComponent.getChildElementByName(playButton, 'icon');
      const state = isPlaying ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
      playButton.userData.uiData.defaultState = state;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (playButtonIcon) playButtonIcon.setState(state);
    }
  }

  protected updateChapterName(component: PresentationMenuComponent) {
    if (!component.currentActionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const videoComponent = component.currentActionComponent.entity.getComponentOrFail(VideoComponent);
    const textContainer = uIDocumentComponent.getElementById(ContainerId.ChapterName);
    if (!textContainer) return;
    const textElement = textContainer.children.find((ch) => ch instanceof ThreeMeshUI.Text);
    if (!textElement) return;
    const name = videoComponent.currentChapterName;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (textElement.content === name) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.set({
      content: name,
    });
  }
}
