import { NetworkEvent } from '../../NetworkEvent';
import { engine } from '../../../../generated';
import { NetworkId } from '../../types';

export default class CreateSpaceEvent extends NetworkEvent<string, NetworkId> {
  public static eventName = 'CreateSpace';

  protected writeRequestBuffer(name: string): Uint8Array | undefined {
    const buffer = engine.network.sessionStore.payloads.SessionRequestSpaceEvent.create({
      spaceName: name,
    });
    return engine.network.sessionStore.payloads.SessionRequestSpaceEvent.encode(buffer).finish();
  }

  protected readRequestBuffer(buffer: Uint8Array): string | null {
    const decoded = engine.network.sessionStore.payloads.SessionRequestSpaceEvent.decode(buffer);
    return decoded.spaceName;
  }

  protected writeResponseBuffer(id: NetworkId): Uint8Array | undefined {
    const buffer = engine.network.sessionStore.payloads.SessionResponseSpaceEvent.create({
      roomId: id,
    });
    return engine.network.sessionStore.payloads.SessionResponseSpaceEvent.encode(buffer).finish();
  }

  protected readResponseBuffer(stream: Uint8Array): NetworkId | null {
    const decoded = engine.network.sessionStore.payloads.SessionResponseSpaceEvent.decode(stream);
    return decoded.roomId;
  }
}
