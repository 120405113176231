import TopMenuComponent from '../../components/UI/TopMenu.component';
import { Container } from '../elements';
import { ButtonId } from '../enum/ButtonId';
import { ActionButton } from '../elements/Buttons/ActionButton';
import { Tooltip } from '../elements/Tooltip';
import { ContainerId } from '../enum/ContainerId';
import { ActionsMenuAdaptive, Adaptive } from '../adaptive';

export function ActionsMenuTemplate(component: TopMenuComponent, adaptive: Adaptive) {
  const {
    padding,
    borderRadius,
    columnContentDirection,
    columnWidth,
    columnHeight,
    contentDirection,
    tooltipWidth,
    tooltipHeight,
    space,
    buttonSize,
    isVRSession,
  } = adaptive as ActionsMenuAdaptive;
  const { width, height } = adaptive;

  if (isVRSession) return null;

  const adaptiveTooltip = (params: { imageUrl: string; iconWidth: number; iconHeight: number }) => {
    return Tooltip({
      imageUrl: params.imageUrl,
      iconWidth: params.iconWidth,
      iconHeight: params.iconHeight,
      width: tooltipWidth,
      height: tooltipHeight,
    });
  };

  return Container({
    id: ContainerId.RootContainer,
    width,
    height,
    backgroundOpacity: 0,
    backgroundColor: '#171A30',
    padding,
    borderRadius,
    contentDirection,
    justifyContent: 'space-between',
    alignItems: 'start',
  }, [
    Container({
      id: ContainerId.TooltipsContainer,
      width: columnWidth,
      height: columnHeight,
      contentDirection: columnContentDirection,
      justifyContent: 'space-between',
      // backgroundOpacity: 1,
      visible: false,
    }, [
      adaptiveTooltip({
        imageUrl: 'assets/ui/icons/esc-key.png',
        iconWidth: 35,
        iconHeight: 22,
      }),
      Container({
        width: space,
        height: space,
      }, []),
      adaptiveTooltip({
        imageUrl: 'assets/ui/icons/c-key.png',
        iconWidth: 22,
        iconHeight: 22,
      }),
      adaptiveTooltip({
        imageUrl: 'assets/ui/icons/q-key.png',
        iconWidth: 23,
        iconHeight: 22,
      }),
      adaptiveTooltip({
        imageUrl: 'assets/ui/icons/shift-key.png',
        iconWidth: 46,
        iconHeight: 22,
      }),
    ]),
    Container({
      width: columnWidth,
      height: columnHeight,
      // backgroundOpacity: 1,
      // backgroundColor: '#FFFFFF',
      contentDirection: columnContentDirection,
      justifyContent: 'space-between',
    }, [
      ActionButton({
        id: ButtonId.SeatButton,
        imageUrl: 'assets/ui/icons/seat_white.png',
        color: '#5156D1',
        opacity: 1,
        visible: false,
        width: buttonSize,
        height: buttonSize,
      }),
      Container({
        width: space,
        height: space,
      }, []),
      ActionButton({
        id: ButtonId.PlayerControllerSwitchButton,
        imageUrl: 'assets/ui/icons/camera.png',
        width: buttonSize,
        height: buttonSize,
      }),
      ActionButton({
        id: ButtonId.DanceButton,
        imageUrl: 'assets/ui/icons/dance.png',
        selected: true,
        width: buttonSize,
        height: buttonSize,
      }),
      ActionButton({
        id: ButtonId.SprintButton,
        imageUrl: 'assets/ui/icons/sprint.png',
        selected: true,
        width: buttonSize,
        height: buttonSize,
      }),
    ]),
  ]);
}
