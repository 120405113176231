import * as Three from 'three';
import { IKConstraint } from './types';
import IKChain from './IKChain';

export type IKJointOptions = {
  bone: Three.Object3D;
  constraints?: IKConstraint[];
};

export default class IKJoint {
  public constraints: IKConstraint[] = [];

  public bone: Three.Object3D;

  public isIKJoint = true;

  constructor(options: IKJointOptions) {
    this.bone = options.bone;
    this.constraints = options.constraints || this.constraints;
  }

  applyConstraints(chain: IKChain, method: 'apply' | 'applyLazy' = 'apply') {
    if (!this.constraints) {
      return;
    }

    let constraintApplied = false;
    for (let i = 0; i < this.constraints.length; i++) {
      const constraint = this.constraints[i];
      if (constraint && method in constraint) {
        const call = constraint[method]?.bind(constraint);
        const applied = call ? call(this, chain) : false;
        constraintApplied = constraintApplied || applied;
      }
    }
    return constraintApplied;
  }
}
