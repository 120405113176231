import EventEmitter from 'eventemitter3';
import { NetworkId, OnCloseConnection, OnJoinedRoomPayload, OnLeftRoomPayload } from '../types';
import { SessionNetworkObject } from './objects/SessionNetworkObject';
import NetworkManager from '../NetworkManager';
import { SessionUsersVariable } from './variables/SessionUsersVariable';
import { SessionUserType } from './payloads/SessionUsersPayload';
import AddUserEvent from './events/AddUserEvent';
import { SessionSpacesVariable } from './variables/SessionSpacesVariable';
import { SessionSpaceType } from './payloads/SessionSpacesPayload';
import CreateSpaceEvent from './events/CreateSpaceEvent';

export type SessionStoreEventTypes = {
  onInit: () => void;
};

export default class SessionStore {
  public id: NetworkId = -1;

  public isInit = false;

  protected netObject: SessionNetworkObject | null = null;

  protected store: Record<string, any> = {};

  protected _events: EventEmitter<SessionStoreEventTypes> = new EventEmitter<SessionStoreEventTypes>();

  get events(): EventEmitter<SessionStoreEventTypes> {
    return this._events;
  }

  init(manager: NetworkManager) {
    SessionNetworkObject.register(manager);
    this.setNetObject(SessionNetworkObject.build(manager));
    manager.events.on('onReceiveObjects', () => {
      this.setNetObject(manager.getObjectByCode<SessionNetworkObject>('session'));
    });
    manager.transport.events.on('oncloseConnection', this.onCloseConnection, this);
    // TODO: is we relly need this?
    manager.transport.events.on('onLeftRoom', this.onLeftRoom.bind(this, manager), this);
    // manager.events.on('onChangeActiveRoom', this.onChangeActiveRoom, this);
    this.syncUsersActiveRoomFromManager(manager);
  }

  public getUser(id: NetworkId) {
    return this.users.find((u) => u.id === id);
  }

  protected syncUsersActiveRoomFromManager(manager: NetworkManager) {
    if (!this.areYouHost()) return;
    const { users } = this;
    Object.entries(manager.onlineUsers).forEach(([roomId, onlineUsers]) => {
      onlineUsers.forEach(({ id }) => {
        const existIndex = users.findIndex((u) => u.id === id);
        const user: SessionUserType = existIndex >= 0 ? users[existIndex]
          : { id, name: '', activeRoomID: Number(roomId), avatarName: '' } as SessionUserType;
        user.activeRoomID = Number(roomId);
        if (existIndex >= 0) users[existIndex] = user;
        else users.push(user);
      });
    });
    this.usersVariable?.set({ users }, true);
  }

  public onChangeActiveRoom({ roomId, clientId }: OnJoinedRoomPayload) {
    return this.tryToAddUser({ id: clientId, activeRoomID: roomId, name: '', avatarName: '' });
  }

  public usersInRoom(roomId: NetworkId): SessionUserType[] {
    return this.users.filter((u) => u.activeRoomID === roomId);
  }

  protected onCloseConnection({ connectionId } : OnCloseConnection) {
    this.removeUser(connectionId);
  }

  protected onLeftRoom(manager: NetworkManager, { roomId } : OnLeftRoomPayload) {
    manager.transport.listRoomConnections(roomId).then(({ connectionIds }) => {
      if (!connectionIds.length) this.removeSpace(roomId);
    });
  }

  protected setNetObject(object: SessionNetworkObject | null) {
    if (this.netObject) return;
    if (!object) return;
    this.netObject = object;
    object.addEvents();
    this.processEvents();
    this.isInit = true;
    this._events.emit('onInit');
  }

  protected processEvents() {
    if (!this.netObject) return;
    this.netObject.eventDispatcher.getEvent(AddUserEvent.eventName).onHandleRequest((data) => {
      if (this.areYouHost()) {
        return Promise.resolve(this.addUser(data));
      }
      return Promise.resolve(true);
    });
    this.netObject.eventDispatcher.getEvent(CreateSpaceEvent.eventName).onHandleRequest((name) => {
      if (this.areYouHost()) {
        return this.addSpace(name);
      }
      // TODO: think about this
      return Promise.resolve(0);
    });
  }

  public get usersVariable() {
    if (!this.netObject) return null;
    return this.netObject.getVariableByName<SessionUsersVariable>('sessionUsers');
  }

  public get spacesVariable() {
    if (!this.netObject) return null;
    return this.netObject.getVariableByName<SessionSpacesVariable>('sessionSpaces');
  }

  public get users(): SessionUserType[] {
    if (!this.usersVariable) return [];
    return this.usersVariable.value?.users ?? [];
  }

  public get spaces(): SessionSpaceType[] {
    if (!this.usersVariable) return [];
    return this.spacesVariable?.value?.spaces ?? [];
  }

  public get hostUser(): SessionUserType | undefined {
    if (!this.usersVariable) return undefined;
    const { users } = this;
    return users.length > 0 ? users[0] : undefined;
  }

  public areYouHost() {
    if (this.netObject && this.hostUser && this.hostUser.id === this.netObject.manager.networkId) return true;
    return this.netObject && this.netObject.manager.isSessionHost;
  }

  protected removeSpace(id: NetworkId) {
    if (!this.spacesVariable) return null;
    if (this.areYouHost()) {
      const spaces = this.spaces.filter((s) => s.roomId !== id);
      this.spacesVariable.set({ spaces }, true);
    }
  }

  protected removeUser(id: NetworkId) {
    if (!this.usersVariable) return null;
    if (this.areYouHost() || this.hostUser?.id === id) {
      const users = this.users.filter((u) => u.id !== id);
      this.usersVariable.set({ users }, true);
    }
  }

  protected addSpace(name: string, roomId?: number): Promise<number> {
    if (!this.spacesVariable || !this.netObject || !this.areYouHost()) return Promise.resolve(0);
    const { spaces } = this;
    const find = this.spaces.find((sp) => sp.spaceName === name);
    if (find) return Promise.resolve(find.roomId);
    const promise = roomId ? Promise.resolve({ roomId })
      : this.netObject.manager.transport.createRoom();
    return promise.then(({ roomId: createdRoomID }) => {
      const space: SessionSpaceType = { spaceName: name, roomId: createdRoomID };
      spaces.push(space);
      this.spacesVariable?.set({ spaces }, true);
      return createdRoomID;
    });
  }

  protected addUser(user: SessionUserType) {
    if (!this.usersVariable || !this.netObject) return true;
    if (this.areYouHost()) {
      const updateUsers: Partial<SessionUserType> = { id: user.id };
      if (user.activeRoomID) updateUsers.activeRoomID = user.activeRoomID;
      if (user.name) updateUsers.name = user.name;
      if (user.avatarName) updateUsers.avatarName = user.avatarName;
      const users = [...this.usersVariable.value?.users || []];
      const existIndex = users.findIndex((u) => u.id === user.id);
      if (existIndex < 0) users.push(user);
      else users[existIndex] = { ...users[existIndex], ...updateUsers };
      // if real host exists -- put as first array element
      // at any time -- use first element as host
      // TODO: may be reset this.netObject.manager.isSessionHost then host is lost
      users.sort((u1, u2) => {
        if (this.netObject && this.netObject.manager.isSessionHost) {
          if (u1.id === this.netObject.manager.networkId) return -1;
          if (u2.id === this.netObject.manager.networkId) return 1;
        }
        return 0;
      });
      this.usersVariable.set({ users }, true);
    }
    return true;
  }

  public tryCreateSpace(name: string, roomId?: NetworkId): Promise<number> {
    // const find = this.spaces.find((sp) => sp.spaceName === name);
    // if (find) return Promise.resolve(find.roomId);

    if (this.areYouHost()) {
      return this.addSpace(name, roomId);
    }
    // if (roomId) return Promise.resolve(roomId);
    return new Promise((resolve) => {
      const { hostUser } = this;
      const registry = this.netObject?.eventDispatcher.send(
        CreateSpaceEvent.eventName,
        name,
        hostUser ? [hostUser.id] : [],
      ).onHandleResponse((createdRoomId) => {
        registry?.remove();
        resolve(createdRoomId);
      });
    });
  }

  public tryToAddUser(user: SessionUserType) {
    if (this.areYouHost()) {
      this.addUser(user);
      return Promise.resolve({ users: this.users });
    }

    // TODO: request to get users from host?
    // TODO: remove user event?

    return new Promise((resolve) => {
      const { hostUser } = this;
      const registry = this.netObject?.eventDispatcher.send(
        AddUserEvent.eventName,
        user,
        hostUser ? [hostUser.id] : [],
      ).onHandleResponse(() => {
        registry?.remove();
        // in theory message going by WS are sorted inorder it was sending & in that case variable already received
        resolve(this.users);
      });
    });
  }

  public get<T>(key: string, defaultValue: T | undefined = undefined): T {
    const value = this.store[key];
    if (typeof value === 'undefined' && typeof defaultValue !== 'undefined') this.set(key, defaultValue);
    return this.store[key];
  }

  public set<T>(key: string, value: T) {
    this.store[key] = value;
  }
}
