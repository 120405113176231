import NetworkManager from './NetworkManager';
import { NetworkEvent } from './NetworkEvent';
import { NetworkId } from './types';
import { IDType } from './services/ID.service';

export default class EventDispatcher {
  protected manager: NetworkManager;

  public events: Record<string, NetworkEvent> = {};

  constructor(manager: NetworkManager) {
    this.manager = manager;
  }

  public registerEvent<EventType extends NetworkEvent>(eventName: string, event: EventType) {
    this.events[eventName] = event;
  }

  public hasEvent(eventName: string) {
    return !!this.events[eventName];
  }

  public getEventById(id: IDType) {

  }

  public getEvent(eventName: string): NetworkEvent {
    return this.events[eventName];
  }

  public send<EventRequestType>(eventName: string, requestArgs: EventRequestType, clients: NetworkId[] = []) {
    const event = this.getEvent(eventName);
    this.manager.sendEventRequest<EventRequestType>(event, requestArgs, clients);
    return event;
  }

  public receiveRequest<EventRequestType>(event: NetworkEvent, requestArgs: EventRequestType, sender: NetworkId): Promise<void> {
    return event.handleRequest(requestArgs).then((responseResult) => {
      if (typeof responseResult !== 'undefined' && responseResult !== null) {
        this.manager.sendEventResponse(event, responseResult, [sender]);
      }
    });
  }

  public receiveResponse<EventResponseType>(event: NetworkEvent, responseResult: EventResponseType) {
    event.handleResponse(responseResult);
  }
}
