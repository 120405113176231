import * as Three from 'three';
import { System } from '../../engine/System';
import { getObjectCenter } from '../services/getObjectCenter';
import AudioComponent from '../components/AudioComponent';
import BaseScene from '../scenes/BaseScene';

export class AudioSystem extends System {
  private raycaster = new Three.Raycaster();

  onUpdate(dt: number) {
    super.onUpdate(dt);
    this.app.componentManager.getComponentsByType(AudioComponent).forEach((component) => {
      this.handleDistanceVolume(component);
    });
  }

  handleDistanceVolume(component: AudioComponent): void {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    if (!component.controller.element?.muted) {
      const { entity } = component;
      const center = getObjectCenter(entity);
      const distance = center.distanceTo(characterEntity.getWorldPosition(new Three.Vector3()));
      // blocked parameter not used in this changeVolume method
      component.changeVolume(distance, false /* this.raycastDistance(component, center) < distance */);
    }
  }

  public raycastDistance(component: AudioComponent, target: Three.Vector3): number {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    // const { entity } = component;
    const position = new Three.Vector3();
    // const target = new Three.Vector3();
    const direction = new Three.Vector3();

    characterEntity.getWorldPosition(position);
    // entity.getWorldPosition(target);
    direction.subVectors(target, position).normalize();

    this.raycaster.set(position, direction);
    this.raycaster.far = component.rayCastFar;
    const intercets = this.raycaster.intersectObjects(component.colliderObjects, component.recursiveIntersection);

    return intercets[0] ? intercets[0].distance + 0.1 : Infinity;
  }
}
