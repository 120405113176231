import * as Three from 'three';
import Ammo from 'ammo.js';

export class ThreeToAmmoConverter {
  // todo: need make more configurable
  public static makeTriMeshCollider(mesh: Three.Mesh, applyTransform = false): Ammo.btBvhTriangleMeshShape {
    const va = new Three.Vector3();
    const vb = new Three.Vector3();
    const vc = new Three.Vector3();

    const bta = new Ammo.btVector3();
    const btb = new Ammo.btVector3();
    const btc = new Ammo.btVector3();
    const triMesh = new Ammo.btTriangleMesh(true, false);

    const { geometry } = mesh;
    const indexes = geometry.index?.array;

    const components = geometry.getAttribute('position').array;
    let matrix = new Three.Matrix4();

    if (applyTransform) {
      mesh.updateMatrix();
      matrix = mesh.matrix;
    }

    if (indexes) {
      for (let j = 0; j < indexes.length; j += 3) {
        const ai = indexes[j] * 3;
        const bi = indexes[j + 1] * 3;
        const ci = indexes[j + 2] * 3;
        va.set(components[ai], components[ai + 1], components[ai + 2]).applyMatrix4(matrix);
        vb.set(components[bi], components[bi + 1], components[bi + 2]).applyMatrix4(matrix);
        vc.set(components[ci], components[ci + 1], components[ci + 2]).applyMatrix4(matrix);
        bta.setValue(va.x, va.y, va.z);
        btb.setValue(vb.x, vb.y, vb.z);
        btc.setValue(vc.x, vc.y, vc.z);
        triMesh.addTriangle(bta, btb, btc, false);
      }
    } else {
      for (let j = 0; j < components.length; j += 9) {
        va.set(components[j + 0], components[j + 1], components[j + 2]).applyMatrix4(matrix);
        vb.set(components[j + 3], components[j + 4], components[j + 5]).applyMatrix4(matrix);
        vc.set(components[j + 6], components[j + 7], components[j + 8]).applyMatrix4(matrix);
        bta.setValue(va.x, va.y, va.z);
        btb.setValue(vb.x, vb.y, vb.z);
        btc.setValue(vc.x, vc.y, vc.z);
        triMesh.addTriangle(bta, btb, btc, false);
      }
    }

    return new Ammo.btBvhTriangleMeshShape(triMesh, true, true);
  }
}
