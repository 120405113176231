import { IDType } from './services/ID.service';
import NetworkObject from './NetworkObject';
import Generated from '../../generated';
import { toArrayBuffer } from '../../shared/helpers/toArrayBuffer';

export type RequestHandlerType<Request, Response> = ((data: Request) => Promise<Response | null>);
export type ResponseHandlerType<Response> = ((data: Response) => void);

export class NetworkEvent<RequestArgType = any, ResponseArgsType = any> {
  public static eventName = '';

  public requestHandlers: RequestHandlerType<RequestArgType, ResponseArgsType>[] = [];

  public responseHandlers: ResponseHandlerType<ResponseArgsType>[] = [];

  public objectId: IDType;

  public constructor(object: NetworkObject) {
    this.objectId = object.id;
  }

  get name(): string {
    return (<typeof NetworkEvent> this.constructor).eventName;
  }

  public onHandleRequest(handler: RequestHandlerType<RequestArgType, ResponseArgsType>) {
    this.requestHandlers.push(handler);
    return {
      remove: () => {
        this.removeRequestHandler(handler);
      },
    };
  }

  public removeRequestHandler(handler: RequestHandlerType<RequestArgType, ResponseArgsType>) {
    this.requestHandlers = this.requestHandlers.filter((h) => h !== handler);
  }

  public onHandleResponse(handler: ResponseHandlerType<ResponseArgsType>) {
    this.responseHandlers.push(handler);
    return {
      remove: () => {
        this.removeResponseHandler(handler);
      },
    };
  }

  public removeResponseHandler(handler: ResponseHandlerType<ResponseArgsType>) {
    this.responseHandlers = this.responseHandlers.filter((h) => h !== handler);
  }

  public handleRequest(requestArgs: RequestArgType): Promise<ResponseArgsType | null> {
    const promises: Promise<ResponseArgsType | null>[] = [];
    this.requestHandlers.forEach((handler) => {
      promises.push(handler(requestArgs));
    });
    return Promise.all(promises).then((results) => {
      if (!results || results.length === 0) return null;
      return results[results.length - 1];
    });
  }

  public handleResponse(responseArgs: ResponseArgsType): void {
    this.responseHandlers.forEach((handler) => {
      handler(responseArgs);
    });
  }

  public createRequestBuffer(requestArgs: RequestArgType): ArrayBuffer {
    const event = Generated.engine.network.NetworkEvent.create({
      eventName: this.name,
      objectId: this.objectId,
      payload: {
        type_url: this.name,
        value: this.writeRequestBuffer(requestArgs),
      },
    });
    return toArrayBuffer(Generated.engine.network.NetworkEvent.encode(event).finish());
  }

  protected writeRequestBuffer(data: RequestArgType): Uint8Array | undefined {
    return undefined;
  }

  public createResponseBuffer(requestArgs: ResponseArgsType): ArrayBuffer {
    const event = Generated.engine.network.NetworkEvent.create({
      eventName: this.name,
      objectId: this.objectId,
      payload: {
        type_url: this.name,
        value: this.writeResponseBuffer(requestArgs),
      },
    });
    return toArrayBuffer(Generated.engine.network.NetworkEvent.encode(event).finish());
  }

  protected writeResponseBuffer(data: ResponseArgsType): Uint8Array | undefined {
    return undefined;
  }

  public parseRequestBuffer(buffer: ArrayBuffer): RequestArgType | null {
    const event = Generated.engine.network.NetworkEvent.decode(new Uint8Array(buffer));
    (<typeof NetworkEvent> this.constructor).eventName = event.eventName;
    this.objectId = event.objectId;
    return event.payload?.value ? this.readRequestBuffer(event.payload?.value) : null;
  }

  protected readRequestBuffer(buffer: Uint8Array): RequestArgType | null {
    return null;
  }

  public parseResponseBuffer(buffer: ArrayBuffer): ResponseArgsType | null {
    const event = Generated.engine.network.NetworkEvent.decode(new Uint8Array(buffer));
    (<typeof NetworkEvent> this.constructor).eventName = event.eventName;
    this.objectId = event.objectId;

    return event.payload?.value ? this.readResponseBuffer(event.payload?.value) : null;
  }

  protected readResponseBuffer(stream: Uint8Array): ResponseArgsType | null {
    return null;
  }
}
