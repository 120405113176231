import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';

function loadTexture(url: string) {
  return new Promise((resolve) => {
    new Three.TextureLoader().load(url, (texture) => {
      resolve(texture);
    });
  });
}

export type IconStatesParams = {
  imageUrl: string;
  altImg?: string;
  altState?: string;
  disableImg?: string;
  disableState?: string;
};

export function addStatesToIconElement(button: ThreeMeshUI.Block, params: IconStatesParams) {
  const iconElement = button.children.find((child) => child instanceof ThreeMeshUI.Block);
  if (iconElement) {
    iconElement.userData.uiData = iconElement.userData.uiData ?? {};
    button.userData.uiElements = {
      icon: iconElement,
    };
    if (params.altImg) {
      Promise.all([
        loadTexture(params.imageUrl),
        loadTexture(params.altImg),
      ])
        .then(([defaultTexture, altTexture]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconElement.setupState({
            state: UIDocumentElementState.Default,
            attributes: { backgroundTexture: defaultTexture },
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconElement.setupState({
            state: params.altState ?? UIDocumentElementState.Selected,
            attributes: { backgroundTexture: altTexture },
          });
        });
    }
    if (params.disableImg) {
      loadTexture(params.disableImg).then((disableTexture) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iconElement.setupState({
          state: params.disableState ?? UIDocumentElementState.User,
          attributes: { backgroundTexture: disableTexture },
        });
      });
    }
  }
}
