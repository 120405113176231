import * as Three from 'three';
import { Application } from '../../engine/Application';
import { TeleportComponent } from '../components/Teleport.component';
import { Entity } from '../../engine/Entity';
import { ActionIconComponent } from '../components/ActionIcon.component';
import SeatPlaceComponent from '../components/SeatPlace.component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import * as Theme from '../ui/Theme';
import { PlaceObjectsType, PlaceObjectType } from '../assets/types';

export type SpawnServiceOptions = {
  app: Application;
};

export default class SpawnService {
  protected app: Application;

  protected objects: PlaceObjectsType = {};

  public static scenePlaceNameTemplate = 'spawn_{}';

  constructor(options: SpawnServiceOptions) {
    this.app = options.app;
  }

  public static getSpawnObject(threeScene: Three.Object3D, placeId: string) {
    return threeScene.getObjectByName(this.scenePlaceNameTemplate.replace('{}', placeId));
  }

  public getEntityTransform(entity: Three.Object3D) {
    entity.updateMatrixWorld(true);
    entity.updateWorldMatrix(true, true);
    return entity.matrixWorld;
  }

  public getSpawnObjectTransform(threeScene: Three.Scene, placeId: string): Three.Matrix4 {
    const spawnEntity = SpawnService.getSpawnObject(threeScene, placeId);
    if (!spawnEntity) return new Three.Matrix4();
    return this.getEntityTransform(spawnEntity);
  }

  public setSpawnObjectTransform(threeScene: Three.Scene, entity: Three.Object3D, placeId: string) {
    const transform = this.getSpawnObjectTransform(threeScene, placeId);
    this.setTransform(entity, transform);
  }

  public setTransform(entity: Three.Object3D, transform: Three.Matrix4) {
    entity.matrix.copy(transform);
    entity.matrix.decompose(entity.position, entity.quaternion, entity.scale);
  }

  public static generatePlaces(threeScene: Three.Object3D, objectsConfig: PlaceObjectsType = {}) {
    Object.keys(objectsConfig).forEach((placeId) => {
      const spawnEntity = SpawnService.getSpawnObject(threeScene, placeId);
      if (!spawnEntity) {
        SpawnService.createSpawnObject(threeScene, placeId, objectsConfig[placeId]);
      }
    });
  }

  public static createSpawnObject(threeScene: Three.Object3D, placeId: string, config: PlaceObjectType) {
    const entity = new Three.Object3D();
    entity.name = this.scenePlaceNameTemplate.replace('{}', placeId);
    entity.position.copy(config.position);
    entity.rotation.copy(config.rotation);
    threeScene.add(entity);
    return entity;
  }

  public spawnTo(placeId: string) {
    this.app.componentManager.getComponentsByType(TeleportComponent).forEach((component) => {
      if (component.placeId === placeId) component.isActive = true;
    });
  }

  public buildEntity(obj: Three.Object3D) {
    obj.updateMatrixWorld(true);
    obj.updateWorldMatrix(true, true);
    const spawnEntity = this.app.entityManager.makeEntity();
    spawnEntity.position.copy(obj.position);
    spawnEntity.rotation.copy(obj.rotation);
    return spawnEntity;
  }

  protected loadTexture(url: string) {
    return new Promise<Three.Texture>((resolve) => {
      new Three.TextureLoader().load(url, (texture) => resolve(texture));
    });
  }

  public addSeatBehavior(entity: Entity) {
    entity.getComponentOrFail(TeleportComponent).toggleRigidAfterTeleport = false;
    return Promise.all([
      this.loadTexture(Theme.seatIcons.default),
      this.loadTexture(Theme.seatIcons.hover),
      this.loadTexture(Theme.seatIcons.active),
    ]).then(([texture, hoverTexture, activeTexture]) => {
      const uiDocument = entity.addComponent(UIDocumentComponent);
      entity.addComponent(ActionIconComponent, {
        texture,
        hoverTexture,
        activeTexture,
        showRadius: 8,
        uiDocument,
      });
      entity.addComponent(SeatPlaceComponent);
    });
  }
}
