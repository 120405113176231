import { getLocaleSettings } from '../../i18n/settings';

export const fontMain = {
  name: 'SpaceGrotesk',
  texture: getLocaleSettings().ui.fontMain.texture,
  json: getLocaleSettings().ui.fontMain.json,
};

export const fontMainBold = {
  name: 'SpaceGroteskBold',
  texture: getLocaleSettings().ui.fontMainBold.texture,
  json: getLocaleSettings().ui.fontMainBold.json,
};

export const bgColor = '#171a30';
export const textColor = '#ffffff';
export const bgOpacity = 0.9;

export const bgBorderRadius: [number, number, number, number] = [24, 24, 24, 24];

export const { letterSpacing } = getLocaleSettings().ui;

export const seatIcons = {
  default: 'assets/ui/icons/icon_seat.png',
  hover: 'assets/ui/icons/icon_seat-hover.png',
  active: 'assets/ui/icons/icon_seat-active.png',
};
