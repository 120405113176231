import { LocaleSettingsInterface } from './LocaleSettingsInterface';
import { ShareButtonCodes } from '../../domain/ui/enum/ShareButtonCodes';

export const EnLocalSettings: LocaleSettingsInterface = {
  mainSite: 'https://ownverse.world',
  ui: {
    letterSpacing: -0.1,
    fontMain: {
      texture: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular.png',
      json: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Regular-msdf.json',
    },
    fontMainBold: {
      texture: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold.png',
      json: 'assets/fonts/SpaceGrotesk/SpaceGrotesk-Bold-msdf.json',
    },
    mainMenu: {
      shareLinks: [ShareButtonCodes.INSTA, ShareButtonCodes.FACEBOOK, ShareButtonCodes.TELEGRAM],
    },
    welcomeMenu: {},
  },
};
