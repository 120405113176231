import * as Three from 'three';
import { Vector2Control } from '../controls/Vector2Control';
import { ButtonControl } from '../controls/ButtonControl';
import { Vector2Sensor } from '../sensors/Vector2Sensor';
import { ButtonSensor } from '../sensors/ButtonSensor';
import { Application } from '../../../Application';

export type MouseDeviceOptions = {
  app: Application;
};

export class MouseDevice {
  protected app: Application;

  public position: Vector2Control = new Vector2Control();

  public delta: Vector2Control = new Vector2Control();

  public leftButton: ButtonControl = new ButtonControl();

  public wheelDelta: Vector2Control = new Vector2Control();

  public wheel: Vector2Control = new Vector2Control();

  protected positionSensor: Vector2Sensor = new Vector2Sensor();

  protected wheelSensor: Vector2Sensor = new Vector2Sensor();

  protected leftButtonSensor: ButtonSensor = new ButtonSensor();

  protected onMouseDownBind: (event: MouseEvent) => void;

  protected onMouseMoveBind: (event: MouseEvent) => void;

  protected onMouseUpBind: (event: MouseEvent) => void;

  protected onWheelBind: (event: WheelEvent) => void;

  protected onCanvasBlurBind = this.onCanvasBlur.bind(this);

  protected onCanvasFocusBind = this.onCanvasFocus.bind(this);

  constructor(options: MouseDeviceOptions) {
    this.app = options.app;
    this.onMouseDownBind = this.onMouseDown.bind(this);
    this.onMouseMoveBind = this.onMouseMove.bind(this);
    this.onMouseUpBind = this.onMouseUp.bind(this);
    this.onWheelBind = this.onWheel.bind(this);
    this.setupListeners();
  }

  public update(): void {
    this.positionSensor.applyToControls(this.position, this.delta);
    this.leftButtonSensor.applyToControls(this.leftButton);
    this.wheelSensor.applyToControls(this.wheel, this.wheelDelta);
  }

  public destroy(): void {
    this.removeListeners();
  }

  protected onMouseDown(): void {
    this.leftButtonSensor.isPressed = true;
  }

  protected onMouseUp(): void {
    this.leftButtonSensor.isPressed = false;
  }

  protected onMouseMove(event: MouseEvent): void {
    this.positionSensor.threeVector2.set(
      // todo: get canvas sizes
      (event.clientX / this.app.renderer.domElement.clientWidth) * 2 - 1,
      -(event.clientY / this.app.renderer.domElement.clientHeight) * 2 + 1,
    );
  }

  protected onWheel(event: WheelEvent): void {
    if (event.deltaX !== 0) this.wheelSensor.threeVector2.x += event.deltaX > 0 ? 1 : -1;
    if (event.deltaY !== 0) this.wheelSensor.threeVector2.y += event.deltaY > 0 ? 1 : -1;
  }

  protected onCanvasBlur(): void {
    this.leftButtonSensor.sleep = true;
  }

  protected onCanvasFocus(): void {
    this.leftButtonSensor.sleep = false;
  }

  protected setupListeners(): void {
    this.app.renderer.domElement.addEventListener('mousedown', this.onMouseDownBind);
    window.addEventListener('mousemove', this.onMouseMoveBind);
    window.addEventListener('mouseup', this.onMouseUpBind);
    this.app.renderer.domElement.addEventListener('wheel', this.onWheelBind);
    this.app.renderer.domElement.addEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.addEventListener('focus', this.onCanvasFocusBind);
  }

  protected removeListeners(): void {
    this.app.renderer.domElement.removeEventListener('mousedown', this.onMouseDownBind);
    window.removeEventListener('mousemove', this.onMouseMoveBind);
    window.removeEventListener('mouseup', this.onMouseUpBind);
    this.app.renderer.domElement.removeEventListener('wheel', this.onWheelBind);
    this.app.renderer.domElement.removeEventListener('blur', this.onCanvasBlurBind);
    this.app.renderer.domElement.removeEventListener('focus', this.onCanvasFocusBind);
  }
}
