import { FC, ReactNode } from 'react';
import { SpaceContext } from '../context/SpaceContext';
import { SpaceStore } from '../stores/SpaceStore';

export interface SummaryProviderProps {
  children?: ReactNode;
}

const SpaceProvider: FC<SummaryProviderProps> = (props) => {
  const { children } = props;

  const store = new SpaceStore();

  return (
    <SpaceContext.Provider value={{ store }}>
      {children}
    </SpaceContext.Provider>
  );
};

export default SpaceProvider;
