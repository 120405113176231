import { Vector2Control } from './Vector2Control';
import { ButtonControl } from './ButtonControl';

export class TouchControl {
  position: Vector2Control = new Vector2Control();

  delta: Vector2Control = new Vector2Control();

  press: ButtonControl = new ButtonControl();
}
