import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import CurrentFrameValueContainer from '../services/CurrentFrameValueContainer';

export type LightMapPreset = {
  path: string;
  intensity: number;
  envIntensity: number;
};

export type LightMapPresets = { [key: string] : LightMapPreset };

export type Preset = {
  lightMaps: LightMapPresets;
  objects: string[];
};

export type Presets = { [key: string] : Preset };

export type SceneLightMapsComponentOptions = ComponentOptions & {
  data?: {
    content: Three.Object3D;
    currentPresetName: string;
    presets: Presets;
    objectsNames: string[];
  };
};

export default class SceneLightMapsComponent extends EngineComponent {
  public isInitialized = false;

  public isLoading = false;

  public timeout = 300;

  public isInitializedInCurrentFrame = new CurrentFrameValueContainer(this.isInitialized);

  public content: Three.Object3D;

  public currentPresetName = '';

  public presets: Presets = {};

  public objectsNames: string[] = [];

  static get code(): string {
    return 'scene_light_maps';
  }

  constructor(options: SceneLightMapsComponentOptions) {
    super(options);
    this.currentPresetName = options.data?.currentPresetName ?? '';
    this.presets = options.data?.presets ?? {};
    this.objectsNames = options.data?.objectsNames ?? [];
    this.content = options.data?.content ?? new Three.Object3D();
  }

  public get preset(): Preset {
    return this.presets[this.currentPresetName];
  }

  public get presetObjects() {
    return this.presets[this.currentPresetName].objects;
  }

  public enableNextPreset() {
    const presetsNames = Object.keys(this.presets);
    const currentIndex = presetsNames.indexOf(this.currentPresetName);
    this.currentPresetName = presetsNames[(currentIndex + 1) % presetsNames.length];
    this.isInitialized = false;
  }
}
