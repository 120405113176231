import { FC, MouseEvent, useCallback } from 'react';
import classNames from './Button.module.css';

export interface ButtonProps {
  title: string;
  width?: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}
const Button: FC<ButtonProps> = (props) => {
  const {
    onClick = () => undefined,
    width = 'auto',
    isDisabled = false,
    title,
  } = props;

  const buttonClickHandler = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (!isDisabled) onClick(e);
  }, [isDisabled, onClick]);

  return (
    <div
      onClick={buttonClickHandler}
      className={classNames.root}
      style={{ width }}
    >
      {title}
    </div>
  );
};

export default Button;
