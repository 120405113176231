export enum PanelId {
  WelcomePanel = 'WelcomePanel',
  MainMenuPanel = 'MainMenuPanel',
  PresentationPanel = 'PresentationPanel',
  PlayerPanel = 'PlayerPanel',
  LibraryPanel = 'LibraryPanel',
  ShopifyPanel = 'ShopifyPanel',
  ShopifyImage = 'ShopifyImage',

  AvatarVideo = 'AvatarVideo',

  TextBlock = 'TextBlock',
}
