import { FC, memo } from 'react';
import classNames from './DebugPanel.module.css';

export interface DebugPanelProps {
  info: string;
}

const DebugPanel: FC<DebugPanelProps> = memo((props) => {
  return (
    <div className={classNames.root}>
      <div className={classNames.info}>{ props.info}</div>
    </div>
  );
});

export default DebugPanel;
