import EventEmitter from 'eventemitter3';
import { Object3D } from 'three';
import { NetworkId } from './types';
import NetworkManager from './NetworkManager';
import { SyncVariable } from './SyncVariable';
import ObjectPayload, { NetworkObjectSerialized } from './payloads/ObjectPayload';
import { IDType } from './services/ID.service';
import EventDispatcher from './EventDispatcher';

export enum NetworkObjectLifeTimeTypes {
  Owner,
  Shared,
}

export enum NetworkObjectStatus {
  Active,
  Removed,
}

export type NetworkObjectEventTypes = {
  onRemove: () => void;
};

export default class NetworkObject {
  public id: IDType = 0;

  public static type = 'base';

  public static payloadType: typeof ObjectPayload = ObjectPayload;

  public eventDispatcher: EventDispatcher;

  public payload: ObjectPayload<any>;

  public ownerId: NetworkId = 0;

  public roomId?: NetworkId = 0;

  public code = '';

  public permanent = false;

  public status = NetworkObjectStatus.Active;

  public lifeTimeType = NetworkObjectLifeTimeTypes.Owner;

  public manager: NetworkManager;

  public isNeedSpawn = true;

  public isCreated = false;

  protected _events: EventEmitter<NetworkObjectEventTypes> = new EventEmitter<NetworkObjectEventTypes>();

  public variables: SyncVariable[] = [];

  public cratedTime = Date.now();

  // public existsInNetwork: NetworkId[] = [];

  constructor(
    manager: NetworkManager,
    lifeTimeType = NetworkObjectLifeTimeTypes.Owner,
  ) {
    this.manager = manager;
    this.lifeTimeType = lifeTimeType;
    this.payload = new (this.constructor as typeof NetworkObject).payloadType(this);
    this.eventDispatcher = new EventDispatcher(manager);
    // this.roomId = roomId;
    // this.manager.events.on('receiveVariable', this.onVariableReceive, this);
  }

  public get objectType() {
    return (this.constructor as typeof NetworkObject).type;
  }

  public get enabled() {
    return this.manager.enabled;
  }

  public reset() {
    if (this.isOwner()) {
      this.variables.forEach((variable) => variable.reset());
    }
  }

  public get isInitialized(): boolean {
    // FIXME: TIME hack
    return this.roomId && this.manager.roomHost[this.roomId]
      ? this.isCreated && (Date.now() - this.cratedTime > 5000)
      : !this.isNeedSpawn && (Date.now() - this.cratedTime > 4000);
  }

  public isLife(removedOwnerId: NetworkId | null): boolean {
    if (this.lifeTimeType === NetworkObjectLifeTimeTypes.Shared) return true;
    if (this.permanent) return true;
    if (this.lifeTimeType === NetworkObjectLifeTimeTypes.Owner) {
      if (!removedOwnerId) return !!this.ownerId;
      return this.ownerId !== removedOwnerId;
    }
    return false;
  }

  public get isShared() {
    return this.lifeTimeType === NetworkObjectLifeTimeTypes.Shared;
  }

  public get events(): EventEmitter<NetworkObjectEventTypes> {
    return this._events;
  }

  public broadcastVariables(clients: NetworkId[] = [], required?: boolean) {
    this.manager.broadcastVariables(this.variables, clients, required);
  }

  public getRemoteVariable() {
    return this.manager.sendSuccessReceiveNewObject(this);
  }

  // FIXME: need variable type, not value type
  public getVariableByName<VariableType extends SyncVariable>(name: string) {
    return this.variables.find((vr) => vr.name === name) as VariableType;
  }

  public getVariableById<VariableType extends SyncVariable>(id: IDType) {
    return this.variables.find((vr) => vr.id === id) as VariableType;
  }

  // TODO: create separate variable collection service
  public addVariable(variable: SyncVariable) {
    variable.initialize(this.manager);
    // TODO: validate name?
    this.variables.push(variable);
    variable.setOwnerNetObject(this);
    variable.events.on('changed', this.onVariableChanged, this);
    // TODO: maybe broadcast init variable OR serialize variable in NetworkObject
    // this.manager.broadcastVariable<T>(variable);
  }

  onVariableChanged({ variable }: { variable: SyncVariable }) {
    variable.writerId = this.manager.networkId;
    // TODO: implement pause state
    // TODO: send only if object exists on another users
    this.manager.broadcastVariable(variable);
  }

  sendVariable(variable: SyncVariable) {
    if (!variable.id || !this.getVariableById(variable.id)) return;
    this.manager.broadcastVariable(variable);
  }

  public remove() {
    console.warn('remove', this);
    // TODO: clear memory ...
    this.status = NetworkObjectStatus.Removed;
    this.events.emit('onRemove');
  }

  public inRoom(roomId?: NetworkId) {
    return this.roomId === roomId;
  }

  public inActiveRoom() {
    return this.roomId === this.manager.activeRoomId;
  }

  public initialize() {
    this.ownerId = this.manager.networkId;
    this.id = this.manager.IDService.getObjectId();
    this.roomId = this.manager.activeRoomId;
    // this.isNeedSpawn = false;
    // this.existsInsNetwork.push(this.manager.networkId);
    return this;
  }

  public setOwner(id: NetworkId) {
    this.ownerId = id;
  }

  public isOwner() {
    return this.ownerId === this.manager.networkId;
  }

  public isWriter(variable: SyncVariable) {
    return this.manager.networkId === variable.writerId;
  }

  public spawn(): Object3D | undefined {
    if (this.isNeedSpawn && this.enabled) {
      this.isNeedSpawn = false;
      const entity = this.spawnEntity();
      if (!entity) {
        this.isNeedSpawn = true;
        // this.getRemoteVariable();
      }
      return entity;
    }
  }

  public spawnEntity(): Object3D | undefined {
    return new Object3D();
  }

  public serialize(): NetworkObjectSerialized {
    return this.payload.toJson();
  }

  public deserialize(dump: NetworkObjectSerialized) {
    this.payload.fromJson(dump);
    return this;
  }
}
