import { Component } from '../../../engine/Component';
import CurrentFrameValueContainer from '../../services/CurrentFrameValueContainer';

export default class WelcomeScreenComponent extends Component {
  public enabled = true;

  public enabledInCurrentFrame = new CurrentFrameValueContainer<boolean>(this.enabled, true);

  static get code(): string {
    return 'welcome_screen';
  }
}
