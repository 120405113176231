import { Button } from '../Button';
import * as Theme from '../../Theme';
import { Icon } from '../Icon';

export type IconTextButtonParams = {
  id: string;
  text: string;
  imgUrl: string;
  width?: number;
  height?: number;
  fontSize?: number;
};
export function IconTextButton(params: IconTextButtonParams) {
  const width = params.width ?? 260;
  const height = params.height ?? 48;
  return Button({
    id: params.id,
    width,
    height,
    titleWidth: width * 0.6,
    title: params.text,
    fontName: Theme.fontMainBold.name,
    titleFontSize: params.fontSize ?? 28,
    letterSpacing: -0.08,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0.1,
    hoverColor: '#FFFFFF',
    hoverOpacity: 0.3,
    activeColor: '#5156D1',
    activeOpacity: 1,
    fontColor: '#FFFFFF',
    borderRadius: 24,
    leftIcon: Icon({
      imageUrl: params.imgUrl,
      width: 32,
      height: 32,
    }),
  });
}
