import * as Three from 'three';
import { Application } from '../Application';

export abstract class AbstractScene {
  protected _threeScene: Three.Scene = new Three.Scene();

  public isSelfRender = false;

  public get threeScene(): Three.Scene {
    return this._threeScene;
  }

  public abstract prepareConfig(): Promise<void>;

  public abstract load(app: Application, data?: unknown): Promise<void>;

  public abstract destroy(app: Application): Promise<void>;

  public render(app: Application, delta: number): void {
    if (app.camera && this.threeScene) {
      app.renderer.render(this.threeScene, app.camera);
    }
  }

  public handleUserInteraction(app: Application): Promise<void> {
    return Promise.resolve();
  }
}
