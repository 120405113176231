import { Component, ComponentOptions } from '../Component';
import TransformVariable from '../network/variables/TransformVariable';

export type NetworkTransformComponentOptions = ComponentOptions & {
  data?: {
    variable?: TransformVariable;
    variableName?: string;
    type?: NetworkTransformComponentTypes;
    coordsSystem?: NetworkTransformComponentCoordsSystem;
  };
};

export enum NetworkTransformComponentTypes {
  Target = 'Target',
  Source = 'Source',
}

export enum NetworkTransformComponentCoordsSystem {
  Local = 'Local',
  World = 'World',
}

export default class NetworkTransformComponent extends Component {
  public variable: TransformVariable | null;

  public variableName: string;

  public enabled = true;

  public type: NetworkTransformComponentTypes;

  public coordsSystem: NetworkTransformComponentCoordsSystem;

  static get code(): string {
    return 'networtransform';
  }

  constructor(options: NetworkTransformComponentOptions) {
    super(options);
    this.variable = options.data?.variable || null;
    this.variableName = options.data?.variableName || '';
    this.type = options.data?.type || NetworkTransformComponentTypes.Target;
    this.coordsSystem = options.data?.coordsSystem || NetworkTransformComponentCoordsSystem.Local;
  }

  public setSourceType() {
    this.type = NetworkTransformComponentTypes.Source;
  }

  public setTargetType() {
    this.type = NetworkTransformComponentTypes.Target;
  }

  public get isSourceType() {
    return this.type === NetworkTransformComponentTypes.Source;
  }

  public get isTargetType() {
    return this.type === NetworkTransformComponentTypes.Target;
  }

  public get isLocalCoordsSystem() {
    return this.coordsSystem === NetworkTransformComponentCoordsSystem.Local;
  }

  public get isWorldCoordsSystem() {
    return this.coordsSystem === NetworkTransformComponentCoordsSystem.World;
  }

  public disable() {
    this.enabled = false;
  }

  public enable() {
    this.enabled = true;
  }
}
