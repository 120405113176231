import * as Three from 'three';
import { Icon, IconParams } from './Icon';
import { addInteractivityToElement, normalizeColor, setAbsolutePosition } from '../helpers';
import { Container } from './Container';

export type TooltipParams = {
  width?: number;
  height?: number;
  backgroundColor?: string;
  backgroundOpacity?: number;
  iconWidth?: number;
  iconHeight?: number;
  borderRadius?: number;
  imageUrl: string;
};
export function Tooltip(params: TooltipParams) {
  const borderRadius = params.borderRadius ?? 8;
  return Container({
    width: params.width ?? 64,
    height: params.height ?? 48,
    backgroundOpacity: params.backgroundOpacity ?? 0.9,
    backgroundColor: params.backgroundColor ?? '#171A30',
    borderRadius: [borderRadius, borderRadius, borderRadius, borderRadius],
    justifyContent: 'center',
  }, [Icon({
    imageUrl: params.imageUrl,
    width: params.iconWidth ?? ((params.width ?? 64) / 1.5),
    height: params.iconHeight ?? ((params.height ?? 48) / 1.5),
  })]);
}
