import { IKConstraint } from '../types';
import IKJoint from '../IKJoint';
import IKChain from '../IKChain';

export default class IKBaseConstraint implements IKConstraint {
  apply(joint: IKJoint, chain: IKChain): boolean {
    return false;
  }

  applyLazy(joint: IKJoint, chain: IKChain): boolean {
    return false;
  }
}
