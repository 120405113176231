import { engine } from '../../../generated';
import {
  MessageId, NetworkId, PayloadType, ProtocolType, QueueId, ResponseHeader, Timestamp,
} from '../types';
import { IDType } from '../services/ID.service';

export type MessageData = {
  type: string;
  payload: Uint8Array;
};

export type SendMessage = {
  id: IDType;
  roomId?: NetworkId;
  clientSentTimestamp: Timestamp;
  queueId?: QueueId;
  transportType: ProtocolType;
  required?: boolean;
  clients?: NetworkId[];
  type: string;
  payload?: PayloadType;
};

export type ReceiveMessage = {
  header: ResponseHeader;
  type: string;
  payload?: Uint8Array;
  clientReceivedTimestamp: Timestamp;
  required?: boolean;
};

export type Message = SendMessage | ReceiveMessage;

export type MessagesByTpe = { [key: string]: Message[] };

export function msgId(msg: Message): MessageId {
  if ((msg as ReceiveMessage).header) return (msg as ReceiveMessage).header.messageId;
  return BigInt((msg as SendMessage).id);
}

export default class MessagesPool<MessageType extends Message> {
  public messages: MessageType[] = [];

  public requiredMessages: MessageType[] = [];

  public savedMessages: MessageType[] = [];

  public saveMessage(message: MessageType) {
    this.savedMessages.push(message);
  }

  public addMessage(message: MessageType) {
    this.messages.push(message);
  }

  public flushMessages() {
    const messages = [...this.savedMessages];
    this.savedMessages = [];
    return messages;
  }

  public getById(id: MessageId): MessageType | undefined {
    return this.messages.find((ms) => msgId(ms) === id)
      || this.requiredMessages.find((ms) => msgId(ms) === id);
  }

  public removeById(id: MessageId) {
    this.messages = this.messages.filter((ms) => msgId(ms) !== id);
    this.requiredMessages = this.requiredMessages.filter((ms) => msgId(ms) !== id);
  }

  getMessagesByType(messages: MessageType[] | null = null): MessagesByTpe {
    const result: MessagesByTpe = {};
    const messagesToProcess = messages || this.messages;
    messagesToProcess.forEach((message) => {
      if (!result[message.type]) result[message.type] = [];
      result[message.type].push(message);
    });
    return result;
  }

  public replaceMessageByType(message: MessageType) {
    const typedMessages = this.getMessagesByType(this.messages)[message.type];
    if (typedMessages && typedMessages.length > 0) {
      typedMessages.forEach((ms) => this.removeById(msgId(ms)));
    }
    this.messages.push(message);
  }

  clearRequiredMessages() {
    this.requiredMessages = [];
  }

  clear() {
  }

  public static encodeMessage({ type, payload }: MessageData): ArrayBuffer {
    const proto = engine.network.messages.MessageData.create({
      payload: { type_url: type, value: payload },
    });
    return engine.network.messages.MessageData.encode(proto).finish();
  }

  public static decodeMessage(buffer: ArrayBuffer): MessageData {
    const decoded = engine.network.messages.MessageData.decode(new Uint8Array(buffer));
    return {
      type: decoded.payload?.type_url as string,
      payload: decoded.payload?.value as Uint8Array, // todo: swypse: if empty?
    };
  }
}
