import * as Three from 'three';
import * as ThreeMeshUI from 'three-mesh-ui';

import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { VideoController, VideoSourceType } from '../services/VideoController';
import VideoVariable from '../network/variables/VideoVariable';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import { VideoVariableValueType } from '../network/payloads/VideoVariablePayload';
import BaseScene from '../scenes/BaseScene';

export type VideoTextureComponentChapter = {
  name: string;
  timeStamp: number;
};

export type VideoTextureComponentChapters = VideoTextureComponentChapter[];

export type VideoTextureComponentOptions = ComponentOptions & {
  data?: {
    targetMesh?: Three.Mesh | ThreeMeshUI.Block;
    source?: VideoSourceType;
    colliderObjects?: Three.Object3D[];
    distanceVolume?: number;
    rayCastFar?: number;
    recursiveIntersection?: boolean; // use this in raycast.intersectObjects with colliderObjects
    chapters?: VideoTextureComponentChapters;
    flipY?: boolean;
    exactTime?: boolean;
    enableSharing?: boolean;
  };
};

export default class VideoComponent extends EngineComponent {
  public controller: VideoController;

  public isInitialized = false;

  public autoplay = false;

  public source?: VideoSourceType;

  public prevVideoState?: VideoVariableValueType;

  public targetMesh: Three.Mesh | ThreeMeshUI.Block | null = null;

  public enabled = true;

  public distanceVolume = 12;

  public colliderObjects: Three.Object3D[] = [];

  public rayCastFar = 15;

  public recursiveIntersection = true; // use this in raycast.intersectObjects with colliderObjects

  public chapters: VideoTextureComponentChapters = [];

  public flipY = false;

  public shareScreenUserId?: number;

  public exactTime = false;

  public enableSharing = true;

  static get code(): string {
    return 'videotexture';
  }

  constructor(options: VideoTextureComponentOptions) {
    super(options);
    this.controller = new VideoController();
    this.controller.autoplay = this.autoplay;
    this.targetMesh = options.data?.targetMesh || this.targetMesh;
    if (!this.targetMesh) {
      this.targetMesh = this.getMeshFromEntity();
    }
    if (options.data?.source) this.source = { ...options.data?.source };
    // this.prevVideoState = this.videoState;
    this.rayCastFar = options.data?.rayCastFar ?? this.rayCastFar;
    this.distanceVolume = options.data?.distanceVolume ?? this.distanceVolume;
    this.rayCastFar = Math.max(this.rayCastFar, this.distanceVolume);
    this.colliderObjects = options.data?.colliderObjects ?? this.colliderObjects;
    this.recursiveIntersection = options.data?.recursiveIntersection ?? this.recursiveIntersection;
    this.chapters = options.data?.chapters ?? this.chapters;
    this.flipY = typeof options.data?.flipY !== 'undefined' ? options.data?.flipY : this.flipY;
    this.exactTime = typeof options.data?.exactTime !== 'undefined' ? options.data.exactTime : this.exactTime;
    this.enableSharing = typeof options.data?.enableSharing !== 'undefined' ? options.data.enableSharing : this.enableSharing;
    // TODO: think about it
    if ((this.entity.app.sceneManager.currentScene as BaseScene)?.userInteractionStarted) {
      this.init();
    }
    return this;
  }

  public init() {
    if (this.isInitialized) return Promise.resolve();
    this.isInitialized = true;
    return this.controller.createElement().then(() => {
      let result = Promise.resolve();
      if (this.source) result = this.setSource(this.source);
      this.setUpTexture();
      return result;
    })
      .then(() => {
        if (!this.source) return Promise.resolve();
        // TODO: test do we really need it
        this.controller.mute();
        return this.controller.toggle(true);
      })
      .then(() => {
        if (!this.source) return Promise.resolve();
        if (!(this.autoplay && this.entity.app.networkManager?.isActiveRoomHost)) return this.controller.toggle(false);
      })
      .then(() => {
        return this.updateMaterial();
      });
  }

  protected get timestamp() {
    return Math.floor((this.entity.app.networkManager ? this.entity.app.networkManager.getCorrectTime(true) : Date.now()) / 1000);
  }

  public get currentChapterIndex(): number {
    if (this.chapters.length === 0) return 0;
    if (!this.controller.element) return 0;
    const videoTime = this.controller.time;
    const chaptersTime = this.chapters.map((ch) => ch.timeStamp);
    chaptersTime.push(this.controller.element.duration);
    let chapterIndex = 0;
    for (let i = 0; i < chaptersTime.length - 1; i++) {
      if (chaptersTime[i] <= videoTime && videoTime < chaptersTime[i + 1]) {
        chapterIndex = i;
      }
    }
    return chapterIndex;
  }

  public get currentChapter(): VideoTextureComponentChapter | null {
    const index = this.currentChapterIndex;
    if (this.chapters.length === 0) return null;
    if (!this.controller.element) return null;
    return this.chapters[index];
  }

  public get currentChapterName(): string {
    const chapter = this.currentChapter;
    return chapter ? chapter.name : '';
  }

  public playNextChapter() {
    const index = this.currentChapterIndex;
    if (this.chapters.length === 0) return;
    if ((index + 1) === this.chapters.length) return;
    this.controller.setTime(this.chapters[index + 1].timeStamp);
    this.variable?.setNeedUpdateFromLocal();
  }

  public playPrevChapter() {
    const index = this.currentChapterIndex;
    if (this.chapters.length === 0) return;
    if (index === 0) return;
    this.controller.setTime(this.chapters[index - 1].timeStamp);
    this.variable?.setNeedUpdateFromLocal();
  }

  public rewind(delta: number) {
    if (!this.controller.element) return;
    const { time } = this.controller;
    const { duration } = this.controller.element;
    // const setTime = Math.min(Math.max(time + delta, 0), duration);
    let setTime = (time + delta);
    if (setTime < 0) setTime = duration - setTime;
    setTime %= duration;
    // if (setTime > duration || setTime < 0) return;
    this.controller.setTime(setTime);
    this.variable?.setNeedUpdateFromLocal();
  }

  public get videoState() : VideoVariableValueType {
    return {
      isPlaying: this.controller.isPlaying(),
      source: this.source,
      videoTime: this.controller.time,
      localTime: this.timestamp,
      shareScreenUserId: this.shareScreenUserId,
    };
  }

  public calculateVideoTime(state: VideoVariableValueType): number {
    let time = state.videoTime;
    if (!this.exactTime) {
      time += (state.isPlaying ? Math.max(0, this.timestamp - (state.localTime || 0)) : 0);
    }
    if (this.controller.element && this.controller.element.duration) {
      time %= this.controller.element.duration;
    }
    return time;
  }

  // TODO: move to variable ?
  public setState(state: VideoVariableValueType) {
    let promise = Promise.resolve();
    if (typeof state.shareScreenUserId !== 'undefined' && state.shareScreenUserId >= 0) {
      this.shareScreenUserId = state.shareScreenUserId;
    }
    if (state.source
      && (this.source?.ogv !== state.source.ogv || this.source?.mp4 !== state.source.mp4)
    ) {
      promise = this.setSource(state.source);
    }
    return (promise || Promise.resolve())
      .then(() => {
        return this.controller.toggle(state.isPlaying);
      })
      .then(() => {
        if (state.videoTime < 0) return;
        const videoTime = this.calculateVideoTime(state);
        this.controller.setTime(videoTime);
      })
      .then(() => {
        this.prevVideoState = state;
      });
  }

  public get variable(): VideoVariable | undefined {
    return this.entity.getComponent(NetworkObjectComponent)?.netObject?.getVariableByName('video') as VideoVariable;
  }

  public get networkId(): number | undefined {
    return this.entity.app.networkManager?.networkId;
  }

  public isHost() {
    return this.entity.app.networkManager?.isActiveRoomHost;
  }

  public play() : Promise<void> {
    return this.controller.toggle(true).then(() => {
      this.updateMaterial();
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public pause() {
    return this.controller.toggle(false).then(() => {
      this.updateMaterial();
      this.variable?.setNeedUpdateFromLocal();
    });
  }

  public toggleSound(value?: boolean) {
    if (typeof value === 'undefined') {
      this.controller.toggleSound();
    } else if (value) {
      this.controller.mute();
    } else {
      this.controller.unmute();
    }
  }

  public toggle() {
    return this.controller.isPlaying() ? this.pause() : this.play();
  }

  protected getMeshFromEntity(): Three.Mesh | null {
    let mesh: Three.Mesh | null = null;
    this.entity.traverse((obj) => {
      if (mesh) return;
      if (obj instanceof Three.Mesh) {
        mesh = obj;
      }
    });
    return mesh;
  }

  public setSource(source: VideoSourceType, skipUpdate = false) {
    if (!this.enabled) return Promise.resolve();
    this.enabled = false;
    this.source = { ...source };
    const promise = this.controller.setSource(source, skipUpdate);
    return (promise || Promise.resolve()).then(() => {
      if (this.source?.stream) this.setUpTexture();
      if (!skipUpdate) this.updateMaterial();
      this.enabled = true;
    });
  }

  public setUpTexture() {
    if (!this.targetMesh) return;
    if (!this.controller.element) return;
    const videoTexture = new Three.VideoTexture(this.controller.element);
    videoTexture.encoding = Three.sRGBEncoding;
    videoTexture.flipY = this.flipY;
    this.entity.app.renderer.initTexture(videoTexture);
    this.setTexture(videoTexture);
    this.updateMaterial();
  }

  protected setTexture(texture: Three.VideoTexture) {
    if (this.targetMesh instanceof Three.Mesh) {
      if (!this.targetMesh.material) {
        this.targetMesh.material = new Three.MeshBasicMaterial();
      }
      const material = this.targetMesh.material as Three.MeshBasicMaterial;
      material.side = Three.FrontSide;
      material.toneMapped = false;
      if (typeof material.map !== undefined) {
        material.map?.dispose();
        material.map = texture;
      }
    }
    // TODO: we need own ui system
    if (this.targetMesh instanceof ThreeMeshUI.Block) {
      const block = this.targetMesh as any;
      if (block.backgroundMaterial.uniforms.u_texture.value) {
        block.backgroundMaterial.uniforms.u_texture.value.dispose();
      }
      // block.frame.userData = { uiData: { depth: true } };
      block.set({ backgroundTexture: texture });
      block.backgroundMaterial.side = Three.FrontSide;
      block.onAfterUpdate = () => {
        block.backgroundMaterial.uniforms.u_tSize.value = new Three.Vector2(640, 480);
      };
    }
  }

  public updateMaterial() {
    if (!(this.targetMesh instanceof Three.Mesh)) return;
    const material = this.targetMesh.material as Three.MeshBasicMaterial;
    if (!material) return;
    material.needsUpdate = true;
    if (material.map) {
      material.needsUpdate = true;
    }
  }

  public changeVolume(distance: number, blocked: boolean) {
    if (!this.isInitialized || !Number.isFinite(distance)) return;
    if (blocked || distance > this.distanceVolume) {
      return this.controller.setVolume(0);
    }
    this.controller.setVolume(1 - (distance / this.distanceVolume));
  }

  public setStream(stream: MediaStream) {
    if (!this.controller.element) return;
    if (stream && this.controller.element.srcObject !== stream) {
      this.controller.element.srcObject = stream;
      this.controller.setAutoplay(true);
      return this.controller.toggle(true);
    }
  }

  public removeStream() {
    if (!this.controller.element || !this.controller.element.srcObject) return;
    this.controller.setAutoplay();
    this.controller.element.srcObject = null;
    return this.autoplay ? this.controller.element.play() : this.controller.update();
  }

  public setScreenShareUser(id?: number) {
    // already have screen share user -- remove if needed
    if (typeof this.shareScreenUserId !== 'undefined' && typeof id === 'undefined' && this.shareScreenUserId === this.networkId) {
      this.shareScreenUserId = undefined;
      if (this.autoplay) return this.play();
      this.variable?.updateFromLocal();
    }
    // no screen share user -- set if needed
    if (!this.shareScreenUserId && typeof id !== 'undefined') {
      this.shareScreenUserId = id;
      this.variable?.updateFromLocal();
    }
  }

  public canShareScreen() {
    return this.isHost() && this.networkId && (!this.shareScreenUserId || this.shareScreenUserId === this.networkId);
  }

  public destroy(): void {
    this.controller.destroy();
  }
}
