import { FC, useCallback, useEffect } from 'react';
import { useController as useControllerHookForm, UseControllerProps } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import Input, { InputProps } from '../../../../../components/Input/Input';
import Errors from './Errors';
import classNames from './Form.module.css';
import Controller from '../Controller/Controller';
import { useControlsStore } from '../../../../../hooks/stores/useSpaceStore';
import {mqMobileMax} from '../../../../../data/variables';

export type FormInputNameProps = InputProps & UseControllerProps<any>;

const Form: FC<FormInputNameProps> = observer((props) => {
  const {
    onChange = () => undefined,
    placeholder = '',
    error = false,
    width,
    value,
    name,
    rules,
    control,
  } = props;

  const isMobile = useMedia(`(max-width:${mqMobileMax})`);

  const {
    setMicroActive,
    microActive,
    setSoundActive,
    soundActive,
    setCameraActive,
    cameraActive,
    setIsErrorValidate,
  } = useControlsStore();

  const { field, fieldState } = useControllerHookForm({
    name, rules, control,
  });

  useEffect(() => {
    setIsErrorValidate(fieldState.invalid);
  }, [fieldState.invalid]);

  const setController = useCallback(() => {
    return (
      <Controller
        onChangeCamera={() => setCameraActive(!cameraActive)}
        microActive={microActive}
        onChangeMicrophone={() => setMicroActive(!microActive)}
        cameraActive={cameraActive}
        onChangeSound={() => setSoundActive(!soundActive)}
        soundActive={soundActive}
      />
    );
  }, [cameraActive, microActive, soundActive]);

  return (
    <div style={{ width: '100%' }}>
      <div className={classNames.controls}>
        <Input
          width={width}
          error={error}
          onChange={(e) => {
            onChange(e);
            field.onChange(e);
          }}
          value={value}
          placeholder={placeholder}
        />
        {!isMobile && setController()}
      </div>
      <Errors
        invalid={fieldState.invalid}
        type={fieldState.error?.type}
        fieldLength={field.value.length}
      />
      {isMobile && setController()}
    </div>
  );
});

export default Form;
