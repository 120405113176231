import Ammo from 'ammo.js';
import * as Three from 'three';
import { AmmoDebugDrawMode } from './enums/AmmoDebugDrawMode';

export type AmmoDebugDrawerOptions = {
  debugDrawMode: AmmoDebugDrawMode;
  bufferGeometrySize?: number;
};

export class AmmoDebugDrawer extends Ammo.DebugDrawer {
  public debugDrawMode: number;

  public activeGeometryIndex = 0;

  public mesh: Three.LineSegments;

  public _enabled = false;

  constructor(options: AmmoDebugDrawerOptions) {
    super();
    this.debugDrawMode = options.debugDrawMode ?? AmmoDebugDrawMode.DrawWireframe;
    this.mesh = this.buildMesh(options.bufferGeometrySize ?? 100000);
    this.drawLine = this.drawLine.bind(this);
    this.drawContactPoint = this.drawContactPoint.bind(this);
    this.reportErrorWarning = this.reportErrorWarning.bind(this);
    this.draw3dText = this.draw3dText.bind(this);
    this.setDebugMode = this.setDebugMode.bind(this);
    this.getDebugMode = this.getDebugMode.bind(this);
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
    this.mesh.geometry.setDrawRange(0, 0);
  }

  public drawLine(fromPointer: number, toPointer: number, colorPointer: number) {
    const color = this.getVector3FromAmmoHeap(colorPointer);
    const from = this.getVector3FromAmmoHeap(fromPointer);
    const to = this.getVector3FromAmmoHeap(toPointer);

    // disable axis in wireframe mode, dangerous
    if ([color.x, color.y, color.z].includes(0.699999988079071)) return;

    this.mesh.geometry.attributes.position.setXYZ(this.activeGeometryIndex, from.x, from.y, from.z);
    this.mesh.geometry.attributes.color.setXYZ(this.activeGeometryIndex, color.x, color.y, color.z);
    this.activeGeometryIndex++;

    this.mesh.geometry.attributes.position.setXYZ(this.activeGeometryIndex, to.x, to.y, to.z);
    this.mesh.geometry.attributes.color.setXYZ(this.activeGeometryIndex, color.x, color.y, color.z);
    this.activeGeometryIndex++;
  }

  public drawContactPoint(pointPointer: number, normalPointer: number, distance: number, lifeTime: number, colorPointer: number) {
    const color = this.getVector3FromAmmoHeap(colorPointer);
    const point = this.getVector3FromAmmoHeap(pointPointer);
    const normal = this.getVector3FromAmmoHeap(normalPointer);

    this.mesh.geometry.attributes.position.setXYZ(this.activeGeometryIndex, point.x, point.y, point.z);
    this.mesh.geometry.attributes.color.setXYZ(this.activeGeometryIndex, color.x, color.y, color.z);
    this.activeGeometryIndex++;

    point.add(normal);
    this.mesh.geometry.attributes.position.setXYZ(this.activeGeometryIndex, point.x, point.y, point.z);
    this.mesh.geometry.attributes.color.setXYZ(this.activeGeometryIndex, color.x, color.y, color.z);
    this.activeGeometryIndex++;
  }

  public reportErrorWarning(warningString: number) {
    console.warn(Ammo.UTF8ToString(warningString));
  }

  public draw3dText() {
    console.warn('Ammo debug drawer: draw3dText not supported');
  }

  public setDebugMode(debugDrawMode: AmmoDebugDrawMode) {
    this.debugDrawMode = debugDrawMode;
  }

  public getDebugMode() {
    return this.debugDrawMode;
  }

  public update(world: Ammo.btDiscreteDynamicsWorld) {
    if (!this._enabled) return;

    if (this.activeGeometryIndex !== 0) {
      this.mesh.geometry.attributes.position.needsUpdate = true;
      this.mesh.geometry.attributes.color.needsUpdate = true;
    }

    this.activeGeometryIndex = 0;

    world.debugDrawWorld();

    this.mesh.geometry.setDrawRange(0, this.activeGeometryIndex);
  }

  public destroy(): void {
    this.mesh.geometry.dispose();
    if (this.mesh.material instanceof Three.Material) this.mesh.material.dispose();
    Ammo.destroy(this);
  }

  protected buildMesh(bufferGeometrySize: number): Three.LineSegments {
    const geometry = new Three.BufferGeometry();

    const vertices = new Float32Array(bufferGeometrySize * 3);
    const colors = new Float32Array(bufferGeometrySize * 3);

    geometry.setAttribute('position', new Three.BufferAttribute(vertices, 3));
    geometry.setAttribute('color', new Three.BufferAttribute(colors, 3));

    const material = new Three.LineBasicMaterial({
      vertexColors: true,
    });

    const mesh = new Three.LineSegments(geometry, material);
    mesh.frustumCulled = false;

    mesh.geometry.setDrawRange(0, 0);

    return mesh;
  }

  protected getVector3FromAmmoHeap(pointer: number): Three.Vector3 {
    return new Three.Vector3(
      Ammo.HEAPF32[pointer / 4],
      Ammo.HEAPF32[(pointer + 4) / 4],
      Ammo.HEAPF32[(pointer + 8) / 4],
    );
  }
}
