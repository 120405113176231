import {
  Actor, CHAT_HISTORY_TYPE, HistoryItem, HistoryItemActor, HistoryItemNarratedAction, HistoryItemTriggerEvent,
} from '@inworld/web-sdk';
import { Box, Fade, Stack, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from './History.module.css';
import { CombinedHistoryItem, EmotionsMap } from '../../../../../../../domain/services/Assistent/Inworld/types';
import { Typing } from '../Typing';
import { InworldService } from '../../../../../../../domain/services/Assistent/Inworld/InworldService';
import classnames from "classnames";

interface HistoryProps {
  history: HistoryItem[];
  emotions: EmotionsMap;
  service: InworldService;
}

export const History = (props: HistoryProps) => {
  const { history, emotions, service } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [combinedChatHistory, setCombinedChatHistory] = useState<CombinedHistoryItem[]>([]);
  const [isInteractionEnd, setIsInteractionEnd] = useState<boolean>(true);

  useEffect(() => {
    // scroll chat down on history change
    if (ref.current && history) {
      ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [history]);

  // TODO: move to service
  useEffect(() => {
    const { records, currentRecord } = service.messages.getCombinedHistoryItems(history);
    setIsInteractionEnd(service.messages.isInteractionEnd(history, currentRecord));
    setCombinedChatHistory(records);
  }, [history]);

  const getContent = (
    message:
    | HistoryItemActor
    | HistoryItemNarratedAction
    | HistoryItemTriggerEvent,
  ) => {
    // console.log(message.text);
    switch (message.type) {
      case CHAT_HISTORY_TYPE.ACTOR:
        return message.text;
      case CHAT_HISTORY_TYPE.NARRATED_ACTION:
        return <span className={classNames.historyAction}>{message.text}</span>;
      case CHAT_HISTORY_TYPE.TRIGGER_EVENT:
        return message.name;
      default:
        return '';
    }
  };

  return (
    <div
      className={classNames.historyRoot}
      ref={ref}
    >
      {combinedChatHistory.map((item, index) => {
        const { messages, title, emoji, actorSource } = service.messages.extractMessage(item, emotions);
        const isAgent = actorSource === 'AGENT';
        const isPlayer = actorSource === 'PLAYER';
        /* eslint-disable-next-line react/no-array-index-key */
        return (

          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`group-${index}`}
            className={classnames(classNames.message, { [classNames.agent]: isAgent, [classNames.player]: isPlayer })}
          >

            <div>
              {emoji && (
              <Box
                className="simulator-message__emoji"
                fontSize={16}
              >
                {emoji}
              </Box>
              )}
            </div>

            <Typography>
              {messages.map((m) => (
                <Fragment key={m.id}>
                  {getContent(m)}
                  {' '}
                </Fragment>
              ))}
            </Typography>
          </div>
        );
      })}
      <Fade
        in={!isInteractionEnd}
        timeout={500}
      >
        <Box margin="0 0 20px 20px">
          <Typing />
        </Box>
      </Fade>
    </div>
  );
};
