import { System } from '../System';
import { CameraComponent } from '../components/Camera.component';

export class CameraSystem extends System {
  onUpdate(ts: number) {
    const components = this.componentManager.getComponentsByType(CameraComponent);

    const firstComponent = components[0];

    // todo: make setup main camera
    if (!firstComponent) {
      this.app.camera = null;
    } else {
      this.app.camera = firstComponent.threeCamera;
      this.app.camera.updateMatrix();
    }
  }

  static get code(): string {
    return 'camera';
  }

  destroy() {
    this.app.camera = null;
  }
}
