import * as Three from 'three';

export enum Errors {
  NotAllowed = 'VRNotAllowed',
  NeedsHTTPS = 'NeedsHTTPS',
  NotAvailable = 'NotAvailable',
  NotFound = 'NotFound',
  NoError = '',
}

export default class VRSession {
  protected currentSession: XRSession | null = null;

  protected renderer: Three.WebGLRenderer;

  protected _onSessionStart: OmitThisParameter<(session: XRSession) => Promise<void>>;

  protected _onSessionEnded: OmitThisParameter<() => void>;

  public lastSessionCheck = false;

  public lastSessionError = Errors.NoError;

  protected sessionIsChecked = false;

  public constructor(renderer: Three.WebGLRenderer) {
    this.renderer = renderer;
    this._onSessionStart = this.onSessionStarted.bind(this);
    this._onSessionEnded = this.onSessionEnded.bind(this);
  }

  public isSessionSupported(forceCheck = false): Promise<[boolean, Errors]> {
    return (() => {
      if (this.sessionIsChecked && !forceCheck) {
        return Promise.resolve([this.lastSessionCheck, this.lastSessionError]);
      }
      if (navigator.xr) {
        return navigator.xr?.isSessionSupported('immersive-vr').then((supported) => {
          if (!supported) return [false, Errors.NotFound];
          return [true, Errors.NoError];
        }).catch(() => {
          return [false, Errors.NotAllowed];
        }) as Promise<[boolean, Errors]>;
      }
      if (!window.isSecureContext) {
        return Promise.resolve([false, Errors.NeedsHTTPS]);
      }
      return Promise.resolve([false, Errors.NotAvailable]);
    })().then(([supported, error]) => {
      this.lastSessionCheck = supported as boolean;
      this.lastSessionError = error as Errors;
      this.sessionIsChecked = true;
      return [supported, error] as [boolean, Errors];
    });
  }

  public startSession() {
    if (!this.currentSession && navigator.xr) {
      const sessionInit = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'layers'] };
      navigator.xr.requestSession('immersive-vr', sessionInit).then(this._onSessionStart);
    }
  }

  protected async onSessionStarted(session: XRSession) {
    session.addEventListener('end', this._onSessionEnded);
    await this.renderer.xr.setSession(session);
    this.currentSession = session;
  }

  protected onSessionEnded() {
    if (!this.currentSession) return;
    this.currentSession.removeEventListener('end', this._onSessionEnded);
    this.currentSession = null;
  }

  public endSession() {
    if (this.currentSession) return this.currentSession.end();
    return Promise.resolve();
  }
}
