import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { addInteractivityToElement, AddInteractivityToElementParams, normalizeColor } from '../helpers';
// import { addInteractivityToElement } from '../helpers/index';

export type ButtonParams = {
  id: string;
  leftIcon?: ThreeMeshUI.Block;
  rightIcon?: ThreeMeshUI.Block;
  width: number;
  height: number;
  titleLineHeight?: number;
  title?: string;
  titleWidth: number;
  titleFontSize: number;
  titleTextAlign?: string;
  padding?: number;
  autoLayout?: boolean;
  justifyContent?: 'center' | 'start' | 'end';
  backgroundColor?: string;
  backgroundOpacity?: number;
  fontColor?: string;
  fontColorHover?: string;
  fontColorActive?: string;
  fontColorSelected?: string;
  fontName?: string;
  hoverColor?: string;
  hoverOpacity?: number;
  activeColor?: string;
  activeOpacity?: number;
  selectedColor?: string;
  selectedOpacity?: number;
  letterSpacing?: number;
  borderRadius?: number;
  selected?: boolean;
  margin?: number;
};

export function Button(params: ButtonParams): ThreeMeshUI.Block {
  const textBlock = new ThreeMeshUI.Block({
    width: params.titleWidth,
    height: params.titleLineHeight || params.titleFontSize,
    margin: 4,
    alignItems: 'center',
    borderRadius: 0,
    hiddenOverflow: true,
    backgroundOpacity: 0,
    justifyContent: 'center',
    fontFamily: params.fontName,
    fontTexture: params.fontName,
    offset: 0.1,
  });
  const textWrapper = new ThreeMeshUI.Block({
    width: params.titleWidth,
    height: params.titleLineHeight || params.titleFontSize,
    margin: 0,
    borderRadius: 0,
    textAlign: params.titleTextAlign ?? 'center',
    backgroundOpacity: 0,
    justifyContent: 'center',
    offset: 0.1,
  });
  const text = new ThreeMeshUI.Text({
    content: params.title,
    fontSize: params.titleFontSize,
    offset: 0.1,
    letterSpacing: params.letterSpacing,
  });

  const bgColor = params.backgroundColor ?? '#222222';
  const fontColor = params.fontColor ?? '#FFFFFF';

  const button = new ThreeMeshUI.Block({
    width: params.width ?? 254,
    height: params.height ?? 60,
    contentDirection: 'row',
    justifyContent: params.justifyContent ?? 'center',
    padding: params.padding ?? 0,
    alignItems: 'center',
    borderRadius: params.borderRadius ?? (params.height ?? 60) / 2,
    borderWidth: 0,
    backgroundColor: normalizeColor(bgColor),
    backgroundOpacity: params.backgroundOpacity ?? 0.85,
    offset: 0.1,
    fontColor: normalizeColor(fontColor),
    margin: params.margin ?? 0,
  });
  textBlock.add(textWrapper);
  textWrapper.add(text);

  if (params.leftIcon) button.add(params.leftIcon);
  button.add(textBlock);
  if (params.rightIcon) button.add(params.rightIcon);

  const interactivityParams: AddInteractivityToElementParams = {
    id: params.id,
    element: button,
    defaultAttributes: {
      backgroundColor: normalizeColor(bgColor),
      backgroundOpacity: params.backgroundOpacity,
      fontColor: normalizeColor(fontColor),
    },
    hoveredAttributes: {
      backgroundColor: normalizeColor(params.hoverColor ?? bgColor),
      backgroundOpacity: params.hoverOpacity ?? 1,
      fontColor: normalizeColor(params.fontColorHover ?? fontColor),
    },
    activeAttributes: {
      backgroundColor: normalizeColor(params.activeColor ?? params.hoverColor ?? bgColor),
      backgroundOpacity: params.activeOpacity ?? 1,
      fontColor: normalizeColor(params.fontColorActive ?? fontColor),
    },
  };

  if (params.selected) {
    const selectedParams = {
      backgroundColor: params.selectedColor ? normalizeColor(params.selectedColor)
        : interactivityParams.activeAttributes.backgroundColor,
      backgroundOpacity: params.selectedOpacity ?? interactivityParams.activeAttributes.backgroundOpacity,
      fontColor: params.fontColorSelected ? normalizeColor(params.fontColorSelected)
        : interactivityParams.activeAttributes.fontColor,
    };
    interactivityParams.selectedAttributes = selectedParams;
  }

  addInteractivityToElement(interactivityParams);

  return button;
}
