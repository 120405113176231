import TrackComponent from './Track.component';

export type TrackSrcType = {
  mp3: string;
};

export default class SpotifyComponent extends TrackComponent<TrackSrcType> {
  // static get code(): string {
  //   return 'spotify';
  // }
}
