import { NetworkId } from '../types';

export type IDType = number;

export type IDServiceOptions = {
  networkID: NetworkId;
};
export default class IDService {
  protected networkID: NetworkId;

  protected globalIncrement = 0;

  protected messageIncrement = 0;

  protected objectIncrement = 0;

  constructor(opts: IDServiceOptions) {
    this.networkID = opts.networkID;
  }

  // used for object & variables
  public getObjectId(): number {
    this.objectIncrement += 1;
    // eslint-disable-next-line no-bitwise
    return ((this.networkID & 0xff) << 8) | (this.objectIncrement & 0xff);
  }

  public getPartsFromObjectId(id: number): { networkId: number; increment: number } {
    return {
      // eslint-disable-next-line no-bitwise
      networkId: (id >> 8) & 0xff,
      // eslint-disable-next-line no-bitwise
      increment: id & 0xff,
    };
  }

  // TODO: maybe reset counter by some rules
  // used only for message id
  public getGlobalId() {
    this.messageIncrement += 1;
    return this.messageIncrement;
  }

  // used only for queueID
  public getIncrement(): number {
    this.globalIncrement += 1;
    return this.globalIncrement;
  }
}
