import * as Three from 'three';
import { Container } from '../elements';
import { PanelId } from '../enum/PanelId';
import * as Theme from '../Theme';
import { DialogButton } from '../elements/Buttons/DialogButton';
import { ButtonId } from '../enum/ButtonId';
import { Text } from '../elements/Text';
import { TabButton } from '../elements/Buttons/TabButton';
import { AvatarItem } from '../elements/Main/AvatarItem';
import { TabsId } from '../enum/TabsId';
import { TabContent } from '../elements/Main/TabContent';
import { Adaptive, MainMenuAdaptive } from '../adaptive';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import { Component } from '../../../engine/Component';
import { GridRows } from '../elements/GridRows';
import { TextBlock } from '../elements/TextBlock';
import { RadioSwitch } from '../elements/RadioSwitch';
import { PlayerButton } from '../elements/Buttons/PlayerButton';
import { i18n } from '../../../i18n/config';
import { ShareButtonCodes } from '../enum/ShareButtonCodes';
import { getLocaleSettings } from '../../../i18n/settings';

export function MainMenuTemplate(component: Component, adaptive: Adaptive) {
  const { width, height, hasNetwork } = adaptive;
  const {
    padding,
    space,
    captionFontSize,
    tabsContainerHeight,
    tabsFontSize,
    avatarItemWidth,
    avatarItemHeight,
    contentHeight,
    avatarsInRow,
    isVrSupported,
    isVRSession,
    isMobile,
    showPlacesTabs,
    avatarsMenuItems,
  } = adaptive as MainMenuAdaptive;
  const percentWidth = (value: number) => (value / 100) * width;

  const tabWidth = (width - 2 * padding - 20) / 4;

  const mainComponent = component as MainMenuComponent;
  mainComponent.tabsContentElements = {};
  mainComponent.tabPositionCopy = undefined;
  mainComponent.tabsContainerElement = undefined;

  const buttonsSpacer = () => Container({ height: 48, width: padding / 2 }, []);

  const shareButtons = [
    {
      code: ShareButtonCodes.INSTA,
      button: PlayerButton({
        id: ButtonId.InstaButton,
        imageUrl: 'assets/ui/icons/insta.png',
      }),
    },
    {
      code: ShareButtonCodes.VK,
      button: PlayerButton({
        id: ButtonId.VKButton,
        imageUrl: 'assets/ui/icons/vk.png',
      }),
    },
    {
      code: ShareButtonCodes.FACEBOOK,
      button: PlayerButton({
        id: ButtonId.FacebookButton,
        imageUrl: 'assets/ui/icons/facebook.png',
      }),
    },
    {
      code: ShareButtonCodes.TELEGRAM,
      button: PlayerButton({
        id: ButtonId.TelegramButton,
        imageUrl: 'assets/ui/icons/telegram.png',
      }),
    },
  ].filter((el) => getLocaleSettings().ui.mainMenu.shareLinks.includes(el.code))
    .map((el) => el.button);

  const localeShareButtons: typeof shareButtons = [];
  for (let i = 0; i < shareButtons.length; i++) {
    localeShareButtons.push(shareButtons[i]);
    if (i !== shareButtons.length - 1) {
      localeShareButtons.push(buttonsSpacer());
    }
  }

  const bottomPanel = !isMobile && (isVrSupported || isVRSession)
    ? [
      Container({
        width,
        height: 20,
      }, []),
      Container({
        width: width - 2 * padding,
        height: 56,
        contentDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
      }, [
        isVRSession ? Container({
          width: 56 * 3,
          height: 56,
          backgroundColor: '#272A44',
          backgroundOpacity: 0.5,
          borderRadius: [8, 8, 8, 8],
          contentDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'start',
        }, [
          DialogButton({
            id: ButtonId.MicButton,
            imageUrl: 'assets/ui/icons/mic.png',
            altImageUrl: 'assets/ui/icons/no-mic.png',
          }),
          DialogButton({
            id: ButtonId.Sound,
            imageUrl: 'assets/ui/icons/sound-gray.png',
            altImageUrl: 'assets/ui/icons/no-sound.png',
          }),
          DialogButton({
            id: ButtonId.DayNightButton,
            imageUrl: 'assets/ui/icons/night-white.png',
            altImageUrl: 'assets/ui/icons/day-white.png',
          }),
        ]) : null,
        Container({
          width: 115 + 165,
          height: 56,
          contentDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'start',
          textAlign: 'left',
        }, [
          TextBlock({
            container: {
              width: 115,
              height: 56,
              alignItems: 'start',
              justifyContent: 'center',
            },
            text: {
              content: i18n.t('canvas.mainMenu.switchTo'),
              fontName: Theme.fontMain.name,
              letterSpacing: -0.1,
            },
          }),
          RadioSwitch({
            width: 165,
            height: 56,
            options: [{ id: TabsId.PC, text: 'PC' }, { id: TabsId.VR, text: 'VR' }],
          }),
        ]),
      ]),
      Container({
        width,
        height: 20,
      }, []),
    ]
    : [];

  return Container({
    id: PanelId.MainMenuPanel,
    interactive: true,
    width,
    height,
    backgroundOpacity: 0,
    visible: component.enabled,
  }, [
    Container({
      interactive: true,
      width,
      height,
      fontName: Theme.fontMain.name,
      justifyContent: 'start',
      alignItems: 'start',
      contentDirection: 'column',
      backgroundOpacity: Theme.bgOpacity,
      backgroundColor: Theme.bgColor,
      borderRadius: Theme.bgBorderRadius,
      padding,
    }, [
      Container({
        width: percentWidth(80),
        height: 48,
        justifyContent: 'center',
      }, [
        Text({
          fontName: Theme.fontMainBold.name,
          content: i18n.t('canvas.mainMenu.poweredByFirstPart'),
          color: Theme.textColor,
          fontSize: captionFontSize,
          letterSpacing: Theme.letterSpacing * 1.5,
        }),
        i18n.t('canvas.mainMenu.poweredBySecondPart') ? Text({
          fontName: Theme.fontMainBold.name,
          content: i18n.t('canvas.mainMenu.poweredBySecondPart'),
          color: Theme.textColor,
          fontSize: captionFontSize,
          letterSpacing: Theme.letterSpacing,
        }) : null,
      ]),
      DialogButton({
        id: ButtonId.CloseButton,
        imageUrl: 'assets/ui/icons/close.png',
        absolutePosition: new Three.Vector2(width - padding - 48, padding + 4),
      }),
      // to remove space by IconButton absolutePosition
      Container({
        width,
        height: 0.01,
        margin: -20,
      }, []),
      DialogButton({
        id: ButtonId.InfoButton,
        imageUrl: 'assets/ui/icons/info.png',
        absolutePosition: new Three.Vector2(padding, padding + 4),
      }),
      // to remove space by IconButton absolutePosition
      Container({
        width,
        height: 0.01,
        margin: -20,
      }, []),
      Container({
        width: width - padding * 2,
        height: tabsContainerHeight,
        contentDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: '#171A30',
        backgroundOpacity: 0.7,
        borderRadius: Theme.bgBorderRadius,
        margin: 10,
        padding: 5,
      }, [
        TabButton({
          id: TabsId.Avatars,
          text: i18n.t('canvas.mainMenu.tabAvatars'),
          height: tabsContainerHeight - 8,
          width: tabWidth,
          fontSize: tabsFontSize,
        }),
        showPlacesTabs ? TabButton({
          id: TabsId.Places,
          text: i18n.t('canvas.mainMenu.tabPlaces'),
          height: tabsContainerHeight - 8,
          width: tabWidth,
          fontSize: tabsFontSize,
        }) : null,
        hasNetwork ? TabButton({
          id: TabsId.Players,
          text: i18n.t('canvas.mainMenu.tabPlayers'),
          height: tabsContainerHeight - 8,
          width: tabWidth,
          fontSize: tabsFontSize,
        }) : null,
        hasNetwork ? TabButton({
          id: TabsId.Invite,
          text: i18n.t('canvas.mainMenu.tabInvite'),
          height: tabsContainerHeight - 8,
          width: tabWidth,
          fontSize: tabsFontSize,
        }) : null,
      ]),
      Container({
        id: TabsId.Container,
        width: width - padding * 2,
        height: contentHeight,
      }, [
        TabContent({
          id: `${TabsId.Avatars}_content`,
          width: width - padding * 2,
          height: contentHeight,
          justifyContent: 'start',
          interactive: true,
          hiddenOverflow: true,
        }, GridRows({
          inRow: avatarsInRow,
          width: width - padding * 3,
          height: contentHeight,
          itemHeight: avatarItemHeight + padding / 2,
          gap: padding / 2,
          contentDirection: 'row',
          justifyContent: 'space-around',
          // backgroundOpacity: 1,
        },
        avatarsMenuItems.map(({ id, imgUrl }) => (AvatarItem({
          id,
          imgUrl,
          width: avatarItemWidth,
          height: avatarItemHeight,
        }))))),
        showPlacesTabs ? TabContent({
          id: `${TabsId.Places}_content`,
          width: width - padding * 2,
          height: contentHeight,
          interactive: true,
          hiddenOverflow: true,
        }, []) : null,
        hasNetwork ? TabContent({
          id: `${TabsId.Players}_content`,
          interactive: true,
          width: width - padding * 2,
          height: contentHeight,
          justifyContent: 'start',
          hiddenOverflow: true,
        }, [
          // UserRow({ adaptive: adaptive as MainMenuAdaptive, name: `ssss${Math.floor(100 * Math.random())}`
          // , id: Math.floor(100 * Math.random()) }),
        ]) : null,
        hasNetwork ? TabContent({
          id: `${TabsId.Invite}_content`,
          width: width - padding * 2,
          height: contentHeight,
          justifyContent: 'start',
          contentDirection: 'column',
        }, [
          TextBlock({
            text: {
              content: i18n.t('canvas.mainMenu.shareLinkDescription'),
              fontSize: 24,
              fontName: Theme.fontMainBold.name,
              letterSpacing: -0.1,
              color: '#FCFEFF',
            },
            container: {
              width: width - padding * (isMobile ? 4 : 3),
              height: isMobile ? 2 * 32 : 32,
              textAlign: 'left',
            },
          }),
          Container({
            width: width - padding * (isMobile ? 4 : 3),
            height: 48 + padding,
            justifyContent: 'space-between',
            alignItems: 'center',
            contentDirection: 'row',
          }, [
            PlayerButton({
              id: ButtonId.CopyButton,
              imageUrl: 'assets/ui/icons/copy.png',
            }),
            TextBlock({
              text: {
                content: window.location.href,
                fontSize: isMobile ? 16 : 24,
                fontName: Theme.fontMainBold.name,
                letterSpacing: -0.1,
                color: '#FFFFFF',
                fontOpacity: 0.7,
              },
              container: {
                width: width - padding * (isMobile ? 4 : 3) - 60,
                height: 48,
                textAlign: 'left',
                justifyContent: 'center',
              },
            }),
          ]),
          TextBlock({
            text: {
              content: i18n.t('canvas.mainMenu.shareSocialMedia'),
              fontSize: 24,
              fontName: Theme.fontMainBold.name,
              letterSpacing: -0.1,
              color: '#FCFEFF',
            },
            container: {
              width: width - padding * (isMobile ? 4 : 3),
              height: 32 + (isMobile ? padding : 0),
              textAlign: 'left',
              justifyContent: 'end',
            },
          }),
          Container({
            width: width - padding * (isMobile ? 4 : 3),
            height: 48 + (isMobile ? padding : padding / 2),
            justifyContent: 'start',
            alignItems: 'center',
            contentDirection: 'row',
          }, localeShareButtons),
        ]) : null,
      ]),
      ...bottomPanel,
    ]),
    Container({
      width,
      height: 80,
      contentDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: Theme.bgColor,
      backgroundOpacity: Theme.bgOpacity,
      padding,
      borderRadius: Theme.bgBorderRadius,
      margin: 8,
      visible: !isMobile,
    }, [
      Container({
        width: isMobile ? width - 100 : width - 230,
        height: isMobile ? 30 : 60,
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
      }, [
        Text({
          fontName: Theme.fontMainBold.name,
          content: i18n.t('canvas.mainMenu.poweredByFirstPart'),
          color: Theme.textColor,
          fontSize: captionFontSize - 5,
          letterSpacing: Theme.letterSpacing * 1.5,
        }),
        i18n.t('canvas.mainMenu.poweredBySecondPart') ? Text({
          fontName: Theme.fontMainBold.name,
          content: i18n.t('canvas.mainMenu.poweredBySecondPart'),
          color: Theme.textColor,
          fontSize: captionFontSize - 5,
          letterSpacing: Theme.letterSpacing,
        }) : null,
      ]),
      Container({
        width: 100,
        height: 60,
        justifyContent: 'center',
      }, [
        PlayerButton({
          id: ButtonId.LogoButton,
          imageUrl: getLocaleSettings().logoUrl ?? 'assets/ui/icons/logo.png',
        }),
      ]),
    ]),
  ]);
}
