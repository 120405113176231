export type WorkerMessage = {
  type: string;
  data?: any;
};

export enum WorkerMessageTypes {
  tick = 'Tick',
  processReceiveMessage = 'processReceiveMessage',
  startWorker = 'startWorker',
  stopWorker = 'stopWorker',
  receiveMessage = 'receiveMessage',
  processSendMessagesBatch = 'processSendMessagesBatch',
  processSendMessage = 'processSendMessage',
}

export type MessagesWorkerEventTypes = {
  onMessage: (payload: WorkerMessage) => void;
};
