import { Object3D, Vector3 } from 'three';
import * as Three from 'three';
import SystemObject from '../../../engine/network/SystemObject';
import { NetworkObjectLifeTimeTypes } from '../../../engine/network/NetworkObject';
import NetworkManager from '../../../engine/network/NetworkManager';
import { Application } from '../../../engine/Application';
import { Entity } from '../../../engine/Entity';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import AudioVariable from '../variables/AudioVariable';
import { AudioSourceType } from '../../services/AudioController';
import AudioComponent from '../../components/AudioComponent';
import { replaceObjectByEntity } from '../../services/replaceObjectByEntity';
import RaycastComponent from '../../components/Raycast.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import SpotifyComponent from '../../components/Spotify.component';
import { AudioPlacesType } from '../../assets/types';

export interface AudioInterface {
  title: string;
  mp3: string;
  previewUrl: string;
}

export default class AudioPlaceObject extends SystemObject {
  public static type = 'audioplace';

  public static audioPlaces: AudioPlacesType = {};

  public lifeTimeType = NetworkObjectLifeTimeTypes.Shared;

  static register(manager: NetworkManager) {
    manager.objectsTypes[AudioPlaceObject.type] = AudioPlaceObject;
  }

  static build(app: Application, code: string): AudioPlaceObject | null {
    if (!app.networkManager) return null;
    if (!app.networkManager.isActiveRoomHost) return null;

    const netObj = app.networkManager?.buildSharedObject<AudioPlaceObject>(AudioPlaceObject.type, code);
    netObj.setApplication(app);
    netObj.addVariables();
    return netObj;
  }

  static setAssetsData(data: AudioPlacesType) {
    AudioPlaceObject.audioPlaces = data;
  }

  public addVariables() {
    this.addVariable(new AudioVariable());
  }

  public attachToEntity(entity: Entity) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
    });
  }

  public static createEntityPrefab(
    app: Application,
    scene: Three.Object3D | null,
    objectName: string,
    source?: AudioSourceType,
    tracks: AudioInterface[] = [],
    colliderObjects: Three.Object3D[] = [],
  ): Entity | undefined {
    if (!scene) return;
    const object = scene.getObjectByName(objectName);
    if (!object) return;
    const entity = app.entityManager.makeEntity();
    // replace object by entity
    // TODO: move to EntityManager
    replaceObjectByEntity(object, entity);

    entity.addComponent(AudioComponent, {
      source,
      colliderObjects,
    });

    entity.addComponent(RaycastComponent).colliderObjects = colliderObjects;
    entity.addComponent(SelectedObjectComponent, {
      hoverColor: new Three.Color(0.5, 0.5, 0.5),
    });
    entity.addComponent(SpotifyComponent, {
      tracks: tracks.map((track, index) => ({
        title: track.title,
        src: track,
        id: `track_${index}`,
        previewUrl: track.previewUrl,
      })),
      mediaComponent: entity.getComponentOrFail(AudioComponent),
    });

    return entity;
  }

  public spawnEntity(
    position: Vector3 | undefined = undefined,
  ): Object3D | undefined {
    if (!this.app) return;
    const variable = this.getVariableByName('audio');
    const scene = this.app.sceneManager.currentThreeScene;
    if (!variable || !scene) return;
    const audioPlace = AudioPlaceObject.audioPlaces[this.code];
    const collider = scene.getObjectByName('spaceCollider');
    const colliders = collider ? [collider] : [];
    const entity = AudioPlaceObject.createEntityPrefab(
      this.app,
      scene,
      this.code,
      variable.value ? variable.value.source : undefined,
      audioPlace.tracks,
      colliders,
    );
    if (entity) {
      this.attachToEntity(entity);
      // entity.getComponent(VideoTextureComponent)?.setState(videoVar.value);
      variable.setNeedUpdateFromNetwork();
    }
    return entity;
  }
}
