import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import WelcomeScreenComponent from '../components/UI/WelcomeScreen.component';
import * as Theme from '../ui/Theme';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import TopMenuComponent from '../components/UI/TopMenu.component';
import { Component } from '../../engine/Component';
import { TopMenuTemplate } from '../ui/templates/TopMenu.template';
import { WelcomeScreenTemplate } from '../ui/templates/WelcomeScreen.template';
import MainMenuComponent from '../components/UI/MainMenu.component';
import { MainMenuTemplate } from '../ui/templates/MainMenu.template';
import { ActionsMenuTemplate } from '../ui/templates/ActionsMenu.template';
import ActionsMenuComponent from '../components/UI/ActionsMenu.component';
import PresentationMenuComponent from '../components/UI/PresentationMenu.component';
import { PresentationMenuTemplate } from '../ui/templates/PresentationMenu.template';
import PlayerMenuComponent from '../components/UI/PlayerMenu.component';
import { PlayerMenuTemplate } from '../ui/templates/PlayerMenu.template';
import ShopifyMenuComponent from '../components/UI/ShopifyMenu.component';
import { ShopifyMenuTemplate } from '../ui/templates/ShopifyMenu.template';
import {
  ActionsMenuAdaptive,
  Adaptive,
  TopMenuAdaptive,
  MainMenuAdaptive,
  PresentationMenuAdaptive,
  ShopifyMenuAdaptive, PlayerMenuAdaptive, AvatarNameAdaptive,
} from '../ui/adaptive';
import { WelcomeScreenAdaptive } from '../ui/adaptive/WelcomeScreen.adaptive';
import { Entity } from '../../engine/Entity';
import { System, SystemOptions } from '../../engine/System';
import AvatarHelpers from '../services/AvatarHelpers';
import CurrentFrameValueContainer from '../services/CurrentFrameValueContainer';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import AvatarNamePanelComponent from '../components/UI/AvatarNamePanel.component';
import { AvatarNameTemplate } from '../ui/templates/AvatarName.template';
import { layers } from '../constants/sceneLayers';
import AvatarVideoComponent from '../components/UI/AvatarVideo.component';
import { AvatarVideoAdaptive } from '../ui/adaptive/AvatarVideo.adaptive';
import { AvatarVideoTemplate } from '../ui/templates/AvatarVideo.template';

export type TemplateCallbackType = (component: Component, adaptive: Adaptive) => ThreeMeshUI.Block | null;

export type EntityData = {
  entity: Entity;
  component: Component;
  template: TemplateCallbackType;
  adaptive: Adaptive;
};

type SetUpAdaptiveEntity = {
  componentType?: typeof Component;
  component?: Component;
  componentEnabled?: boolean;
  parent?: Three.Object3D;
  adaptive: Adaptive;
  makeRoot: TemplateCallbackType;
  depth?: boolean;
  layer?: number;
};

// TODO: rewrite to System
export class UIBuilderSystem extends System {
  protected fontsPromise: Promise<Three.Texture[]> | null = null;

  protected uiEntities: EntityData[] = [];

  protected avatarName: string | null = null;

  protected isSitInCurrentFrame = new CurrentFrameValueContainer<boolean>(false, true);

  static get code(): string {
    return 'ui_builder_system';
  }

  constructor(options: SystemOptions) {
    super(options);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.updateEntities();
      }, 200);
    }, false);
    // window.addEventListener('orientationchange', () => {
    //   console.log('orientationchange');
    //   this.updateEntities();
    // }, false);
  }

  public destroy(): void {
    this.uiEntities = [];
  }

  public onUpdate(dt: number) {
    this.handleUpdatePositions();
    // this.handleVRRayLength();
    //
  }

  public handleVRRayLength() {
    const uiSystem = this.app.getSystemOrFail(UIDocumentSystem);
    const length = uiSystem.isAnyDocumentHovered() ? 1 : 3;
    const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
    xrInputSystem.raySpaces.forEach((raySpace) => {
      raySpace.traverse((obj) => {
        if (obj instanceof Three.Line) {
          obj.scale.z = length;
        }
      });
    });
  }

  public handleUpdatePositions() {
    this.isSitInCurrentFrame.update(
      !!AvatarHelpers.getCharacterEntity(this.app)?.getComponentOrFail(PlayerControlsComponent).sitIsActive,
    );
    const avatarName = AvatarHelpers.getName(this.app);
    if (!this.avatarName) this.avatarName = avatarName;
    if (this.app.renderer.xr.isPresenting && this.avatarName !== avatarName) {
      // TODO: remove timeout -- maybe update world matrix
      this.updateEntitiesPosition();
      this.avatarName = avatarName;
    }
    if (this.isSitInCurrentFrame.changedInCurrentFrame()) {
      this.updateEntitiesPosition();
    }
  }

  public onXRSessionStart() {
    this.updateEntities();
  }

  public onXRSessionEnd() {
    this.updateEntities();
  }

  public getAdaptiveServiceByComponent(component: Component): Adaptive | undefined {
    const el = this.uiEntities.find((data) => data.component === component);
    if (el) return el.adaptive;
  }

  public addEntity(data: EntityData) {
    this.uiEntities.push(data);
    return data.entity;
  }

  // public setLayers(object?: Three.Object3D | null, layers: number[] = [sceneLayers.ui]) {
  //   if (!object) return;
  //   layers.forEach((layer) => object.layers.set(layer));
  //   object.traverse((child) => {
  //     layers.forEach((layer) => child.layers.set(layer));
  //   });
  // }

  public loadFont(fontName: string, textureUrl: string, jsonUrl: string) {
    return fetch(jsonUrl)
      .then((res) => res.json())
      .then((json) => {
        return new Promise<Three.Texture>((resolve) => {
          new Three.TextureLoader().load(textureUrl, (texture) => {
            ThreeMeshUI.FontLibrary.addFont(fontName, json, texture);
            resolve(texture);
          });
        });
      });
  }

  public loadFonts() {
    if (this.fontsPromise) return this.fontsPromise;
    this.fontsPromise = Promise.all([
      this.loadFont(Theme.fontMain.name, Theme.fontMain.texture, Theme.fontMain.json),
      this.loadFont(Theme.fontMainBold.name, Theme.fontMainBold.texture, Theme.fontMainBold.json),
    ]);
    return this.fontsPromise;
  }

  protected setUIData(element: Three.Object3D, data: any) {
    element.traverse((obj) => {
      if (!obj.userData.uiData) obj.userData.uiData = {};
      obj.userData.uiData = Object.assign(obj.userData.uiData, data);
    });
  }

  public setupAdaptiveUIEntity(options: SetUpAdaptiveEntity) {
    const entity = options.component ? options.component.entity : this.app.entityManager.makeEntity();
    const component = options.componentType ? entity.addComponent(options.componentType) : options.component;
    if (!component) return Promise.resolve(entity);
    if (typeof options.componentEnabled !== 'undefined') component.enabled = options.componentEnabled;
    const uiComponent = entity.addComponent(UIDocumentComponent);
    if (options.parent) {
      options.parent.add(entity);
    }
    entity.position.copy(options.adaptive.position);

    return this.loadFonts().then(() => {
      uiComponent.setRoot(options.makeRoot(component, options.adaptive));
      if (uiComponent.root) {
        uiComponent.root.scale.set(options.adaptive.scale, options.adaptive.scale, options.adaptive.scale);
        uiComponent.root.position.add(options.adaptive.positionOffset);
        this.setUIData(uiComponent.root, { depth: options.depth, layer: options.layer });
      }
      // }
      ThreeMeshUI.update();
      // this.setLayers(uiComponent.root);
      return entity;
    });
  }

  public updateEntities() {
    this.uiEntities.forEach((data) => this.updateEntity(data));
  }

  public findEntityDataByComponent(component: Component) {
    return this.uiEntities.find((data) => data.component === component);
  }

  public updateEntitiesPosition() {
    // TODO: remove timeout -- maybe update world matrix
    setTimeout(() => {
      this.uiEntities.forEach((data) => {
        data.entity.position.copy(data.adaptive.position);
      });
    }, 1000);
  }

  public updateEntity({ entity, component, template, adaptive }: EntityData) {
    const uiComponent = entity.getComponentOrFail(UIDocumentComponent);
    const depth = uiComponent.root?.userData.uiData.depth;
    const layer = uiComponent.root?.userData.uiData.layer;
    adaptive.parent?.add(entity);
    entity.position.copy(adaptive.position);
    uiComponent.setRoot(template(component, adaptive));
    if (uiComponent.root) {
      uiComponent.root.scale.set(adaptive.scale, adaptive.scale, adaptive.scale);
      uiComponent.root.position.add(adaptive.positionOffset);
      this.setUIData(uiComponent.root, { depth, layer });
    }
    ThreeMeshUI.update();
    uiComponent.reset = true;
    // this.setLayers(uiComponent.root);
  }

  public setupAvatarNamePanel(parent: Entity) {
    const adaptive = new AvatarNameAdaptive(this.app);
    adaptive.setParent(parent);
    return this.setupAdaptiveUIEntity({
      componentType: AvatarNamePanelComponent,
      parent,
      adaptive,
      makeRoot: AvatarNameTemplate,
      depth: true,
      layer: layers.underUI,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(AvatarNamePanelComponent), template: AvatarNameTemplate,
    }));
  }

  public setupAvatarVideo(component: AvatarVideoComponent) {
    const adaptive = new AvatarVideoAdaptive(this.app, component);
    return this.setupAdaptiveUIEntity({
      adaptive,
      makeRoot: AvatarVideoTemplate,
      component,
      parent: adaptive.parent,
      depth: true,
      layer: layers.underUI,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(AvatarVideoComponent), template: AvatarVideoTemplate,
    }));
  }

  // TODO: on resize
  public setupTopMenu() {
    const adaptive = new TopMenuAdaptive(this.app);
    const { parent } = adaptive;

    if (!parent) return;

    return this.setupAdaptiveUIEntity({
      componentType: TopMenuComponent,
      parent,
      adaptive,
      makeRoot: TopMenuTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(TopMenuComponent), template: TopMenuTemplate,
    }));
  }

  public setupActionsMenu() {
    const adaptive = new ActionsMenuAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;

    return this.setupAdaptiveUIEntity({
      componentType: ActionsMenuComponent,
      parent,
      adaptive,
      makeRoot: ActionsMenuTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(ActionsMenuComponent), template: ActionsMenuTemplate,
    }));
  }

  public setupWelcomeScreen(initSpace = false) {
    const adaptive = new WelcomeScreenAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;
    return this.setupAdaptiveUIEntity({
      componentType: WelcomeScreenComponent,
      componentEnabled: initSpace,
      parent,
      adaptive,
      makeRoot: WelcomeScreenTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(WelcomeScreenComponent), template: WelcomeScreenTemplate,
    }));
  }

  public setupMainMenu() {
    const adaptive = new MainMenuAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;
    return this.setupAdaptiveUIEntity({
      componentType: MainMenuComponent,
      parent,
      adaptive,
      makeRoot: MainMenuTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(MainMenuComponent), template: MainMenuTemplate,
    }));
  }

  public setupPresentationMenu() {
    const adaptive = new PresentationMenuAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;
    return this.setupAdaptiveUIEntity({
      componentType: PresentationMenuComponent,
      parent,
      adaptive,
      makeRoot: PresentationMenuTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(PresentationMenuComponent), template: PresentationMenuTemplate,
    }));
  }

  public setupPlayerMenu() {
    const adaptive = new PlayerMenuAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;
    return this.setupAdaptiveUIEntity({
      componentType: PlayerMenuComponent,
      parent,
      adaptive,
      makeRoot: PlayerMenuTemplate,
    }).then((entity) => {
      this.addEntity({
        adaptive, entity, component: entity.getComponentOrFail(PlayerMenuComponent), template: PlayerMenuTemplate,
      });
    });
  }

  public setupShopifyMenu() {
    const adaptive = new ShopifyMenuAdaptive(this.app);
    const { parent } = adaptive;
    if (!parent) return;
    return this.setupAdaptiveUIEntity({
      componentType: ShopifyMenuComponent,
      parent,
      adaptive,
      makeRoot: ShopifyMenuTemplate,
    }).then((entity) => this.addEntity({
      adaptive, entity, component: entity.getComponentOrFail(ShopifyMenuComponent), template: ShopifyMenuTemplate,
    }));
  }
}
