import { Vector2Sensor } from './Vector2Sensor';
import { ButtonSensor } from './ButtonSensor';
import { TouchControl } from '../controls/TouchControl';

export class TouchSensor {
  positionSensor: Vector2Sensor = new Vector2Sensor();

  pressSensor: ButtonSensor = new ButtonSensor();

  set sleep(value: boolean) {
    this.pressSensor.sleep = value;
  }

  applyToControls(touchControl: TouchControl): void {
    this.positionSensor.applyToControls(touchControl.position, touchControl.delta);
    this.pressSensor.applyToControls(touchControl.press);
  }
}
