import EventEmitter from 'eventemitter3';
import WelcomeScreenComponent from '../components/UI/WelcomeScreen.component';
import ShopifyMenuComponent from '../components/UI/ShopifyMenu.component';
import SceneLightMapsComponent from '../components/SceneLightMaps.component';
import { Application } from '../../engine/Application';
import CurrentFrameValueContainer from '../services/CurrentFrameValueContainer';
import BaseScene from '../scenes/BaseScene';
import { BackgroundImageAssetsType } from '../assets/types';

export type UIApiEventTypes = {
  ScreenOpened: () => void;
  ScreenClosed: () => void;
  StartLoading: () => void;
  EndLoading: () => void;
  StartSceneLoading: (background: BackgroundImageAssetsType, title: string) => void;
  EndSceneLoading: () => void;
  CloseStartMenu: () => void;
};

export class UIApi {
  protected _events: EventEmitter<UIApiEventTypes> = new EventEmitter<UIApiEventTypes>();

  protected app: Application;

  public enterScene = true;

  protected isLightLoading = false;

  protected isSceneLoading = false;

  protected isLoadingInCurrentFrame = new CurrentFrameValueContainer<boolean>(false);

  protected isLightLoadingInCurrentFrame = new CurrentFrameValueContainer<boolean>(false);

  protected isSceneLoadingInCurrentFrame = new CurrentFrameValueContainer<boolean>(false);

  constructor(app: Application) {
    this.app = app;
    this.app.events.on('render', this.onUpdate, this);
  }

  public get events(): EventEmitter<UIApiEventTypes> {
    return this._events;
  }

  public get isLoading() {
    return this.isLightLoading && !this.isSceneLoading;
  }

  public onUpdate(dt: number) {
    const components: (WelcomeScreenComponent | ShopifyMenuComponent)[] = [
      ...this.app.componentManager.getComponentsByType(WelcomeScreenComponent),
    ];
    components.push(...this.app.componentManager.getComponentsByType(ShopifyMenuComponent));
    if (components.some((cp) => cp.enabled)) this.events.emit('ScreenOpened');
    else this.events.emit('ScreenClosed');

    this.isSceneLoading = this.app.sceneManager.sceneIsLoading;

    this.app.componentManager.getComponentsByType(SceneLightMapsComponent).forEach((lightMapComponent) => {
      if (this.isSceneLoading) {
        this.isLightLoading = false;
        return;
      }
      if (!lightMapComponent.isInitializedInCurrentFrame.changedInCurrentFrame()) return;
      const value = lightMapComponent.isInitialized;
      if (value) {
        setTimeout(() => {
          this.isLightLoading = false;
        }, lightMapComponent.timeout);
      } else {
        this.isLightLoading = true;
      }
    });

    this.isLoadingInCurrentFrame.update(this.isLoading);
    this.isSceneLoadingInCurrentFrame.update(this.isSceneLoading);
    this.isLightLoadingInCurrentFrame.update(this.isLightLoading);

    if (this.isSceneLoadingInCurrentFrame.changedInCurrentFrame()) {
      if (this.isSceneLoading) {
        const background = (this.app.sceneManager.currentScene as BaseScene).background || '';
        const title = (this.app.sceneManager.currentScene as BaseScene).title || '';
        this.events.emit('StartSceneLoading', background, title);
      } else this.events.emit('EndSceneLoading');
    }

    if (this.isLoadingInCurrentFrame.changedInCurrentFrame() && this.enterScene) {
      if (this.isLoading) {
        this.events.emit('StartLoading');
      } else {
        this.events.emit('EndLoading');
      }
    }
  }
}
