import EventEmitter from 'eventemitter3';
import { Entity } from './Entity';

export type ComponentOptions = {
  id: string;
  index: number;
  entity: Entity;
  data?: unknown;
};

export type ComponentEventTypes = {
  test2: () => void;
};

export class Component extends EventEmitter<ComponentEventTypes> {
  public index: number;

  public enabled = true;

  protected id: string;

  protected _entity: Entity;

  constructor(options: ComponentOptions) {
    super();
    this.id = options.id;
    this.index = options.index;
    this._entity = options.entity;

    return this;
  }

  // think about it
  public static get code(): string {
    return 'component';
  }

  public get entity(): Entity {
    return this._entity;
  }

  public static make(options: ComponentOptions): InstanceType<typeof this> {
    return new this(options);
  }

  public destroy(): void {
    // todo: nothing
  }
}
