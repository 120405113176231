import { Component, ComponentOptions } from '../../engine/Component';
import VideoComponent from './VideoComponent';
import AudioComponent from './AudioComponent';
import { VideoSourceType } from '../services/VideoController';
import { AudioSourceType } from '../services/AudioController';

export type TrackSimpleSrcType = VideoSourceType & AudioSourceType;

export type TrackType<TrackSrcType> = {
  title?: string;
  id: string;
  previewUrl?: string;
  src: TrackSrcType;
};

export type MediaComponentType = VideoComponent | AudioComponent;

export type TrackComponentOptions<TrackSrcType> = ComponentOptions & {
  data?: {
    tracks: TrackType<TrackSrcType>[];
    mediaComponent: MediaComponentType;
  };
};

// TODO: create TrackComponent for Video & Audio
export default class TrackComponent<TrackSrcType = TrackSimpleSrcType> extends Component {
  public tracks: TrackType<TrackSrcType>[] = [];

  public currentTrackIndex = 0;

  public mediaComponent: MediaComponentType | null = null;

  constructor(options: TrackComponentOptions<TrackSrcType>) {
    super(options);
    this.tracks = options.data?.tracks ?? [];
    this.mediaComponent = options.data?.mediaComponent ?? null;
  }

  public setCurrentTrackByMediaSource() {
    if (!this.mediaComponent?.source) return;
    let findIndex = -1;
    this.tracks.forEach((track, index) => {
      Object.keys(track.src as object).forEach((key) => {
        if (findIndex >= 0) return;
        const mediaSource = this.mediaComponent?.source;
        if (!mediaSource) return;
        if (track.src[key as keyof typeof track.src] && mediaSource[key as keyof typeof mediaSource]
          && track.src[key as keyof typeof track.src] === mediaSource[key as keyof typeof mediaSource]) {
          findIndex = index;
        }
      });
    });
    if (findIndex >= 0) this.currentTrackIndex = findIndex;
  }

  public get currentTrack() {
    return this.tracks[this.currentTrackIndex];
  }

  public setNextTrack() {
    if (!this.tracks.length) return;
    if (this.currentTrackIndex === this.tracks.length - 1) {
      this.currentTrackIndex = 0;
      return;
    }
    this.currentTrackIndex = (this.currentTrackIndex + 1) % this.tracks.length;
  }

  public setPrevTrack() {
    if (!this.tracks.length) return;
    if (this.currentTrackIndex === 0) {
      this.currentTrackIndex = this.tracks.length - 1;
      return;
    }
    this.currentTrackIndex = (this.currentTrackIndex - 1) % this.tracks.length;
  }

  static get code(): string {
    return 'track';
  }
}
