import { SyncVariable } from '../../../engine/network/SyncVariable';
import { Application } from '../../../engine/Application';
import NetworkManager from '../../../engine/network/NetworkManager';
import { Component } from '../../../engine/Component';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';

export default class EngineVariable<ValueType = any> extends SyncVariable<ValueType> {
  public static type = '';

  public static app: Application;

  public static register(manager: NetworkManager, app: Application) {
    manager.variableTypes[this.type] = this;
    this.app = app;
  }

  public getComponent<T extends typeof Component>(app: Application, componentType: T): InstanceType<T> | undefined {
    return app.componentManager.getComponentsByType<T>(componentType).find((comp) => {
      return this.id && !!comp.entity.getComponent(NetworkObjectComponent)?.netObject?.getVariableById(this.id);
    });
  }
}
