import ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { Application } from '../../engine/Application';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { Entity } from '../../engine/Entity';
import AvatarComponent from '../components/Avatar.component';
import { Component } from '../../engine/Component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import { ContainerId } from '../ui/enum/ContainerId';
import BaseScene from '../scenes/BaseScene';
import { Adaptive } from '../ui/adaptive';

export default class AvatarHelpers {
  static getHead(app: Application, component?: Component) {
    const vrm = this.getAvatarEntity(app, component)?.getComponentOrFail(MeshRendererComponent).getVRM();
    return vrm?.humanoid?.getRawBoneNode('head') ?? undefined;
  }

  static getCharacterEntity(app: Application, component?: Component) {
    return component
      ? component.entity.getComponentFromParents(NetworkObjectComponent)?.entity
      : (app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
  }

  static getAvatarEntity(app: Application, component?: Component) {
    return component
      ? component.entity.getComponentFromParents(NetworkObjectComponent)?.entity.getObjectByName('avatarEntity') as Entity
      : (app.sceneManager.currentScene as BaseScene).getAvatarEntity();
  }

  static getName(app: Application, component?: Component) {
    const entity = this.getAvatarEntity(app, component);
    if (!entity) return null;
    return entity.getComponentOrFail(AvatarComponent).avatarName;
  }

  static setUIName(app: Application, component: Component, userId?: number) {
    if (typeof userId === 'undefined') return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const users = app.networkManager?.sessionStore.users;
    const name = users?.find((u) => u.id === userId)?.name;
    if (!name) return;
    const textContainer = uIDocumentComponent.getElementById(ContainerId.UserName);
    if (!textContainer) return;
    const textElement = textContainer.children.find((ch) => ch instanceof ThreeMeshUI.Text);
    if (!textElement) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (textElement.content === name) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.set({
      content: name,
    });
  }

  static setComponentPositionUnderAvatarHead(
    app: Application,
    component: Component,
    adaptive?: Adaptive,
    avatar?: Entity,
    offset = 0,
  ) {
    const avatarEntity = avatar ?? this.getAvatarEntity(app, component);
    if (avatarEntity && component.entity.parent !== avatarEntity) avatarEntity.add(component.entity);
    const head = AvatarHelpers.getHead(app, component);
    const charEntity = AvatarHelpers.getCharacterEntity(app, component);
    if (!charEntity || !head || !avatarEntity || !adaptive) return;
    const parentY = component.entity.parent ? component.entity.parent?.getWorldPosition(new Three.Vector3()).y : 0;
    const headY = head.getWorldPosition(new Three.Vector3()).y - parentY;
    const newY = headY + adaptive.height * adaptive.scale + offset;
    if (Math.abs(newY - component.entity.position.y) > 0.05) {
      component.entity.position.y = newY;
    }
  }
}
