import * as ThreeMeshUI from 'three-mesh-ui';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';

export type AddInteractivityToElementParams = {
  id: string;
  element: ThreeMeshUI.Block;
  defaultAttributes: Partial<ThreeMeshUI.BlockOptions>;
  hoveredAttributes: Partial<ThreeMeshUI.BlockOptions>;
  activeAttributes: Partial<ThreeMeshUI.BlockOptions>;
  userAttributes?: Partial<ThreeMeshUI.BlockOptions>;
  selectedAttributes?: Partial<ThreeMeshUI.BlockOptions>;
};

export function addInteractivityToElement(params: AddInteractivityToElementParams): void {
  params.element.userData.uiData = {
    id: params.id,
    interactive: true,
  };

  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  params.element.setupState({
    state: UIDocumentElementState.Hovered,
    attributes: params.hoveredAttributes,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  params.element.setupState({
    state: UIDocumentElementState.Default,
    attributes: params.defaultAttributes,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  params.element.setupState({
    state: UIDocumentElementState.Active,
    attributes: params.activeAttributes,
  });

  if (params.userAttributes) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params.element.setupState({
      state: UIDocumentElementState.User,
      attributes: params.userAttributes,
    });
  }

  if (params.selectedAttributes) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params.element.setupState({
      state: UIDocumentElementState.Selected,
      attributes: params.selectedAttributes,
    });
  }
}
