import MessagesPool, { Message, SendMessage } from './MessagesPool';

export default class SendMessagesPool extends MessagesPool<SendMessage> {
  public getSendMessagesPool(): Message[] {
    this.messages.sort((m1, m2) => {
      return Number(m1.clientSentTimestamp - m2.clientSentTimestamp);
    });
    const messages = [...this.messages];
    // if (messages.length > 0) {
    //   console.log('Send message count', messages.length);
    // }
    this.messages = [];
    return messages;
  }

  public replaceVariableMessage(message: SendMessage) {
    if (message.queueId) {
      this.messages = this.messages.filter((ms) => ms.queueId !== message.queueId);
    }
    this.messages.push(message);
  }

  public sendMessage(message: SendMessage) {
    message.clientSentTimestamp = message.clientSentTimestamp || BigInt(Date.now());
    if (message.required) {
      this.messages.push(message);
    } else {
      this.replaceVariableMessage(message);
    }
  }

  clear() {
    super.clear();
  }
}
