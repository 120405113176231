import { Component } from '../../../engine/Component';
import TrackComponent from '../Track.component';

export default class PlayerMenuComponent extends Component {
  public enabled = false;

  public currentActionComponent: Component | null = null;

  public actionComponentType: typeof Component = TrackComponent;

  public trackFilled = false;

  public isLoading = false;

  public libraryIsOpen = true;

  public screenShareUserId?: number;

  static get code(): string {
    return 'youtube_menu';
  }
}
