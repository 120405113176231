import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const Camera: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M17.7866 15.309C18.3412 15.2069 18.9015 15.4966 19.1092 16.0209V16.0209C19.3729 16.6869 18.9624 17.4279 18.2579 17.5575L17.103 17.7699C14.7623 18.0598 12.3661 18.1187 9.99819 18.0098C9.17891 17.9722 8.60341 17.259 8.92177 16.5031V16.5031C9.12335 16.0246 9.65327 15.6424 10.172 15.6662C12.6988 15.782 15.3008 15.7665 17.7866 15.309Z"
          fill={fill}
        />
        <path
          d="M6.94026 17.2934C6.77354 17.8253 6.28665 18.2018 5.72936 18.1917V18.1917C4.97909 18.1781 4.42496 17.4859 4.63096 16.7643C5.44623 13.9087 6.93987 11.0035 8.64075 8.58244C9.11201 7.91164 10.0088 7.80097 10.5051 8.45344V8.45344C10.8334 8.88494 10.9005 9.56128 10.6075 10.0175C9.14971 12.2877 7.74606 14.7229 6.94026 17.2934Z"
          fill={fill}
        />
        <path
          d="M12.8713 5.30841C12.4289 4.75736 11.592 4.77241 11.1541 5.32709V5.32709C10.8109 5.76175 10.8399 6.38014 11.1991 6.80165C12.8397 8.7269 14.1555 10.9738 15.3196 13.2217C15.5584 13.6829 16.1543 13.9507 16.6696 13.886V13.886C17.4833 13.7838 17.8131 12.9287 17.4301 12.2034C16.1298 9.74048 14.6115 7.47571 12.8713 5.30841Z"
          fill={fill}
        />
      </g>
    </svg>

  );
});

export default Camera;
