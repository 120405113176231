import * as Three from 'three';
import { AdditionalPhonemeInfo } from '@inworld/web-sdk';
import { JsonAnimationLoader } from './JsonAnimationLoader';

const MODELS_URI_PREFIX = 'https://assets.inworld.ai/models';
const ANIMATION_FADE_TIME_S = 0.5;
const END_TALKING_DEBOUNCE_TIME_MS = 500;

export type AssistantAnimationOptions = {
  animations: string[];
};

export class AssistantAnimation {
  protected _talkingCountDown = 0;

  protected _idleTimeout?: ReturnType<typeof setTimeout>;

  protected _animations: string[] = [];

  protected _entity: Three.Object3D;

  protected _animationMixer: Three.AnimationMixer;

  protected _clips: Record<string, Three.AnimationClip> = {};

  protected _lastClipName = '';

  constructor(entity: Three.Object3D, opts: AssistantAnimationOptions) {
    this._animations = opts.animations;
    this._entity = entity;
    this._animationMixer = new Three.AnimationMixer(this._entity);
  }

  public async loadJsonClips() {
    const idle = await JsonAnimationLoader(`${MODELS_URI_PREFIX}/idle/IdleNeutral.json`);
    if (idle) this._clips.Idle = idle;
    await Promise.all(
      this._animations.map(async (name) => {
        const clip = await JsonAnimationLoader(`${MODELS_URI_PREFIX}/talking/${name}.json`);
        if (clip) this._clips[name] = clip;
      }),
    );
    setTimeout(this.startIdleAnimation.bind(this), END_TALKING_DEBOUNCE_TIME_MS);
  }

  public onPhonemes(phonemes: AdditionalPhonemeInfo[]) {
    this._talkingCountDown += phonemes.length;
    this.startTalkingAnimation();
  }

  public update(dt: number) {
    this._animationMixer.update(dt);
    if (this._talkingCountDown > 0) {
      this._talkingCountDown -= 0.9999; // Not Integer to make sure the timing "count down < 0" can happen only once per sentence.
    }
    if (this._talkingCountDown < 0) {
      this._talkingCountDown = 0;
      // TODO:
      // emotionRef.current = 'Neutral';
      setTimeout(this.startIdleAnimation.bind(this), END_TALKING_DEBOUNCE_TIME_MS);
    }
  }

  protected getTalkingClipName() {
    // At least 2 animations to rotate!
    if (this._animations.length < 2) return '';
    return this._animations[
      Math.floor(Math.random() * (this._animations.length - 1))
    ];
  }

  protected startIdleAnimation() {
    if (this._talkingCountDown > 0 || this._lastClipName === 'Idle') {
      return;
    }
    if (this._animationMixer) {
      if (this._lastClipName) {
        this._animationMixer
          .clipAction(this._clips[this._lastClipName])
          .fadeOut(ANIMATION_FADE_TIME_S);
      }
      const clipName = 'Idle';
      if (this._clips[clipName]) {
        const durationSeconds = this._clips[clipName].duration;
        this._animationMixer
          .clipAction(this._clips[clipName])
          .reset()
          .fadeIn(0.5)
          .play();
        this._lastClipName = clipName;
        this._idleTimeout = setTimeout(this.startIdleAnimation.bind(this), durationSeconds * 1000);
      }
    }
  }

  protected startTalkingAnimation() {
    if (this._idleTimeout !== null) {
      clearTimeout(this._idleTimeout);
    }
    let clipName = '';
    while (!clipName || clipName === this._lastClipName) {
      clipName = this.getTalkingClipName();
    }

    if (this._animationMixer) {
      if (this._lastClipName) {
        this._animationMixer
          .clipAction(this._clips[this._lastClipName])
          .fadeOut(ANIMATION_FADE_TIME_S);
      }
      this._animationMixer
        .clipAction(this._clips[clipName])
        .reset()
        .fadeIn(ANIMATION_FADE_TIME_S)
        .setLoop(Three.LoopPingPong, 20)
        .play();
      this._lastClipName = clipName;
    }
  }
}
