import { makeAutoObservable } from 'mobx';
import { useCallback } from 'react';
import { PlayerExternalApi } from '../../domain/externalApi/PlayerExternalApi';
import ControlsStore from './ControlsStore';
import BootstrapService from '../../domain/services/Bootstrap.service';
import { SettingSpacesType } from '../types/SettingSpaces';
import { UIApi } from '../../domain/externalApi/UIApi';
import { BackgroundImageAssetsType } from '../../domain/assets/types';
import BaseScene from '../../domain/scenes/BaseScene';
import { InworldService } from '../../domain/services/Assistent/Inworld/InworldService';

export class SpaceStore {
  public isLoading = true;

  public showStart = false;

  public playerApi: PlayerExternalApi | null = null;

  public assistant?: InworldService;

  public showEnterScreen = false;

  public controlsStore;

  public debugInfo = '';

  public settingsCurrentSpace: SettingSpacesType | null = null;

  private _bootstrap!: BootstrapService;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.controlsStore = new ControlsStore();
  }

  public setBootstrap(bootStrapService: BootstrapService): void {
    this._bootstrap = bootStrapService;
  }

  public setSettingsSpace(setting: SettingSpacesType): void {
    this.settingsCurrentSpace = setting;
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public setShowEnterScreen(showEnterScreen: boolean): void {
    this.showEnterScreen = showEnterScreen;
  }

  public setShowStart(isShowStart: boolean): void {
    this.showStart = isShowStart;
  }

  public setPlayerApi(api: PlayerExternalApi): void {
    this.playerApi = api;
  }

  public setAssistant(value: InworldService): void {
    this.assistant = value;
  }

  public setDebugInfo(debugInfo: string): void {
    this.debugInfo = debugInfo;
  }

  public updateNetwork(): void {
    this._bootstrap.updateNetworkObjects();
  }

  public switchToNextSpace(): Promise<void> | Promise<null> {
    if ((this._bootstrap.app?.sceneManager.currentScene as BaseScene).needVRAfterLoading) {
      this._bootstrap.app?.vrSession.startSession();
    }
    return this._bootstrap.handleUserInteraction();
  }

  public startSpace(): void {
    if (!this._bootstrap) throw new Error('bootstrapService is not defined');
    if (!this._bootstrap.app) throw new Error('app is not defined');
    const { soundActive, cameraActive, microActive, userName } = this.controlsStore;
    Promise.all([
      this._bootstrap.handleUserInteraction(soundActive),
      this._bootstrap.initVRSession(),
      this._bootstrap.addUser(userName, microActive, soundActive, cameraActive),
    ])
      .then(() => {
        this._bootstrap.updateNetworkObjects();
        this.setIsLoading(false);
        if (this._bootstrap.assistant) this.setAssistant(this._bootstrap.assistant);
        const UIApiInstance = this._bootstrap.uiApi;
        if (!UIApiInstance) throw new Error('UIApiInstance is not defined');
        UIApiInstance.enterScene = true;
        this.sceneStartSubscribe(UIApiInstance);
      });
  }

  private sceneStartSubscribe(UIApiInstance: UIApi): void {
    const { setShowControls } = this.controlsStore;
    UIApiInstance.events.on('ScreenOpened', () => {
      setShowControls(false);
    });
    UIApiInstance.events.on('ScreenClosed', () => {
      setShowControls(true);
    });
    UIApiInstance.events.on('StartLoading', () => {
      this.setIsLoading(true);
    });
    UIApiInstance.events.on('EndLoading', () => {
      this.setIsLoading(false);
    });
    UIApiInstance.events.on('StartSceneLoading', (background: BackgroundImageAssetsType, title: string) => {
      this.setIsLoading(true);
      this.setSettingsSpace({ background, title });
      this.setShowEnterScreen(true);
    });
    UIApiInstance.events.on('EndSceneLoading', () => {
      UIApiInstance.enterScene = false;
    });
  }
}
