import * as Three from 'three';
import { System } from '../../engine/System';
import VideoComponent from '../components/VideoComponent';
import { getObjectCenter } from '../services/getObjectCenter';
import BaseScene from '../scenes/BaseScene';

export class VideoSystem extends System {
  private raycaster = new Three.Raycaster();

  onUpdate(dt: number) {
    super.onUpdate(dt);
    this.app.componentManager.getComponentsByType(VideoComponent).forEach((component) => {
      this.handleDistanceVolume(component);
      this.handleScreenShare(component);
    });
  }

  protected handleScreenShare(component: VideoComponent) {
    const { videoService } = this.app.chatsService || {};
    if (!videoService || !component.controller.element || !component.isInitialized) return;
    if (typeof component.shareScreenUserId !== 'undefined' && component.shareScreenUserId >= 0) {
      const stream = videoService.getUserShareStream(String(component.shareScreenUserId));
      if (stream) component.setStream(stream);
      else {
        component.removeStream();
        const lostScreenShare = this.app.networkManager?.networkId === component.shareScreenUserId;
        if (lostScreenShare) {
          component.setScreenShareUser(undefined);
        }
      }
    } else if (component.controller.element.srcObject && !component.source?.stream) {
      component.removeStream();
    }
  }

  protected handleDistanceVolume(component: VideoComponent): void {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    if (!component.controller.element?.muted) {
      const { entity } = component;
      const center = getObjectCenter(entity);
      const distance = center.distanceTo(characterEntity.getWorldPosition(new Three.Vector3()));
      if (distance > component.distanceVolume) {
        component.changeVolume(distance, true);
      } else {
        component.changeVolume(distance, this.raycastDistance(component, center) < distance);
      }
    }
  }

  public raycastDistance(component: VideoComponent, target: Three.Vector3): number {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    // const { entity } = component;
    const position = new Three.Vector3();
    // const target = new Three.Vector3();
    const direction = new Three.Vector3();

    characterEntity.getWorldPosition(position);
    // entity.getWorldPosition(target);
    direction.subVectors(target, position).normalize();

    this.raycaster.set(position, direction);
    this.raycaster.far = component.rayCastFar;
    const intercets = this.raycaster.intersectObjects(component.colliderObjects, component.recursiveIntersection);

    return intercets[0] ? intercets[0].distance + 0.1 : Infinity;
  }
}
