import { Component } from '../../engine/Component';
import { Entity } from '../../engine/Entity';

export default class SeatPlaceComponent extends Component {
  public isBusy = false;

  public returnEntity?: Entity;

  public distanceSeat = 7;

  static get code(): string {
    return 'seat_place';
  }
}
