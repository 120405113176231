import * as Three from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { Application } from '../../../engine/Application';
import { layers } from '../../constants/sceneLayers';

let envCache: Three.Texture | undefined;

function generateShadows(threeScene: Three.Scene, characterObject: Three.Object3D) {
  const parent = threeScene;
  // setInterval(() => {
  //   console.log(characterObject.getWorldPosition(new Three.Vector3()));
  // }, 500);
  const collider = threeScene.getObjectByName('spaceCollider');
  if (!collider) return;
  const bbox = new Three.Box3().setFromObject(collider);
  const size = 8;
  const doubleSize = 2 * size;
  // all scene
  const length = [[bbox.min.x, bbox.max.x], [bbox.min.z, bbox.max.z]];
  let count = 0;
  // some around character
  // const length = [[-8, 8], [-16, 8]];
  for (let x = length[0][0]; x < length[0][1]; x += doubleSize) {
    for (let z = length[1][0]; z < length[1][1]; z += doubleSize) {
      count += 1;
      if (count > 10) break;
      const first = x === length[0][0] && z === length[1][0];
      const offset = new Three.Vector3(x + size, 0, z + size);
      const sunlight = new Three.DirectionalLight(0xffffff);
      // const sunlight = new Three.PointLight(0xffffff);
      sunlight.position.set(3, 22, 0).add(offset);
      sunlight.target = new Three.Object3D();
      sunlight.target.position.set(0, 0, 0).add(offset);
      parent.add(sunlight.target);
      sunlight.intensity = 0.7 * (first ? 1 : 0);
      sunlight.castShadow = true;
      sunlight.shadow.camera.near = 1;
      sunlight.shadow.camera.far = 100;
      sunlight.shadow.camera.right = size;
      sunlight.shadow.camera.left = -size;
      sunlight.shadow.camera.top = size;
      sunlight.shadow.camera.bottom = -size;
      sunlight.shadow.mapSize.width = 512;
      sunlight.shadow.mapSize.height = 512;
      // sunlight.shadow.radius = 1.1;
      sunlight.shadow.bias = -0.002;

      // threeScene.add(sunlight);
      parent.add(sunlight);

      sunlight.layers.set(layers.avatarFirstPerson);
      sunlight.layers.enable(layers.avatarThirdPerson);

      // const helper = new Three.CameraHelper(sunlight.shadow.camera);
      // const color = new Three.Color(x, z, 0);
      // helper.setColors(color, color, color, color, color);
      // parent.add(helper);
    }
  }
}

// todo: refactor to components
export function setupEnvironment(app: Application, threeScene: Three.Scene, characterObject: Three.Object3D): void {
  const pmremGenerator = new Three.PMREMGenerator(app.renderer);

  new RGBELoader()
    .load('/assets/hdr/environment.hdr', (texture) => {
      threeScene.environment = pmremGenerator.fromEquirectangular(texture).texture;
      pmremGenerator.dispose();
      envCache = threeScene.environment;
    });

  const hemilight = new Three.HemisphereLight();
  hemilight.position.set(0, 50, 0);
  hemilight.color = new Three.Color(0.87, 0.77, 0.61);
  hemilight.groundColor = new Three.Color(0.058, 0.058, 0.058);
  hemilight.intensity = 0.57;
  threeScene.add(hemilight);
  hemilight.layers.set(layers.avatarFirstPerson);
  hemilight.layers.enable(layers.avatarThirdPerson);

  generateShadows(threeScene, characterObject);
}
