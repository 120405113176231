import * as Three from 'three';
import { AdditionalPhonemeInfo, EmotionEvent } from '@inworld/web-sdk';
import { Component, ComponentOptions } from '../../../engine/Component';
import { Entity } from '../../../engine/Entity';
import { InworldService } from '../../services/Assistent/Inworld/InworldService';
import { BehaviorToFacial } from '../../services/Assistent/Inworld/Facial/FacialEmotionMap';
import { AssistantAnimation } from '../../services/Assistent/Inworld/Animation/AssistantAnimation';

// export type InworldAssistantComponentOptions = ComponentOptions & {
//   data?: {
//     service: InworldService;
//   };
// };

export class InworldAssistantComponent extends Component {
  protected _phonemes: AdditionalPhonemeInfo[] = [];

  protected _skinnedMeshes: Three.SkinnedMesh[] = [];

  protected _visemeStartIndex = -1;

  protected _eyesClosedIndex = -1;

  protected _emotionEvent?: EmotionEvent;

  protected _animation?: AssistantAnimation;

  public visemeOffsetS = 0;

  public visemesAmount = 15;

  public visemeSilUserDataName = 'viseme_sil';

  public eyesClosedName = 'eyesClosed';

  public lastEmo = 'Neutral';

  public currMorph = 0;

  public elapsdTime = 0;

  static get code(): string {
    return 'assistant_inworld';
  }

  constructor(options: ComponentOptions) {
    super(options);
    this.analyzeEntity(this.entity);
  }

  public setAnimationService(animation: AssistantAnimation) {
    this._animation = animation;
  }

  public updateAnimation(dt: number) {
    this._animation?.update(dt);
  }

  public get eyesClosedIndex() {
    return this._eyesClosedIndex;
  }

  public get currEmo() {
    return this.emotionEvent ? BehaviorToFacial[this.emotionEvent.behavior.code] : 'Neutral';
  }

  public get randomEmo() {
    const values = Object.values(BehaviorToFacial);
    return values[Math.floor(Math.random() * values.length) - 1];
  }

  public get meshes() {
    return this._skinnedMeshes;
  }

  public get visemeStartIndex() {
    return this._visemeStartIndex;
  }

  public get phonemes() {
    return this._phonemes;
  }

  public get emotionEvent() {
    return this._emotionEvent;
  }

  public setPhonemes(phonemes: AdditionalPhonemeInfo[]) {
    this._phonemes = phonemes;
  }

  public setEmotionEvent(e: EmotionEvent) {
    this._emotionEvent = e;
  }

  protected analyzeEntity(entity: Entity) {
    this._skinnedMeshes = [];
    entity.traverse((obj) => {
      if (obj instanceof Three.SkinnedMesh && obj.userData.targetNames) {
        this._skinnedMeshes.push(obj);
      }
    });
    if (this._skinnedMeshes.length > 0) {
      const mesh = this._skinnedMeshes[0];
      for (let i = 0; i < mesh.userData.targetNames.length; i++) {
        if (mesh.userData.targetNames[i] === this.eyesClosedName) {
          this._eyesClosedIndex = i;
        }
        if (mesh.userData.targetNames[i] === this.visemeSilUserDataName) {
          this._visemeStartIndex = i;
        }
      }
    }
  }
}
