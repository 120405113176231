import TWEEN from '@tweenjs/tween.js';
import { AbstractScene } from '../../engine/scene/AbstractScene';
import { AbstractSpaceGenerator } from '../services/SpaceZoneGenerator/AbstractSpaceGenerator';
import SpawnService from '../services/Spawn.service';
import { Application } from '../../engine/Application';
import CharacterObject from '../network/objects/CharacterObject';
import VideoObject from '../network/objects/VideoObject';
import VideoVariable from '../network/variables/VideoVariable';
import AudioPlaceObject from '../network/objects/AudioPlaceObject';
import AudioVariable from '../network/variables/AudioVariable';
import VideoComponent from '../components/VideoComponent';
import AudioComponent from '../components/AudioComponent';
import { CameraComponent } from '../../engine/components/Camera.component';
import { setupControllers } from './helpers/setupControllers';
import { layers } from '../constants/sceneLayers';
import { UIBuilderSystem } from '../systems/UIBuilder.system';
import { setupSceneSettings } from './helpers/setupSceneSettings';
import { setupEnvironment } from './helpers/setupEnvironment';
import { ThreeMemoryCleaner } from '../../engine/services/ThreeMemoryCleaner';
import { InputSystem } from '../../engine/systems/InputSystem';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { CameraSystem } from '../../engine/systems/Camera.system';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { AnimatorSystem } from '../../engine/systems/Animator.system';
import RaycastSystem from '../systems/Raycast.system';
import { UIDocumentSystem } from '../../engine/systems/UIDocument.system';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import NetworkSystem from '../../engine/systems/NetworkSystem';
import MeshRendererSystem from '../../engine/systems/MeshRenderer.system';
import MeshLoaderSystem from '../systems/MeshLoader.system';
import AvatarNamePanelSystem from '../systems/UI/AvatarNamePanel.system';
import SelectedObjectSystem from '../systems/SelectedObject.system';
import { SceneLightMapsSystem } from '../systems/SceneLightMaps.system';
import WelcomeScreenSystem from '../systems/UI/WelcomeScreen.system';
import TopMenuSystem from '../systems/UI/TopMenu.system';
import ActionsMenuSystem from '../systems/UI/ActionsMenu.system';
import MainMenuSystem from '../systems/UI/MainMenu.system';
import PresentationMenuSystem from '../systems/UI/PresentationMenu.system';
import PlayerMenuSystem from '../systems/UI/PlayerMenu.system';
import ShopifyMenuSystem from '../systems/UI/ShopifyMenu.system';
import { VideoSystem } from '../systems/Video.system';
import { AudioSystem } from '../systems/Audio.system';
import { ActionIconSystem } from '../systems/ActionIcon.system';
import { AnimationSystem } from '../systems/Animation.system';
import SeatPlaceSystem from '../systems/SeatPlace.system';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';
import { FlyControlsSystem } from '../systems/FlyControls.system';
import { FPControllerSystem } from '../systems/FPController.system';
import { TPControllerSystem } from '../systems/TPController.system';
import AvatarSystem from '../systems/Avatar.system';
import VrmIKSystem from '../systems/VrmIKSystem';
import NetworkIKSystem from '../systems/NetworkIK.system';
import { TeleportSystem } from '../systems/Teleport.system';
import { XRFPControllerSystem } from '../systems/XRFPController.system';
import { System } from '../../engine/System';
import { Entity } from '../../engine/Entity';
import AvatarVideoSystem from '../systems/UI/AvatarVideo.system';
import SessionStore from '../../engine/network/sessionStore/SessionStore';
import LookAtSystem from '../systems/LookAt.system';
import { BackgroundImageAssetsType } from '../assets/types';
import { InworldAssistantSystem } from '../systems/Assistant/InworldAssistant.system';

export type BaseSceneLoadData = {
  initSpace: boolean;
  inVR?: boolean;
};

export default abstract class BaseScene<Options extends Record<string, unknown> = Record<string, unknown>> extends AbstractScene {
  public isSelfRender = true;

  public isLoading = true;

  public needVRAfterLoading = false;

  public name = '';

  public spaceGeneratorService?: AbstractSpaceGenerator;

  public spawnService?: SpawnService;

  public userInteractionComplete = false;

  public userInteractionStarted = false;

  constructor(name = '', protected readonly options?: Options) {
    super();
    this.name = name;
  }

  public isSame(name: string, options?: Options): boolean {
    return this.name === name;
  }

  public get background(): BackgroundImageAssetsType {
    return this.spaceGeneratorService?.settings.background || { desktop: '', mobile: '' };
  }

  public async prepareConfig(): Promise<void> {
    return this.spaceGeneratorService?.prepareConfig();
  }

  get title(): string {
    return this.spaceGeneratorService?.settings.title || '';
  }

  public abstract getSpaceGeneratorService(app: Application): AbstractSpaceGenerator;

  public abstract getSpawnService(app: Application): SpawnService;

  protected _nextRenderCallback: (() => void) | null = null;

  protected setupNetwork(app: Application): void {
    if (app.networkManager) {
      CharacterObject.register(app.networkManager);
      VideoObject.register(app.networkManager);
      VideoVariable.register(app.networkManager, app);
      AudioPlaceObject.register(app.networkManager);
      AudioVariable.register(app.networkManager, app);
    }
  }

  public getMediaComponents(app: Application): (VideoComponent | AudioComponent)[] {
    const components: (VideoComponent | AudioComponent)[] = app.componentManager.getComponentsByType(AudioComponent);
    components.push(...app.componentManager.getComponentsByType(VideoComponent));
    return components;
  }

  public handleUserInteraction(app: Application) {
    this.userInteractionStarted = true;
    const components = this.getMediaComponents(app);
    let resultPromise = Promise.resolve();
    components.forEach((component) => {
      resultPromise = resultPromise.then(component.init.bind(component));
    });
    return resultPromise.then(() => {
      this.userInteractionComplete = true;
    });
  }

  public setSound(app: Application, value: boolean) {
    this.getMediaComponents(app).forEach((comp) => {
      if (value) comp.controller.unmute();
      else comp.controller.mute();
    });
  }

  public createSpaceGeneratorService(app: Application): void {
    this.spawnService = this.getSpawnService(app);
    this.spaceGeneratorService = this.getSpaceGeneratorService(app);
  }

  load(app: Application, { initSpace, inVR }: BaseSceneLoadData = { initSpace: false, inVR: false }): Promise<void> {
    if (typeof inVR !== 'undefined') this.needVRAfterLoading = inVR;
    this.setupNetwork(app);
    this.setupSystems(app);

    // if (inVR) app.vrSession.startSession();

    return (initSpace ? Promise.resolve() : this.createOrJoinNetworkRoom(app)).then(() => {
      if (!this.spaceGeneratorService) return Promise.resolve();
      const [characterEntity, cameraEntity] = this.addCharacter(app);
      app.camera = cameraEntity.getComponentOrFail(CameraComponent).threeCamera;
      setupControllers(app, cameraEntity);
      app.camera.layers.enable(layers.underUI);
      app.camera.layers.enable(layers.ui);
      app.camera.layers.enable(layers.overUI);

      return this.prepareConfig().then(() => {
        if (!this.spaceGeneratorService) return Promise.resolve();
        return Promise.all(this.spaceGeneratorService.generateEntities()).then((entities) => {
          this.threeScene.add(...entities);
          setupSceneSettings(app, this.threeScene);
          return this.setupCharacter(app, characterEntity, cameraEntity);
        })
          .then(() => {
            setupEnvironment(app, this.threeScene, characterEntity);
            return app.vrSession.isSessionSupported();
          })
          .then(() => this.afterSceneLoaded(app, initSpace))
          .then(() => {
            app.networkManager?.finishInitialization();
            this.isLoading = false;
          });
      });
    });
  }

  protected afterSceneLoaded(app: Application, initSpace: boolean) {
    return Promise.all([this.setupCamVideo(app), this.setupUI(app, initSpace)]);
  }

  protected setupCamVideo(app: Application) {
    const session = app.networkManager?.sessionStore;
    if (!session) return Promise.resolve();
    session.usersVariable?.events.on('anyUpdate', () => {
      this.spaceGeneratorService?.createUserCamVideos(app);
    });
    this.spaceGeneratorService?.createUserCamVideos(app);
    return Promise.resolve();
  }

  protected setupUI(app: Application, initSpace: boolean) {
    const uiBuilder = app.getSystemOrFail(UIBuilderSystem);
    return Promise.all([
      uiBuilder.setupWelcomeScreen(initSpace),
      uiBuilder.setupTopMenu(),
      uiBuilder.setupMainMenu(),
      uiBuilder.setupActionsMenu(),
      uiBuilder.setupPresentationMenu(),
      uiBuilder.setupPlayerMenu(),
      uiBuilder.setupShopifyMenu(),
    ]);
  }

  createOrJoinNetworkRoom(app: Application, sessionRoom = false): Promise<{ roomId?: number }> {
    if (!app.networkManager) return Promise.resolve({});
    const session = app.networkManager.sessionStore as SessionStore;
    const existRoomId = sessionRoom ? session.id : undefined;
    return session.tryCreateSpace(this.name, existRoomId).then((roomId) => {
      console.log(`${this.name} Room ID`, roomId);
      return app.networkManager && roomId && !sessionRoom ? app.networkManager?.changeRoom(roomId) : Promise.resolve({});
    });
  }

  destroy(app: Application): Promise<void> {
    TWEEN.removeAll();
    return (app.isInVR ? app.vrSession.endSession() : Promise.resolve())
      .then(() => (app.networkManager ? app.networkManager.leaveActiveRoom() : Promise.resolve())).then(() => {
        app.destroyAllSystems();
        app.componentManager.destroyAllComponents();
        ThreeMemoryCleaner.disposeThreeGraph(this.threeScene);
      });
  }

  protected getSystemsList(app: Application): System[] {
    return [
      // engine
      new InputSystem({ app }),
      new XRInputSystem({ app, drawLayers: [layers.overUI] }),
      new CameraSystem({ app }),
      new PhysicSystem({ app }),
      new AnimatorSystem({ app }),
      new RaycastSystem({ app }),
      new UIDocumentSystem({
        app,
        raycastLayers: [
          layers.ui, layers.underUI, /* , layers.avatarFirstPerson, layers.avatarThirdPerson, */
        ],
        drawLayers: [layers.ui],
        dotInVR: UIDocumentSystem.buildDefaultCursorInVR(layers.overUI),
      }),
      new XRStatsSystem({ app }),
      new NetworkSystem({ app }),
      new MeshRendererSystem({ app }),
      // domain
      new MeshLoaderSystem({ app }),
      new AvatarNamePanelSystem({ app }),
      new UIBuilderSystem({ app }),
      new SelectedObjectSystem({ app }),
      new SceneLightMapsSystem({ app }),
      new WelcomeScreenSystem({ app }),
      new TopMenuSystem({ app }),
      new ActionsMenuSystem({ app }),
      new MainMenuSystem({ app }),
      new PresentationMenuSystem({ app }),
      new PlayerMenuSystem({ app }),
      new ShopifyMenuSystem({ app }),
      new VideoSystem({ app }),
      new AudioSystem({ app }),
      new ActionIconSystem({ app }),
      new AnimationSystem({ app }),
      new SeatPlaceSystem({ app }),
      new PlayerControlsSystem({ app }),
      new FlyControlsSystem({ app }),
      new FPControllerSystem({ app }),
      new TPControllerSystem({ app }),
      new AvatarSystem({ app }),
      new VrmIKSystem({ app }),
      new NetworkIKSystem({ app }),
      new TeleportSystem({ app }),
      new XRFPControllerSystem({ app }),
      new AvatarVideoSystem({ app }),
      new LookAtSystem({ app }),
      new InworldAssistantSystem({ app }),
    // new DashboardSystem({ app, graphqlClient: this.graphqlClient }),
    ];
  }

  protected setupSystems(app: Application): void {
    this.getSystemsList(app).forEach((system) => app.addSystem(system));
  }

  protected addCharacter(app: Application) {
    const cameraEntity = app.entityManager.makeEntity();
    cameraEntity.addComponent(CameraComponent);
    this.threeScene.add(cameraEntity);
    CharacterObject.setAssetsData(this.getSpaceGeneratorService(app).avatarsAssets);
    const characterEntity = CharacterObject.createCharacterEntity(app);
    this.threeScene.add(characterEntity);
    return [characterEntity, cameraEntity];
  }

  public getCharacterEntityOrFail(): Entity {
    const entity = this.threeScene.getObjectByName('CharacterEntity') as Entity;
    if (!entity) throw new Error('Character Entity not found');
    return entity;
  }

  public getAvatarEntity(): Entity | undefined {
    return this.threeScene.getObjectByName('avatarEntity') as Entity;
  }

  public setupCharacter(app: Application, characterEntity: Entity, cameraEntity: Entity) {
    return CharacterObject.buildEntity(app, characterEntity, cameraEntity);
  }

  protected setXRCameraLayer(app: Application, layer: number) {
    if (app.renderer.xr.isPresenting) {
      app.renderer.xr.getCamera().layers.set(layer);
      app.renderer.xr.getCamera().cameras.forEach((xrCamera) => {
        xrCamera.layers.set(layer);
      });
    }
  }

  protected setXRCameraMask(app: Application, mask: number) {
    if (app.renderer.xr.isPresenting) {
      app.renderer.xr.getCamera().layers.mask = mask;
      app.renderer.xr.getCamera().cameras.forEach((xrCamera) => {
        xrCamera.layers.mask = mask;
      });
    }
  }

  protected enableXRCameraLayer(app: Application, layer: number) {
    if (app.renderer.xr.isPresenting) {
      app.renderer.xr.getCamera().layers.enable(layer);
      app.renderer.xr.getCamera().cameras.forEach((xrCamera) => {
        xrCamera.layers.enable(layer);
      });
    }
  }

  public render(app: Application, delta: number): void {
    if (!this.threeScene || !app.camera || this.isLoading) return;

    this.setXRCameraMask(app, app.camera.layers.mask);

    app.renderer.autoClear = true;
    const { mask } = app.camera.layers;
    Object.keys(layers).forEach((name) => {
      if (!app.camera || !this.threeScene) return;
      const layer = layers[name];
      if (app.camera.layers.isEnabled(layer)) {
        app.camera.layers.set(layer);
        this.setXRCameraLayer(app, layer);
        app.renderer.render(this.threeScene, app.camera);
        app.camera.layers.mask = mask;
        this.setXRCameraMask(app, mask);
        app.renderer.autoClear = false;
      }
    });

    if (this._nextRenderCallback) {
      this._nextRenderCallback();
      this._nextRenderCallback = null;
    }
  }

  public nextRender(callback: () => void) {
    this._nextRenderCallback = callback;
  }
}
