import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { System } from '../../../engine/System';
import { InputSystem } from '../../../engine/systems/InputSystem';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { PanelId } from '../../ui/enum/PanelId';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import ShopifyMenuComponent from '../../components/UI/ShopifyMenu.component';
import ShopifyComponent from '../../components/Shopify.component';
import { Entity } from '../../../engine/Entity';
import { Component } from '../../../engine/Component';
import { ContainerId } from '../../ui/enum/ContainerId';
import AvatarComponent from '../../components/Avatar.component';

export default class ShopifyMenuSystem extends System {
  onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(ShopifyMenuComponent).forEach((component) => {
      this.handleInput(component);
      this.handleComponentActivation(component);
      this.handleComponentEnabled(component);
      if (!component.enabled) return;
      this.handleActiveButtons(component);
      this.handleLoadedImageVisible(component);
    });
  }

  public handleInput(component: ShopifyMenuComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    if (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame) {
      this.closeMenu(component);
    }
  }

  public closeMenu(component: ShopifyMenuComponent) {
    // TODO: remove layers mask == optimize raycast
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = false;
    uIDocumentComponent.enabled = false;
    if (component.currentActionComponent) {
      this.getSelectedComponents(component.currentActionComponent).forEach((selComponent) => {
        selComponent.isActive = false;
      });
    }
    component.currentActionComponent = null;
  }

  public resolveOtherMenu(component: ShopifyMenuComponent, enabledInLastFrame: boolean) {
    this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
    this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
  }

  protected getSelectedComponents(actionComponent: Component) {
    const selectedComponents: SelectedObjectComponent[] = [];
    actionComponent.entity.traverse((entity) => {
      if (!(entity instanceof Entity)) return;
      const selectComponent = entity.getComponent(SelectedObjectComponent);
      if (selectComponent) selectedComponents.push(selectComponent);
    });
    return selectedComponents;
  }

  public handleComponentActivation(component: ShopifyMenuComponent): void {
    const enabledInLastFrame = component.enabled;
    component.currentActionComponent = null;
    let selectedComponents: SelectedObjectComponent[] = [];
    this.app.componentManager.getComponentsByType(ShopifyComponent).forEach((actionComponent) => {
      if (component.currentActionComponent) return;
      selectedComponents = this.getSelectedComponents(actionComponent);
      const isSelected = selectedComponents.some((selectComponent) => selectComponent.isActive);
      if (isSelected) {
        // component.enabled = true;
        // this.resolveOtherMenu(component, enabledInLastFrame);
        component.currentActionComponent = actionComponent;
      }
    });
    if (component.currentActionComponent) {
      selectedComponents.forEach((selectComponent) => { selectComponent.isActive = true; });
      this.updateInfo(component).then(() => {
        component.enabled = true;
        this.resolveOtherMenu(component, enabledInLastFrame);
      });
    } else {
      component.isInfoUpdated = false;
      component.enabled = false;
    }
  }

  public handleComponentEnabled(component: ShopifyMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const panel = uIDocumentComponent.getElementById(PanelId.ShopifyPanel);
    uIDocumentComponent.enabled = component.enabled;
    if (panel) {
      panel.visible = component.enabled;
    }
  }

  public handleActiveButtons(component: ShopifyMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;

    if (states[ButtonId.CloseButton]?.state === UIDocumentElementState.Active) {
      this.closeMenu(component);
    }
  }

  public handleLoadedImageVisible(component: ShopifyMenuComponent): void {
    if (component.isInfoUpdated && component.isImageLoaded) {
      const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
      const imgContainer = uIDocumentComponent.getElementById(PanelId.ShopifyImage);
      if (imgContainer && !imgContainer.visible) imgContainer.visible = true;
    }
  }

  public updateInfo(component: ShopifyMenuComponent) {
    if (!component.currentActionComponent) return Promise.resolve();
    if (component.isInfoUpdated) return Promise.resolve();
    const actionComponent = component.currentActionComponent;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const titleContainer = uIDocumentComponent.getElementById(ContainerId.ShopifyTitle);
    const descContainer = uIDocumentComponent.getElementById(ContainerId.ShopifyDescription);
    const imgContainer = uIDocumentComponent.getElementById(PanelId.ShopifyImage);
    if (titleContainer) this.changeUIText(titleContainer, actionComponent.title);
    if (descContainer) this.changeUIText(descContainer, actionComponent.description);
    if (imgContainer) imgContainer.visible = false;
    return new Promise<void>((resolve) => {
      component.isImageLoaded = false;
      new Three.TextureLoader().load(actionComponent.previewUrl, (texture) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        imgContainer.set({ backgroundTexture: texture });
        component.isImageLoaded = true;
      });
      // imgContainer.update(true, true, true);
      component.isInfoUpdated = true;
      // FIXME: render time for image
      setTimeout(() => {
        resolve();
      }, 150);
    });
  }

  private changeUIText(container: ThreeMeshUI.Block, content: string) {
    const textElement = container.children.find((ch) => ch instanceof ThreeMeshUI.Text);
    if (!textElement) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (textElement.content === content) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.set({
      content,
    });
  }
}
