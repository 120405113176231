import { Vector3 } from 'three';
import InterpolationBuffer from './InterpolationBuffer';

export default class PositionBuffer extends InterpolationBuffer<Vector3> {
  interpolateBetween(t: number, v1: Vector3, t1: number, v2: Vector3, t2: number): Vector3 {
    // console.log('interpolateBetween');
    if (!v2) return v1;
    if (!v1) return v2;
    return new Vector3().lerpVectors(v1, v2, (t - t1) / (t2 - t1));
  }

  getPosition(t: number, ts: number, position: Vector3): Vector3 {
    // return this.buffer[0];
    const nexPosition = this.buffer[0];
    // console.log(nexPosition);
    return this.interpolateBetween(t, position, t - ts, nexPosition, t + ts);
  }

  interpolateFuture(t: number, v1: Vector3, t1: number, v2: Vector3, t2: number) {
    // console.log('interpolateFuture');
    if (!v2) return v1;
    if (!v1) return v2;
    return v1;
    // return v2.clone().add(v2).multiplyScalar(0.5);
    // return this.interpolateBetween(t, v1, t1, v2, t2);
    // const dir = new Vector3().subVectors(v2, v1);
    // return v2.clone().add(dir.multiplyScalar(Math.max((t - t2) / (t2 - t1), (t2 - t1) / 2)));
  }
  //
  // interpolatePast(t: number, v1: Vector3, t1: number, v2: Vector3, t2: number) {
  //   return this.interpolateBetween(t, v1, t1, v2, t2);
  // }
}
