import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

export const Close: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon"
      >
        <path
          id="Vector 2"
          d="M5 5L19 19"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector 3"
          d="M19 5L5 19"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
});

export default Close;
