import AgoraRTC from 'agora-rtc-sdk-ng';

import AudioService from './Audio.service';
import VideoService from './Video.service';

import type { ConferenceServiceInterface, UserInfo } from '../types';

AgoraRTC.setLogLevel(3);

export default class ConferenceService implements ConferenceServiceInterface {
  protected $accessToken = '';

  protected $roomName = '';

  protected $externalId = '';

  protected refreshToken = '';

  protected $externService = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  protected $audioService?: AudioService;

  protected $videoService?: VideoService;

  constructor(
    public readonly appId: string,
    public readonly appCertificate: string,
    public readonly tokenURL: string,
  ) {
  }

  get externService() {
    return this.$externService;
  }

  get audioService() {
    return this.$audioService;
  }

  get videoService() {
    return this.$videoService;
  }

  get accessToken() {
    return this.$accessToken;
  }

  get roomName() {
    return this.$roomName;
  }

  get externalId() {
    return this.$externalId;
  }

  setAudioService(service: AudioService) {
    this.$audioService = service;
    return this;
  }

  setVideoService(service: VideoService) {
    this.$videoService = service;
    return this;
  }

  public getAccessToken(user: UserInfo, roomName: string, rewrite = true) {
    const tokenParams = {
      method: 'POST',
    };

    const tokenURLSearchParams = new URLSearchParams({
      channelName: roomName,
      uid: user.id,
    }).toString();

    return fetch(`${this.tokenURL}?${tokenURLSearchParams}`, tokenParams)
      .then((response) => response.json())
      .then(({ token }) => {
        if (rewrite) this.$accessToken = token;
        return token;
      });
  }

  public createAndJoinConference(user: UserInfo, roomName: string) {
    this.$roomName = roomName;
    this.$externalId = user.id;
    return this.getAccessToken(user, roomName)
      .then(() => {
        return this.externService.join(this.appId, roomName, this.accessToken, user.id);
      })
      .then(() => {
        const promises = [];
        if (this.audioService) promises.push(this.audioService.afterJoin());
        if (this.videoService) promises.push(this.videoService.afterJoin());
        return Promise.all(promises);
      })
      .then(() => {});
  }

  public findUserById(externalId: string) {
    return this.externService.remoteUsers.find(({ uid }) => uid === externalId);
  }
}
