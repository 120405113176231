import * as Three from 'three';
import { System } from '../../engine/System';
import SeatPlaceComponent from '../components/SeatPlace.component';
import { ActionIconComponent } from '../components/ActionIcon.component';
import { TeleportComponent } from '../components/Teleport.component';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { PlayerControlsSystem } from './PlayerControls.system';
import { InputSystem } from '../../engine/systems/InputSystem';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { UIDocumentComponent } from '../../engine/components/UIDocument.component';
import VrmIKComponent from '../components/VrmIKComponent';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import AvatarComponent from '../components/Avatar.component';
import BaseScene from '../scenes/BaseScene';

export default class SeatPlaceSystem extends System {
  onUpdate(ts: number) {
    const components = this.app.componentManager.getComponentsByType(SeatPlaceComponent);
    components.forEach((component) => {
      this.handleActiveState(component);
      this.handleInput(component);
      this.handleActionComponentVisible(component);
    });
    this.handleVisible(components);
  }

  protected handleVisible(components: SeatPlaceComponent[]) {
    let isBusy = false;
    components.forEach((component) => { isBusy = isBusy || component.isBusy; });
    components.forEach((component) => {
      const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
      uiDocument.enabled = isBusy ? component.isBusy : true;
      if (uiDocument.root && isBusy) {
        uiDocument.root.visible = false;
      }
    });
  }

  // TODO: not optimized  -- need updates via network
  protected handleActionComponentVisible(component: SeatPlaceComponent) {
    const actionComponent = component.entity.getComponentOrFail(ActionIconComponent);
    if (!actionComponent.enabled) return;
    const seatPosition = component.entity.getWorldPosition(new Three.Vector3());
    seatPosition.setY(0);
    let busy = false;
    this.app.componentManager.getComponentsByType(AvatarComponent).forEach((avatarComponent) => {
      if (busy) return;
      const avatarPosition = avatarComponent.entity.getWorldPosition(new Three.Vector3());
      avatarPosition.setY(0);
      const dist = avatarPosition.distanceTo(seatPosition);
      busy = dist < 0.1;
    });
    actionComponent.switch(!busy);
  }

  // TODO: this is rather dirt solution
  // When character is moving and teleporting to a seat at the same time,
  // a rotation glitch occurs in third-person control. The method is needed to fix the glitch.
  // Obviously that will work as long as the sit places only have one rotation.
  protected checkAvatarRotation(component: SeatPlaceComponent) {
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    if (!characterEntity.quaternion.equals(component.entity.quaternion)) {
      characterEntity.setRotationFromQuaternion(component.entity.quaternion);
    }
  }

  handleActiveState(component: SeatPlaceComponent) {
    const actionComponent = component.entity.getComponentOrFail(ActionIconComponent);
    this.app.componentManager.getComponentsByType(AvatarComponent).forEach((avatarComponent) => {
      const uiButtonBlock = actionComponent.getUiElement();
      const avatarPosition = avatarComponent.entity.getWorldPosition(new Three.Vector3());
      const uiSeatPosition = uiButtonBlock.getWorldPosition(new Three.Vector3());

      const isHandleSeat = avatarPosition.distanceTo(uiSeatPosition) < component.distanceSeat;

      if (actionComponent.isActiveInCurrentFrame && isHandleSeat) this.activate(component);
      else if (component.isBusy) {
        const activeTeleport = this.app.componentManager.getComponentsByType(TeleportComponent).find((teleportComponent) => {
          return teleportComponent.isActive && teleportComponent.entity.uuid !== component.entity.uuid;
        });
        if (activeTeleport) {
          component.returnEntity = undefined;
          this.deactivate(component);
        } else {
          this.checkAvatarRotation(component);
        }
      }
    });
    // else this.deactivate(component);
  }

  setActivation(component: SeatPlaceComponent, value: boolean) {
    const actionComponent = component.entity.getComponentOrFail(ActionIconComponent);
    if (value) actionComponent.hide();
    else actionComponent.show();
    actionComponent.enabled = !value;

    component.isBusy = value;
    const characterEntity = (this.app.sceneManager.currentScene as BaseScene).getCharacterEntityOrFail();
    const avatarEntity = (this.app.sceneManager.currentScene as BaseScene).getAvatarEntity();
    const controlsComponent = characterEntity.getComponentOrFail(PlayerControlsComponent);
    const ikComponent = avatarEntity?.getComponentOrFail(VrmIKComponent);
    this.app.getSystemOrFail(PlayerControlsSystem).setSitState(controlsComponent, value);

    characterEntity.getComponentOrFail(RigidBodyComponent).enabled = !value;
    component.entity.getComponentOrFail(TeleportComponent).isActive = value;
    if (ikComponent && this.app.renderer.xr.isPresenting) ikComponent.enabled = !value;
  }

  activate(component: SeatPlaceComponent) {
    this.setActivation(component, true);
    const scene = this.app.sceneManager.currentScene as BaseScene;
    const placeId = 'SeatReturn';
    component.returnEntity = scene.spawnService?.buildEntity(scene.getCharacterEntityOrFail());
    component.returnEntity?.addComponent(TeleportComponent, { placeId, toggleRigidAfterTeleport: true });
  }

  deactivate(component: SeatPlaceComponent) {
    this.setActivation(component, false);
    if (component.returnEntity) {
      component.returnEntity.getComponentOrFail(TeleportComponent).isActive = true;
      component.returnEntity.removeFromParent();
      component.returnEntity = undefined;
    }
  }

  handleInput(component: SeatPlaceComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const xrMove = xrInputSystem.getLeftXrStandardThumbstick().xAxis || xrInputSystem.getLeftXrStandardThumbstick().yAxis;
    if (component.isBusy && (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame || xrMove)) this.deactivate(component);
  }
}
