import TrackComponent from './Track.component';

export type TrackSrcType = {
  mp4: string;
  ogv?: string;
};

export default class YoutubeComponent extends TrackComponent<TrackSrcType> {
  // static get code(): string {
  //   return 'yuotube';
  // }
}
