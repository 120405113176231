import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const SlashCamera: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6675_20520)">
        <mask
          id="mask0_6675_20520"
          maskUnits="userSpaceOnUse"
          x="0"
          y="4"
          width="24"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.46308 5.12119C1.86091 4.61467 2.48688 4.20459 3.30464 4.20459H15.3687C16.0395 4.20459 16.6658 4.49693 17.1152 4.9889C17.5624 5.47837 17.8023 6.12749 17.8023 6.79042V8.11436L21.1532 6.48839C21.1661 6.48214 21.1791 6.47623 21.1923 6.47066C21.7762 6.224 22.3681 6.35697 22.7863 6.69239C23.1879 7.01449 23.4322 7.51865 23.4322 8.05822L23.4322 8.06067L23.4034 17.7589C23.4053 18.2673 23.1816 18.7675 22.8299 19.1068C22.4576 19.4659 21.849 19.7134 21.1923 19.436C21.1764 19.4293 21.1606 19.422 21.1451 19.4143L17.8023 17.7468V19.1162C17.8023 20.3496 16.8689 21.7021 15.3687 21.7021H3.30464C1.80444 21.7021 0.871094 20.3496 0.871094 19.1162V6.79042C0.871094 6.22958 1.07862 5.6107 1.46308 5.12119ZM2.76069 6.14036C2.59987 6.34512 2.52109 6.60665 2.52109 6.79042V19.1162C2.52109 19.6676 2.92915 20.0521 3.30464 20.0521H15.3687C15.7442 20.0521 16.1523 19.6676 16.1523 19.1162V16.4133C16.1523 16.1275 16.3002 15.8621 16.5432 15.7117C16.7862 15.5614 17.0898 15.5475 17.3455 15.6751L21.726 17.8602C21.7288 17.8546 21.7316 17.8488 21.7342 17.8427C21.75 17.8058 21.7535 17.7773 21.7534 17.7651L21.7533 17.7573H21.7534L21.7822 8.05822C21.7822 8.05802 21.7822 8.05783 21.7822 8.05763C21.7821 8.04399 21.78 8.03129 21.7766 8.0199L17.3374 10.1739C17.0817 10.298 16.7802 10.2817 16.5394 10.1309C16.2985 9.98002 16.1523 9.71585 16.1523 9.43167V6.79042C16.1523 6.51935 16.0532 6.27272 15.897 6.10175C15.7431 5.93328 15.5512 5.85459 15.3687 5.85459H3.30464C3.08645 5.85459 2.90815 5.95262 2.76069 6.14036ZM21.6777 17.925C21.6777 17.925 21.678 17.9248 21.6784 17.9245Z"
            fill={fill}
          />
        </mask>
        <g mask="url(#mask0_6675_20520)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.4653 6.20404C24.974 6.7099 36.2316 18.7877 35.7244 19.2951L15.826 39.2005C15.3188 39.7079 3.2388 28.4527 2.73004 27.9469C2.22129 27.441 2.22004 26.6196 2.72725 26.1122L12.6765 16.1595L17.4039 7.62792C17.9111 7.12053 23.9565 5.69818 24.4653 6.20404Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.55902 -8.04598C10.0678 -7.54012 21.3254 4.53764 20.8182 5.04504L0.919732 24.9504C0.412516 25.4578 -11.6675 14.2027 -12.1762 13.6969C-12.685 13.191 -12.6862 12.3696 -12.179 11.8622L7.71944 -8.0432C8.22666 -8.55059 9.05027 -8.55184 9.55902 -8.04598Z"
            fill={fill}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3438 3.15811C21.7638 3.57686 21.7648 4.25681 21.3461 4.67683L3.32659 22.7462C2.90785 23.1662 2.22789 23.1672 1.80788 22.7485C1.38786 22.3297 1.38683 21.6498 1.80557 21.2297L19.825 3.16042C20.2438 2.7404 20.9237 2.73937 21.3438 3.15811Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_20520">
          <rect
            width="24"
            height="24"
            fill={fill}
            transform="translate(0.152344)"
          />
        </clipPath>
      </defs>
    </svg>

  );
});

export default SlashCamera;
