import * as Three from 'three';
import { Entity } from '../../../engine/Entity';
import { Adaptive } from './Adaptive';

export class AvatarNameAdaptive extends Adaptive {
  protected _parent?: Entity;

  public setParent(value: Entity) {
    this._parent = value;
  }

  public get parent() {
    return this._parent;
  }

  get isMobile() {
    return this.elementSize.width < 975 && this.elementSize.width < this.elementSize.height;
  }

  get padding() {
    return 8;
  }

  get width() {
    return this.textBlockWidth + this.buttonSize + this.padding / 2;
  }

  get textBlockWidth() {
    return 320 * 0.6;
  }

  get buttonSize() {
    return 32;
  }

  get height() {
    return 32;
  }

  get scale() {
    const base = 0.003;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    return kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get position() {
    return new Three.Vector3(0, 0, 0);
  }
}
