import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { Component } from '../../../engine/Component';
import { Adaptive } from '../adaptive';
import { Container } from '../elements';
import { PanelId } from '../enum/PanelId';
import { AvatarVideoAdaptive } from '../adaptive/AvatarVideo.adaptive';
import { MainButton } from '../elements/Buttons/MainButton';
import { ButtonId } from '../enum/ButtonId';
import { TextBlock } from '../elements/TextBlock';
import { ContainerId } from '../enum/ContainerId';
import * as Theme from '../Theme';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import { normalizeColor } from '../helpers';
import { i18n } from '../../../i18n/config';

export function AvatarVideoTemplate(component: Component, adaptive: Adaptive) {
  const { width, height } = adaptive;
  const {
    borderRadius, padding, videoWidth, videoHeight, buttonsWidth, nameHeight,
    pinned, buttonSize, fontSize,
    isMobile,
  } = adaptive as AvatarVideoAdaptive;
  const debug = false;

  const videBlock = Container({
    id: PanelId.AvatarVideo,
    offset: 0.15,
    width: videoWidth,
    height: videoHeight,
    borderRadius: [borderRadius, borderRadius, borderRadius, borderRadius],
    backgroundOpacity: 1,
    borderWidth: isMobile ? 2 : 4,
    borderColor: '#1D1E26',
  }, []);

  const textBlock = TextBlock({
    container: {
      backgroundOpacity: 0.7,
      backgroundColor: '#171A30',
      borderRadius: [borderRadius, borderRadius, borderRadius, borderRadius],
      borderWidth: isMobile ? 1 : 2,
      borderColor: '#171A30',
      borderOpacity: 0.7,
      width: videoWidth - (pinned ? 4 * padding : 0),
      height: nameHeight,
      // textAlign: 'center',
      id: ContainerId.UserName,
    },
    text: {
      content: i18n.t('canvas.avatarVideo.waitingMessage'),
      fontName: Theme.fontMainBold.name,
      color: '#FCFEFF',
      fontSize,
      letterSpacing: Theme.letterSpacing,
      hiddenOverflow: true,
    },
  });

  const root = Container({
    width,
    height,
    padding,
    contentDirection: 'column',
    justifyContent: 'end',
    alignItems: 'end',
    backgroundOpacity: debug ? 1 : undefined,
    backgroundColor: debug ? '#FF0000' : undefined,
  }, [
    Container({
      justifyContent: 'space-around',
      contentDirection: 'row',
      width: width - 2 * padding,
      height: videoHeight,
      backgroundOpacity: debug ? 1 : undefined,
      backgroundColor: debug ? '#FFFF00' : undefined,
    }, [
      videBlock,
      Container({
        width: buttonsWidth - padding,
        height: videoHeight,
        contentDirection: 'column',
        justifyContent: 'start',
        absolutePosition: pinned ? new Three.Vector2(videoWidth - buttonsWidth, padding) : undefined,
      }, [
        !adaptive.isVRSession ? MainButton({
          id: ButtonId.PinButton,
          imageUrl: 'assets/ui/icons/pin.png',
          altImageUrl: 'assets/ui/icons/unpin.png',
          width: buttonSize,
          height: buttonSize,
        }) : null,
        !adaptive.isVRSession ? Container({
          width: padding,
          height: padding / 2,
        }, []) : null,
        MainButton({
          id: ButtonId.CamButton,
          imageUrl: 'assets/ui/icons/cam-white.png',
          altImageUrl: 'assets/ui/icons/no-cam-white.png',
          width: buttonSize,
          height: buttonSize,
        }),
      ]),
    ]),
    Container({
      name: 'AvatarName',
      width: width - 2 * padding,
      height: nameHeight + padding,
      backgroundOpacity: debug ? 1 : undefined,
      backgroundColor: debug ? '#FF00FF' : undefined,
      contentDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
      offset: 0.2,
      absolutePosition: pinned ? new Three.Vector2(0, videoHeight - nameHeight - padding / 2) : undefined,
    }, [
      pinned ? Container({
        width: 3 * padding,
        height: nameHeight,
      }, []) : null,
      textBlock,
    ]),
  ]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  videBlock.setupState({
    state: UIDocumentElementState.Default,
    attributes: { borderColor: normalizeColor('#171A30') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  videBlock.setupState({
    state: UIDocumentElementState.Selected,
    attributes: { borderColor: normalizeColor('#D4FF00') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  videBlock.setState(UIDocumentElementState.Default);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setupState({
    state: UIDocumentElementState.Default,
    attributes: { borderColor: normalizeColor('#171A30') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setupState({
    state: UIDocumentElementState.Selected,
    attributes: { borderColor: normalizeColor('#D4FF00') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setState(UIDocumentElementState.Default);

  const textElement = textBlock.children.find((ch) => ch instanceof ThreeMeshUI.Text);
  if (textElement) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.setupState({
      state: UIDocumentElementState.Default,
      attributes: { fontColor: normalizeColor('#FCFEFF') },
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.setupState({
      state: UIDocumentElementState.Selected,
      attributes: { fontColor: normalizeColor('#D4FF00') },
    });
  }

  return root;
}
