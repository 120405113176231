import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';

export type LookAtComponentOptions = ComponentOptions & {
  data?: {
    target?: Three.Object3D;
  };
};

export default class LookAtComponent extends EngineComponent {
  static get code(): string {
    return 'look_at';
  }

  public target?: Three.Object3D;

  constructor(options: LookAtComponentOptions) {
    super(options);
    this.target = options.data?.target;
  }
}
