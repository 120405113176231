import AgoraRTC from 'agora-rtc-sdk-ng';

import type { IAgoraRTCRemoteUser, ILocalAudioTrack } from 'agora-rtc-sdk-ng';
import { AudioUserStatus } from '../types';
import type ConferenceService from './Conference.service';
import type { AudioServiceInterface } from '../types';

const SPEAKING_LEVEL = 0.6;

export type ParticipantsStatuses = { [key: string]: { spiking: boolean } };

export default class AudioService implements AudioServiceInterface {
  public conference: ConferenceService;

  public changeAudioInProgress = false;

  public localAudioEnabled = false;

  private localAudioTrack: ILocalAudioTrack | null = null;

  public localSoundEnabled = true;

  private loopHandler: ReturnType<typeof setInterval> | null = null;

  public participantsStatuses: ParticipantsStatuses = {};

  constructor(conference: ConferenceService) {
    this.conference = conference;
  }

  public get externService() {
    return this.conference.externService;
  }

  public get isAudioEnabled() {
    return this.localAudioEnabled;
  }

  public get isSoundEnabled() {
    return this.localSoundEnabled;
  }

  public getUserAudioStatus(externalId: string): AudioUserStatus {
    const user = this.conference.findUserById(externalId);

    if (!user) return AudioUserStatus.Disable;
    if (!user.hasAudio) return AudioUserStatus.Disable;
    if (!user.audioTrack?.isPlaying) return AudioUserStatus.Muted;
    return AudioUserStatus.Active;
  }

  public getUserSpikingStatus(externalId: string): boolean {
    return this.participantsStatuses[externalId]?.spiking;
  }

  public async askPermissionsForMic() {
    try {
      const name = 'microphone' as PermissionName;
      const permission = await navigator.permissions.query({ name });

      if (!permission) return;

      if (permission.state === 'prompt' || permission.state === 'denied') {
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((stream) => {
          })
          .catch((err) => {
            // TODO: show message for user
            console.warn('Please enable mic');
          });
      }

      this.localAudioEnabled = permission.state === 'granted';

      permission.onchange = () => {
        const enabled = permission.state === 'granted';
        if (enabled !== this.localAudioEnabled && !this.changeAudioInProgress) {
          this.localAudioEnabled = enabled;
          if (this.localAudioEnabled) this.enableAudio();
          else this.disableAudio();
        }
      };
    } catch (e) {
      console.log(e);
      return Promise.resolve();
    }
  }

  public afterJoin() {
    this.runLoop();

    this.externService.on('user-published', (user, mediaType) => {
      if (mediaType !== 'audio') {
        return;
      }

      this.externService.subscribe(user, mediaType)
        .then(() => {
          return this.muteUser(user, this.localSoundEnabled);
        });
    });

    this.externService.on('connection-state-change', (curState) => {
      if (curState === 'DISCONNECTING') {
        if (this.loopHandler) {
          clearInterval(this.loopHandler);
        }
      }
    });
  }

  protected runLoop(interval = 500) {
    this.loopHandler = setInterval(() => {
      this.loopProcess();
    }, interval);
  }

  protected loopProcess() {
    this.participantsStatuses = {};
    this.externService.remoteUsers.forEach((remoteUser) => {
      const userId = remoteUser.uid;
      const volumeLevel = remoteUser.audioTrack?.getVolumeLevel() ?? 0;
      this.participantsStatuses[userId] = { spiking: volumeLevel > SPEAKING_LEVEL };
    });
  }

  public muteUserById(externalId: string, value: boolean) {
    const user = this.conference.findUserById(externalId);
    if (!user) return Promise.resolve();
    return this.muteUser(user, value);
  }

  public muteUser(user: IAgoraRTCRemoteUser, value: boolean) {
    return new Promise<void>((resolve) => {
      if (value) {
        user.audioTrack?.play();
      } else {
        user.audioTrack?.stop();
      }

      resolve();
    });
  }

  public muteAll(value: boolean) {
    const proms: Array<Promise<void>> = [];

    this.externService.remoteUsers
      .forEach((user) => {
        proms.push(this.muteUser(user, value));
      });

    return Promise.all(proms).then(() => {});
  }

  public enableAudio() {
    let createTrackPromise = Promise.resolve();
    if (!this.localAudioEnabled) {
      createTrackPromise = createTrackPromise.then(() => this.askPermissionsForMic());
    }
    if (!this.localAudioTrack) {
      createTrackPromise = createTrackPromise.then(() => {
        if (!this.localAudioEnabled) return;
        AgoraRTC.createMicrophoneAudioTrack()
          .then((localAudioTrack) => {
            this.localAudioTrack = localAudioTrack;
            return this.externService?.publish(this.localAudioTrack);
          });
      });
    }

    this.changeAudioInProgress = this.localAudioEnabled;

    return createTrackPromise.then(() => {
      this.changeAudioInProgress = false;
      if (!this.localAudioEnabled) return;
      this.localAudioTrack?.setEnabled(true)
        .then(() => {
          this.localAudioEnabled = true;
        });
    });
  }

  public disableAudio() {
    if (!this.localAudioTrack) {
      return Promise.resolve();
    }

    this.changeAudioInProgress = true;

    return this.localAudioTrack.setEnabled(false)
      .then(() => {
        this.localAudioEnabled = false;
        this.changeAudioInProgress = false;
      });
  }

  public toggleAudio() {
    if (this.isAudioEnabled) return this.disableAudio();
    return this.enableAudio();
  }

  public toggleSound() {
    return this.muteAll(!this.localSoundEnabled)
      .then(() => {
        this.localSoundEnabled = !this.localSoundEnabled;
      });
  }
}
