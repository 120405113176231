import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from '../Loader.module.css';
import { useSpaceStore } from '../../../../../hooks/stores/useSpaceStore';

const LoaderAnimation: FC = observer(() => {
  const { showStart, showEnterScreen } = useSpaceStore();

  if (showStart || showEnterScreen) return null;

  return (
    <>
      <div className={classNames.loading}>
        <div className={classNames.arc} />
        <div className={classNames.arc} />
        <div className={classNames.arc} />
      </div>
    </>
  );
});

export default LoaderAnimation;
