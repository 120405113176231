import * as Three from 'three';
import { Component as EngineComponent } from '../../engine/Component';
import CurrentFrameValueContainer from '../services/CurrentFrameValueContainer';

export default class AvatarComponent extends EngineComponent {
  public avatarName = '';

  public avatarNameContainer = new CurrentFrameValueContainer('');

  public isGhost = false;

  public walkAnimations: string[] = ['walk'];

  public idleAnimation = 'idle';

  public prevPosition: Three.Vector3 | null = null;

  public isMoving = false;

  public lastMoveTime = 0;

  static get code(): string {
    return 'avatar';
  }
}
