export enum ButtonId {
  CloseButton = 'CloseButton',
  StartButton = 'StartButton',
  MainButton = 'MainButton',
  InfoButton = 'InfoButton',
  DayNightButton = 'DayNightButton',
  SeatButton = 'SeatButton',
  PlayerControllerSwitchButton = 'PlayerControllerSwitchButton',
  DanceButton = 'DanceButton',
  SprintButton = 'SprintButton',
  PrevButton = 'PrevButton',
  NextButton = 'NextButton',
  PlayButton = 'PlayButton',
  RewindNext = 'RewindNext',
  RewindPrev = 'RewindPrev',
  Sound = 'Sound',
  Library = 'Library',
  ShopifyButton = 'ShopifyButton',
  MicButton = 'MicButton',
  CamButton = 'CamButton',
  ShareScreenButton = 'ShareScreenButton',
  CopyButton = 'CopyButton',
  InstaButton = 'InstaButton',
  VKButton = 'VkButton',
  FacebookButton = 'FacebookButton',
  TelegramButton = 'TelegramButton',
  LogoButton = 'LogoButton',
  PinButton = 'PinButton',

  DeleteButton = 'DeleteButton',
}
