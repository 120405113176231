import StatsVR from 'statsvr';
import { System } from '../System';

export class XRStatsSystem extends System {
  protected stats: StatsVR | null = null;

  protected enabled = false;

  static get code(): string {
    return 'x_r_stats';
  }

  public toggleEnabled(): void {
    if (!this.stats) return;

    this.enabled = !this.enabled;
    this.stats.setEnabled(this.enabled);
  }

  public onUpdate() {
    if (!this.stats && this.app.camera && this.app.sceneManager.currentThreeScene) {
      this.stats = new StatsVR(this.app.sceneManager.currentThreeScene, this.app.camera);
      this.stats.setX(-1);
      this.stats.setY(0);
      this.stats.setZ(-4);
      this.stats.setEnabled(this.enabled);
    }
  }

  public onAfterRender() {
    if (this.stats && this.enabled) this.stats.update();
  }
}
