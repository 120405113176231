import { Bone, Skeleton, SkinnedMesh } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

/**
 * Clones GLTF
 *
 * Unlike the standard Object3D.clone it can:
 * 1. Clone skeletons and bones
 *
 * @param gltf
 */
export const cloneGLTF = (gltf: GLTF) => {
  const clone = {
    animations: gltf.animations,
    scene: gltf.scene.clone(true),
  };

  const skinnedMeshes: Record<string, SkinnedMesh> = {};

  gltf.scene.traverse((node) => {
    if (node instanceof SkinnedMesh) {
      skinnedMeshes[node.name] = node;
    }
  });

  const cloneBones: Record<string, Bone> = {};
  const cloneSkinnedMeshes: Record<string, SkinnedMesh> = {};

  clone.scene.traverse((node) => {
    if (node instanceof Bone) {
      cloneBones[node.name] = node;
    }

    if (node instanceof SkinnedMesh) {
      cloneSkinnedMeshes[node.name] = node;
    }
  });

  Object.keys(skinnedMeshes).forEach((name: string) => {
    const skinnedMesh = skinnedMeshes[name];
    const skeleton = skinnedMesh.skeleton as Skeleton;
    const cloneSkinnedMesh = cloneSkinnedMeshes[name];

    const orderedCloneBones = [];

    for (let i = 0; i < skeleton.bones.length; ++i) {
      const cloneBone = cloneBones[skeleton.bones[i].name];
      orderedCloneBones.push(cloneBone);
    }

    cloneSkinnedMesh.bind(new Skeleton(orderedCloneBones, skeleton.boneInverses), cloneSkinnedMesh.matrixWorld);
  });

  return clone;
};
