import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { Icon, IconParams } from './Icon';
import {
  addInteractivityToElement,
  AddInteractivityToElementParams,
  normalizeColor,
  setAbsolutePosition,
} from '../helpers';
import { Container } from './Container';

export type IconButtonParams = IconParams & {
  id: string;
  color: string;
  opacity?: number;
  hoverColor: string;
  hoverOpacity?: number;
  activeColor?: string;
  activeOpacity?: number;
  absolutePosition?: Three.Vector2;
  iconWidth?: number;
  iconHeight?: number;
  selected?: boolean;
  borderRadius?: [number, number, number, number];
  borderColor?: string;
  borderWidth?: number;
};
export function IconButton(params: IconButtonParams) {
  const container = Container({
    ...params,
    backgroundOpacity: 1,
    borderRadius: params.borderRadius ?? [12, 12, 12, 12],
    justifyContent: 'center',
  }, [Icon({
    ...params,
    width: params.iconWidth ?? params.width / 1.5,
    height: params.iconHeight ?? params.height / 1.5,
  })]);

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    container.autoLayout = false;
    container.addEventListener('added', () => {
      if (container.parent) setAbsolutePosition(container.parent as ThreeMeshUI.Block, container, params.absolutePosition);
    });
  }

  const interactivityParams: AddInteractivityToElementParams = {
    id: params.id,
    element: container,
    defaultAttributes: {
      backgroundColor: normalizeColor(params.color),
      backgroundOpacity: params.opacity ?? 1,
    },
    hoveredAttributes: {
      backgroundColor: normalizeColor(params.hoverColor),
      backgroundOpacity: params.hoverOpacity ?? 1,
    },
    activeAttributes: {
      backgroundColor: normalizeColor(params.activeColor ?? params.hoverColor),
      backgroundOpacity: params.activeOpacity ?? 1,
    },
  };

  if (params.selected) {
    interactivityParams.selectedAttributes = { ...interactivityParams.activeAttributes };
  }

  addInteractivityToElement(interactivityParams);

  return container;
}
