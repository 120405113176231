import * as Three from 'three';

export class Vector2Control {
  public threeVector2 = new Three.Vector2();

  public get x(): number {
    return this.threeVector2.x;
  }

  public get y(): number {
    return this.threeVector2.y;
  }

  public get magnitude(): number {
    return this.threeVector2.length();
  }
}
