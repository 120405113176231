import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { Component } from '../../../engine/Component';
import { ThreeMemoryCleaner } from '../../../engine/services/ThreeMemoryCleaner';
import { SessionUserType } from '../../../engine/network/sessionStore/payloads/SessionUsersPayload';

export default class MainMenuComponent extends Component {
  public enabled = false;

  public avatarIsLoading = false;

  public selectedTab = 'TabAvatars';

  public tabsContentElements: Record<string, ThreeMeshUI.Block> = {};

  public tabsContainerElement?: ThreeMeshUI.Block;

  public tabPositionCopy?: Three.Vector3;

  public users: { id: number; hash: string }[] = [];

  /**
   * When `false` Places menu will be rebuilt from its components.
   * It is mandatory to reset this flag to `false` after adding, removing or editing PlaceMenuItemsComponent's
   */
  public placeMenuFilled = false;

  static get code(): string {
    return 'main_menu';
  }

  public updateUsers(users: SessionUserType[]): boolean {
    let changed = users.length !== this.users.length;
    users.forEach((user) => {
      const hash = JSON.stringify(user);
      const componentUser = this.users.find((cu) => cu.id === user.id);
      if (!componentUser) {
        changed = true;
        this.users.push({ id: user.id, hash });
      } else if (hash !== componentUser.hash) {
        changed = true;
        componentUser.hash = hash;
      }
    });
    const ids = users.map((u) => u.id);
    this.users = this.users.filter((cu) => ids.includes(cu.id));
    return changed;
  }

  public destroy() {
    if (this.tabsContainerElement) ThreeMemoryCleaner.disposeThreeGraph(this.tabsContainerElement);
  }
}
