import * as Three from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { Component as EngineComponent, ComponentOptions } from '../../engine/Component';
import { AnimatorComponent } from '../../engine/components/Animator.component';
import { AnimationComponent } from './Animation.component';
import { cloneGLTF } from '../../engine/services/cloneGLTF';

export type MeshLoaderComponentOptions = ComponentOptions & {
  data?: {
    object: Three.Object3D;
    clips?: Three.AnimationClip[];
  };
};

export default class MeshLoaderComponent extends EngineComponent {
  public enabled = false;

  static get code(): string {
    return 'mesh_loader';
  }

  constructor(options: MeshLoaderComponentOptions) {
    super(options);
    if (!options.data) return;
    const { object, clips } = options.data;
    if (!this.entity.getObjectById(object.id)) {
      this.entity.add(object);
    }
    if (!this.entity.getComponent(AnimationComponent) && clips && clips.length > 0) {
      this.entity.addComponent(AnimationComponent, {
        animations: clips,
      });
    }
  }

  public clone() {
    const object = this.entity.children[0] as Three.Object3D;
    // Animation filter from scratch
    let firstBone : Three.Bone | null = null;
    object.traverse((obj) => {
      if (!firstBone && obj instanceof Three.Bone) firstBone = obj;
    });
    // Use only animation who has at least first bone name
    const animations = this.entity.getComponent(AnimationComponent)?.animations.filter((clip) => {
      return clip.tracks.find((track) => {
        return firstBone && track.name.split('.')[0] === firstBone.name;
      });
    });

    const cloneData = cloneGLTF({
      scene: object,
      animations,
    } as GLTF);
    const cloneEntity = this.entity.app.entityManager.makeEntity();
    this.entity.parent?.add(cloneEntity);
    return cloneEntity.addComponent(MeshLoaderComponent, {
      object: cloneData.scene,
      clips: cloneData.animations,
    });
  }
}
