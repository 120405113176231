import { FC, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import classNames from './Form.module.css';
import Icon from '../../../../../components/Icons/Icon';

export interface ErrorsProps {
  invalid: boolean;
  type?: string;
  fieldLength: number;
}

const Errors: FC<ErrorsProps> = (props) => {
  const {
    invalid,
    type,
    fieldLength,
  } = props;

  const colorRegexp = useMemo(() => {
    if (!invalid && fieldLength > 2) return '#d4ff00';
    if (invalid && (type === 'matches')) return '#ff764d';
    return '#ffffff';
  }, [invalid, fieldLength, type]);

  const colorValueLength = useMemo(() => {
    if (!invalid && fieldLength > 2) return '#d4ff00';
    if (invalid && (type === 'max' || type === 'min')) return '#ff764d';
    return '#ffffff';
  }, [invalid, fieldLength, type]);

  const { t } = useTranslation();

  return (
    <div
      className={classNames.errors}
    >
      <div className={classNames.text}>
        <div className={classNames.text_icon}>
          <Icon
            width={24}
            height={24}
            name="bullit"
            fill={colorRegexp}
          />
        </div>
        <div
          className={classnames(
            classNames.textMessage,
            { [classNames.textMessageSuccess]: !invalid && fieldLength > 2 },
            { [classNames.textMessageError]: invalid && (type === 'matches') },
          )}
        >
          {t('containers.enterScreen.nameForm.inputCharTypeRule')}
        </div>
      </div>
      <div className={classNames.text}>
        <Icon
          width={24}
          height={24}
          fill={colorValueLength}
          name="bullit"
        />
        <div
          className={classnames(
            classNames.textMessage,
            { [classNames.textMessageSuccess]: !invalid && fieldLength > 2 },
            { [classNames.textMessageError]: invalid && (type === 'max' || type === 'min') },
          )}
        >
          {t('containers.enterScreen.nameForm.inputCharLengthRule')}
        </div>
      </div>
    </div>
  );
};

export default Errors;
