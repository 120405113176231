import { Vector3 } from 'three';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import { IKConfig, JointConstraintType } from './types';

// Configured with only the required bones of VRM0.x
export const defaultIKConfig: IKConfig = {
  system: 'simple', // 'simple' | 'system'
  iteration: 8,
  ikSimpleChainConfigs: [
    // Left Shoulder -> Hand
    {
      name: 'leftHand',
      jointConfigs: [
        {
          boneName: VRMHumanBoneName.LeftLowerArm,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'YZX',
              rotationMin: new Vector3(0, -(135 / 180) * Math.PI, -Math.PI / 2),
              rotationMax: new Vector3(0, 0, Math.PI / 2),

            },
            {
              type: JointConstraintType.TargetRotations,
              axes: {
                xAxis: {
                  targetAxis: 'y',
                  multiplier: -1,
                  parentMaxDegrees: 70,
                  parentMinDegrees: -70,
                },
              },
            },
          ],
        },
        {
          boneName: VRMHumanBoneName.LeftUpperArm,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'ZXY',
              rotationMin: new Vector3(-Math.PI / 2, -(65 / 180) * Math.PI, -(45 / 180) * Math.PI),
              rotationMax: new Vector3(Math.PI / 2, (45 / 180) * Math.PI, (60 / 180) * Math.PI),

            },
          ],
        },
        {
          boneName: VRMHumanBoneName.LeftShoulder,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'ZXY',
              rotationMin: new Vector3(0, 0, -(30 / 180) * Math.PI),
              rotationMax: new Vector3(0, 0, (15 / 180) * Math.PI),
            },
          ],
        },
      ],
      effectorBoneName: VRMHumanBoneName.LeftHand,
    },
    // Right Shoulder -> Hand
    {
      name: 'rightHand',
      jointConfigs: [
        {
          boneName: VRMHumanBoneName.RightLowerArm,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'YZX',
              rotationMin: new Vector3(0, 0, -Math.PI / 2),
              rotationMax: new Vector3(0, (135 / 180) * Math.PI, Math.PI / 2),
            },
            {
              type: JointConstraintType.TargetRotations,
              axes: {
                xAxis: {
                  targetAxis: 'y',
                  multiplier: 1,
                  parentMaxDegrees: 70,
                  parentMinDegrees: -70,
                },
              },
            },
          ],
        },
        {
          boneName: VRMHumanBoneName.RightUpperArm,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'ZXY',
              rotationMin: new Vector3(-Math.PI / 2, -(45 / 180) * Math.PI, -(60 / 180) * Math.PI),
              rotationMax: new Vector3(Math.PI / 2, (65 / 180) * Math.PI, (45 / 180) * Math.PI),
            },
          ],
        },
        {
          boneName: VRMHumanBoneName.RightShoulder,
          constraints: [
            {
              type: JointConstraintType.MinMax,
              order: 'ZXY',
              rotationMin: new Vector3(0, 0, -(15 / 180) * Math.PI),
              rotationMax: new Vector3(0, 0, (30 / 180) * Math.PI),
            },
          ],
        },
      ],
      effectorBoneName: VRMHumanBoneName.RightHand,
    },
  ],
  ikSystemChainConfigs: [ // for IKSystem
    // Left Shoulder -> Hand
    {
      name: 'leftHand',
      jointConfigs: [
        {
          boneName: VRMHumanBoneName.LeftUpperArm,
          constraints: [
            { type: JointConstraintType.Ball, angle: 180, up: new Vector3(0, 0, -1) },
          ],
        },
        {
          boneName: VRMHumanBoneName.LeftLowerArm,
          constraints: [
            { type: JointConstraintType.Ball, angle: 180 },
            {
              type: JointConstraintType.TargetRotations,
              axes: {
                zAxis: {
                  targetAxis: 'z',
                  multiplier: -1,
                  addDegrees: -90,
                },
              },
            },
          ],
        },
      ],
      effectorBoneName: VRMHumanBoneName.LeftHand,
    },
    // Right Shoulder -> Hand
    {
      name: 'rightHand',
      jointConfigs: [
        {
          boneName: VRMHumanBoneName.RightUpperArm,
          constraints: [
            { type: JointConstraintType.Ball, angle: 180, up: new Vector3(0, 0, -1) },
          ],
        },
        {
          boneName: VRMHumanBoneName.RightLowerArm,
          constraints: [
            { type: JointConstraintType.Ball, angle: 180 },
            {
              type: JointConstraintType.TargetRotations,
              axes: {
                zAxis: {
                  targetAxis: 'z',
                  multiplier: -1,
                  addDegrees: 90,
                },
              },
            },
          ],
        },
      ],
      effectorBoneName: VRMHumanBoneName.RightHand,
    },
  ],
};
