import { LocaleSettingsInterface } from './LocaleSettingsInterface';
import { EnLocalSettings } from './en';
import { RuLocalSettings } from './ru';
import { i18n } from '../config';

const settings: Record<string, LocaleSettingsInterface> = {
  en: EnLocalSettings,
  ru: RuLocalSettings,
};

export function getLocaleSettings() {
  const localeSettings = settings[i18n.language];
  if (!localeSettings) throw new Error('Locale Setting not defined');
  return localeSettings;
}
