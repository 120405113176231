import * as Three from 'three';
import IKJoint from './IKJoint';

export type IKChainOptions = {
  name: string;
};

/**
 * Class representing an IK chain, comprising multiple IKJoints.
 */
class IKChain {
  public name: string;

  public isIKChain = true;

  public totalLengths = 0;

  public base: IKJoint | null = null;

  public effector: IKJoint | null = null;

  // public effectorIndex: Three.Object3D | null = null;

  public chains: Map<number, IKChain[]> = new Map();

  /* THREE.Vector3 world position of base node */
  public origin: Three.Vector3 | null = null;

  public targetPosition = new Three.Vector3();

  public target: Three.Object3D | null = null;

  public camera: Three.Object3D | null = null;

  public joints: IKJoint[] = [];

  constructor(options: IKChainOptions) {
    this.name = options.name;
  }

  setTarget(target: Three.Object3D) {
    this.target = target;
  }

  setCamera(camera: Three.Object3D) {
    this.camera = camera;
  }

  addEffector(joint: IKJoint, target: Three.Object3D | null = null) {
    if (target) this.target = target;
    this.addJoint(joint);
    this.effector = joint;
    // this.effectorIndex = joint;
    return this;
  }

  /**
   * Add an IKJoint to the end of this chain.
   */
  addJoint(joint: IKJoint) {
    if (this.effector) {
      throw new Error('Cannot add additional joints to a chain with an end effector.');
    }

    this.joints.push(joint);

    // If this is the first joint, set as base.
    if (this.joints.length === 1) {
      [this.base] = this.joints;
      this.origin = new Three.Vector3().copy(this.base.getWorldPosition());
    } else {
      // Otherwise, calculate the distance for the previous joint,
      // and update the total length.
      const previousJoint = this.joints[this.joints.length - 2];
      previousJoint.updateMatrixWorld();
      previousJoint.updateWorldPosition();
      joint.updateWorldPosition();

      const distance = previousJoint.getWorldDistance(joint);
      if (distance === 0) {
        throw new Error('bone with 0 distance between adjacent bone found');
      }
      joint.setDistance(distance);

      joint.updateWorldPosition();
      const direction = previousJoint.getWorldDirection(joint);
      previousJoint.originalDirection = new Three.Vector3().copy(direction);
      joint.originalDirection = new Three.Vector3().copy(direction);

      this.totalLengths += distance;
    }
    return this;
  }

  /**
   * Returns a boolean indicating whether or not this chain has an end effector.
   */
  hasEffector() {
    return !!this.effector;
  }

  /**
   * Returns the distance from the end effector to the target. Returns -1 if
   * this chain does not have an end effector.
   */
  getDistanceFromTarget() {
    return this.effector && this.target ? this.effector.getWorldDistance(this.target) : -1;
  }

  /**
   * Connects another IKChain to this chain. The additional chain's root
   * joint must be a member of this chain.
   */
  connect(chain: IKChain) {
    if (!chain.isIKChain) {
      throw new Error('Invalid connection in an IKChain. Must be an IKChain.');
    }

    if (!chain.base || !chain.base.isIKJoint) {
      throw new Error('Connecting chain does not have a base joint.');
    }

    const index = this.joints.indexOf(chain.base);

    // If we're connecting to the last joint in the chain, ensure we don't
    // already have an effector.
    if (this.target && index === this.joints.length - 1) {
      throw new Error('Cannot append a chain to an end joint in a chain with a target.');
    }

    if (index === -1) {
      throw new Error('Cannot connect chain that does not have a base joint in parent chain.');
    }

    this.joints[index].setIsSubBase();

    let chains = this.chains.get(index);
    if (!chains) {
      chains = [];
      this.chains.set(index, chains);
    }
    chains.push(chain);

    return this;
  }

  /**
   * Update joint world positions for this chain.
   */
  updateJointWorldPositions() {
    for (let i = 0; i < this.joints.length; i++) {
      const joint = this.joints[i];
      joint.updateWorldPosition();
    }
  }

  /**
   * Runs the forward pass of the FABRIK algorithm.
   */
  forward() {
    // Copy the origin so the forward step can use before `_backward()`
    // modifies it.
    if (!this.origin || !this.base || !this.effector) return;

    this.origin.copy(this.base.getWorldPosition());

    // Set the effector's position to the target's position.

    if (this.target) {
      this.targetPosition.setFromMatrixPosition(this.target.matrixWorld);
      this.effector.setWorldPosition(this.targetPosition);
    } else if (!this.joints[this.joints.length - 1].isSubBase) {
      // If this chain doesn't have additional chains or a target,
      // not much to do here.
      return;
    }

    // Apply sub base positions for all joints except the base,
    // as we want to possibly write to the base's sub base positions,
    // not read from it.
    for (let i = 1; i < this.joints.length; i++) {
      const joint = this.joints[i];
      if (joint.isSubBase) {
        joint.applySubBasePositions();
      }
    }

    for (let i = this.joints.length - 1; i > 0; i--) {
      const joint = this.joints[i];
      const prevJoint = this.joints[i - 1];
      const direction = prevJoint.getWorldDirection(joint);

      const worldPosition = direction.multiplyScalar(joint.distance).add(joint.getWorldPosition());

      // If this chain's base is a sub base, set it's position in
      // `_subBaseValues` so that the forward step of the parent chain
      // can calculate the centroid and clear the values.
      // @TODO Could this have an issue if a subchain `x`'s base
      // also had its own subchain `y`, rather than subchain `x`'s
      // parent also being subchain `y`'s parent?
      if (prevJoint === this.base && this.base.isSubBase) {
        this.base.subBasePositions.push(worldPosition);
      } else {
        prevJoint.setWorldPosition(worldPosition);
      }
    }
  }

  /**
   * Runs the backward pass of the FABRIK algorithm.
   */
  backward() {
    if (!this.origin || !this.base || !this.effector) return this.getDistanceFromTarget();

    // If base joint is a sub base, don't reset it's position back
    // to the origin, but leave it where the parent chain left it.
    if (!this.base.isSubBase) {
      this.base.setWorldPosition(this.origin);
    }

    for (let i = 0; i < this.joints.length - 1; i++) {
      const joint = this.joints[i];
      const nextJoint = this.joints[i + 1];
      const jointWorldPosition = joint.getWorldPosition();

      const direction = nextJoint.getWorldDirection(joint);
      joint.setDirection(direction);

      joint.applyConstraints(this);

      direction.copy(joint.direction);

      // Now apply the world position to the three.js matrices. We need
      // to do this before the next joint iterates so it can generate rotations
      // in local space from its parent's matrixWorld.
      // If this is a chain sub base, let the parent chain apply the world position
      if (!(this.base === joint && joint.isSubBase)) {
        joint.applyWorldPosition();
      }

      nextJoint.setWorldPosition(direction.multiplyScalar(nextJoint.distance).add(jointWorldPosition));

      // Since we don't iterate over the last joint, handle the applying of
      // the world position. If it's also a non-effector, then we must orient
      // it to its parent rotation since otherwise it has nowhere to point to.
      if (i === this.joints.length - 2) {
        if (nextJoint !== this.effector) {
          nextJoint.setDirection(direction);
        }
        nextJoint.applyWorldPosition();
      }
    }

    return this.getDistanceFromTarget();
  }
}

export default IKChain;
