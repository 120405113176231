import { IconTextButton } from '../Buttons/IconTextButton';

export type PlaceMenuItemParams = {
  placeId: string;
  text: string;
  imgUrl: string;
  width: number;
  height: number;
  fontSize: number;
};
export function PlaceMenuItem({
  placeId, text, imgUrl, width, height, fontSize,
}: PlaceMenuItemParams) {
  return IconTextButton({
    id: placeId,
    text,
    imgUrl,
    width,
    height,
    fontSize,
  });
}
