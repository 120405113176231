import ThreeMeshUI from 'three-mesh-ui';
import * as Theme from '../Theme';
import { Container } from '../elements';
import { Adaptive, AvatarNameAdaptive } from '../adaptive';
import { TextBlock } from '../elements/TextBlock';
import { Component } from '../../../engine/Component';
import { ContainerId } from '../enum/ContainerId';
import { MainButton } from '../elements/Buttons/MainButton';
import { ButtonId } from '../enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import { normalizeColor } from '../helpers';
import { i18n } from '../../../i18n/config';

export function AvatarNameTemplate(component: Component, adaptive: Adaptive) {
  const {
    textBlockWidth, buttonSize,
  } = adaptive as AvatarNameAdaptive;
  const { width, height } = adaptive;

  const textBlock = TextBlock({
    container: {
      borderRadius: [12, 12, 12, 21],
      backgroundOpacity: 0.7,
      backgroundColor: '#171A30',
      width: textBlockWidth,
      borderWidth: 2,
      borderColor: '#171A30',
      borderOpacity: 0.7,
      height,
      textAlign: 'center',
      id: ContainerId.UserName,
    },
    text: {
      content: i18n.t('canvas.avatarName.waitingMessage'),
      fontName: Theme.fontMainBold.name,
      color: '#FCFEFF',
      fontSize: 24,
      letterSpacing: Theme.letterSpacing,
      hiddenOverflow: true,
    },
  });
  const root = Container({
    name: 'AvatarName',
    width,
    height,
    // padding,
    // backgroundOpacity: 1,
    // backgroundColor: '#FF0000',
    contentDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    offset: 0.5,
  }, [
    textBlock,
    MainButton({
      id: ButtonId.CamButton,
      imageUrl: 'assets/ui/icons/cam-white.png',
      altImageUrl: 'assets/ui/icons/no-cam-white.png',
      disableImg: 'assets/ui/icons/cam-disabled.png',
      width: buttonSize,
      height: buttonSize,
    }),
  ]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setupState({
    state: UIDocumentElementState.Default,
    attributes: { borderColor: normalizeColor('#171A30') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setupState({
    state: UIDocumentElementState.Selected,
    attributes: { borderColor: normalizeColor('#D4FF00') },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  textBlock.setState(UIDocumentElementState.Default);

  const textElement = textBlock.children.find((ch) => ch instanceof ThreeMeshUI.Text);
  if (textElement) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.setupState({
      state: UIDocumentElementState.Default,
      attributes: { fontColor: normalizeColor('#FCFEFF') },
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.setupState({
      state: UIDocumentElementState.Selected,
      attributes: { fontColor: normalizeColor('#D4FF00') },
    });
  }

  return root;
}
