import { Application } from '../Application';
import NetworkObject from './NetworkObject';

// TODO: move to engine
export default class SystemObject extends NetworkObject {
  public app: Application | null = null;

  public setApplication(app: Application) {
    this.app = app;
  }
}
