import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { System } from '../../../engine/System';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import { ButtonId } from '../../ui/enum/ButtonId';
import { InputSystem } from '../../../engine/systems/InputSystem';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import { PanelId } from '../../ui/enum/PanelId';
import AvatarComponent from '../../components/Avatar.component';
import CharacterObject from '../../network/objects/CharacterObject';
import { TeleportComponent } from '../../components/Teleport.component';
import { XRInputSystem } from '../../../engine/systems/XRInputSystem';
import SceneLightMapsComponent from '../../components/SceneLightMaps.component';
import { TabsId } from '../../ui/enum/TabsId';
import { MainMenuAdaptive, TopMenuAdaptive } from '../../ui/adaptive';
import { UIBuilderSystem } from '../UIBuilder.system';
import { UserRow } from '../../ui/elements/Main/UserRow';
import BaseScene from '../../scenes/BaseScene';
import { AudioUserStatus, VideoStatus } from '../../../engine/network/services/chats/types';
import AvatarVideoComponent from '../../components/UI/AvatarVideo.component';
import { handleUIScroll } from '../../services/HandleUIScroll';
import { MAINSITE } from '../../constants/redirectPages';
import PlaceMenuItemComponent from '../../components/UI/PlaceMenuItem.component';
import { PlaceMenuContent } from '../../ui/elements/Main/PlaceMenuContent';

export default class MainMenuSystem extends System {
  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((component) => {
      this.handleInput(component);
      this.handleComponentEnabled(component);
      if (!component.enabled) return;
      this.handleUIReset(component);
      this.handlePlacesMenu(component);
      this.handlePlacesMenuListScroll(component);
      this.handleUsersList(component);
      this.handleUsersListScroll(component);
      this.handleAvatarsListScroll(component);
      this.handleActiveButtons(component);
      this.resetSelectedState(component);
      this.handleUsersListActions(component);
      this.resetTabs(component);
      this.handleTabsSelection(component);
      this.handleTabsActions(component);
      this.handleAvatarsSelection(component);
      this.handleAvatarsActions(component);
      this.handlePlaceActions(component);
      this.handleVRModeEnabled(component);
      this.handleInviteActions(component);
    });
  }

  public handleUIReset(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    if (uIDocumentComponent.reset) {
      component.tabsContentElements = {};
      component.tabsContainerElement = undefined;
      component.tabPositionCopy = undefined;
      component.users = [];
      component.placeMenuFilled = false;
      uIDocumentComponent.reset = false;
    }
  }

  public handleInput(component: MainMenuComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    const xrInputSystem = this.app.getSystemOrFail(XRInputSystem);
    const welcomeEnabled = this.app.componentManager.getComponentsByType(WelcomeScreenComponent).some((cm) => cm.enabled);
    if (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame) {
      this.closeMenu(component);
    }
    if (xrInputSystem.getAButton().state === 'pressed' && !welcomeEnabled) {
      this.openMenu(component);
    }
  }

  public handleActiveButtons(component: MainMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const { audioService } = this.app.chatsService || {};

    if (states[ButtonId.CloseButton]?.state === UIDocumentElementState.Active) {
      this.closeMenu(component);
    }
    if (states[ButtonId.InfoButton]?.state === UIDocumentElementState.Active) {
      this.closeMenu(component);
      this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((wcomponent) => {
        wcomponent.enabled = true;
      });
    }

    if (states[ButtonId.DayNightButton]?.state === UIDocumentElementState.Active) {
      this.app.componentManager.getComponentsByType(SceneLightMapsComponent)
        .forEach((lightComponent: SceneLightMapsComponent) => {
          lightComponent.enableNextPreset();
        });
      this.setIconState(uIDocumentComponent, ButtonId.DayNightButton, null);
    }

    if (states[ButtonId.Sound]?.state === UIDocumentElementState.Active) {
      audioService?.toggleSound().then(() => {
        const { currentScene } = this.app.sceneManager;
        if (currentScene instanceof BaseScene) {
          currentScene.setSound(this.app, audioService?.isSoundEnabled ?? false);
        }
      });
    }

    if (states[ButtonId.MicButton]?.state === UIDocumentElementState.Active) {
      audioService?.toggleAudio();
    }

    if (states[ButtonId.LogoButton]?.state === UIDocumentElementState.Active) {
      window.open(MAINSITE, '_blank');
    }

    this.setIconState(uIDocumentComponent, ButtonId.MicButton,
      audioService?.isAudioEnabled ? UIDocumentElementState.Default : 'alt');
    this.setIconState(uIDocumentComponent, ButtonId.Sound,
      audioService?.isSoundEnabled ? UIDocumentElementState.Default : 'alt');
  }

  protected setIconState(doc: UIDocumentComponent, btnId: string, state: string | null, iconName = 'icon') {
    const btnElement = doc.getElementById(btnId);
    if (btnElement) {
      const iconElement = doc.getChildElementByName(btnElement, iconName);
      if (state === null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iconElement?.setState(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !iconElement?.currentState || iconElement?.currentState === UIDocumentElementState.Default
            ? 'alt' : UIDocumentElementState.Default,
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (iconElement?.states[state]) iconElement?.setState(state);
    }
  }

  public handleComponentEnabled(component: MainMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const panel = uIDocumentComponent.getElementById(PanelId.MainMenuPanel);
    uIDocumentComponent.enabled = component.enabled;
    if (panel) {
      panel.visible = component.enabled;
    }
  }

  public closeMenu(component: MainMenuComponent) {
    // TODO: remove layers mask == optimize raycast
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = false;
    uIDocumentComponent.enabled = false;
  }

  public openMenu(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = true;
    uIDocumentComponent.enabled = true;
  }

  // TODO: move tabs system
  public resetTabs(component: MainMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
    uiDocument.root?.traverse((element) => {
      const id = element.userData?.uiData?.id;
      if (id && this.isTabContentId(element.userData?.uiData?.id)) {
        if (!component.tabsContentElements[id]) component.tabsContentElements[id] = element;
        if (!component.tabsContainerElement) component.tabsContainerElement = element.parent as ThreeMeshUI.Block;
      }
    });
    Object.keys(component.tabsContentElements).forEach((tabContentId) => {
      const tabContentElement = component.tabsContentElements[tabContentId];
      const tabId = tabContentId.replace('_content', '');
      if (!component.tabPositionCopy) {
        component.tabPositionCopy = new Three.Vector3();
      }
      if (tabContentElement.position.y > component.tabPositionCopy.y) {
        component.tabPositionCopy.copy(tabContentElement.position);
      }
      if (tabId !== component.selectedTab && tabContentElement.parent) {
        tabContentElement.removeFromParent();
      }
    });
  }

  public handleTabsSelection(component: MainMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
    const tabElement = uiDocument.getElementById(component.selectedTab);
    if (tabElement) {
      tabElement.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }
    const tabContentElement = component.tabsContentElements[`${component.selectedTab}_content`];
    if (tabContentElement && component.tabsContainerElement && !tabContentElement.parent) {
      component.tabsContainerElement.add(tabContentElement);
    }
    if (tabContentElement && component.tabPositionCopy) {
      tabContentElement.position.copy(component.tabPositionCopy);
    }
  }

  public handleTabsActions(component: MainMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uiDocument.elementStateDataList;
    Object.keys(component.tabsContentElements).forEach((tabContentId) => {
      const tabId = tabContentId.replace('_content', '');
      if (states[tabId].state === UIDocumentElementState.Active) {
        component.selectedTab = tabId;
      }
    });
  }

  public resetSelectedState(component: MainMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);

    uiDocument.root?.traverse((element) => {
      // const id = element?.userData?.uiData?.id;
      if (element?.userData?.uiData?.defaultState) {
        element.userData.uiData.defaultState = UIDocumentElementState.Default;
      }
    });
  }

  public handleAvatarsSelection(component: MainMenuComponent) {
    const avatarEntity = (this.app.sceneManager.currentScene as BaseScene).getAvatarEntity();
    if (!avatarEntity) return;
    const { avatarName } = avatarEntity.getComponentOrFail(AvatarComponent);
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);

    const id = this.getAvatarIdByName(avatarName);
    const avatarElement = uiDocument.getElementById(id);
    if (avatarElement) {
      avatarElement.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }
    const avatarBgElement = uiDocument.getElementById(`${id}_bg`);
    if (avatarBgElement) {
      avatarBgElement.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }
  }

  public handleAvatarsActions(component: MainMenuComponent) {
    if (component.avatarIsLoading) return;
    const avatarEntity = (this.app.sceneManager.currentScene as BaseScene).getAvatarEntity();
    if (!avatarEntity) return;
    const { avatarName } = avatarEntity.getComponentOrFail(AvatarComponent);
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const containerId = `${TabsId.Avatars}_content`;
    if (!states[containerId] || states[containerId].state !== UIDocumentElementState.Active) return;
    Object.keys(states).filter((name) => this.isAvatarId(name)).forEach((id) => {
      if (states[id].state === UIDocumentElementState.Active) {
        const newAvatarName = this.getAvatarNameById(id);
        if (newAvatarName !== avatarName) {
          component.avatarIsLoading = true;
          CharacterObject.changeModel(avatarEntity, newAvatarName).then(() => {
            component.avatarIsLoading = false;
            this.app.networkManager?.sendOwnedObjects();
          });
        }
      }
    });
  }

  public handlePlacesMenu(component: MainMenuComponent): void {
    if (component.placeMenuFilled) return;
    const adaptive = this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component) as MainMenuAdaptive;
    const uiDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    if (!uiDocumentComponent) return;
    const placesMenu = uiDocumentComponent.getElementById(`${TabsId.Places}_content`);
    if (!placesMenu) return;
    const itemComponents = this.app.componentManager.getComponentsByType(PlaceMenuItemComponent);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    placesMenu.childrenBoxes.forEach((box) => box.removeFromParent());

    PlaceMenuContent({ items: itemComponents, adaptive }).forEach((row) => placesMenu.add(row));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    placesMenu.update(true, true, true);
    ThreeMeshUI.update();
    component.placeMenuFilled = true;
  }

  public handlePlaceActions(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    Object.keys(states).filter((name) => name.startsWith('Place_')).forEach((id) => {
      if (states[id].state === UIDocumentElementState.Active) {
        const placeName = id.replace('Place_', '');
        this.app.componentManager.getComponentsByType(TeleportComponent).forEach((teleportComponent) => {
          if (teleportComponent.placeId === placeName) teleportComponent.isActive = true;
        });
      }
    });
  }

  public handleVRModeEnabled(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;

    const selectedElement = uIDocumentComponent.getElementById(this.app.renderer.xr.isPresenting ? TabsId.VR : TabsId.PC);
    if (selectedElement) {
      selectedElement.userData.uiData.defaultState = UIDocumentElementState.Selected;
    }
    if (states[TabsId.PC]?.state === UIDocumentElementState.Active) {
      this.app.vrSession.endSession();
    }
    if (states[TabsId.VR]?.state === UIDocumentElementState.Active) {
      this.app.vrSession.startSession();
    }
  }

  protected handleUsersList(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const adaptive = this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component) as MainMenuAdaptive;
    if (!adaptive) return;
    const contentElement = uIDocumentComponent.getElementById(`${TabsId.Players}_content`);
    if (!contentElement) return;
    const users = this.app.networkManager?.sessionStore.users.filter((u) => u.id);
    if (!users) return;
    if (!component.updateUsers(users)) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contentElement.childrenBoxes.forEach((box) => box.removeFromParent());
    users.filter((user) => user.id !== this.app.networkManager?.networkId).forEach((user) => {
      contentElement.add(UserRow({
        adaptive,
        name: user.name,
        id: user.id,
      }));
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contentElement.parent.update(true, true, true);
  }

  public handleUsersListActions(component: MainMenuComponent) {
    const { audioService, videoService } = this.app.chatsService || {};
    if (!audioService) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;

    Object.keys(states).filter((id) => id.startsWith(ButtonId.MicButton)).forEach((id) => {
      const micButton = uIDocumentComponent.getElementById(id);
      if (micButton) {
        const iconElement = uIDocumentComponent.getChildElementByName(micButton, 'icon');
        const userId = id.split('-')[1];
        const status = audioService.getUserAudioStatus(userId);
        if (status !== AudioUserStatus.Disable && states[id]?.state === UIDocumentElementState.Active) {
          audioService.muteUserById(userId, status === AudioUserStatus.Muted);
          // status = voiceService.getUserAudioStatus(userId);
        }
        if (iconElement) {
          const state = {
            [AudioUserStatus.Active]: UIDocumentElementState.Default,
            [AudioUserStatus.Muted]: UIDocumentElementState.Selected,
            [AudioUserStatus.Disable]: UIDocumentElementState.User,
          }[status];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (iconElement.states[state]) iconElement.setState(state);
        }
      }
    });

    if (!videoService) return;

    Object.keys(states).filter((id) => id.startsWith(ButtonId.CamButton)).forEach((id) => {
      const button = uIDocumentComponent.getElementById(id);
      if (button) {
        const iconElement = uIDocumentComponent.getChildElementByName(button, 'icon');
        const userId = id.split('-')[1];
        const status = videoService.getUserVideoStatus(userId);
        const cameraEnabled = status === VideoStatus.ActiveMain || status === VideoStatus.ActiveMainAndShare;
        if (status !== VideoStatus.Disabled && states[id]?.state === UIDocumentElementState.Active) {
          videoService.turnUserCameraById(userId, !cameraEnabled);
        }
        if (iconElement) {
          const state = {
            [VideoStatus.ActiveMain]: UIDocumentElementState.Default,
            [VideoStatus.ActiveMainAndShare]: UIDocumentElementState.Default,
            [VideoStatus.Muted]: UIDocumentElementState.Selected,
            [VideoStatus.Disabled]: UIDocumentElementState.User,
            [VideoStatus.ActiveShare]: UIDocumentElementState.Selected,
          }[status];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (iconElement.states[state]) iconElement.setState(state);
        }
      }
    });

    Object.keys(states).filter((id) => id.startsWith(ButtonId.DeleteButton)).forEach((id) => {
      const button = uIDocumentComponent.getElementById(id);
      if (!button) return;
      const userId = id.split('-')[1];
      if (states[id]?.state === UIDocumentElementState.Active && this.app.networkManager?.isSessionHost) {
        this.app.networkManager?.sendRemoveUser(Number(userId));
      }
    });

    const countOfPinnedComponent = this.app.componentManager.getComponentsByType(AvatarVideoComponent)
      .filter((cmp) => cmp.pinned).length;

    Object.keys(states).filter((id) => id.startsWith(ButtonId.PinButton)).forEach((id) => {
      const button = uIDocumentComponent.getElementById(id);
      if (!button) return;
      const iconElement = uIDocumentComponent.getChildElementByName(button, 'icon');
      const userId = id.split('-')[1];
      const videoComponent = this.app.componentManager.getComponentsByType(AvatarVideoComponent)
        .find((cmp) => cmp.userId === Number(userId));
      if (!videoComponent) return;
      if (states[id]?.state === UIDocumentElementState.Active && countOfPinnedComponent < 3) {
        videoComponent.pinned = !videoComponent.pinned;
      }
      if (!iconElement) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iconElement.setState(videoComponent.pinned ? UIDocumentElementState.Selected : UIDocumentElementState.Default);
    });
  }

  protected handleAvatarsListScroll(component: MainMenuComponent) {
    handleUIScroll(this.app, component, `${TabsId.Avatars}_content`);
  }

  public handleUsersListScroll(component: MainMenuComponent) {
    handleUIScroll(this.app, component, `${TabsId.Players}_content`);
  }

  protected handlePlacesMenuListScroll(component: MainMenuComponent) {
    handleUIScroll(this.app, component, `${TabsId.Places}_content`);
  }

  public handleInviteActions(component: MainMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    if (states[ButtonId.CopyButton]?.state === UIDocumentElementState.Active) {
      navigator.clipboard.writeText(window.location.href);
    }
  }

  protected isTabContentId(id?: string) {
    return id && id.startsWith('Tab') && id.endsWith('_content');
  }

  protected isTabId(id?: string) {
    return id && id.startsWith('Tab');
  }

  protected getAvatarIdByName(name: string) {
    return `Avatar_${name}`;
  }

  protected isAvatarId(id: string) {
    return id.startsWith('Avatar_') && !id.endsWith('_bg');
  }

  protected getAvatarNameById(id: string) {
    return id.replace('Avatar_', '');
  }
}
