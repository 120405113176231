import { NetworkEvent } from '../../NetworkEvent';
import { SessionUserType } from '../payloads/SessionUsersPayload';
import { engine } from '../../../../generated';

export default class AddUserEvent extends NetworkEvent<SessionUserType, void> {
  public static eventName = 'AddUser';

  protected writeRequestBuffer(data: SessionUserType): Uint8Array | undefined {
    const userBuffer = engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.create({
      id: data.id,
      name: data.name,
      activeRoomId: data.activeRoomID,
      avatarName: data.avatarName,
    });
    return engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.encode(userBuffer).finish();
  }

  protected readRequestBuffer(buffer: Uint8Array): SessionUserType | null {
    const decoded = engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.decode(buffer);
    return {
      name: decoded.name,
      id: decoded.id,
      activeRoomID: decoded.activeRoomId,
      avatarName: decoded.avatarName,
    };
  }
}
