import { Component, ComponentOptions } from '../../engine/Component';

export type ShopifyComponentOptions = ComponentOptions & {
  data?: {
    title: string;
    description: string;
    previewUrl: string;
  };
};

export default class ShopifyComponent extends Component {
  static get code(): string {
    return 'shopify';
  }

  public title: string;

  public description: string;

  public previewUrl: string;

  constructor(options: ShopifyComponentOptions) {
    super(options);
    this.title = options.data?.title ?? '';
    this.description = options.data?.description ?? '';
    this.previewUrl = options.data?.previewUrl ?? '';
  }
}
