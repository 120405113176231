import { Container, ContainerParams } from '../Container';
import { addInteractivityToElement, normalizeColor } from '../../helpers';
import { TextBlock } from '../TextBlock';
import * as Theme from '../../Theme';

export type TrackParams = Pick<ContainerParams, 'contentDirection'> & {
  width?: number;
  height?: number;
  id?: string;
  fontName?: string;
  text: string;
  imgUrl: string;
};
export function Track(params: TrackParams) {
  const padding = 8;
  const width = params.width ?? 157;
  const height = params.height ?? 160;
  const direction = params.contentDirection ?? 'column';

  const textBlock = TextBlock({
    text: {
      content: params.text,
      fontName: params.fontName ?? Theme.fontMain.name,
      color: '#FFFFFF',
      letterSpacing: -0.1,
      fontSize: 18,
      fontOpacity: 0.9,
    },
    container: {
      width: direction === 'column' ? width - 2 * padding : 0.66 * width,
      height: direction === 'column' ? height * 0.26 : height - 2 * padding,
      margin: 0,
      alignItems: 'center',
      backgroundOpacity: 0,
      justifyContent: 'center',
      // fontFamily: params.fontName,
      // fontTexture: params.fontName,
    },
  });

  const rootContainer = Container({
    width,
    height,
    borderRadius: [16, 16, 16, 16],
    padding,
    backgroundOpacity: 0.9,
    backgroundColor: '#171A30',
    contentDirection: direction,
    justifyContent: 'space-between',
  }, [
    Container({
      width: direction === 'column' ? width - 2 * padding : 0.28 * width,
      height: direction === 'column' ? height * 0.6 : height - 2 * padding,
      backgroundImage: params.imgUrl,
      // backgroundSize: 'contain',
      borderRadius: [12, 12, 12, 12],
      backgroundOpacity: 1,
      borderWidth: 0,
      margin: 0,
    }, []),
    textBlock,
  ]);

  addInteractivityToElement({
    id: params.id ?? 'track_1',
    element: rootContainer,
    defaultAttributes: { backgroundColor: normalizeColor('#171A30'), backgroundOpacity: 0.9 },
    hoveredAttributes: { backgroundColor: normalizeColor('#FFFFFF'), backgroundOpacity: 0.15 },
    activeAttributes: { backgroundColor: normalizeColor('#202252'), backgroundOpacity: 1 },
    selectedAttributes: { backgroundColor: normalizeColor('#202252'), backgroundOpacity: 1 },
  });

  // setTimeout(() => {
  //   const textElement = textBlock.children.find((ch) => ch instanceof ThreeMeshUI.Text);
  //   if (textElement && textElement.textContent) {
  //     console.log(textElement);
  //     textElement.textContent.layers.set(layers.uiText);
  //   }
  // }, 1000);

  return rootContainer;
}
