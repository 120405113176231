import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class PresentationMenuAdaptive extends Adaptive {
  get isMobile() {
    return this.elementSize.width < 650;
  }

  get width() {
    return this.isMobile ? 400 : 400;
  }

  get height() {
    return this.isMobile ? 164 : 164;
  }

  get scale() {
    const base = 0.0005;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    const kfVR = this.isVRSession ? 1.7 : 1;
    return kfVR * kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return 8;
  }

  get position() {
    let x = 0.13;
    let y = -0.1;
    const { width } = this.elementSize;
    if (width < 700) x = 0.05;
    if (width < 550) {
      x = 0;
      y = -0.05;
    }
    if (this.isVRSession) y = this.headPositionY - 0.5;
    return new Three.Vector3(
      x,
      y,
      -this.z,
    );
  }
}
