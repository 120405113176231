import * as Three from 'three';
import { Component } from '../../engine/Component';
import CurrentFrameValueContainer from '../services/CurrentFrameValueContainer';

export enum PlayerControls {
  None = 'None',
  FP = 'FP',
  TP = 'TP',
  XR = 'XR',
}

export class PlayerControlsComponent extends Component {
  public cameraSensitivity = 0.7 * Math.PI;

  public virtualMovementDirection: Three.Vector2 = new Three.Vector2();

  public lastActiveControls = PlayerControls.None;

  public sitIsActive = false;

  public sprintIsActive = false;

  public danceIsActive = false;

  public danceIsActiveInCurrentFrame = new CurrentFrameValueContainer<boolean>(this.danceIsActive, true);

  static get code(): string {
    return 'player_controls';
  }
}
