import * as Three from 'three';
import { System } from '../../engine/System';
import { PlayerControls, PlayerControlsComponent } from '../components/PlayerControls.component';
import { InputSystem } from '../../engine/systems/InputSystem';
import { TPControllerComponent } from '../components/TPController.component';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { PhysicSystem } from '../../engine/systems/Physic.system';
import { XRStatsSystem } from '../../engine/systems/XRStats.system';
import { FPControllerComponent } from '../components/FPController.component';
import { KeyboardKeyCode } from '../../engine/systems/InputSystem/devices/KeyboardDevice';
import { Entity } from '../../engine/Entity';
import { XRFPControllerComponent } from '../components/XRFPController.component';
import VrmIKComponent from '../components/VrmIKComponent';
import FlyControlsComponent from '../components/FlyControls.component';

/**
 * Third person controller
 */
export class PlayerControlsSystem extends System {
  static get code(): string {
    return 'player_controls';
  }

  protected lastPhysicsDebugButtonState = 'default';

  protected lastXRStatsButtonState = 'default';

  protected get inputSystem(): InputSystem | undefined {
    return this.app.getSystem(InputSystem);
  }

  public onUpdate() {
    this.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerControlsComponent) => {
      this.handleXRController(playerControlsComponent);

      if (this.app.renderer.xr.isPresenting) return; // todo: add xr input handling
      this.handleCharacterControllerSwitch(playerControlsComponent);
      this.handleCharacterControls(playerControlsComponent);
      this.handlePhysicsDebugControls();
      this.handleXRStatsControls();
      this.handleFreeControls(playerControlsComponent);
    });
  }

  protected handleFreeControls(component: PlayerControlsComponent) {
    const inputSystem = this.app.getSystem(InputSystem);
    if (!inputSystem || !inputSystem.keyboard.getKeyByCode('Digit0').wasPressedThisFrame) return;
    if (this.app.renderer.xr.isPresenting) return;
    if (process.env.NODE_ENV !== 'development') return;
    const flyComponent = component.entity.getComponentOrFail(FlyControlsComponent);
    const tPComponent = component.entity.getComponentOrFail(TPControllerComponent);
    const fPComponent = component.entity.getComponentOrFail(FPControllerComponent);
    flyComponent.enabled = !flyComponent.enabled;
    if (flyComponent.enabled) {
      component.lastActiveControls = fPComponent.enabled ? PlayerControls.FP : PlayerControls.TP;
    }
    fPComponent.enabled = !flyComponent.enabled && component.lastActiveControls === PlayerControls.FP;
    tPComponent.enabled = !flyComponent.enabled && component.lastActiveControls === PlayerControls.TP;
    tPComponent.isInitialized = false;
    fPComponent.isInitialized = false;
  }

  protected handleXRController(component: PlayerControlsComponent) {
    const xrControllerComponent = component.entity.getComponent(XRFPControllerComponent);
    const avatarEntity = component.entity.children[0] as Entity;
    if (xrControllerComponent) xrControllerComponent.danceIsActive = component.danceIsActive;
    const ikComponent = avatarEntity.getComponentOrFail(VrmIKComponent);
    component.danceIsActiveInCurrentFrame.update(component.danceIsActive);
    if (this.app.renderer.xr.isPresenting && component.danceIsActiveInCurrentFrame.changedInCurrentFrame()) {
      ikComponent.enabled = !component.danceIsActive;
    }
  }

  // maybe need move to separate system
  public toggleCharacterController(playerControlsComponent: PlayerControlsComponent): void {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
    tPControllerComponent.isInitialized = false;
    fPControllerComponent.isInitialized = false;
    tPControllerComponent.enabled = !tPControllerComponent.enabled;
    fPControllerComponent.enabled = !fPControllerComponent.enabled;
  }

  protected handleCharacterControllerSwitch(playerControlsComponent: PlayerControlsComponent): void {
    const inputSystem = this.app.getSystem(InputSystem);

    if (!inputSystem || !inputSystem.keyboard.getKeyByCode('KeyC').wasPressedThisFrame) return;

    this.toggleCharacterController(playerControlsComponent);
  }

  public setSitState(playerControlsComponent: PlayerControlsComponent, value: boolean) {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
    const xrFPControllerComponent = playerControlsComponent.entity.getComponentOrFail(XRFPControllerComponent);
    tPControllerComponent.sitIsActive = value;
    fPControllerComponent.sitIsActive = value;
    playerControlsComponent.sitIsActive = value;
    xrFPControllerComponent.sitIsActive = value;
  }

  protected handleCharacterControls(playerControlsComponent: PlayerControlsComponent): void {
    const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
    const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);

    let controllerComponent: TPControllerComponent | FPControllerComponent | undefined;

    if (tPControllerComponent.enabled) controllerComponent = tPControllerComponent;
    if (fPControllerComponent.enabled) controllerComponent = fPControllerComponent;

    if (!controllerComponent) return;

    const { cameraSensitivity } = playerControlsComponent;
    const cameraDelta = this.getCameraDelta();
    if (!controllerComponent.enableCameraRotation) cameraDelta.set(0, 0);
    const movementDirection = this.getMovementDirection(playerControlsComponent);

    movementDirection.setY(movementDirection.y * -1);

    controllerComponent.danceIsActive = this.inputSystem?.keyboard?.getKeyByCode('KeyQ').isPressed || playerControlsComponent.danceIsActive;
    // controllerComponent.sitIsActive = this.inputSystem?.keyboard?.getKeyByCode('KeyE').isPressed ?? false;
    if (controllerComponent.danceIsActive || controllerComponent.sitIsActive) {
      movementDirection.set(0, 0);
    }

    controllerComponent.movementVector.copy(movementDirection);
    controllerComponent.cameraTheta += (cameraDelta.x * cameraSensitivity);
    controllerComponent.cameraPhi -= (cameraDelta.y * cameraSensitivity);
    // controllerComponent.cameraPhi -= (cameraDelta.y * cameraSensitivity);
    controllerComponent.sprintIsActive = this.inputSystem?.keyboard?.getKeyByCode('ShiftLeft').isPressed || playerControlsComponent.sprintIsActive;

    const wheelDeltaY = this.inputSystem?.mouse.wheelDelta.y;
    if (tPControllerComponent.enabled && tPControllerComponent.enableZoom && !!wheelDeltaY) {
      tPControllerComponent.cameraDistance += Math.sin(-wheelDeltaY) * 0.4;
      tPControllerComponent.cameraLastDistance = tPControllerComponent.cameraDistance;
    }
  }

  protected handlePhysicsDebugControls(): void {
    if (!this.debugButtonPressedInCurrentFrame()) return;

    const { debugDrawer } = this.app.getSystemOrFail(PhysicSystem);
    debugDrawer.enabled = !debugDrawer.enabled;
  }

  protected handleXRStatsControls(): void {
    const statsSystem = this.app.getSystemOrFail(XRStatsSystem);

    if (!statsSystem) return;
    if (!this.statsButtonPressedInCurrentFrame()) return;

    statsSystem.toggleEnabled();
  }

  protected getMovementDirection(component: PlayerControlsComponent): Three.Vector2 {
    const movementDirectionVector = new Three.Vector2(0, 0);

    if (component.virtualMovementDirection.length() !== 0) {
      return movementDirectionVector.copy(component.virtualMovementDirection).clampScalar(-1, 1);
    }

    if (!this.inputSystem) return movementDirectionVector;

    const keyPressed = (keys: KeyboardKeyCode[]) => {
      return keys.map((code) => this.inputSystem?.keyboard.getKeyByCode(code)).some((key) => key?.isPressed);
    };

    if (keyPressed([KeyboardKeyCode.KeyW, KeyboardKeyCode.ArrowUp])) {
      movementDirectionVector.y = 1;
    }
    if (keyPressed([KeyboardKeyCode.KeyS, KeyboardKeyCode.ArrowDown])) {
      movementDirectionVector.y = -1;
    }
    if (keyPressed([KeyboardKeyCode.KeyA, KeyboardKeyCode.ArrowLeft])) {
      movementDirectionVector.x = -1;
    }
    if (keyPressed([KeyboardKeyCode.KeyD, KeyboardKeyCode.ArrowRight])) {
      movementDirectionVector.x = 1;
    }

    return movementDirectionVector;
  }

  protected getCameraDelta(): Three.Vector2 {
    const cameraDeltaVector = new Three.Vector2(0, 0);

    if (!this.inputSystem) return cameraDeltaVector;

    const { touchscreen, mouse } = this.inputSystem;
    const { primaryTouch } = touchscreen;

    if (mouse.leftButton.isPressed && !mouse.leftButton.wasPressedThisFrame) {
      return cameraDeltaVector.copy(mouse.delta.threeVector2);
    }

    if (primaryTouch.press.isPressed && !primaryTouch.press.wasPressedThisFrame) {
      return cameraDeltaVector.copy(primaryTouch.delta.threeVector2);
    }

    return new Three.Vector2();
  }

  // todo: improve xrinput system with current frame states
  // todo: TEMPORARY here
  protected debugButtonPressedInCurrentFrame(): boolean {
    const xRInputSystem = this.app.getSystem(XRInputSystem);
    const inputSystem = this.app.getSystem(InputSystem);

    if (this.app.renderer.xr.isPresenting) {
      if (!xRInputSystem) return false;

      const currentState = xRInputSystem.getBButton().state;
      const prevState = this.lastPhysicsDebugButtonState;

      if (currentState === 'touched') {
        this.lastPhysicsDebugButtonState = currentState;
        return false;
      }

      if (currentState === prevState) return false;

      this.lastPhysicsDebugButtonState = currentState;

      return currentState === 'pressed';
    }

    if (!inputSystem) return false;
    if (process.env.NODE_ENV === 'development') return inputSystem.keyboard.getKeyByCode('KeyB').wasPressedThisFrame;
    return false;
  }

  // todo: improve xrinput system with current frame states
  // todo: TEMPORARY here
  protected statsButtonPressedInCurrentFrame(): boolean {
    const xRInputSystem = this.app.getSystem(XRInputSystem);
    const inputSystem = this.app.getSystem(InputSystem);

    if (this.app.renderer.xr.isPresenting) {
      if (!xRInputSystem) return false;

      const currentState = xRInputSystem.getAButton().state;
      const prevState = this.lastXRStatsButtonState;

      if (currentState === 'touched') {
        this.lastXRStatsButtonState = currentState;
        return false;
      }

      if (currentState === prevState) return false;

      this.lastXRStatsButtonState = currentState;

      return currentState === 'pressed';
    }

    if (!inputSystem) return false;

    if (process.env.NODE_ENV === 'development') return inputSystem.keyboard.getKeyByCode('KeyX').wasPressedThisFrame;
    return false;
  }
}
