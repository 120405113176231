import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { useCallback } from 'react';

export interface UseAppSnackbarProps {
  preventDuplicateErrors?: boolean;
}

export interface UseAppSnackbarResult {
  enqueueErrorSnackbar: (message: string) => SnackbarKey | undefined;
  enqueueWarningSnackbar: (message: string) => SnackbarKey | undefined;
  enqueueSuccessSnackbar: (message: string) => SnackbarKey;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

/**
 * Snackbar helper hook. Can override any snackbars api
 * @see notistack
 * @param props
 */
export default function useAppSnackbar(props: UseAppSnackbarProps = {}): UseAppSnackbarResult {
  const {
    preventDuplicateErrors = false,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = useCallback((message: string) => {
    return enqueueSnackbar(message, {
      variant: 'error',
      preventDuplicate: preventDuplicateErrors,
    });
  }, []);

  const enqueueSuccessSnackbar = useCallback((successMessage: string) => {
    return enqueueSnackbar(successMessage, { variant: 'success' });
  }, []);

  const enqueueWarningSnackbar = useCallback((message: string) => {
    return enqueueSnackbar(message, {
      variant: 'warning',
      preventDuplicate: preventDuplicateErrors,
    });
  }, []);

  return {
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    enqueueWarningSnackbar,
    enqueueSnackbar,
  };
}
