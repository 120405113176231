export type InterpolationBufferOptions = {
  bufferSize?: number;
};

export default class InterpolationBuffer<ValueType> {
  public buffer: ValueType[] = [];

  public timing: number[] = [];

  public bufferSize = 240;

  constructor({ bufferSize }: InterpolationBufferOptions = {}) {
    this.bufferSize = bufferSize || this.bufferSize;
  }

  setValue(value: ValueType, time: number) {
    this.buffer.unshift(value);
    this.timing.unshift(time);
    this.cutBuffer();
  }

  cutBuffer() {
    this.buffer = this.buffer.slice(0, this.bufferSize);
    this.timing = this.timing.slice(0, this.bufferSize);
  }

  getValue(t: number): ValueType {
    if (this.buffer.length === 1) return this.buffer[0];

    // console.log(t, Math.min(...this.timing), Math.max(...this.timing));

    const leftValues = this.timing.filter((ti) => ti < t);
    const rightValues = this.timing.filter((ti) => ti >= t);
    const leftTime = Math.max(...leftValues);
    const rightTime = Math.min(...rightValues);
    const leftIndex = this.timing.indexOf(leftTime);
    const rightIndex = this.timing.indexOf(rightTime);

    if (leftIndex >= 0 && rightIndex >= 0) {
      return this.interpolateBetween(t, this.buffer[leftIndex], leftTime, this.buffer[rightIndex], rightTime);
    }
    if (rightIndex < 0 && leftIndex >= 0) {
      const secondLeftTime = Math.max(...this.timing.filter((ti) => ti !== leftTime));
      const secondLeftIndex = this.timing.indexOf(secondLeftTime);
      return this.interpolateFuture(t, this.buffer[leftIndex], leftTime, this.buffer[secondLeftIndex], secondLeftTime);
    }
    if (leftIndex < 0 && rightIndex >= 0) {
      const secondRightTime = Math.min(...this.timing.filter((ti) => ti !== rightTime));
      const secondRightIndex = this.timing.indexOf(secondRightTime);
      return this.interpolatePast(t, this.buffer[rightIndex], leftTime, this.buffer[secondRightIndex], secondRightTime);
    }
    return this.buffer[0];
  }

  interpolateBetween(t: number, v1: ValueType, t1: number, v2: ValueType, t2: number): ValueType {
    return v2;
  }

  interpolateFuture(t: number, v1: ValueType, t1: number, v2: ValueType, t2: number) {
    return this.interpolateBetween(t, v1, t1, v2, t2);
  }

  interpolatePast(t: number, v1: ValueType, t1: number, v2: ValueType, t2: number) {
    return this.interpolateBetween(t, v1, t1, v2, t2);
  }
}
