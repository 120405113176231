import type { IAgoraRTCRemoteUser, ILocalVideoTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import AgoraRTC from 'agora-rtc-sdk-ng';
import type { VideoServiceInterface } from '../types';
import { VideoStatus } from '../types';
import type ConferenceService from './Conference.service';

export type StreamType = 'main' | 'share';

export default class VideoService implements VideoServiceInterface {
  public static ScreenShareUserSuffix = '-share';

  public conference: ConferenceService;

  protected $screenAgoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

  protected $isScreenClientInitialized = false;

  public changeVideoInProgress = false;

  public localVideoEnabled = false;

  private localVideoTrack: ILocalVideoTrack | null = null;

  public localShareEnabled = false;

  private localShareTrack: ILocalVideoTrack | null = null;

  public streams: Record<string, Record<StreamType, MediaStream | undefined>> = {};

  public count = 0;

  constructor(conference: ConferenceService) {
    this.conference = conference;
  }

  public get screenClient() {
    return this.$screenAgoraClient;
  }

  public get externService() {
    return this.conference.externService;
  }

  public get isVideoEnabled() {
    return this.localVideoEnabled;
  }

  public get isScreenShareEnabled() {
    return this.localShareEnabled;
  }

  public async askPermissionsForCamera() {
    try {
      const name = 'camera' as PermissionName;
      const permission = await navigator.permissions.query({ name });

      if (!permission) return;

      if (permission.state === 'prompt' || permission.state === 'denied') {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
          })
          .catch((err) => {
            // TODO: show message for user
            console.warn('Please enable camera');
          });
      }

      this.localVideoEnabled = permission.state === 'granted';

      permission.onchange = () => {
        const enabled = permission.state === 'granted';
        if (enabled !== this.localVideoEnabled && !this.changeVideoInProgress) {
          this.localVideoEnabled = enabled;
          if (this.localVideoEnabled) this.enableVideo();
          else this.disableVideo();
        }
      };
    } catch (e) {
      console.log(e);
      return Promise.resolve();
    }
  }

  public getUserVideoStatus(externalId: string): VideoStatus {
    const user = this.conference.findUserById(externalId);
    const stream = this.streams[externalId];
    if (!stream) return VideoStatus.Disabled;
    const { main, share } = stream;
    if (main && share) return VideoStatus.ActiveMainAndShare;
    if (main) return VideoStatus.ActiveMain;
    if (share) return VideoStatus.ActiveShare;
    if (!user?.videoTrack?.isPlaying) return VideoStatus.Muted;
    return VideoStatus.Disabled;
  }

  public getUserStream(externalId: string): MediaStream | undefined {
    return this.streams[externalId] ? this.streams[externalId].main : undefined;
  }

  public getUserShareStream(externalId: string): MediaStream | undefined {
    return this.streams[externalId] ? this.streams[externalId].share : undefined;
  }

  public addVideoStream(participant: IAgoraRTCRemoteUser, stream: IRemoteVideoTrack, type: StreamType) {
    const userId = String(participant.uid).replace('-share', '');

    if (!this.streams[userId]) this.streams[userId] = { main: undefined, share: undefined };

    this.streams[userId][type] = new MediaStream([stream.getMediaStreamTrack()]);
    console.log('streams', this.streams);
  }

  public removeVideoStream(participant: IAgoraRTCRemoteUser, type: StreamType) {
    const userId = String(participant.uid).replace(VideoService.ScreenShareUserSuffix, '');
    delete this.streams[userId][type];
  }

  protected isShareUser(user: IAgoraRTCRemoteUser) {
    return user.uid.toString().endsWith(VideoService.ScreenShareUserSuffix);
  }

  public afterJoin() {
    this.externService.on('user-published', (user, mediaType) => {
      if (mediaType !== 'video') {
        return;
      }

      this.externService.subscribe(user, mediaType)
        .then(() => {
          if (user.videoTrack) {
            const type = this.isShareUser(user) ? 'share' : 'main';
            this.addVideoStream(user, user.videoTrack, type);
          }
        });
    });

    this.externService.on('user-unpublished', (user, mediaType) => {
      if (mediaType !== 'video') {
        return;
      }
      const type = this.isShareUser(user) ? 'share' : 'main';
      this.removeVideoStream(user, type);
    });
  }

  public turnUserCameraById(externalId: string, value: boolean) {
    const user = this.conference.findUserById(externalId);

    if (!user || !user.videoTrack) {
      return Promise.resolve();
    }

    if (!value) {
      user.videoTrack.stop();
      this.removeVideoStream(user, 'main');
    } else {
      // user.videoTrack.play(String(user.videoTrack.getUserId()));
      this.addVideoStream(user, user.videoTrack, 'main');
    }

    return Promise.resolve();
  }

  public enableVideo() {
    let createVideoTrackPromise = Promise.resolve();
    if (!this.localVideoEnabled) {
      createVideoTrackPromise = createVideoTrackPromise.then(() => this.askPermissionsForCamera());
    }
    if (!this.localVideoTrack) {
      // TODO: if camera is disabled, we should not do this
      createVideoTrackPromise = createVideoTrackPromise.then(() => {
        if (!this.localVideoEnabled) return;
        AgoraRTC.createCameraVideoTrack()
          .then((localVideoTrack) => {
            this.localVideoTrack = localVideoTrack;
            return this.externService.publish(this.localVideoTrack);
          });
      });
    }

    this.changeVideoInProgress = this.localVideoEnabled;

    return createVideoTrackPromise.then(() => {
      this.changeVideoInProgress = false;
      if (!this.localVideoTrack) return;
      this.localVideoTrack?.setEnabled(true)
        .then(() => {
          this.localVideoEnabled = true;
        });
    });
  }

  public disableVideo() {
    if (!this.localVideoTrack) {
      return Promise.resolve();
    }

    this.changeVideoInProgress = true;

    return this.localVideoTrack.setEnabled(false)
      .then(() => {
        this.localVideoEnabled = false;
        this.changeVideoInProgress = false;
      });
  }

  protected initScreenShareClient() {
    if (!this.$isScreenClientInitialized) {
      this.$isScreenClientInitialized = true;
      const shareUserId = `${this.conference.externalId}${VideoService.ScreenShareUserSuffix}`;
      return Promise.resolve()
        .then(() => {
          return this.conference.getAccessToken({ name: '', id: shareUserId }, this.conference.roomName);
        })
        .then((token) => {
          return this.$screenAgoraClient.join(
            this.conference.appId,
            this.conference.roomName,
            token,
            shareUserId,
          );
        })
        .then(() => {});
    }
    return Promise.resolve();
  }

  protected waitFor(cb: () => boolean, timeout = 1000, intervalTimeout = 100) {
    return new Promise<void>((resolve, reject) => {
      const interval = setInterval(() => {
        if (cb()) {
          clearInterval(interval);
          resolve();
        }
      }, intervalTimeout);
      setTimeout(() => {
        clearInterval(interval);
        reject();
      }, timeout);
    });
  }

  protected createScreenShareTrack() {
    if (!this.localShareTrack) {
      return Promise.resolve().then(() => AgoraRTC.createScreenVideoTrack({})
        .then((screenShareVideoTrack) => {
          this.localShareEnabled = true;
          this.localShareTrack = screenShareVideoTrack as ILocalVideoTrack;
          // TODO: check this
          this.localShareTrack.on('track-ended', this.disableScreenShare.bind(this));
          return this.$screenAgoraClient.publish(this.localShareTrack);
        }))
        .then(() => {
          return this.waitFor(() => !!(
            this.streams[this.conference.externalId] && this.streams[this.conference.externalId].share
          ));
        });
    }
    return Promise.resolve();
  }

  public enableScreenShare() {
    const createPromise = this.initScreenShareClient().then(() => this.createScreenShareTrack());
    this.changeVideoInProgress = true;

    return createPromise.then(() => this.localShareTrack?.setEnabled(true)
      .then(() => {
        this.localShareEnabled = true;
        this.changeVideoInProgress = false;
      }));
  }

  public disableScreenShare() {
    if (!this.localShareTrack) {
      return Promise.resolve();
    }
    this.changeVideoInProgress = true;
    return this.localShareTrack.setEnabled(false)
      .then(() => {
        this.localShareTrack?.close();
      })
      .then(() => {
        return this.waitFor(() => (!this.streams[this.conference.externalId] || !this.streams[this.conference.externalId].share));
      });
  }

  public toggleVideo() {
    if (this.isVideoEnabled) return this.disableVideo();
    return this.enableVideo();
  }

  public toggleScreenShare() {
    if (this.localShareEnabled) return this.disableScreenShare();
    return this.enableScreenShare();
  }
}
