import * as Three from 'three';
import { Component, ComponentOptions } from '../Component';
import { ThreeMemoryCleaner } from '../services/ThreeMemoryCleaner';

export type PrimitiveComponentOptions = ComponentOptions & {
  data?: {
    radiusTop?: number;
    radiusBottom?: number;
    height?: number;
    visible?: boolean;
    position?: {
      x?: number;
      y?: number;
      z?: number;
    };
  };
};

// todo: make configurable (any primitive), refactor
export class PrimitiveComponent extends Component {
  // public arrowHelper: Three.ArrowHelper;

  public data: Three.Object3D = new Three.Object3D();

  static get code(): string {
    return 'primitive';
  }

  constructor(options: PrimitiveComponentOptions) {
    super(options);
    const geometry = new Three.CylinderGeometry(
      options.data?.radiusTop || 0.2,
      options.data?.radiusBottom || 0.2,
      options.data?.height || 1.8,
    );
    geometry.rotateY(Math.PI / 2);
    const material = new Three.MeshStandardMaterial({ color: 0x17517C, wireframe: true, wireframeLinewidth: 4 });
    const cube = new Three.Mesh(geometry, material);
    cube.position.set(
      options.data?.position?.x || 0,
      options.data?.position?.y || 0,
      options.data?.position?.z || 0,
    );
    // this.arrowHelper = new Three.ArrowHelper(new Three.Vector3(0, 0, -1), new Three.Vector3(0, 0, 0), 1, 0x2f8a6e);
    // cube.add(this.arrowHelper);
    cube.visible = typeof options.data?.visible !== 'undefined' ? options.data?.visible : false;
    this.entity.add(this.data);
    this.data.add(cube);
  }

  public destroy() {
    this.data.removeFromParent();
    ThreeMemoryCleaner.disposeThreeGraph(this.data);
  }
}
