import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const Microphone: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.707 19.1042C17.0882 19.1003 19.8282 16.3603 19.832 12.9792V7.87499C19.832 4.49223 17.0898 1.75 13.707 1.75C10.3243 1.75 7.58203 4.49223 7.58203 7.87499V12.9791C7.58589 16.3603 10.3259 19.1003 13.707 19.1042V19.1042ZM11.6654 10.9375H9.62371V8.89583H11.6654C12.2292 8.89583 12.6862 8.43879 12.6862 7.87499C12.6862 7.31118 12.2292 6.85415 11.6654 6.85415H9.75813C10.3205 4.67174 12.5456 3.35843 14.728 3.92079C16.5316 4.38554 17.7916 6.0125 17.7904 7.87495V12.9791C17.7904 15.2342 15.9622 17.0624 13.7071 17.0624C11.452 17.0624 9.62371 15.2343 9.62371 12.9791H11.6654C12.2292 12.9791 12.6862 12.5221 12.6862 11.9583C12.6862 11.3945 12.2292 10.9375 11.6654 10.9375V10.9375Z"
        fill={fill}
      />
      <path
        d="M13.7084 26.25C14.2722 26.25 14.7293 25.7929 14.7293 25.2291V23.1346C19.525 22.6547 23.3427 18.9059 23.9098 14.1196C23.9759 13.5597 23.5755 13.0522 23.0156 12.9861C22.4556 12.9201 21.9482 13.3204 21.8821 13.8803C21.3492 18.3945 17.2577 21.622 12.7435 21.0891C8.96255 20.6427 5.98117 17.6613 5.53482 13.8803C5.46876 13.3204 4.96125 12.9201 4.40134 12.9861C3.8414 13.0522 3.44107 13.5597 3.50713 14.1196C4.07423 18.9059 7.89188 22.6547 12.6876 23.1346V25.2291C12.6876 25.7929 13.1447 26.25 13.7084 26.25V26.25Z"
        fill={fill}
      />
    </svg>
  );
});

export default Microphone;
