import { NetworkId } from '../types';
import { BaseNetworkError } from './BaseNetworkError';

export class WrongRoomIdError extends BaseNetworkError {
  public readonly $isWrongRoomIdError = true;

  public static readonly code = 'PL-4001';

  constructor(private readonly roomId: NetworkId, private readonly error?: any) {
    super(WrongRoomIdError.code, `Room with id ${roomId} does not exists`, error);
    this.name = this.constructor.name;
  }
}
