import * as Yup from 'yup';

export const nameSchemas = Yup.object().shape({
  fullName: Yup.string()
    .matches(
      /^[a-zA-Z0-9_\-~]+$/,
    )
    .trim()
    .min(3)
    .max(8),
});
