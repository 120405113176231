import * as Three from 'three';

export type BlinkToMeshOptions = {
  eyesClosedIndex: number;
  elapsdTime: number
  BLINK_SPEED?: number;
  BLINK_THRESH?: number;
};

export const DefaultBlinkTOMeshOptions = {
  BLINK_SPEED: 1,
  BLINK_THRESH: 100,
};

export function BlinkToMesh(meshes: Three.Mesh[], opts: BlinkToMeshOptions) {
  const { BLINK_SPEED, BLINK_THRESH, eyesClosedIndex, elapsdTime } = {
    ...DefaultBlinkTOMeshOptions, ...opts,
  };
  const eyeClosedVal = Three.MathUtils.clamp(
    Math.sin(elapsdTime * BLINK_SPEED) * BLINK_THRESH - BLINK_THRESH + 1,
    0,
    1,
  );
  if (eyesClosedIndex !== -1) {
    meshes.forEach((smesh) => {
      smesh.morphTargetInfluences![eyesClosedIndex] = eyeClosedVal;
    });
  }
}
