import { System } from '../../engine/System';
import { AnimationComponent } from '../components/Animation.component';
import MeshLoaderComponent from '../components/MeshLoader.component';

export default class MeshLoaderSystem extends System {
  public onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(MeshLoaderComponent).forEach((component) => {
      const animationComponent = component.entity.getComponent(AnimationComponent);
      component.entity.visible = component.enabled;
      if (animationComponent) {
        if (component.enabled) animationComponent.playAllActions();
        else animationComponent.stopAllActions();
      }
    });
  }
}
