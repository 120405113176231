import * as Three from 'three';

export type LightMap = {
  path: string;
  intensity: number;
  envIntensity: number;
};

export type LightMaps = { [key: string]: LightMap };

export class LightMapsService {
  public allLightObjectsNames: string[] = [];

  public setAllObjectsNames(names: string[]) {
    this.allLightObjectsNames = names;
  }

  public toggleObjects(content: Three.Object3D, objectsNames: string[]) {
    this.allLightObjectsNames.forEach((name) => {
      const obj = content.getObjectByName(name);
      if (!obj) return;
      obj.visible = objectsNames.includes(name);
    });
  }

  public setupLightMaps(content: Three.Object3D, lightMaps: LightMaps) {
    const promises: Promise<void>[] = Object.keys(lightMaps).map((name) => {
      const child = content.getObjectByName(name);
      if (!child) return Promise.resolve();
      const meshes: Three.Mesh[] = [];
      child.traverse((obj) => {
        if (obj instanceof Three.Mesh) meshes.push(obj);
      });
      return new Promise((resolve) => {
        const lightMap = lightMaps[name];
        const textureLoader = new Three.TextureLoader();
        textureLoader.load(lightMap.path, (texture) => {
          meshes.forEach((mesh) => {
            const material = mesh.material as Three.MeshStandardMaterial;
            material.lightMap = texture;
            material.lightMapIntensity = lightMap.intensity;
            material.lightMap.flipY = false;
            material.envMapIntensity = lightMap.envIntensity;
            material.needsUpdate = true;
          });
          resolve();
        });
      });
    });
    return Promise.all(promises);
  }
}
