export type VisemeDataOptions = {
  SMOOTH_FACTOR_S: number;
  LAST_PHONEME_DURATION: number;
  EXPRESSION_FACTOR: number;
  AMOUNT: number;
};

export const DefaultVisemeDataOptions: VisemeDataOptions = {
  SMOOTH_FACTOR_S: 0.15,
  LAST_PHONEME_DURATION: 1,
  EXPRESSION_FACTOR: 0.5,
  AMOUNT: 15,
};
