import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class PlayerMenuAdaptive extends Adaptive {
  get isMobile() {
    return this.elementSize.width < 975 && this.elementSize.width < this.elementSize.height;
  }

  get width() {
    return this.isMobile ? 328 : 567;
  }

  get height() {
    return this.isMobile ? 644 + 2 * this.padding : 168;
  }

  get scale() {
    const base = 0.0004;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    const kfVR = this.isVRSession ? 1.7 : 1;
    return kfVR * kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return 16;
  }

  get space() {
    return this.isMobile ? 2 : 30;
  }

  get borderRadius(): [number, number, number, number] {
    return [16, 16, 16, 16];
  }

  get tracksInRow() {
    return this.isMobile ? 6 : 3;
  }

  get tracksRowCount() {
    return this.isMobile ? 1 : 2;
  }

  get tracksPanelHeight() {
    return this.tracksRowHeight * this.tracksRowCount + 2 * this.padding;
  }

  get tracksRowHeight() {
    return this.isMobile ? 412 : 170;
  }

  get trackRowDirection() {
    return this.isMobile ? 'column' : 'row';
  }

  get trackItemDirection() {
    return this.isMobile ? 'row' : 'column';
  }

  trackItemWidth(trackCount: number) {
    return this.isMobile ? 300 : 157;
  }

  trackItemHeight(trackCount: number) {
    const count = Math.max(trackCount, 3);
    return this.isMobile ? Math.max(60, this.tracksRowHeight / count - this.padding / 2) : 160;
  }

  get controlsHeight() {
    return this.isMobile ? 224 : 168;
  }

  get controlsTitleHeight() {
    return 48 + this.padding;
  }

  get controlsRowHeight() {
    return 48 + this.padding;
  }

  get controlsCompact() {
    return this.isMobile;
  }

  get position() {
    let y = this.isMobile ? 0.035 : 0;
    if (this.isVRSession) y = this.headPositionY;
    return new Three.Vector3(
      this.isMobile ? 0 : 0.13,
      y,
      -this.z,
    );
  }
}
