import EventEmitter from 'eventemitter3';
import * as Pluto from '@own/pluto_client';
import {
  MessageOptions,
  NetworkId,
  OnJoinedRoomPayload, OnLeftRoomPayload,
  OnListRoomConnectionsPayload, OnRoomClosedPayload,
  OnRoomCreatedPayload, ProtocolType, RequestHeader, ResponseHeader, TimeInfo,
  TransportEventTypes,
} from './types';
import { RoomDoesNotExistError } from './errors/RoomDoesNotExistError';
import { WrongRoomIdError } from './errors/WrongRoomIdError';

export default class TransportPlutoBinary {
  public client: Pluto.Client;

  public config: Pluto.Client_config;

  public networkId: NetworkId;

  public lastError: Pluto.IError_event | null = null;

  public currentRoomId: NetworkId = 0;

  protected _events: EventEmitter<TransportEventTypes> = new EventEmitter<TransportEventTypes>();

  constructor(client: Pluto.Client, config: Pluto.Client_config) {
    this.client = client;
    this.config = config;
    this.networkId = client.id.value;
    this.initEvents();
  }

  get wsTimeIfo(): TimeInfo {
    return { ...this.client.ws_time_info };
  }

  get dcTimeIfo(): TimeInfo {
    return { ...this.client.dc_time_info };
  }

  protected initEvents() {
    this.clientEvents.on('error', this.onError);
    const onWSMessage = (event: Pluto.IMessage_event) => {
      this.onWSMessage(event.header, event.body);
      this.onMessage(ProtocolType.WS, event.header, event.body);
    };
    const onDCMessage = (event: Pluto.IMessage_event) => {
      this.onDCMessage(event.header, event.body);
      this.onMessage(ProtocolType.DC, event.header, event.body);
    };
    this.clientEvents.on('message_ws', onWSMessage);
    this.clientEvents.on('serverwide_broadcast_ws', onWSMessage);
    this.clientEvents.on('roomwide_broadcast_ws', onWSMessage);
    this.clientEvents.on('message_dc', onDCMessage);
    this.clientEvents.on('serverwide_broadcast_dc', onDCMessage);
    this.clientEvents.on('roomwide_broadcast_dc', onDCMessage);

    this.clientEvents.on('join_room', (event) => {
      this.events.emit('onJoinedRoom', { roomId: event.room_id.value, clientId: event.header.sender_id.value });
    });

    this.clientEvents.on('leave_room', (event) => {
      this.events.emit('onLeftRoom', { roomId: event.room_id.value, connectionId: event.header.sender_id.value });
    });
    this.clientEvents.on('connection_closed', (event) => {
      this.events.emit('oncloseConnection', { connectionId: event.connection_id.value });
    });
  }

  public reconnect(roomId: NetworkId) {
    return this.closeSession(roomId, false).then(() => Pluto.Client.create(this.config))
      .then((client) => {
        this.client = client;
        this.networkId = client.id.value;
      });
  }

  public get clientEvents(): EventEmitter<Pluto.Client_event_types> {
    return this.client.events;
  }

  public get events(): EventEmitter<TransportEventTypes> {
    return this._events;
  }

  public isError() {
    return !!this.lastError;
  }

  public clearError() {
    this.lastError = null;
  }

  protected requestWithErrorCheck<PromiseValueType>(
    request: Promise<PromiseValueType>,
    timeout = 5000,
  ): Promise<PromiseValueType> {
    return new Promise<PromiseValueType>((resolve, reject) => {
      this.clearError();
      const timoutId = setTimeout(() => {
        const message = this.isError() ? this.lastError?.error.message : `Request timeout ${timeout}ms`;
        reject(new Error(message));
      }, timeout);
      return request.then((arg) => {
        clearTimeout(timoutId);
        resolve(arg);
      }).catch((e: any) => {
        clearTimeout(timoutId);
        reject(e);
      });
    });
  }

  public closeSession(roomId: NetworkId, closeRoom = true) {
    let promise: Promise<void | OnRoomClosedPayload> = Promise.resolve();
    if (closeRoom) {
      promise = this.listRoomConnections(roomId).then(({ connectionIds }) => {
        if (connectionIds.length === 1 && connectionIds[0] === this.networkId) return this.closeRoom(roomId);
      });
    }
    return promise
      .then(() => this.leaveRoom(roomId))
      .then(() => this.closeConnection());
  }

  public closeConnection() {
    return this.client.close();
  }

  public createOrJointRoom(roomId: NetworkId | null, timeout = 3000): Promise<OnJoinedRoomPayload> {
    const promise = roomId !== null && roomId >= 0 ? Promise.resolve({ roomId }) : this.createRoom();
    return this.requestWithErrorCheck(
      promise.then(({ roomId: createdRoomId }) => this.joinRoom(createdRoomId)),
    );
  }

  public createRoom(): Promise<OnRoomCreatedPayload> {
    return this.client.create_room().then((roomId) => {
      return { roomId: roomId.value };
    });
  }

  public closeRoom(roomId: NetworkId): Promise<OnRoomClosedPayload> {
    return this.client.close_room(new Pluto.Room_id(roomId)).then(() => {
      return { roomId };
    });
  }

  public async joinRoom(roomId: NetworkId): Promise<OnJoinedRoomPayload> {
    const clientId = this.networkId;
    try {
      await this.client.join_room(new Pluto.Room_id(roomId));
    } catch (e: any) {
      if (e?.message && e?.message.match(/Room with id=\[Int_id value=(\d+)\] does not exist/ig)) {
        throw new RoomDoesNotExistError(roomId, e);
      }
      if (e?.message && e?.message === 'Value out of bounds') {
        throw new WrongRoomIdError(roomId, e);
      }
      throw e;
    }
    return { clientId, roomId };
  }

  public listRoomConnections(roomId: NetworkId): Promise<OnListRoomConnectionsPayload> {
    if (roomId < 0) return Promise.resolve({ roomId: 0, connectionIds: [] });
    return this.client.list_room_connections(new Pluto.Room_id(roomId)).then((ids) => {
      return { roomId, connectionIds: ids.map((id) => id.value) };
    });
  }

  public leaveRoom(roomId: NetworkId): Promise<OnLeftRoomPayload> {
    const connectionId = this.networkId;
    return this.client.leave_room(new Pluto.Room_id(roomId)).then(() => {
      return { roomId, connectionId };
    });
  }

  public parseRequestHeader(header: Pluto.Request_header): RequestHeader {
    return {
      route: header.route,
      queueId: header.queue_id.value,
      senderId: header.sender_id.value,
      messageId: header.message_id.value,
      clientSentTimestamp: header.client_sent_timestamp.value,
    };
  }

  public parseResponseHeader(header: Pluto.Response_header): ResponseHeader {
    return {
      route: header.route,
      queueId: header.queue_id.value,
      senderId: header.sender_id.value,
      messageId: header.message_id.value,
      clientSentTimestamp: header.client_sent_timestamp.value,
      serverReceivedTimestamp: header.server_received_timestamp.value,
      serverSentTimestamp: header.server_sent_timestamp.value,
      clientReceivedTimestamp: header.client_recieved_timestamp.value,
    };
  }

  protected getTypedMessageOpts(opts: MessageOptions): Pluto.Client_msg_opt_args {
    const typedOpts: Pluto.Client_msg_opt_args = {};
    if (opts.queueId) typedOpts.queue_id = new Pluto.Queue_id(opts.queueId);
    if (opts.messageId) typedOpts.message_id = new Pluto.Message_id(opts.messageId);
    return typedOpts;
  }

  public onError(event: Pluto.IError_event) {
    console.log('onError');
    console.warn(event);
    this.events.emit('onError', {
      header: this.parseResponseHeader(event.header),
      message: event.error.message,
    });
    this.lastError = event;
  }

  public onMessage(protocol: ProtocolType, header: Pluto.Response_header, message: ArrayBuffer) {
    this.events.emit('onMessage', { header: this.parseResponseHeader(header), message, protocol });
  }

  public onWSMessage(header: Pluto.Response_header, message: ArrayBuffer) {
    this.events.emit('onWSMessage', { header: this.parseResponseHeader(header), message });
  }

  public onDCMessage(header: Pluto.Response_header, message: ArrayBuffer) {
    this.events.emit('onDCMessage', { header: this.parseResponseHeader(header), message });
  }

  public sendWSMessageInRoom(message: ArrayBuffer, roomId: NetworkId, opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.roomwide_broadcast_ws(message, new Pluto.Room_id(roomId), this.getTypedMessageOpts(opts)),
    );
  }

  public sendDCMessageInRoom(message: ArrayBuffer, roomId: NetworkId, opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.roomwide_broadcast_dc(message, new Pluto.Room_id(roomId), this.getTypedMessageOpts(opts)),
    );
  }

  public sendWSSMessageInServer(message: ArrayBuffer, opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.serverwide_broadcast_ws(message, this.getTypedMessageOpts(opts)),
    );
  }

  public sendDCMessageInServe(message: ArrayBuffer, opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.serverwide_broadcast_dc(message, this.getTypedMessageOpts(opts)),
    );
  }

  public sendWSMessageTo(message: ArrayBuffer, clients: NetworkId[], opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.message_ws(message, clients.map((id) => new Pluto.Connection_id(id)), this.getTypedMessageOpts(opts)),
    );
  }

  public sendDCMessageTo(message: ArrayBuffer, clients: NetworkId[], opts: MessageOptions = {}): RequestHeader {
    return this.parseRequestHeader(
      this.client.message_dc(message, clients.map((id) => new Pluto.Connection_id(id)), this.getTypedMessageOpts(opts)),
    );
  }
}
