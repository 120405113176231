import NetworkObject, { NetworkObjectLifeTimeTypes } from '../../NetworkObject';
import NetworkManager from '../../NetworkManager';
import { SessionUsersVariable } from '../variables/SessionUsersVariable';
import AddUserEvent from '../events/AddUserEvent';
import { SessionSpacesVariable } from '../variables/SessionSpacesVariable';
import CreateSpaceEvent from '../events/CreateSpaceEvent';

export class SessionNetworkObject extends NetworkObject {
  public static type = 'session';

  public lifeTimeType = NetworkObjectLifeTimeTypes.Shared;

  public permanent = true;

  static register(manager: NetworkManager) {
    manager.objectsTypes[SessionNetworkObject.type] = SessionNetworkObject;
    manager.variableTypes[SessionUsersVariable.type] = SessionUsersVariable;
    manager.variableTypes[SessionSpacesVariable.type] = SessionSpacesVariable;
  }

  static build(manager: NetworkManager, code = 'session'): SessionNetworkObject | null {
    if (!manager.isSessionHost) return null;

    const netObj = manager.buildSharedObject<SessionNetworkObject>(SessionNetworkObject.type, code);
    netObj.roomId = manager.sessionStore.id;
    netObj.addVariables();
    // netObj.addEvents();
    return netObj;
  }

  public addVariables() {
    this.addVariable(new SessionUsersVariable());
    this.addVariable(new SessionSpacesVariable());
  }

  public addEvents() {
    if (!this.eventDispatcher.hasEvent(AddUserEvent.eventName)) {
      this.eventDispatcher.registerEvent<AddUserEvent>(AddUserEvent.eventName, new AddUserEvent(this));
    }
    if (!this.eventDispatcher.hasEvent(CreateSpaceEvent.eventName)) {
      this.eventDispatcher.registerEvent<CreateSpaceEvent>(CreateSpaceEvent.eventName, new CreateSpaceEvent(this));
    }
  }
}
