import * as Three from 'three';
import { AnimationSourceType as AnimatorSourceType } from '../../engine/components/Animator.component';

export type BackgroundImageAssetsType = {
  mobile: string;
  desktop: string;
};

export type SettingsType = {
  baseAssetsUrl: string;
  title: string;
  code: string;
  background?: BackgroundImageAssetsType;
  disableFrustumCulled?: boolean;
  assistant?: {
    sceneName: string;
    playerName: string;
    characterName: string;
    modelUrl?: string;
    animations?: string[];
  };
};

export type ModelsUrlList = string[];

export interface LightMapInterface {
  [key:string]: {
    path: string;
    intensity: number;
    envIntensity: number;
  };
}

export type LightObjectsInterface = string[];

export interface LightStateInterface {
  [key: string] : {
    lightMaps: LightMapInterface;
    lightObjects: LightObjectsInterface;
  };
}

export type AvatarType = {
  model: string;
  animations: string;
};

export type AvatarsMenuItemType = {
  id: string;
  imgUrl: string;
};

export type AvatarsMenuListType = AvatarsMenuItemType[];

export type AvatarList = Record<string, AvatarType>;

export type AvatarsModels = Record<string, string>;

export type AnimationSourceType = {
  url: string;
  clipName: string;
  type: AnimatorSourceType;
};

export type AvatarAssetsType = {
  avatarsMenu: AvatarsMenuListType;
  avatarAnimations: AvatarAnimations;
  avatars: AvatarList;
  avatarsModels: AvatarsModels;
  avatarWalkDuration: number;
  defaultAvatar: string;
};

export type AvatarAnimations = Record<string, Record<string, AnimationSourceType>>;

export const scenePlaceNameTemplate = 'spawn_{}'; // if end with _seat will be seat: spawn_test_seat

export interface VideoInterface {
  title?: string;
  mp4: string;
  ogv?: string;
  previewUrl?: string;
}

export enum VideoBannerType {
  Presentation = 'Presentation',
  Video = 'Video',
}
export interface VideoBannersInterface {
  videos: VideoInterface[];
  timeline?: VideoBannerTimelineInterface;
  type: VideoBannerType;
  autoplay?: boolean;
  distance?: number;
  distanceVolume?: number;
  enableSharing?: boolean;
}

export interface VideoBannerTimelineFrameInterface {
  name: string;
  timeStamp: number;
}

export type VideoBannerTimelineInterface = VideoBannerTimelineFrameInterface[];

export type VideoBannersType = Record<string, VideoBannersInterface>;

export interface AudioInterface {
  title: string;
  mp3: string;
  previewUrl: string;
}
export interface AudioPlaceInterface {
  tracks: AudioInterface[];
}

export type AudioPlacesType = Record<string, AudioPlaceInterface>;

export type PlaceObjectType = {
  position: Three.Vector3;
  rotation: Three.Euler;
  isSeat?: boolean;
  iconUrl?: string;
  title?: string;
};

export type PlaceObjectsType = Record<string, PlaceObjectType>;

export type TeleportType = {
  triggerMeshName?: string;
  triggerPosition?: Three.Vector3;
  spaceName: string;
  spaceParameters?: Record<string, unknown>;
  message?: string;
  texturePath?: string;
  textureMeshName?: string;
};

export type ShopifyAssetType = {
  title: string;
  description: string;
  previewUrl: string;
  nameplateObject?: string;
};

export type ShopifyAssetsType = Record<string, ShopifyAssetType>;

export type MirrorObject = { name: string; width: number; height: number };

export type AnimatedObject = { name: string; resetPosition?: boolean; visible?: boolean; loader?: boolean };

export type MaterialType = {
  name: string;
  anisotropy?: number;
};

export type AnimatedObjectsConfig = {
  enabled?: boolean;
  animateAll?: boolean;
  defaultSettings?: Partial<AnimatedObject>;
  objects: AnimatedObject[];
};
