import * as THREE from 'three';
import { VRMHumanBoneName } from '@pixiv/three-vrm';

export enum JointConstraintType {
  Ball = 'Ball',
  MinMax = 'MinMax',
  Test = 'Test',
  TargetRotations = 'TargetRotations',
}

export type JointConstraintTest = {
  type: JointConstraintType.Test;
};

export type JointConstraintBall = {
  type: JointConstraintType.Ball;
  angle: number;
  up?: THREE.Vector3;
};

export type JointConstraintMinMax = {
  type: JointConstraintType.MinMax;
  order?: 'XYZ' | 'YZX' | 'ZXY' | 'XZY' | 'YXZ' | 'ZYX';
  rotationMin: THREE.Vector3;
  rotationMax: THREE.Vector3;
};

export type JointConstraintTargetAxisConfig = {
  targetAxis: string;
  multiplier?: number;
  addDegrees?: number;
  parentMaxDegrees?: number;
  parentMinDegrees?: number;
};

export type JointConstraintTargetRotations = {
  type: JointConstraintType.TargetRotations;
  axes?: {
    xAxis?: JointConstraintTargetAxisConfig;
    yAxis?: JointConstraintTargetAxisConfig;
    zAxis?: JointConstraintTargetAxisConfig;
  };
};

export type JointConstraintConfig = JointConstraintBall
| JointConstraintMinMax | JointConstraintTest | JointConstraintTargetRotations;

export type IkSystemType = 'system' | 'simple';

// Information for generating IKChain from VRM
export interface IKConfig {
  system: IkSystemType;
  iteration: number;
  ikSystemChainConfigs: Array<ChainConfig>;
  ikSimpleChainConfigs: Array<ChainConfig>;
}

export interface ChainConfig {
  name: string;
  jointConfigs: Array<JointConfig>;
  effectorBoneName: VRMHumanBoneName; // Bone to set in IKChain.effector
}

export interface JointConfig {
  boneName: VRMHumanBoneName;
  system?: IkSystemType;
  constraints: JointConstraintConfig[];
}
