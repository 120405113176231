import { System, SystemOptions } from '../../System';
import { KeyboardDevice } from './devices/KeyboardDevice';
import { MouseDevice } from './devices/MouseDevice';
import { TouchscreenDevice } from './devices/TouchscreenDevice';

export class InputSystem extends System {
  public keyboard: KeyboardDevice;

  public mouse: MouseDevice;

  public touchscreen: TouchscreenDevice;

  constructor(options: SystemOptions) {
    super(options);
    this.keyboard = new KeyboardDevice({ app: this.app });
    this.mouse = new MouseDevice({ app: this.app });
    this.touchscreen = new TouchscreenDevice({ app: this.app });
  }

  static get code(): string {
    return 'input';
  }

  onUpdate(dt: number) {
    this.keyboard.update();
    this.mouse.update();
    this.touchscreen.update();
  }
}
