import { domain, google } from '../../../generated';
import { VideoSourceType } from '../../services/VideoController';
import { VariablePayload } from '../../../engine/network/payloads/VariablePayload';
import Any = google.protobuf.Any;
import IAny = google.protobuf.IAny;
import payloads = domain.network.payloads;

export type VideoVariableValueType = {
  source?: VideoSourceType;
  isPlaying: boolean;
  videoTime: number;
  localTime: number;
  shareScreenUserId?: number;
};

export default class VideoVariablePayload extends VariablePayload<VideoVariableValueType> {
  static payloadFromProto(payload: IAny): VideoVariableValueType | null {
    if (!payload.value) {
      return null;
    }
    const decoded = payloads.VideoVariablePayload.decode(payload.value);
    return {
      videoTime: decoded.videoTime,
      localTime: decoded.localTime as number,
      isPlaying: decoded.isPlaying,
      shareScreenUserId: decoded.shareScreenUserId ?? 0,
      source: {
        ogv: decoded.source?.ogv ?? '',
        mp4: decoded.source?.mp4 ?? '',
      },
    };
  }

  payloadToProto(): Any | null {
    if (!this.target.value) {
      return null;
    }
    const payload = payloads.VideoVariablePayload.create({
      isPlaying: this.target.value?.isPlaying,
      videoTime: this.target.value?.videoTime,
      localTime: this.target.value?.localTime,
      shareScreenUserId: this.target.value?.shareScreenUserId ?? 0,
      source: payloads.VideoVariablePayload.VideoSource.create({
        ogv: this.target.value?.source?.ogv,
        mp4: this.target.value?.source?.mp4,
      }),
    });
    return Any.create({
      type_url: this.targetType,
      value: payloads.VideoVariablePayload.encode(payload).finish(),
    });
  }
}
