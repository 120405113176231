import { NetworkId } from '../types';
import { BaseNetworkError } from './BaseNetworkError';

export class RoomDoesNotExistError extends BaseNetworkError {
  public readonly $isRoomDoesNotExistsError = true;

  public static readonly code = 'PL-4000';

  constructor(private readonly roomId: NetworkId, private readonly error?: any) {
    super(RoomDoesNotExistError.code, `Room with id ${roomId} does not exists`, error);
    this.name = this.constructor.name;
  }
}
