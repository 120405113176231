import * as Three from 'three';
import { Component as EngineComponent, ComponentOptions } from '../../../engine/Component';
import { Entity } from '../../../engine/Entity';
import CurrentFrameValueContainer from '../../services/CurrentFrameValueContainer';
import { ThreeMemoryCleaner } from '../../../engine/services/ThreeMemoryCleaner';
import AvatarComponent from '../Avatar.component';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import SessionStore from '../../../engine/network/sessionStore/SessionStore';

export type AvatarVideoComponentOptions = ComponentOptions & {
  data?: {
    pinned: boolean;
    // defaultEntity: Three.Object3D;
    pinnedEntity: Three.Object3D;
    // avatarEntity: Entity;
    userId?: number;
  };
};

export type SessionPinnedVideoType = Record<number, boolean>;

export default class AvatarVideoComponent extends EngineComponent {
  // public pinned = false;

  public pinIndex = 0;

  public userId?: number;

  public pinnedInCurrentFrame = new CurrentFrameValueContainer(this.pinned);

  public pinIndexInCurrentFrame = new CurrentFrameValueContainer(this.pinIndex);

  // public defaultEntity: Three.Object3D;

  public pinnedEntity: Three.Object3D;

  // public avatarEntity: Entity;

  public get pinned(): boolean | undefined {
    if (!this.session || !this.userId) return false;
    return this.session.get<SessionPinnedVideoType>('pinnedVideo', {})[this.userId];
  }

  public set pinned(value: boolean | undefined) {
    if (!this.session || !this.userId || typeof value === 'undefined') return;
    this.session.get<SessionPinnedVideoType>('pinnedVideo', {})[this.userId] = value;
  }

  public get session(): SessionStore | undefined {
    return this.entity.app.networkManager?.sessionStore;
  }

  public getParentEntity() {
    return this.pinned ? this.pinnedEntity : this.avatarEntity;
  }

  public get avatarEntity(): Entity | undefined {
    const comp = this.entity.app.componentManager.getComponentsByType(AvatarComponent).find((avatarComponent) => {
      return this.userId === avatarComponent.entity.getComponentFromParents(NetworkObjectComponent)?.netObject?.ownerId;
    });
    return comp?.entity;
  }

  public constructor(options: AvatarVideoComponentOptions) {
    super(options);
    this.pinnedEntity = options.data?.pinnedEntity ?? this.entity;
    // this.defaultEntity = options.data?.defaultEntity ?? this.entity;
    // this.avatarEntity = options.data?.avatarEntity ?? this.entity;
    this.userId = options.data?.userId;
    if (typeof this.pinned === 'undefined') {
      this.pinned = options.data?.pinned ?? false;
    }
  }

  public destroy() {
    // ThreeMemoryCleaner.disposeThreeGraph(this.defaultEntity);
    ThreeMemoryCleaner.disposeThreeGraph(this.pinnedEntity);
    if (this.avatarEntity) ThreeMemoryCleaner.disposeThreeGraph(this.avatarEntity);
  }

  static get code(): string {
    return 'avatar_video';
  }
}
