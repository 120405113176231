import * as Three from 'three';
import { Spector } from 'spectorjs';
import TWEEN from '@tweenjs/tween.js';
import EventEmitter from 'eventemitter3';
import { EntityManager } from './EntityManager';
import { ComponentManager } from './ComponentManager';
import { System } from './System';
import { SceneManager } from './scene/SceneManager';
import NetworkManager from './network/NetworkManager';
import VRSession from './services/VRSession';
import { ConferenceServiceInterface } from './network/services/chats/types';
import { engine } from '../generated';
import payloads = engine.network.sessionStore.payloads;

export type ApplicationOptions = {
  xrEnabled: boolean;
};

export type ApplicationEventTypes = {
  render: (dt: number) => void;
};

type RenderResolves = { resolve: () => void; renders: number };

export class Application {
  public entityManager: EntityManager;

  public componentManager: ComponentManager;

  public networkManager: NetworkManager | null = null;

  public camera: Three.PerspectiveCamera | null = null;

  public renderer: Three.WebGLRenderer = new Three.WebGLRenderer({
    antialias: true,
    powerPreference: 'high-performance',
    alpha: true,
  });

  public vrSession: VRSession = new VRSession(this.renderer);

  public chatsService?: ConferenceServiceInterface;

  public clock: Three.Clock = new Three.Clock();

  public systems: System[] = [];

  public sceneManager: SceneManager;

  protected xrEnabled: boolean;

  // todo: fix bug with xr rotation delay on 1 frame (first frame has wrong rotation)
  protected isFirstRender = true;

  protected _events: EventEmitter<ApplicationEventTypes> = new EventEmitter<ApplicationEventTypes>();

  protected nextRenderResolves: RenderResolves[] = [];

  constructor(options: ApplicationOptions) {
    this.xrEnabled = options.xrEnabled;
    this.entityManager = new EntityManager({ app: this });
    this.componentManager = new ComponentManager();
    this.sceneManager = new SceneManager({ app: this });
    // this.setupXRLiveReload();
    this.setupRenderer();
    this.setupXRSessionHandling();
  }

  public get events() {
    return this._events;
  }

  public nextRenderPromise(renders = 1): Promise<void> {
    return new Promise<void>((resolve) => {
      this.nextRenderResolves.push({ resolve, renders });
    });
  }

  public get targetFramerate(): number {
    return this.renderer.xr.getSession()?.frameRate || 60;
  }

  public setNetworkManager(manager: NetworkManager | null) {
    this.networkManager = manager;
  }

  public setChatsService(conference: ConferenceServiceInterface) {
    this.chatsService = conference;
  }

  public get isInVR(): boolean {
    return this.renderer.xr.isPresenting;
  }

  public run(): void {
    // this.enableSpector();
    this.clock.start();

    this.renderer.setAnimationLoop((ts) => {
      TWEEN.update(ts);
      this.render();
    });
  }

  public render() {
    const delta = this.clock.getDelta();

    this.updateRendererSize();
    if (this.camera) this.renderer.xr.updateCamera(this.camera);

    this._events.emit('render', delta);

    this.nextRenderResolves.forEach(({ resolve, renders }) => {
      if (renders === 0) resolve();
    });
    this.nextRenderResolves = this.nextRenderResolves.filter(({ renders }) => renders > 0);
    this.nextRenderResolves = this.nextRenderResolves.map(({ resolve, renders }) => ({ resolve, renders: renders - 1 }));

    this.systems.forEach((system) => {
      if (!this.sceneManager.sceneIsLoaded) return; // todo: think about it
      system.onUpdate(delta);
    });

    if (!this.sceneManager.sceneIsLoaded) { // todo: think about it
      this.renderer.render(new Three.Scene(), new Three.PerspectiveCamera());
      this.renderer.clear(true, true, true);
      this.isFirstRender = true;
      return;
    }

    if (this.camera && this.sceneManager.currentThreeScene) {
      if (this.isFirstRender) {
        this.isFirstRender = false;
        return;
      }
      this.sceneManager.currentScene && this.sceneManager.currentScene.isSelfRender
        ? this.sceneManager.currentScene.render(this, delta)
        : this.renderer.render(this.sceneManager.currentThreeScene, this.camera);
    }

    this.systems.forEach((system) => system.onAfterRender(delta));
  }

  public destroy(): void {
    this.networkManager?.stop();
    this.renderer.dispose();
    this.renderer.domElement.remove();
    this.systems.forEach((system) => system.destroy());
  }

  public getSystem<T extends typeof System>(SystemType: T): InstanceType<T> | undefined {
    const system = this.systems.find((_system) => _system instanceof SystemType);

    if (system) return system as InstanceType<T>;

    return undefined;
  }

  public getSystemOrFail<T extends typeof System>(SystemType: T): InstanceType<T> {
    const system = this.getSystem(SystemType);

    if (!system) throw new Error(`System ${SystemType.code} not found`);

    return system;
  }

  public destroyAllSystems(): void {
    this.systems.forEach((system) => {
      this.removeSystem(system);
      system.destroy();
    });
    this.systems = [];
  }

  public addSystem(system: System): void {
    this.systems.push(system);
    system.onAdded();
  }

  public removeSystem(system: System): void {
    this.systems = this.systems.filter((_system) => _system !== system);
    system.onRemoved();
  }

  protected enableFramerateLimit(limit: number): void {
    // REFACTOR!!!
    this.renderer.xr.addEventListener('sessionstart', () => {
      const session = this.renderer.xr.getSession();

      if (!session) return;

      if (!session.supportedFrameRates?.includes(limit)) return;

      session.onframeratechange = (test) => {
        if (test.session.frameRate !== limit) {
          session.updateTargetFrameRate(limit).catch(() => undefined);
        }
      };

      session.updateTargetFrameRate(limit).catch(() => undefined);
    });
  }

  protected enableSpector(): void {
    const spector = new Spector();
    spector.spyCanvases();
    spector.displayUI();
  }

  protected setupXRLiveReload(): void {
    const hot = window?.module?.hot || import.meta.webpackHot;
    // temporary solution for fast develop in oculus quest2 1
    if (hot?.addStatusHandler) {
      hot.addStatusHandler((e) => {
        if (e === 'check') {
          if (this.renderer) {
            this.renderer.xr.getSession()?.end();
          }
          window.location.reload();
        }
      });
    }
  }

  protected setupRenderer(): void {
    this.renderer.domElement.style.userSelect = 'none';
    this.renderer.domElement.style.outline = 'none';
    this.renderer.domElement.setAttribute('tabindex', '0');
    this.renderer.setClearColor(0x95b1cc);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.localClippingEnabled = true;
    this.renderer.outputEncoding = Three.sRGBEncoding;
    this.renderer.toneMapping = Three.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.domElement.oncontextmenu = () => false;

    if (this.xrEnabled) {
      this.renderer.xr.enabled = true;
      // just for test
      // const isOculus = /OculusBrowser/i.test(navigator.userAgent);
      // if (isOculus) {
      //   document.body.appendChild(VRButton.createButton(this.renderer));
      // }
    }

    this.renderer.xr.setReferenceSpaceType('local');
    // this.renderer.xr.setFramebufferScaleFactor(2); // decrease performance
    // this.renderer.xr.setFoveation(0); // decrease performance
    // this.enableFramerateLimit(90); // think about it
    // this.enableSpector(); // think about it
  }

  protected setupXRSessionHandling(): void {
    this.renderer.xr.addEventListener('sessionstart', () => this.systems.forEach((system) => {
      system.onXRSessionStart();
      this.isFirstRender = true;
    }));
    this.renderer.xr.addEventListener('sessionend', () => this.systems.forEach((system) => {
      system.onXRSessionEnd();
      this.isFirstRender = true;
    }));
  }

  protected updateRendererSize(): void {
    if (this.renderer.xr.isPresenting) return;
    if (!this.renderer.domElement.parentElement) return;

    const { innerWidth, innerHeight } = window; // this.renderer.domElement.parentElement;
    const { width, height } = this.renderer.getSize(new Three.Vector2());

    if ((innerWidth === width) && (innerHeight === height)) return;

    this.renderer.setSize(innerWidth, innerHeight, true);
    if (this.camera) {
      this.camera.aspect = innerWidth / innerHeight;
      this.camera.updateProjectionMatrix();
    }
  }
}
