import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

export const ChatMic: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      // fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon"
      >
        <path
          id="Rectangle 81"
          d="M7 12V12C7 14.7614 9.23858 17 12 17V17C14.7614 17 17 14.7614 17 12V12"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          fillOpacity="0"
        />
        <path
          id="Vector 2"
          d="M11.25 21C11.25 21.4142 11.5858 21.75 12 21.75C12.4142 21.75 12.75 21.4142 12.75 21H11.25ZM11.25 17V21H12.75V17H11.25Z"
          fill={fill}
        />
        <rect
          id="Rectangle 80"
          x="9.75"
          y="3.75"
          width="4.5"
          height="10.5"
          rx="2.25"
          stroke={fill}
          strokeWidth="1.5"
          fillOpacity="0"
        />
      </g>
    </svg>
  );
});

export default ChatMic;
