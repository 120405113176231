import { Container } from '../Container';
import { addInteractivityToElement } from '../../helpers';

export type AvatarItemParams = {
  id: string;
  imgUrl: string;
  width?: number;
  height?: number;
};
export function AvatarItem(params: AvatarItemParams) {
  const padding = 2;
  const width = params.width ?? 125;
  const height = params.height ?? 200;
  const bgContainer = Container({
    width: width - padding * 2,
    height: height - padding * 2,
    borderRadius: [12, 12, 12, 12],
    borderWidth: 0,
    backgroundColor: '#FFFFFF',
    backgroundOpacity: 0.1,
    padding: 0,
  }, [
    Container({
      width: width - padding * 2,
      height: height - padding * 2,
      backgroundImage: params.imgUrl,
      backgroundOpacity: 1,
      borderWidth: 0,
      borderRadius: [12, 12, 12, 12],
      margin: 0,
      backgroundSize: 'contain',
    }, []),
  ]);
  const rootContainer = Container({
    width: width + 9 - padding * 2,
    height: height + 8 - padding * 2,
    padding,
    borderColor: '#FFFFFF',
    borderWidth: 0,
    borderRadius: [16, 16, 16, 16],
    justifyContent: 'center',
    alignItems: 'center',
  }, [
    bgContainer,
  ]);

  addInteractivityToElement({
    id: params.id,
    element: rootContainer,
    defaultAttributes: { borderWidth: 0 },
    hoveredAttributes: { borderWidth: 0 },
    activeAttributes: { borderWidth: 1 },
    selectedAttributes: { borderWidth: 1 },
  });

  addInteractivityToElement({
    id: `${params.id}_bg`,
    element: bgContainer,
    defaultAttributes: { backgroundOpacity: 0.1 },
    hoveredAttributes: { backgroundOpacity: 0.3 },
    activeAttributes: { backgroundOpacity: 0.3 },
    selectedAttributes: { backgroundOpacity: 0.3 },
  });

  return rootContainer;
}
