import { Application } from '../../engine/Application';
import { PlayerControlsComponent } from '../components/PlayerControls.component';
import { PlayerControlsSystem } from '../systems/PlayerControls.system';

export type PlayerExternalApiOptions = {
  app: Application;
};

// todo: need refactoring
export class PlayerExternalApi {
  protected app: Application;

  constructor(options: PlayerExternalApiOptions) {
    this.app = options.app;
  }

  /**
   * Allows you to set the movement vector of the player's character
   * @param x
   * @param y
   */
  public setMovementDirection(x: number, y: number): void {
    // maybe need named character entity
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
      component.virtualMovementDirection.set(x, y);
    });
  }

  /**
   * Allows toggling the player's character controller (first person / third person)
   */
  public toggleCharacterController(): void {
    const playerControlSystem = this.app.getSystem(PlayerControlsSystem);

    if (!playerControlSystem) return;

    // maybe need named character entity
    this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
      playerControlSystem.toggleCharacterController(component);
    });
  }
}
