export default class CurrentFrameValueContainer<T> {
  public value: T;

  public prevValue?: T;

  public testValue?: T;

  constructor(initValue: T, testValue?: T) {
    this.value = initValue;
    this.testValue = testValue;
  }

  public update(value: T) {
    this.prevValue = this.value;
    this.value = value;
  }

  public changedInCurrentFrame() {
    return this.value !== this.prevValue;
  }

  public checkValueInCurrentFrame(inputTestValue?: T) {
    const testValue = inputTestValue ?? this.testValue;
    if (typeof testValue === 'undefined') return false;
    return this.changedInCurrentFrame() && this.value === testValue;
  }
}
