import { NetworkId } from '../types';
import NetworkObject, { NetworkObjectLifeTimeTypes, NetworkObjectStatus } from '../NetworkObject';
import Payload from './Payload';
import { IDType } from '../services/ID.service';
import Generated, { google } from '../../../generated';
import IAny = google.protobuf.IAny;

export type NetworkObjectSerialized<T = any> = {
  type: string;
  id: IDType;
  ownerId: NetworkId;
  roomId: NetworkId;
  status: NetworkObjectStatus;
  lifeTimeType: NetworkObjectLifeTimeTypes;
  code: string;
  isNeedSpawn?: boolean;
  permanent: boolean;
  payload: T | undefined;
};

export default class ObjectPayload<PayloadType, TargetType extends NetworkObject = NetworkObject>
  extends Payload<TargetType, NetworkObjectSerialized<PayloadType>> {
  static fromProto<PayloadType>(buffer: ArrayBuffer): NetworkObjectSerialized<PayloadType> {
    const decoded = Generated.engine.network.payloads.ObjectPayload.decode(new Uint8Array(buffer));
    return {
      type: decoded.type,
      code: decoded.code,
      id: decoded.id,
      ownerId: decoded.ownerId,
      status: decoded.status,
      lifeTimeType: decoded.lifeTimeType,
      isNeedSpawn: decoded.isNeedSpawn,
      roomId: decoded.roomId,
      permanent: decoded.permanent,
      payload: this.payloadFromProto(decoded.payload), // todo?
    };
  }

  get type(): string {
    return (this.target.constructor as any)?.type ?? '';
  }

  toProto(): Uint8Array {
    const proto = Generated.engine.network.payloads.ObjectPayload.create({
      id: this.target.id,
      type: this.type,
      ownerId: this.target.ownerId,
      status: this.target.status,
      lifeTimeType: this.target.lifeTimeType,
      // isNeedSpawn: this.target.isNeedSpawn,
      code: this.target.code,
      roomId: this.target.roomId,
      permanent: this.target.permanent,
      payload: this.payloadToProto(),
    });
    return Generated.engine.network.payloads.ObjectPayload.encode(proto).finish();
  }

  payloadToProto(): IAny | undefined {
    return undefined;
  }

  // todo: swypse: return type
  // noinspection JSUnusedLocalSymbols
  static payloadFromProto(payload: IAny | null | undefined): any | undefined {
    return undefined;
  }

  toJson(): NetworkObjectSerialized<PayloadType> {
    return {
      type: this.type,
      id: this.target.id,
      ownerId: this.target.ownerId,
      status: this.target.status,
      lifeTimeType: this.target.lifeTimeType,
      code: this.target.code,
      permanent: this.target.permanent,
      // isNeedSpawn: this.target.isNeedSpawn,
      // payload: this.payloadToJson(),
    } as NetworkObjectSerialized<PayloadType>;
  }

  payloadToJson(): PayloadType | undefined {
    return undefined;
  }

  fromJson(data: NetworkObjectSerialized<PayloadType>) {
    this.target.id = data.id;
    this.target.ownerId = data.ownerId;
    this.target.status = data.status;
    this.target.lifeTimeType = data.lifeTimeType;
    this.target.code = data.code;
    this.target.permanent = data.permanent;
    if (data.roomId) {
      this.target.roomId = data.roomId;
    }
  }
}
