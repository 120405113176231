import * as Three from 'three';
import { Application } from '../../../engine/Application';
import AvatarHelpers from '../../services/AvatarHelpers';

export class Adaptive {
  protected app: Application | null;

  constructor(app: Application | null) {
    this.app = app;
  }

  get application() {
    return this.app;
  }

  public get isVRSession() {
    return this.app?.renderer.xr.isPresenting;
  }

  public get isVrSupported() {
    return this.app?.vrSession.lastSessionCheck;
  }

  public get isHost() {
    return this.app?.networkManager?.isSessionHost;
  }

  public get hasNetwork(): boolean {
    return !!this.app?.networkManager;
  }

  public get headPositionY() {
    const characterEntity = this.parent;
    const head = this.app ? AvatarHelpers.getHead(this.app) : undefined;
    if (!head || !characterEntity) return 0.5;
    const headY = head.getWorldPosition(new Three.Vector3()).y - characterEntity.getWorldPosition(new Three.Vector3()).y;
    return headY;
  }

  public getPixelRatioScale(value: number) {
    return window.devicePixelRatio * 0.00005 + value;
  }

  public get rendererSize() {
    if (!this.app) return { width: 0, height: 0 };
    return this.app.renderer.getSize(new Three.Vector2());
  }

  public get elementSize() {
    const result = { width: 0, height: 0 };
    if (!this.app) return result;
    const element = this.app.renderer.domElement.parentElement || this.app.renderer.domElement;
    if (!element || !element.clientWidth) return this.rendererSize;
    result.width = element.clientWidth;
    result.height = element.clientHeight;
    return result;
  }

  public getMaxScreenSize(camera: Three.PerspectiveCamera, z: number) {
    const x = z * Math.tan(Three.MathUtils.degToRad(camera.fov) / 2) * camera.aspect;
    const y = z * Math.tan(Three.MathUtils.degToRad(camera.fov) / 2);
    return new Three.Vector2(x, y);
  }

  get positionOffset() {
    return new Three.Vector3(0, 0, 0);
  }

  get width() {
    return 0;
  }

  get height() {
    return 0;
  }

  get scale() {
    return 0.00040;
  }

  get z() {
    return this.isVRSession ? 1.7 : 0.5;
  }

  public get parent() {
    return this.isVRSession && this.app ? AvatarHelpers.getCharacterEntity(this.app) : this.app?.camera;
  }

  public bottom(value = 0) {
    if (!this.app || !this.app.camera) return 0;
    const { y: wy } = this.getMaxScreenSize(this.app.camera, this.z);
    return -wy + value * this.scale;
  }

  public top(value = 0) {
    if (!this.app || !this.app.camera) return 0;
    const { y: wy } = this.getMaxScreenSize(this.app.camera, this.z);
    return wy - value * this.scale;
  }

  public right(value = 0) {
    if (!this.app || !this.app.camera) return 0;
    const { x: wx } = this.getMaxScreenSize(this.app.camera, this.z);
    return wx - value * this.scale;
  }

  public left(value = 0) {
    if (!this.app || !this.app.camera) return 0;
    const { x: wx } = this.getMaxScreenSize(this.app.camera, this.z);
    return -wx + value * this.scale;
  }

  get position() {
    return new Three.Vector3();
  }

  static isTouchDevice() {
    return (('ontouchstart' in window)
      || (navigator.maxTouchPoints > 0)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      || (navigator.msMaxTouchPoints > 0));
  }
}
