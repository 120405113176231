import * as THREE from 'three';
import MessagesPool, { msgId, ReceiveMessage } from './MessagesPool';
import { NetworkId } from '../types';

export default class ReceiveMessagesPool extends MessagesPool<ReceiveMessage> {
  public lastTime = 0;

  protected clock = new THREE.Clock();

  public receiveMessage(message: ReceiveMessage) {
    // now from server
    // message.header.clientReceiveTime = Date.now();
    // const timeDiff = message.receiveTime - message.serverSendTime;
    const messages = message.required ? this.requiredMessages : this.messages;
    messages.push(message);
    this.sortMessages(messages);
  }

  protected sortMessages(messages: ReceiveMessage[]) {
    const messagesBySender: Record<NetworkId, ReceiveMessage[]> = {};
    messages.forEach((message) => {
      if (!messagesBySender[message.header.senderId]) messagesBySender[message.header.senderId] = [];
      messagesBySender[message.header.senderId].push(message);
    });
    messages.length = 0;
    Object.keys(messagesBySender).forEach((senderId) => {
      const senderMessages = messagesBySender[Number(senderId)];
      senderMessages.sort((m1, m2) => {
        return Number(m1.header.clientSentTimestamp - m2.header.clientSentTimestamp);
      });
      messages.push(...senderMessages);
    });
    return messages;
  }

  public getReceiveMessagesPool(): ReceiveMessage[] {
    // TODO: move rom Date.now to lastTime + Clock.delta();
    const delta = 1000 * this.clock.getDelta();
    const messages = [...this.requiredMessages];
    messages.push(...this.messages);
    // const messages = this.messages.filter((message) => {
    //   // const now = Date.now(); // TODO: sync time
    //   const messageTime = Number(message.header.serverReceivedTimestamp);
    //   return /* messageTime <= now  && */ messageTime >= this.lastTime;
    // });
    // if (messages.length) console.log('!!!!!!!!!!!!!!!!', messages);
    this.sortMessages(messages);
    if (messages.length) {
      this.lastTime = Number(messages[messages.length - 1].header.serverReceivedTimestamp);
    }
    // console.log('------');
    // console.log(this.messages.length, messages.length);
    this.messages = this.messages.filter((tm) => !messages.find((em) => msgId(tm) === msgId(em)));
    // console.log(this.messages.length);
    // console.log('------');
    return messages;
  }

  clear() {
    this.requiredMessages = [];
    this.messages = [];
    // super.clear();
    // this.clearRequiredMessages();
    // this.clearPoolByReceiveTime();
    // this.clearPoolBySendTime();

    // Check duplicates
    // const uidCounts: { [key: string]: number } = {};
    // this.messages.forEach((message) => {
    //   if (typeof uidCounts[message.uid] === undefined) uidCounts[message.uid] = 0;
    //   uidCounts[message.uid] += 1;
    // });
    // Object.keys(uidCounts).forEach((uid) => {
    //   if (uidCounts[uid] > 1) console.log('duplicate!!!!', uid);
    // });

    // this.messages = this.messages.filter((message) => message.receiveTime - message.sendTime < 5000);
    // ????
    // this.messages = this.messages.filter((message) => message.sendTime > (this.lastTime[message.data.type] || now));
  }

  clearPoolByReceiveTime(timeLimit = 6000) {
    const now = BigInt(Date.now());
    this.messages = this.messages.filter((message) => now - message.header.serverReceivedTimestamp < timeLimit);
  }

  clearPoolBySendTime(timeLimit = 8000) {
    // return;
    const now = BigInt(Date.now());
    this.messages = this.messages.filter((message) => now - message.header.serverSentTimestamp < timeLimit);
  }
}
