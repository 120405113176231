import * as THREE from 'three';
import { VRM } from '@pixiv/three-vrm';
import { defaultIKConfig } from './DefaultVrmConfig';
import { ChainConfig, IKConfig, JointConfig, JointConstraintType } from './types';
import IKSystemChain from '../IKSystem/IKChain';
import IKSimpleChain from '../IKSimple/IKChain';
import { IKSystem as IKSystemSolver } from '../IKSystem';
import IKSystemJoint from '../IKSystem/IKJoint';
import IKSimpleJoint from '../IKSimple/IKJoint';
import IKSystemHelper from '../IKSystem/IKHelper';
import IKSystemBallConstraint from '../IKSystem/constraints/IKBallConstraint';
import { IKConstraint as IKSystemConstraint } from '../IKSystem/types';
import { IKConstraint as IKSimpleConstraint } from '../IKSimple/types';
import IKTestConstraint from '../IKSystem/constraints/IKTestConstraint';
import { IKSolver as IKSimpleSolver } from '../IKSimple';
import IKSystemTargetRotationConstraint from '../IKSystem/constraints/IKTargetRotationConstraint';
import IKSimpleTargetRotationConstraint from '../IKSimple/constraints/IKTargetRotationConstraint';
import IKMinMaxRotationConstraint from '../IKSimple/constraints/IKMinMaxRotationConstraint';

type VRMHumanoid = VRM & {
  humanoid: VRMHumanoid;
};

export class VrmIK {
  protected _solver: IKSystemSolver | IKSimpleSolver;

  protected _helper: IKSystemHelper | null = null;

  protected _ikConfig: IKConfig;

  constructor(vrm: VRM, scene: THREE.Scene, ikConfig: IKConfig = defaultIKConfig) {
    this._ikConfig = ikConfig;
    this._solver = this._ikConfig.system === 'system'
      ? new IKSystemSolver({ iterations: ikConfig.iteration || 1 })
      : new IKSimpleSolver({ iterations: ikConfig.iteration || 1 });
    const chainConfigs = this._ikConfig.system === 'system' ? this._ikConfig.ikSystemChainConfigs : this._ikConfig.ikSimpleChainConfigs;
    chainConfigs.forEach((chainConfig) => {
      const chain = this._createIKChain(vrm, scene, chainConfig);
      if (chain) this._solver.add(chain);
    });
    if (this._ikConfig.system === 'system') {
      this._helper = new IKSystemHelper(this._solver as IKSystemSolver);
      scene.add(this._helper);
    }
  }

  public get ikChains(): Array<IKSystemChain | IKSimpleChain> {
    return this._solver.chains;
  }

  public update() {
    // if (this._helper) this._helper.updateMatrixWorld(true);
    if (!this._solver) return;
    this._solver.solve();
  }

  private _createIKChain(vrm: VRM, scene: THREE.Scene, chainConfig: ChainConfig): IKSystemChain & IKSimpleChain | null {
    const target = new THREE.Object3D();
    const effectorJoint = this._createJoint(vrm, {
      boneName: chainConfig.effectorBoneName,
      constraints: [],
    });
    if (!effectorJoint) return null;
    const chain: IKSystemChain | IKSimpleChain = this._ikConfig.system === 'system'
      ? new IKSystemChain({ name: chainConfig.name })
      : new IKSimpleChain({ name: chainConfig.name });
    const jointConfigs = [...chainConfig.jointConfigs];
    jointConfigs.forEach((jointConfig) => {
      const joint = this._createJoint(vrm, jointConfig);
      if (joint) chain.addJoint(joint);
    });
    effectorJoint.bone.getWorldPosition(target.position);
    scene.add(target);
    chain.addEffector(effectorJoint, target);
    return chain as IKSystemChain & IKSimpleChain;
  }

  private _createJoint(vrm: VRM, jointConfig: JointConfig): IKSystemJoint & IKSimpleJoint | null {
    if (jointConfig.system && (this._ikConfig.system !== jointConfig.system)) return null;
    const bone = vrm.humanoid?.getRawBoneNode(jointConfig.boneName);
    if (!bone) return null;
    const simpleConstraints: IKSimpleConstraint[] = [];
    const systemConstraints: IKSystemConstraint[] = [];
    jointConfig.constraints.forEach((config) => {
      if (config.type === JointConstraintType.Ball) {
        systemConstraints.push(new IKSystemBallConstraint({
          angle: config.angle,
          up: config.up,
        }));
      }
      if (config.type === JointConstraintType.TargetRotations) {
        systemConstraints.push(new IKSystemTargetRotationConstraint({
          axes: config.axes,
        }));
        simpleConstraints.push(new IKSimpleTargetRotationConstraint({
          axes: config.axes,
        }));
      }
      if (config.type === JointConstraintType.Test) {
        systemConstraints.push(new IKTestConstraint());
      }
      if (config.type === JointConstraintType.MinMax) {
        simpleConstraints.push(new IKMinMaxRotationConstraint(config));
      }
    });
    return (this._ikConfig.system === 'system'
      ? new IKSystemJoint({ bone, constraints: systemConstraints })
      : new IKSimpleJoint({ bone, constraints: simpleConstraints })) as IKSystemJoint & IKSimpleJoint;
  }
}
