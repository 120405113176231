import {
  AdditionalPhonemeInfo,
  AudioPlaybackConfig,
  Capabilities, Character,
  HistoryItem,
  InworldClient,
  InworldConnectionService,
  InworldPacket,
} from '@inworld/web-sdk';
import EventEmitter from 'eventemitter3';
import { Config } from './Config';
import { InworldMessages } from './Messages/InworldMessages';

export type InworldServiceEvents = {
  Error: (err: Error) => void;
  Ready: () => void;
  Phoneme: (phonemeData: AdditionalPhonemeInfo[]) => void;
  Message: (inworldPacket: InworldPacket) => void;
  HistoryChange: (history: HistoryItem[]) => void;
  Disconnect: () => void;
  Speak: (value: boolean) => void;
};

export interface InworldServiceProps {
  audioPlayback?: AudioPlaybackConfig;
  capabilities: Capabilities;
  sceneName: string;
  playerName: string;
}

export class InworldService {
  protected _connection: InworldConnectionService;

  protected _currentCharacter?: Character;

  protected _events: EventEmitter<InworldServiceEvents> = new EventEmitter<InworldServiceEvents>();

  protected _messages = new InworldMessages();

  public constructor(props: InworldServiceProps) {
    let starSpeak = 0;
    let endSpeak = 0;
    let endSpeakTimer: ReturnType<typeof setTimeout> | undefined;
    let isSpeak = false;

    const client = new InworldClient()

      .setOnBeforePlaying(() => {
        if (this.getMute()) return;
        starSpeak = Date.now();
        if (!isSpeak) {
          isSpeak = true;
          this._events.emit('Speak', isSpeak);
        }
      })
      .setOnAfterPlaying(() => {
        endSpeak = Date.now();
        if (endSpeakTimer) clearTimeout(endSpeakTimer);
        endSpeakTimer = setTimeout(() => {
          if (endSpeak > starSpeak) {
            isSpeak = false;
            this._events.emit('Speak', isSpeak);
          }
        }, 2000);
      })


      .setConfiguration({
        capabilities: props.capabilities,
        audioPlayback: props.audioPlayback,
      })
      .setUser({ fullName: props.playerName })
      .setScene(props.sceneName)
      .setGenerateSessionToken(this.generateSessionToken)
      .setOnError((err) => {
        console.log(err);
        this._events.emit('Error', err);
      })
      .setOnReady(() => {
        this._events.emit('Ready');
      })
      .setOnMessage((...args) => {
        this._events.emit('Message', ...args);
      })
      .setOnPhoneme((...args) => {
        this._events.emit('Phoneme', ...args);
      })
      .setOnHistoryChange((...args) => {
        this._events.emit('HistoryChange', ...args);
      })
      .setOnDisconnect(() => {
        this._events.emit('Disconnect');
      });
    this._connection = client.build();
  }

  public get events() {
    return this._events;
  }

  public get messages() {
    return this._messages;
  }

  private async generateSessionToken() {
    const response = await fetch(Config.GENERATE_TOKEN_URL);
    return response.json();
  }

  public async setCharacter(name: string) {
    const characters = await this._connection.getCharacters();
    const character = characters.find(
      (c: Character) => c.resourceName === name,
    );
    if (character) {
      this._connection.setCurrentCharacter(character);
      this._currentCharacter = character;
    } else throw new Error(`Assistant: can't find character ${name}`);
  }

  public async sendText(text: string) {
    await this._connection.sendText(text);
  }

  public getCurrentCharacterUrl(externUrl?: string) {
    return externUrl ?? this._currentCharacter?.assets.rpmModelUri;
  }

  public async mute(value: boolean) {
    await this._connection.recorder.initPlayback();
    this._connection.player.mute(value);
    await this._connection.sendTTSPlaybackMute(value);
  }

  public getMute() {
    return this._connection.player.getMute();
  }

  public async startRecording() {
    await this._connection.sendAudioSessionStart();
    await this._connection.recorder.start();
  }

  public async stopRecording() {
    this._connection.recorder.stop();
    await this._connection.sendAudioSessionEnd();
  }
}
