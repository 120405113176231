import * as Three from 'three';
import { FacialEmotionMap } from './FacialEmotionMap';
import { getMorphIndex } from '../Morph/getMorphIndex';

export type FacialToMeshOptions = {
  dt: number;
  LastEmo: string;
  CurrEmo: string;
  CurrMorph: number;
  MORPH_DURATION?: number;
  LERP_FACTOR?: number;
};
export function FacialToMesh(meshes: Three.Mesh[], opts: FacialToMeshOptions) {
  const currEmo = opts.CurrEmo;
  let lastEmo = opts.LastEmo;
  let currMorph = opts.CurrMorph;
  const MORPH_DURATION = opts.MORPH_DURATION ?? 0.25;
  const LERP_FACTOR = opts.LERP_FACTOR ?? 0.25;

  if (currEmo !== lastEmo) {
    currMorph += opts.dt;
    // Morph Emo!
    if (FacialEmotionMap[currEmo]) {
      // Reset OLD EMO
      if (FacialEmotionMap[lastEmo]) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(
          FacialEmotionMap[lastEmo],
        )) {
          const targetVal = FacialEmotionMap[currEmo][key] ?? 0;
          const targetIndex = getMorphIndex(key, meshes[0]);
          if (targetIndex !== -1) {
            meshes.forEach((smesh) => {
              smesh!.morphTargetInfluences![targetIndex] = Three.MathUtils.lerp(
                smesh!.morphTargetInfluences![targetIndex],
                targetVal * 0.01,
                LERP_FACTOR,
              );
            });
          }
        }
      }
      // APPly NEW Emo
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(FacialEmotionMap[currEmo])) {
        const targetIndex = getMorphIndex(key, meshes[0]);
        if (targetIndex !== -1) {
          meshes.forEach((smesh) => {
            smesh!.morphTargetInfluences![targetIndex] = Three.MathUtils.lerp(
              smesh!.morphTargetInfluences![targetIndex],
              value * 0.01,
              LERP_FACTOR,
            );
          });
        }
      }
    }
    if (currMorph >= MORPH_DURATION) {
      currMorph = 0;
      lastEmo = currEmo;
    }
  }

  return { lastEmo, currMorph };
}
