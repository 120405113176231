import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const Camera: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.65058 5.12888C2.04841 4.62236 2.67438 4.21228 3.49214 4.21228H15.5562C16.227 4.21228 16.8533 4.50462 17.3027 4.99659C17.7499 5.48606 17.9898 6.13518 17.9898 6.79811V8.12205L21.3407 6.49608C21.3536 6.48983 21.3666 6.48392 21.3798 6.47835C21.9637 6.23169 22.5556 6.36466 22.9738 6.70008C23.3754 7.02218 23.6197 7.52634 23.6197 8.06591L23.6197 8.06836L23.5909 17.7666C23.5928 18.275 23.3691 18.7752 23.0174 19.1145C22.6451 19.4736 22.0365 19.7211 21.3798 19.4437C21.3639 19.4369 21.3481 19.4297 21.3326 19.422L17.9898 17.7545V19.1239C17.9898 20.3573 17.0564 21.7098 15.5562 21.7098H3.49214C1.99194 21.7098 1.05859 20.3573 1.05859 19.1239V6.79811C1.05859 6.23727 1.26612 5.61839 1.65058 5.12888ZM2.94819 6.14805C2.78737 6.35281 2.70859 6.61434 2.70859 6.79811V19.1239C2.70859 19.6753 3.11665 20.0598 3.49214 20.0598H15.5562C15.9317 20.0598 16.3398 19.6753 16.3398 19.1239V16.421C16.3398 16.1352 16.4877 15.8698 16.7307 15.7194C16.9737 15.5691 17.2773 15.5552 17.533 15.6828L21.9135 17.8678C21.9163 17.8623 21.9191 17.8565 21.9217 17.8504C21.9375 17.8135 21.941 17.785 21.9409 17.7728L21.9408 17.765H21.9409L21.9697 8.06591C21.9697 8.06571 21.9697 8.06552 21.9697 8.06532C21.9696 8.05168 21.9675 8.03898 21.9641 8.02759L17.5249 10.1816C17.2692 10.3057 16.9677 10.2894 16.7269 10.1386C16.486 9.98771 16.3398 9.72354 16.3398 9.43936V6.79811C16.3398 6.52704 16.2407 6.28041 16.0845 6.10944C15.9306 5.94097 15.7387 5.86228 15.5562 5.86228H3.49214C3.27395 5.86228 3.09565 5.96031 2.94819 6.14805ZM21.8652 17.9327C21.8652 17.9327 21.8655 17.9325 21.8659 17.9322Z"
        fill={fill}
      />
    </svg>
  );
});

export default Camera;
