import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { IconButton } from '../IconButton';
import { normalizeColor } from '../../helpers';
import { UIDocumentElementState } from '../../../../engine/systems/UIDocument.system';

export type IconButtonParams = {
  id: string;
  imageUrl: string;
  altImageUrl?: string;
  color?: string;
  opacity?: number;
  visible?: boolean;
  selected?: boolean;
  borderRadius?: [number, number, number, number];
  selectedImg?: string;
  selectedColor?: string;
  selectedOpacity?: number;
  disableImg?: string;
  width?: number;
  height?: number;
};

function loadTexture(url: string) {
  return new Promise((resolve) => {
    new Three.TextureLoader().load(url, (texture) => {
      resolve(texture);
    });
  });
}

export function PlayerButton(params: IconButtonParams) {
  const buttonParams = {
    id: params.id,
    width: params.width ?? 48,
    height: params.height ?? 48,
    iconWidth: 24,
    iconHeight: 24,
    imageUrl: params.imageUrl,
    color: params.color ?? '#FFFFFF',
    opacity: params.opacity ?? 0.1,
    hoverColor: '#FFFFFF',
    hoverOpacity: 0.3,
    activeColor: '#5156D1',
    selected: params.selected,
    selectedColor: params.selectedColor,
    borderRadius: params.borderRadius,
  };
  const button = IconButton(buttonParams);
  if (typeof params.visible !== 'undefined') {
    button.visible = params.visible;
  }

  const iconElement = button.children.find((child) => child instanceof ThreeMeshUI.Block);
  if (iconElement) {
    iconElement.userData.uiData = iconElement.userData.uiData ?? {};
    button.userData.uiElements = {
      icon: iconElement,
    };
    if (params.selectedImg) {
      Promise.all([
        loadTexture(params.imageUrl),
        loadTexture(params.selectedImg),
      ])
        .then(([defaultTexture, altTexture]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconElement.setupState({
            state: UIDocumentElementState.Default,
            attributes: { backgroundTexture: defaultTexture },
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          iconElement.setupState({
            state: UIDocumentElementState.Selected,
            attributes: { backgroundTexture: altTexture },
          });
        });
    }
    if (params.disableImg) {
      loadTexture(params.disableImg).then((disableTexture) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iconElement.setupState({
          state: UIDocumentElementState.User,
          attributes: { backgroundTexture: disableTexture },
        });
      });
    }
  }

  if (params.selectedColor || params.selectedOpacity) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    button.setupState({
      state: UIDocumentElementState.Selected,
      attributes: {
        backgroundColor: normalizeColor(params.selectedColor ?? buttonParams.color),
        backgroundOpacity: params.selectedOpacity ?? buttonParams.opacity,
      },
    });
  }

  return button;
}
