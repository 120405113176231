import ThreeMeshUI from 'three-mesh-ui';
import { System } from '../../../engine/System';
import PlayerMenuComponent from '../../components/UI/PlayerMenu.component';
import { InputSystem } from '../../../engine/systems/InputSystem';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { PanelId } from '../../ui/enum/PanelId';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import SelectedObjectComponent from '../../components/SelectedObject.component';
import { ContainerId } from '../../ui/enum/ContainerId';
import { Component } from '../../../engine/Component';
import { Track } from '../../ui/elements/Player/Track';
import TrackComponent, { MediaComponentType } from '../../components/Track.component';
import { PlayerMenuAdaptive } from '../../ui/adaptive';
import { UIBuilderSystem } from '../UIBuilder.system';
import VideoComponent from '../../components/VideoComponent';
import { Container } from '../../ui/elements';
import UIHelper from '../../services/UIHelper';

export default class PlayerMenuSystem extends System {
  onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(PlayerMenuComponent).forEach((component) => {
      this.handleInput(component);
      this.handleComponentActivation(component);
      this.handleComponentEnabled(component);
      if (!component.enabled) return;
      this.handleUIReset(component);
      this.handleActiveButtons(component);
    });
  }

  protected handleUIReset(component: PlayerMenuComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    if (uIDocumentComponent.reset) {
      component.trackFilled = false;
      uIDocumentComponent.reset = false;
    }
  }

  public handleInput(component: PlayerMenuComponent) {
    const inputSystem = this.app.getSystemOrFail(InputSystem);
    if (inputSystem.keyboard.getKeyByCode('Escape').wasPressedThisFrame) {
      this.closeMenu(component);
    }
  }

  public closeMenu(component: PlayerMenuComponent) {
    // TODO: remove layers mask == optimize raycast
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    component.enabled = false;
    uIDocumentComponent.enabled = false;
    if (component.currentActionComponent) {
      component.currentActionComponent.entity.getComponentOrFail(SelectedObjectComponent).isActive = false;
    }
    component.currentActionComponent = null;
  }

  public resolveOtherMenu(component: PlayerMenuComponent, enabledInLastFrame: boolean) {
    this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
    this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((oDoc) => {
      if (!enabledInLastFrame) oDoc.enabled = false;
      if (enabledInLastFrame && oDoc.enabled) component.enabled = false;
    });
  }

  // TODO: refactoring this hell
  public handleComponentActivation(component: PlayerMenuComponent): void {
    const enabledInLastFrame = component.enabled;
    component.currentActionComponent = null;
    component.enabled = false;
    // TODO: get component by type
    this.app.componentManager.getComponentsByType(component.actionComponentType).forEach((actionComponent) => {
      const selectedComponent = actionComponent.entity.getComponentOrFail(SelectedObjectComponent);
      if (selectedComponent.isActive) {
        component.enabled = true;
        this.resolveOtherMenu(component, enabledInLastFrame);
        component.currentActionComponent = actionComponent;
      }
    });
    if (component.currentActionComponent) {
      this.fillTracks(component);
      (component.currentActionComponent as Component)
        .entity.getComponentOrFail(SelectedObjectComponent).isActive = component.enabled;
    } else {
      component.trackFilled = false;
    }
  }

  public getAdaptive(component: Component) {
    return this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component);
  }

  public fillTracks(component: PlayerMenuComponent) {
    if (!component.currentActionComponent) return;
    if (component.trackFilled) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const actionComponent = component.currentActionComponent as TrackComponent;
    if (!actionComponent) return;
    const adaptive = this.getAdaptive(component) as PlayerMenuAdaptive;
    if (!adaptive) return;

    const rowElements = uIDocumentComponent.getElementsByIds([`${PanelId.LibraryPanel}_row`]);
    const { tracksInRow } = adaptive;

    rowElements.forEach((row, index) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row.childrenBoxes.forEach((box) => box.removeFromParent());
      const rowTracks = actionComponent.tracks.slice(index * tracksInRow, (index + 1) * tracksInRow);
      rowTracks.forEach((track) => {
        row.add(
          Track({
            id: track.id,
            imgUrl: track.previewUrl ?? '',
            text: track.title ?? '',
            contentDirection: adaptive.trackItemDirection,
            width: adaptive.trackItemWidth(rowTracks.length),
            height: adaptive.trackItemHeight(rowTracks.length),
          }),
        );
      });
      if (!adaptive.isMobile && rowTracks.length % adaptive.tracksInRow > 1) {
        const emptyPlacesCount = (rowTracks.length % adaptive.tracksInRow) - 1;
        for (let i = 0; i < emptyPlacesCount; i++) {
          row.add(Container({
            id: `track_filler_${i}`,
            contentDirection: adaptive.trackItemDirection,
            width: adaptive.trackItemWidth(rowTracks.length),
            height: adaptive.trackItemHeight(rowTracks.length),
          }, []));
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row.update(true, true, true);
    });
    component.trackFilled = true;
    ThreeMeshUI.update();
  }

  public handleComponentEnabled(component: PlayerMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const playerPanel = uIDocumentComponent.getElementById(PanelId.PlayerPanel);
    const libraryPanel = uIDocumentComponent.getElementById(PanelId.LibraryPanel);
    uIDocumentComponent.enabled = component.enabled;
    if (playerPanel) {
      playerPanel.visible = component.enabled;
    }
    if (libraryPanel) {
      libraryPanel.visible = component.libraryIsOpen && component.enabled;
    }
  }

  public handleActiveButtons(component: PlayerMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const actionComponent = component.currentActionComponent as TrackComponent;
    const { videoService } = this.app.chatsService || {};

    if (states[ButtonId.CloseButton]?.state === UIDocumentElementState.Active) {
      this.closeMenu(component);
    }

    if (!actionComponent) return;

    this.updatePlayButton(component);
    this.updateSoundButton(component);
    this.updateChapterName(component);
    this.updateActiveTrack(component);
    if (component.isLoading) return;
    const { mediaComponent } = actionComponent.entity.getComponentOrFail(TrackComponent);
    if (!mediaComponent) return;

    const isVideo = mediaComponent instanceof VideoComponent;
    const videoComponent = mediaComponent as VideoComponent;
    const isSharing = isVideo && !!videoComponent.shareScreenUserId;

    actionComponent.setCurrentTrackByMediaSource();

    const shareButton = uIDocumentComponent.getElementById(ButtonId.CamButton);
    if (shareButton) shareButton.visible = isVideo && videoComponent.enableSharing;

    if (states[ButtonId.PlayButton]?.state === UIDocumentElementState.Active && !isSharing) {
      component.isLoading = true;
      mediaComponent.toggle().then(() => { component.isLoading = false; });
    }

    const trackIndex = actionComponent.currentTrackIndex;

    if (states[ButtonId.NextButton]?.state === UIDocumentElementState.Active && !isSharing) {
      actionComponent.setNextTrack();
    }

    if (states[ButtonId.PrevButton]?.state === UIDocumentElementState.Active && !isSharing) {
      actionComponent.setPrevTrack();
    }
    // FIXME: 'track' hardcoded
    Object.keys(states).filter((id) => id.startsWith('track')).forEach((id) => {
      const index = id.split('_')[1];
      if (typeof index !== 'undefined' && states[id]?.state === UIDocumentElementState.Active && !isSharing) {
        actionComponent.currentTrackIndex = Number(index);
      }
    });

    if (trackIndex !== actionComponent.currentTrackIndex && !videoService?.isScreenShareEnabled) {
      this.playCurrentTrack(component, actionComponent, mediaComponent);
    }

    if (states[ButtonId.RewindNext]?.state === UIDocumentElementState.Active && !isSharing) {
      mediaComponent.rewind(5);
    }

    if (states[ButtonId.RewindPrev]?.state === UIDocumentElementState.Active && !isSharing) {
      mediaComponent.rewind(-5);
    }

    if (states[ButtonId.Sound]?.state === UIDocumentElementState.Active) {
      mediaComponent.toggleSound();
    }

    if (states[ButtonId.CamButton]?.state === UIDocumentElementState.Active && videoService) {
      UIHelper.toggleScreenShare(this.app, videoComponent, videoService);
    }

    // if (videoService && videoService.isScreenShareEnabled) {
    //   const stream = videoService.getUserShareStream(String(this.app.networkManager?.networkId));
    //   if (stream && mediaComponent && (mediaComponent as VideoComponent).source?.stream !== stream) {
    //     (mediaComponent as VideoComponent).setSource({ stream });
    //   }
    // }

    if (states[ButtonId.Library]?.state === UIDocumentElementState.Active) {
      component.libraryIsOpen = !component.libraryIsOpen;
    }

    const libraryButton = uIDocumentComponent.getElementById(ButtonId.Library);
    if (libraryButton) {
      libraryButton.userData.uiData.defaultState = component.libraryIsOpen
        ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    }

    this.setIconState(uIDocumentComponent, ButtonId.CamButton, isSharing
      ? UIDocumentElementState.Selected : UIDocumentElementState.Default);

    if (shareButton) {
      shareButton.userData.uiData.defaultState = isSharing
        ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    }
  }

  protected playCurrentTrack(
    component: PlayerMenuComponent,
    actionComponent: TrackComponent,
    mediaComponent: MediaComponentType,
    playing?: boolean,
  ) {
    component.isLoading = true;
    const isPlay = typeof playing !== 'undefined' ? playing : mediaComponent.controller.isPlaying();
    return mediaComponent.pause()
      .then(() => (mediaComponent.setSource(actionComponent.currentTrack.src)))
      .then(() => (isPlay ? mediaComponent.play() : Promise.resolve()))
      .then(() => { component.isLoading = false; });
  }

  protected setIconState(doc: UIDocumentComponent, btnId: string, state: string, iconName = 'icon') {
    return UIHelper.setIconState(doc, btnId, state, iconName);
  }

  protected updateSoundButton(component: PlayerMenuComponent) {
    if (!component.currentActionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const { mediaComponent } = component.currentActionComponent.entity.getComponentOrFail(TrackComponent);
    if (!mediaComponent) return;
    const muted = mediaComponent.controller.isMuted;
    const state = muted ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    this.setIconState(uIDocumentComponent, ButtonId.Sound, state);
  }

  protected updatePlayButton(component: PlayerMenuComponent) {
    if (!component.currentActionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const { mediaComponent } = component.currentActionComponent.entity.getComponentOrFail(TrackComponent);
    if (!mediaComponent) return;
    const isPlaying = mediaComponent.controller.isPlaying();
    const state = isPlaying ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    const playButton = this.setIconState(uIDocumentComponent, ButtonId.PlayButton, state);
    if (playButton) {
      playButton.userData.uiData.defaultState = state;
    }
  }

  protected updateActiveTrack(component: PlayerMenuComponent) {
    const actionComponent = component.currentActionComponent as TrackComponent;
    if (!actionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const { id } = actionComponent.currentTrack;
    const elements = uIDocumentComponent.getElementsByIdPrefix('track');
    elements.forEach((element) => {
      const state = element.userData?.uiData?.id === id ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
      element.userData.uiData.defaultState = state;
    });
  }

  protected updateChapterName(component: PlayerMenuComponent) {
    if (!component.currentActionComponent) return;
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const actionComponent = component.currentActionComponent as TrackComponent;
    if (!actionComponent) return;
    const textContainer = uIDocumentComponent.getElementById(ContainerId.ChapterName);
    if (!textContainer) return;
    const textElement = textContainer.children.find((ch) => ch instanceof ThreeMeshUI.Text);
    if (!textElement) return;
    const name = actionComponent.currentTrack.title;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (textElement.content === name) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    textElement.set({
      content: name,
    });
  }
}
