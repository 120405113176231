import { System } from '../System';
import { MeshRendererComponent } from '../components/MeshRenderer.component';

export default class MeshRendererSystem extends System {
  public onUpdate(dt: number) {
    this.app.componentManager.getComponentsByType(MeshRendererComponent).forEach((component) => {
      this.updateVRM(component, dt);
    });
  }

  protected updateVRM(component: MeshRendererComponent, dt: number) {
    if (!component.enabled) return;
    const vrm = component.getVRMAvatar();
    if (!vrm) return;
    vrm.update(dt);
  }
}
