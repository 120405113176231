import * as Three from 'three';
import { Vector2Control } from '../controls/Vector2Control';

export class Vector2Sensor {
  public threeVector2 = new Three.Vector2();

  public get x(): number {
    return this.threeVector2.x;
  }

  public get y(): number {
    return this.threeVector2.y;
  }

  applyToControls(positionControl: Vector2Control, deltaControl: Vector2Control): void {
    deltaControl.threeVector2.copy(positionControl.threeVector2).sub(this.threeVector2);
    positionControl.threeVector2.copy(this.threeVector2);
  }
}
