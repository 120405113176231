import * as Three from 'three';
import { System } from '../../engine/System';
import LookAtComponent from '../components/LookAt.component';

export default class LookAtSystem extends System {
  static get code(): string {
    return 'look_at_system';
  }

  public onUpdate(dt: number): void {
    this.app.componentManager.getComponentsByType(LookAtComponent).forEach((component) => {
      if (!component.enabled) return;
      this.handleLookAt(component);
    });
  }

  protected handleLookAt(component: LookAtComponent) {
    const target = component.target ?? this.app.camera;
    if (!target) return;
    component.entity.lookAt(target.getWorldPosition(new Three.Vector3()));
  }
}
