import * as EmptyVideo from './EmptyVideo';

export type VideoSourceType = {
  mp4?: string;
  ogv?: string;
  stream?: MediaStream;
};

const START_TIME = 0.1;
// let index = 1;

export class VideoController {
  public element: HTMLVideoElement | null = null;

  public autoplay = false;

  protected handleLoadedData: () => void = () => {};

  public createElement(appendTo: HTMLElement = document.body): Promise<HTMLVideoElement | null> {
    this.element = document.createElement('video');
    this.element.autoplay = this.autoplay;
    this.element.controls = true;
    this.element.muted = true;
    this.element.playsInline = true;
    this.element.loop = true;
    this.element.preload = 'metadata';
    this.element.style.display = 'none';
    // this.element.style.position = 'absolute';
    // this.element.style.width = '256px';
    // this.element.style.height = '256px';
    // this.element.style.top = `${index * 256}px`;
    // this.element.setAttribute('id', `vid-${index}`);
    // index += 1;
    appendTo.appendChild(this.element);

    ['mp4', 'ogg', 'webm'].forEach((type) => {
      const source = document.createElement('source');
      source.src = EmptyVideo[type as keyof typeof EmptyVideo];
      source.type = `video/${type}`;
      this.element?.appendChild(source);
    });

    return this.update().then(() => {
      if (!this.element) return Promise.resolve(null);
      return this.element.play().then(() => {
        this.element?.pause();
        if (this.element) this.element.innerHTML = '';
        return this.element;
      });
    });
  }

  public destroy() {
    this.element?.remove();
  }

  public setAutoplay(value?: boolean) {
    const play = typeof value !== 'undefined' ? value : this.autoplay;
    if (play) this.element?.setAttribute('autoplay', 'autoplay');
    else this.element?.removeAttribute('autoplay');
  }

  public isPlaying(): boolean {
    if (!this.element) return false;
    // return this.element.currentTime >= START_TIME &&
    return !this.element.paused && !this.element.ended
      && this.element.readyState >= this.element.HAVE_CURRENT_DATA;
  }

  public toggle(isPlaying: boolean): Promise<void> {
    if (!this.element) return Promise.resolve();
    if (isPlaying && !this.isPlaying()) {
      return this.element.play();
    }
    if (!isPlaying && this.isPlaying()) {
      // this.mute();
      this.element.pause();
    }
    return Promise.resolve();
  }

  public mute() {
    if (!this.element) return;
    this.element.muted = true;
  }

  public unmute() {
    if (!this.element) return;
    this.element.muted = false;
  }

  public toggleSound() {
    if (!this.element) return;
    if (this.element.muted) this.unmute();
    else this.mute();
  }

  public get isMuted() {
    if (!this.element) return true;
    return this.element.muted;
  }

  public get time(): number {
    if (!this.element) return 0;
    return this.element.currentTime;
  }

  public setTime(time: number) {
    if (!this.element) return 0;
    this.element.currentTime = time;
  }

  public setSource({ mp4, ogv, stream }: VideoSourceType, skipUpdate = false) {
    if (!this.element) return Promise.resolve();
    if (this.isPlaying()) this.element.pause();
    this.setTime(START_TIME);
    this.element.innerHTML = '';
    if (stream) {
      this.element.srcObject = stream;
      this.setAutoplay(true);
    } else {
      this.element.srcObject = null;
      this.setAutoplay();
      [{
        type: 'mp4',
        src: mp4,
      }, {
        type: 'ogv',
        src: ogv,
      }].forEach((({
        type,
        src,
      }) => {
        if (!src) return;
        const source = document.createElement('source');
        source.src = src;
        source.type = `video/${type}`;
        this.element?.appendChild(source);
      }));
    }
    this.element.playsInline = true;
    this.element.loop = true;
    if (this.element.srcObject) return this.toggle(true);
    return skipUpdate || this.element.srcObject ? Promise.resolve() : this.update();
  }

  public update() {
    if (!this.element) return Promise.resolve();
    this.element?.load();
    return new Promise<void>((resolve) => {
      this.element?.removeEventListener('loadeddata', this.handleLoadedData);
      this.element?.addEventListener('loadeddata', this.handleLoadedData = () => {
        // this.setTime(START_TIME);
        this.element?.removeEventListener('loadeddata', this.handleLoadedData);
        resolve();
      }, false);
    });
  }

  public setVolume(value: number) {
    if (!this.element) return;
    this.element.volume = value;
  }
}
