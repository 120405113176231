import { AdditionalPhonemeInfo } from '@inworld/web-sdk';
import { phonemeToVisemeMap } from './PhonemesToVisemesMap';
import { DefaultVisemeDataOptions, VisemeDataOptions } from './types';

const smooth = (delta: number, opts: VisemeDataOptions) => {
  return 1 * ((100 - (100 * delta) / opts.SMOOTH_FACTOR_S) / 100) * opts.EXPRESSION_FACTOR;
};

export const getVisemeData = (
  offset: number,
  phonemeData: AdditionalPhonemeInfo[],
  opts: VisemeDataOptions = { ...DefaultVisemeDataOptions },
) => {
  const visemeData: number[] = Array(opts.AMOUNT).fill(0);

  // exit condition 1;
  if (!phonemeData[phonemeData.length - 1]) {
    return;
  }

  // exit condition 2;
  // LAST phoneme is always '<INTERSPERSE_CHARACTER>';
  if (phonemeData[phonemeData.length - 1].startOffsetS! < offset) {
    return;
  }

  for (
    let currentIndex = 0;
    currentIndex < phonemeData.length;
    currentIndex++
  ) {
    // iterating though all phonemes, trying to calculate smoothed blendshape;
    const currentOffset = phonemeData[currentIndex].startOffsetS!;
    const nextOffset = phonemeData[currentIndex + 1]
      ? phonemeData[currentIndex + 1].startOffsetS!
      : currentOffset + opts.LAST_PHONEME_DURATION;

    const currentPhoneme = phonemeData[currentIndex].phoneme;

    if (!currentPhoneme) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const currentViseme = phonemeToVisemeMap[currentPhoneme] ?? 0;
    const visemeLength = nextOffset - currentOffset;

    let visemeValue = 0;

    if (offset > currentOffset + visemeLength) {
      // phoneme finished;
      const delta = offset - currentOffset + visemeLength;
      if (delta < opts.SMOOTH_FACTOR_S) {
        visemeValue = smooth(delta, opts);
      }
    } else if (offset > currentOffset) {
      // phoneme started;
      visemeValue = opts.EXPRESSION_FACTOR;
    } else {
      // phoneme NOT started;
      const delta = currentOffset - offset;
      if (delta < opts.SMOOTH_FACTOR_S) {
        visemeValue = smooth(delta, opts);
      }
    }

    if (visemeValue > visemeData[currentViseme]) {
      visemeData[currentViseme] = visemeValue;
    }
  }
  return visemeData;
};
