export class BaseNetworkError extends Error {
  public readonly $isBaseNetworkError = true;

  public readonly rawMessage: string;

  public readonly code: string;

  constructor(code: string, message: string, error?: any) {
    super(`[${code}] ${message}`, { cause: error });
    this.code = code;
    this.rawMessage = message;
    this.name = this.constructor.name;
  }

  get messageWithoutCode(): string {
    return this.rawMessage;
  }
}
