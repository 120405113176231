import { useContext } from 'react';
import { SpaceStore } from '../../stores/SpaceStore';
import { SpaceContext } from '../../context/SpaceContext';
import ControlsStore from '../../stores/ControlsStore';

export const useSpaceStore = (): SpaceStore => {
  const rootContext = useContext(SpaceContext);
  if (!rootContext) throw new Error('not found rootStore');

  return rootContext.store;
};

export const useControlsStore = (): ControlsStore => {
  const rootContext = useContext(SpaceContext);
  if (!rootContext) throw new Error('not found rootStore');

  return rootContext.store.controlsStore;
};
