import * as ThreeMeshUI from 'three-mesh-ui';
import * as Three from 'three';
import { addInteractivityToElement, normalizeColor, setAbsolutePosition } from '../helpers';

export type ContainerParams = {
  width: number;
  height: number;
  fontName?: string;
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  contentDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  backgroundColor?: string;
  backgroundOpacity?: number;
  borderRadius?: [number, number, number, number];
  autoLayout?: boolean;
  absolutePosition?: Three.Vector2;
  borderColor?: string;
  borderWidth?: number;
  borderOpacity?: number;
  padding?: number;
  visible?: boolean;
  id?: string;
  margin?: number;
  inline?: boolean;
  backgroundImage?: string;
  interactive?: boolean;
  textAlign?: string;
  whiteSpace?: string;
  fontKerning?: string;
  backgroundSize?: string;
  offset?: number;
  hiddenOverflow?: boolean;
  interLine?: number;
  name?: string;
};

export function Container(params: ContainerParams, children: (ThreeMeshUI.Block | null)[]): ThreeMeshUI.Block {
  const block = params.inline ? ThreeMeshUI.InlineBlock : ThreeMeshUI.Block;
  const container = new block({
    width: params.width,
    height: params.height,
    fontFamily: params.fontName,
    fontTexture: params.fontName,
    justifyContent: params.justifyContent,
    contentDirection: params.contentDirection,
    backgroundOpacity: params.backgroundOpacity ?? 0,
    backgroundColor: params.backgroundColor ? normalizeColor(params.backgroundColor) : undefined,
    offset: params.offset ?? 0.1,
    borderRadius: params.borderRadius || [0, 0, 0, 0],
    borderColor: params.borderColor ? normalizeColor(params.borderColor) : undefined,
    borderWidth: params.borderWidth,
    borderOpacity: params.borderOpacity,
    padding: params.padding,
    margin: params.margin,
    textAlign: params.textAlign,
    whiteSpace: params.whiteSpace,
    fontKerning: params.fontKerning,
    backgroundSize: params.backgroundSize,
    hiddenOverflow: params.hiddenOverflow,
    interLine: params.interLine,
  });

  container.name = params.name ?? params.id ?? '';

  container.visible = params.visible ?? true;

  if (params.id) {
    container.userData.uiData = {
      id: params.id,
      interactive: params.interactive,
    };
  }

  if (params.interactive && params.id) {
    addInteractivityToElement({
      id: params.id,
      element: container,
      defaultAttributes: {},
      hoveredAttributes: {},
      activeAttributes: {},
    });
  }

  if (params.backgroundImage) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new Three.TextureLoader().load(params.backgroundImage, (texture) => container.set({ backgroundTexture: texture }));
  }

  // todo: missing types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  container.autoLayout = params.autoLayout ?? true;

  if (children.length) container.add(...children.filter((ch) => ch) as ThreeMeshUI.Block[]);

  if (params.absolutePosition) {
    // todo: missing types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    container.autoLayout = false;
    container.addEventListener('added', () => {
      if (container.parent) setAbsolutePosition(container.parent, container, params.absolutePosition);
    });
  }

  return container;
}
