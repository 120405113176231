import * as ThreeMeshUI from 'three-mesh-ui';
import { MainMenuAdaptive } from '../../adaptive';
import { PlaceMenuItem, PlaceMenuItemParams } from './PlaceMenuItem';
import { Container } from '../Container';

export type PlaceMenuRowParams = {
  id: number;
  adaptive: MainMenuAdaptive;
  items: PlaceMenuItemParams[];
  padding?: number | undefined;
};
export function PlaceMenuRow({ adaptive, id, items, padding }: PlaceMenuRowParams): ThreeMeshUI.Block {
  return Container({
    id: `PlaceMenuColumn_${id}`,
    width: adaptive.width - adaptive.padding * 2 - 20,
    height: adaptive.placeElementHeight + (padding !== undefined ? padding : (adaptive.padding / 4)),
    contentDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'start',
  }, items.map((item) => PlaceMenuItem(item)));
}
