import { IconButton } from '../IconButton';
import { addStatesToIconElement } from '../../helpers/addStatesToIconElement';

export type IconButtonParams = {
  id: string;
  imageUrl: string;
  altImageUrl?: string;
  disableImg?: string;
  width?: number;
  height?: number;
};

export function MainButton(params: IconButtonParams) {
  const block = IconButton({
    id: params.id,
    width: params.width ?? 64,
    height: params.height ?? 64,
    imageUrl: params.imageUrl,
    color: '#171A30',
    opacity: 0.9,
    hoverColor: '#171A30',
    hoverOpacity: 0.3,
    activeColor: '#4145A7',
  });

  addStatesToIconElement(block, {
    imageUrl: params.imageUrl,
    altImg: params.altImageUrl,
    disableImg: params.disableImg,
    altState: 'alt',
  });

  return block;
}
