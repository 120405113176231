import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

export const ChatSound: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 19"
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Volume up"
      >
        <path
          id="Union (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 3.37061C12.25 2.3004 10.9927 1.72507 10.1828 2.42468L6.85346 5.30077C6.35394 5.73229 5.71587 5.96972 5.05577 5.96973L3.49999 5.96974C2.53349 5.96975 1.75 6.75325 1.75 7.71974V11.0127C1.75 11.9792 2.5335 12.7627 3.5 12.7627H5.0558C5.7159 12.7627 6.35398 13.0002 6.85351 13.4317L10.1828 16.3077C10.9927 17.0073 12.25 16.432 12.25 15.3618V3.37061ZM9.20228 1.28956C10.984 -0.249575 13.75 1.01615 13.75 3.37061V15.3618C13.75 17.7163 10.984 18.982 9.20228 17.4428L5.87294 14.5668C5.64588 14.3706 5.35584 14.2627 5.0558 14.2627H3.5C1.70507 14.2627 0.25 12.8076 0.25 11.0127V7.71974C0.25 5.92483 1.70506 4.46976 3.49998 4.46974L5.05576 4.46973C5.3558 4.46973 5.64583 4.3618 5.87289 4.16566L9.20228 1.28956Z"
          // fill="#111111"
        />
        <path
          id="Rectangle 2 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5495 5.7671C15.8807 5.51828 16.3508 5.58501 16.5996 5.91616C17.3218 6.87724 17.75 8.07304 17.75 9.3667C17.75 10.6604 17.3218 11.8562 16.5996 12.8172C16.3508 13.1484 15.8807 13.2151 15.5495 12.9663C15.2184 12.7175 15.1516 12.2473 15.4004 11.9162C15.9341 11.2059 16.25 10.3243 16.25 9.3667C16.25 8.40914 15.9341 7.52748 15.4004 6.81724C15.1516 6.48609 15.2184 6.01593 15.5495 5.7671Z"
          // fill="#111111"
        />
        <path
          id="Rectangle 3 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5495 2.7671C17.8807 2.51828 18.3508 2.58501 18.5996 2.91616C19.9497 4.7129 20.75 6.94744 20.75 9.3667C20.75 11.786 19.9497 14.0205 18.5996 15.8172C18.3508 16.1484 17.8807 16.2151 17.5495 15.9663C17.2184 15.7175 17.1516 15.2473 17.4004 14.9162C18.562 13.3703 19.25 11.4499 19.25 9.3667C19.25 7.28353 18.562 5.36314 17.4004 3.81724C17.1516 3.48609 17.2184 3.01593 17.5495 2.7671Z"
          // fill="#111111"
        />
      </g>
    </svg>
  );
});

export default ChatSound;
