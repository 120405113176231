import { AnimatedObjectsConfig } from './types';

export const defaultAnimatedObjectsConfig: AnimatedObjectsConfig = {
  enabled: true,
  animateAll: true,
  defaultSettings: {
    resetPosition: false,
    visible: false,
  },
  objects: [{
    name: 'logo_arm',
    resetPosition: true,
    visible: false,
    loader: true,
  }],
};
