import {
  avatarsIconBasePath,
} from '../DefaultAvatars';

export const extendedDefaultAvatars = {
  yuki: {
    model: 'yuki',
    animations: 'yuki',
  },
  yuki_hacker: {
    model: 'yuki_hacker',
    animations: 'yuki',
  },
  mira: {
    model: 'mira',
    animations: 'mira',
  },
  mira_space: {
    model: 'mira_space',
    animations: 'mira',
  },
  base_male: {
    model: 'base_male',
    animations: 'yuki',
  },
  base_female: {
    model: 'base_female',
    animations: 'mira',
  },
  uno_base_male: {
    model: 'uno_base_male',
    animations: 'yuki',
  },
  uno_base_female: {
    model: 'uno_base_female',
    animations: 'mira',
  },
};

export const extendedDefaultMenuItems = [
  { id: 'Avatar_yuki', imgUrl: `${avatarsIconBasePath}/yuki.png` },
  { id: 'Avatar_yuki_hacker', imgUrl: `${avatarsIconBasePath}/yuki_hacker.png` },
  { id: 'Avatar_mira', imgUrl: `${avatarsIconBasePath}/mira.png` },
  { id: 'Avatar_mira_space', imgUrl: `${avatarsIconBasePath}/mira_space.png` },
  { id: 'Avatar_base_male', imgUrl: `${avatarsIconBasePath}/male_base.png` },
  { id: 'Avatar_base_female', imgUrl: `${avatarsIconBasePath}/female_base.png` },
  { id: 'Avatar_uno_base_male', imgUrl: `${avatarsIconBasePath}/male_base.png` },
  { id: 'Avatar_uno_base_female', imgUrl: `${avatarsIconBasePath}/female_base.png` },
];

export const extendedDefaultAvatarModels = {
  yuki: 'assets/avatars/VRM/yuki.vrm',
  yuki_hacker: 'assets/avatars/VRM/yuki_hacker.vrm',
  mira: 'assets/avatars/VRM/mira.vrm',
  mira_space: 'assets/avatars/VRM/mira_space.vrm',
  base_male: 'assets/avatars/VRM/male_base_vrm.vrm',
  base_female: 'assets/avatars/VRM/female_base_vrm.vrm',
  uno_base_male: 'assets/avatars/VRM/uno_male_base_vrm.vrm',
  uno_base_female: 'assets/avatars/VRM/uno_female_base_vrm.vrm',
};
