import { Button } from '../Button';
import * as Theme from '../../Theme';

export type GreenButtonParams = {
  width?: number;
  height?: number;
  titleWidth?: number;
  id: string;
  title: string;
};

export function GreenButton(params: GreenButtonParams) {
  const width = params.width ?? 208;
  return Button({
    id: params.id,
    width: params.width ?? 208,
    titleWidth: width * 0.85,
    height: params.height ?? 48,
    title: params.title,
    fontName: Theme.fontMainBold.name,
    titleFontSize: 20,
    backgroundColor: '#D4FF00',
    hoverColor: '#E7FF72',
    activeColor: '#AACD00',
    fontColor: '#3D0F99',
    letterSpacing: -0.1,
  });
}
