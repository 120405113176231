import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { IconButton } from '../IconButton';

export type IconButtonParams = {
  id: string;
  imageUrl: string;
  altImageUrl?: string;
  color?: string;
  opacity?: number;
  visible?: boolean;
  selected?: boolean;
  width?: number;
  height?: number;
};

function loadTexture(url: string) {
  return new Promise((resolve) => {
    new Three.TextureLoader().load(url, (texture) => {
      resolve(texture);
    });
  });
}

export function ActionButton(params: IconButtonParams) {
  const button = IconButton({
    id: params.id,
    width: params.width ?? 64,
    height: params.height ?? 64,
    iconWidth: 32,
    iconHeight: 32,
    imageUrl: params.imageUrl,
    color: params.color ?? '#FFFFFF',
    opacity: params.opacity ?? 0.7,
    hoverColor: '#FFFFFF',
    hoverOpacity: 0.3,
    activeColor: '#5156D1',
    selected: params.selected,
    borderColor: '#171A30',
    borderWidth: 1,
  });
  if (typeof params.visible !== 'undefined') {
    button.visible = params.visible;
  }

  const iconElement = button.children.find((child) => child instanceof ThreeMeshUI.Block);
  if (params.altImageUrl && iconElement) {
    button.userData.uiElements = {
      icon: iconElement,
    };
    Promise.all([
      loadTexture(params.imageUrl),
      loadTexture(params.altImageUrl),
    ]).then(([defaultTexture, altTexture]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iconElement.setupState({
        state: 'default',
        attributes: { backgroundTexture: defaultTexture },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iconElement.setupState({
        state: 'alt',
        attributes: { backgroundTexture: altTexture },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      iconElement.setState('default');
    });
  }
  return button;
}
