import * as ThreeMeshUI from 'three-mesh-ui';
import { Container } from '../Container';
import * as Theme from '../../Theme';

export type TabContentParams = {
  id: string;
  width?: number;
  height?: number;
  contentDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  hiddenOverflow?: boolean;
  interactive?: boolean;
};
export function TabContent(params: TabContentParams, children: ThreeMeshUI.Block[]) {
  return Container({
    id: params.id,
    interactive: params.interactive,
    width: params.width ?? 562,
    height: params.height ?? 224,
    contentDirection: params.contentDirection ?? 'column',
    justifyContent: params.justifyContent ?? 'space-between',
    backgroundColor: '#171A30',
    backgroundOpacity: 0.7,
    borderRadius: Theme.bgBorderRadius,
    margin: 10,
    padding: 10,
    hiddenOverflow: params.hiddenOverflow,
  }, children);
}
