/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.engine = (function() {

    /**
     * Namespace engine.
     * @exports engine
     * @namespace
     */
    var engine = {};

    engine.network = (function() {

        /**
         * Namespace network.
         * @memberof engine
         * @namespace
         */
        var network = {};

        network.messages = (function() {

            /**
             * Namespace messages.
             * @memberof engine.network
             * @namespace
             */
            var messages = {};

            messages.MessagesData = (function() {

                /**
                 * Properties of a MessagesData.
                 * @memberof engine.network.messages
                 * @interface IMessagesData
                 * @property {Array.<engine.network.messages.IMessageData>|null} [messages] MessagesData messages
                 */

                /**
                 * Constructs a new MessagesData.
                 * @memberof engine.network.messages
                 * @classdesc Represents a MessagesData.
                 * @implements IMessagesData
                 * @constructor
                 * @param {engine.network.messages.IMessagesData=} [properties] Properties to set
                 */
                function MessagesData(properties) {
                    this.messages = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MessagesData messages.
                 * @member {Array.<engine.network.messages.IMessageData>} messages
                 * @memberof engine.network.messages.MessagesData
                 * @instance
                 */
                MessagesData.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new MessagesData instance using the specified properties.
                 * @function create
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {engine.network.messages.IMessagesData=} [properties] Properties to set
                 * @returns {engine.network.messages.MessagesData} MessagesData instance
                 */
                MessagesData.create = function create(properties) {
                    return new MessagesData(properties);
                };

                /**
                 * Encodes the specified MessagesData message. Does not implicitly {@link engine.network.messages.MessagesData.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {engine.network.messages.IMessagesData} message MessagesData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessagesData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messages != null && message.messages.length)
                        for (var i = 0; i < message.messages.length; ++i)
                            $root.engine.network.messages.MessageData.encode(message.messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MessagesData message, length delimited. Does not implicitly {@link engine.network.messages.MessagesData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {engine.network.messages.IMessagesData} message MessagesData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessagesData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MessagesData message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.messages.MessagesData} MessagesData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessagesData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.messages.MessagesData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push($root.engine.network.messages.MessageData.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MessagesData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.messages.MessagesData} MessagesData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessagesData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MessagesData message.
                 * @function verify
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessagesData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (var i = 0; i < message.messages.length; ++i) {
                            var error = $root.engine.network.messages.MessageData.verify(message.messages[i]);
                            if (error)
                                return "messages." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MessagesData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.messages.MessagesData} MessagesData
                 */
                MessagesData.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.messages.MessagesData)
                        return object;
                    var message = new $root.engine.network.messages.MessagesData();
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".engine.network.messages.MessagesData.messages: array expected");
                        message.messages = [];
                        for (var i = 0; i < object.messages.length; ++i) {
                            if (typeof object.messages[i] !== "object")
                                throw TypeError(".engine.network.messages.MessagesData.messages: object expected");
                            message.messages[i] = $root.engine.network.messages.MessageData.fromObject(object.messages[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MessagesData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {engine.network.messages.MessagesData} message MessagesData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessagesData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (var j = 0; j < message.messages.length; ++j)
                            object.messages[j] = $root.engine.network.messages.MessageData.toObject(message.messages[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this MessagesData to JSON.
                 * @function toJSON
                 * @memberof engine.network.messages.MessagesData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessagesData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MessagesData
                 * @function getTypeUrl
                 * @memberof engine.network.messages.MessagesData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MessagesData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.messages.MessagesData";
                };

                return MessagesData;
            })();

            messages.MessageData = (function() {

                /**
                 * Properties of a MessageData.
                 * @memberof engine.network.messages
                 * @interface IMessageData
                 * @property {google.protobuf.IAny|null} [payload] MessageData payload
                 */

                /**
                 * Constructs a new MessageData.
                 * @memberof engine.network.messages
                 * @classdesc Represents a MessageData.
                 * @implements IMessageData
                 * @constructor
                 * @param {engine.network.messages.IMessageData=} [properties] Properties to set
                 */
                function MessageData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MessageData payload.
                 * @member {google.protobuf.IAny|null|undefined} payload
                 * @memberof engine.network.messages.MessageData
                 * @instance
                 */
                MessageData.prototype.payload = null;

                /**
                 * Creates a new MessageData instance using the specified properties.
                 * @function create
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {engine.network.messages.IMessageData=} [properties] Properties to set
                 * @returns {engine.network.messages.MessageData} MessageData instance
                 */
                MessageData.create = function create(properties) {
                    return new MessageData(properties);
                };

                /**
                 * Encodes the specified MessageData message. Does not implicitly {@link engine.network.messages.MessageData.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {engine.network.messages.IMessageData} message MessageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                        $root.google.protobuf.Any.encode(message.payload, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MessageData message, length delimited. Does not implicitly {@link engine.network.messages.MessageData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {engine.network.messages.IMessageData} message MessageData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MessageData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MessageData message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.messages.MessageData} MessageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.messages.MessageData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.payload = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MessageData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.messages.MessageData} MessageData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MessageData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MessageData message.
                 * @function verify
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MessageData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.google.protobuf.Any.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    return null;
                };

                /**
                 * Creates a MessageData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.messages.MessageData} MessageData
                 */
                MessageData.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.messages.MessageData)
                        return object;
                    var message = new $root.engine.network.messages.MessageData();
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".engine.network.messages.MessageData.payload: object expected");
                        message.payload = $root.google.protobuf.Any.fromObject(object.payload);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MessageData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {engine.network.messages.MessageData} message MessageData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MessageData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.payload = null;
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.google.protobuf.Any.toObject(message.payload, options);
                    return object;
                };

                /**
                 * Converts this MessageData to JSON.
                 * @function toJSON
                 * @memberof engine.network.messages.MessageData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MessageData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MessageData
                 * @function getTypeUrl
                 * @memberof engine.network.messages.MessageData
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MessageData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.messages.MessageData";
                };

                return MessageData;
            })();

            messages.UserIdMessage = (function() {

                /**
                 * Properties of a UserIdMessage.
                 * @memberof engine.network.messages
                 * @interface IUserIdMessage
                 * @property {number|null} [id] UserIdMessage id
                 */

                /**
                 * Constructs a new UserIdMessage.
                 * @memberof engine.network.messages
                 * @classdesc Represents a UserIdMessage.
                 * @implements IUserIdMessage
                 * @constructor
                 * @param {engine.network.messages.IUserIdMessage=} [properties] Properties to set
                 */
                function UserIdMessage(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserIdMessage id.
                 * @member {number} id
                 * @memberof engine.network.messages.UserIdMessage
                 * @instance
                 */
                UserIdMessage.prototype.id = 0;

                /**
                 * Creates a new UserIdMessage instance using the specified properties.
                 * @function create
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {engine.network.messages.IUserIdMessage=} [properties] Properties to set
                 * @returns {engine.network.messages.UserIdMessage} UserIdMessage instance
                 */
                UserIdMessage.create = function create(properties) {
                    return new UserIdMessage(properties);
                };

                /**
                 * Encodes the specified UserIdMessage message. Does not implicitly {@link engine.network.messages.UserIdMessage.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {engine.network.messages.IUserIdMessage} message UserIdMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserIdMessage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified UserIdMessage message, length delimited. Does not implicitly {@link engine.network.messages.UserIdMessage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {engine.network.messages.IUserIdMessage} message UserIdMessage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserIdMessage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserIdMessage message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.messages.UserIdMessage} UserIdMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserIdMessage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.messages.UserIdMessage();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserIdMessage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.messages.UserIdMessage} UserIdMessage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserIdMessage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserIdMessage message.
                 * @function verify
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserIdMessage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    return null;
                };

                /**
                 * Creates a UserIdMessage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.messages.UserIdMessage} UserIdMessage
                 */
                UserIdMessage.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.messages.UserIdMessage)
                        return object;
                    var message = new $root.engine.network.messages.UserIdMessage();
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a UserIdMessage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {engine.network.messages.UserIdMessage} message UserIdMessage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserIdMessage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.id = 0;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this UserIdMessage to JSON.
                 * @function toJSON
                 * @memberof engine.network.messages.UserIdMessage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserIdMessage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserIdMessage
                 * @function getTypeUrl
                 * @memberof engine.network.messages.UserIdMessage
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserIdMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.messages.UserIdMessage";
                };

                return UserIdMessage;
            })();

            return messages;
        })();

        network.sessionStore = (function() {

            /**
             * Namespace sessionStore.
             * @memberof engine.network
             * @namespace
             */
            var sessionStore = {};

            sessionStore.payloads = (function() {

                /**
                 * Namespace payloads.
                 * @memberof engine.network.sessionStore
                 * @namespace
                 */
                var payloads = {};

                payloads.SessionUsersPayload = (function() {

                    /**
                     * Properties of a SessionUsersPayload.
                     * @memberof engine.network.sessionStore.payloads
                     * @interface ISessionUsersPayload
                     * @property {Array.<engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload>|null} [users] SessionUsersPayload users
                     */

                    /**
                     * Constructs a new SessionUsersPayload.
                     * @memberof engine.network.sessionStore.payloads
                     * @classdesc Represents a SessionUsersPayload.
                     * @implements ISessionUsersPayload
                     * @constructor
                     * @param {engine.network.sessionStore.payloads.ISessionUsersPayload=} [properties] Properties to set
                     */
                    function SessionUsersPayload(properties) {
                        this.users = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SessionUsersPayload users.
                     * @member {Array.<engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload>} users
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @instance
                     */
                    SessionUsersPayload.prototype.users = $util.emptyArray;

                    /**
                     * Creates a new SessionUsersPayload instance using the specified properties.
                     * @function create
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionUsersPayload=} [properties] Properties to set
                     * @returns {engine.network.sessionStore.payloads.SessionUsersPayload} SessionUsersPayload instance
                     */
                    SessionUsersPayload.create = function create(properties) {
                        return new SessionUsersPayload(properties);
                    };

                    /**
                     * Encodes the specified SessionUsersPayload message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionUsersPayload.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionUsersPayload} message SessionUsersPayload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionUsersPayload.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.users != null && message.users.length)
                            for (var i = 0; i < message.users.length; ++i)
                                $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.encode(message.users[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionUsersPayload message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionUsersPayload.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionUsersPayload} message SessionUsersPayload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionUsersPayload.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionUsersPayload message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.sessionStore.payloads.SessionUsersPayload} SessionUsersPayload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionUsersPayload.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionUsersPayload();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.users && message.users.length))
                                        message.users = [];
                                    message.users.push($root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.decode(reader, reader.uint32()));
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionUsersPayload message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.sessionStore.payloads.SessionUsersPayload} SessionUsersPayload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionUsersPayload.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionUsersPayload message.
                     * @function verify
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionUsersPayload.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.users != null && message.hasOwnProperty("users")) {
                            if (!Array.isArray(message.users))
                                return "users: array expected";
                            for (var i = 0; i < message.users.length; ++i) {
                                var error = $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.verify(message.users[i]);
                                if (error)
                                    return "users." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SessionUsersPayload message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.sessionStore.payloads.SessionUsersPayload} SessionUsersPayload
                     */
                    SessionUsersPayload.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.sessionStore.payloads.SessionUsersPayload)
                            return object;
                        var message = new $root.engine.network.sessionStore.payloads.SessionUsersPayload();
                        if (object.users) {
                            if (!Array.isArray(object.users))
                                throw TypeError(".engine.network.sessionStore.payloads.SessionUsersPayload.users: array expected");
                            message.users = [];
                            for (var i = 0; i < object.users.length; ++i) {
                                if (typeof object.users[i] !== "object")
                                    throw TypeError(".engine.network.sessionStore.payloads.SessionUsersPayload.users: object expected");
                                message.users[i] = $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.fromObject(object.users[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SessionUsersPayload message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.SessionUsersPayload} message SessionUsersPayload
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionUsersPayload.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.users = [];
                        if (message.users && message.users.length) {
                            object.users = [];
                            for (var j = 0; j < message.users.length; ++j)
                                object.users[j] = $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.toObject(message.users[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SessionUsersPayload to JSON.
                     * @function toJSON
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionUsersPayload.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for SessionUsersPayload
                     * @function getTypeUrl
                     * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    SessionUsersPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionUsersPayload";
                    };

                    SessionUsersPayload.SessionUserPayload = (function() {

                        /**
                         * Properties of a SessionUserPayload.
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                         * @interface ISessionUserPayload
                         * @property {number|null} [id] SessionUserPayload id
                         * @property {string|null} [name] SessionUserPayload name
                         * @property {number|null} [activeRoomId] SessionUserPayload activeRoomId
                         * @property {string|null} [avatarName] SessionUserPayload avatarName
                         */

                        /**
                         * Constructs a new SessionUserPayload.
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload
                         * @classdesc Represents a SessionUserPayload.
                         * @implements ISessionUserPayload
                         * @constructor
                         * @param {engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload=} [properties] Properties to set
                         */
                        function SessionUserPayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SessionUserPayload id.
                         * @member {number} id
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @instance
                         */
                        SessionUserPayload.prototype.id = 0;

                        /**
                         * SessionUserPayload name.
                         * @member {string} name
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @instance
                         */
                        SessionUserPayload.prototype.name = "";

                        /**
                         * SessionUserPayload activeRoomId.
                         * @member {number} activeRoomId
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @instance
                         */
                        SessionUserPayload.prototype.activeRoomId = 0;

                        /**
                         * SessionUserPayload avatarName.
                         * @member {string} avatarName
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @instance
                         */
                        SessionUserPayload.prototype.avatarName = "";

                        /**
                         * Creates a new SessionUserPayload instance using the specified properties.
                         * @function create
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload=} [properties] Properties to set
                         * @returns {engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload} SessionUserPayload instance
                         */
                        SessionUserPayload.create = function create(properties) {
                            return new SessionUserPayload(properties);
                        };

                        /**
                         * Encodes the specified SessionUserPayload message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.verify|verify} messages.
                         * @function encode
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload} message SessionUserPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionUserPayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                            if (message.activeRoomId != null && Object.hasOwnProperty.call(message, "activeRoomId"))
                                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.activeRoomId);
                            if (message.avatarName != null && Object.hasOwnProperty.call(message, "avatarName"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.avatarName);
                            return writer;
                        };

                        /**
                         * Encodes the specified SessionUserPayload message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionUsersPayload.ISessionUserPayload} message SessionUserPayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionUserPayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SessionUserPayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload} SessionUserPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionUserPayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.id = reader.uint32();
                                        break;
                                    }
                                case 2: {
                                        message.name = reader.string();
                                        break;
                                    }
                                case 3: {
                                        message.activeRoomId = reader.uint32();
                                        break;
                                    }
                                case 4: {
                                        message.avatarName = reader.string();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SessionUserPayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload} SessionUserPayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionUserPayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SessionUserPayload message.
                         * @function verify
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SessionUserPayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isInteger(message.id))
                                    return "id: integer expected";
                            if (message.name != null && message.hasOwnProperty("name"))
                                if (!$util.isString(message.name))
                                    return "name: string expected";
                            if (message.activeRoomId != null && message.hasOwnProperty("activeRoomId"))
                                if (!$util.isInteger(message.activeRoomId))
                                    return "activeRoomId: integer expected";
                            if (message.avatarName != null && message.hasOwnProperty("avatarName"))
                                if (!$util.isString(message.avatarName))
                                    return "avatarName: string expected";
                            return null;
                        };

                        /**
                         * Creates a SessionUserPayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload} SessionUserPayload
                         */
                        SessionUserPayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload)
                                return object;
                            var message = new $root.engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload();
                            if (object.id != null)
                                message.id = object.id >>> 0;
                            if (object.name != null)
                                message.name = String(object.name);
                            if (object.activeRoomId != null)
                                message.activeRoomId = object.activeRoomId >>> 0;
                            if (object.avatarName != null)
                                message.avatarName = String(object.avatarName);
                            return message;
                        };

                        /**
                         * Creates a plain object from a SessionUserPayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload} message SessionUserPayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SessionUserPayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = 0;
                                object.name = "";
                                object.activeRoomId = 0;
                                object.avatarName = "";
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.name != null && message.hasOwnProperty("name"))
                                object.name = message.name;
                            if (message.activeRoomId != null && message.hasOwnProperty("activeRoomId"))
                                object.activeRoomId = message.activeRoomId;
                            if (message.avatarName != null && message.hasOwnProperty("avatarName"))
                                object.avatarName = message.avatarName;
                            return object;
                        };

                        /**
                         * Converts this SessionUserPayload to JSON.
                         * @function toJSON
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SessionUserPayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for SessionUserPayload
                         * @function getTypeUrl
                         * @memberof engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        SessionUserPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionUsersPayload.SessionUserPayload";
                        };

                        return SessionUserPayload;
                    })();

                    return SessionUsersPayload;
                })();

                payloads.SessionSpacesPayload = (function() {

                    /**
                     * Properties of a SessionSpacesPayload.
                     * @memberof engine.network.sessionStore.payloads
                     * @interface ISessionSpacesPayload
                     * @property {Array.<engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload>|null} [spaces] SessionSpacesPayload spaces
                     */

                    /**
                     * Constructs a new SessionSpacesPayload.
                     * @memberof engine.network.sessionStore.payloads
                     * @classdesc Represents a SessionSpacesPayload.
                     * @implements ISessionSpacesPayload
                     * @constructor
                     * @param {engine.network.sessionStore.payloads.ISessionSpacesPayload=} [properties] Properties to set
                     */
                    function SessionSpacesPayload(properties) {
                        this.spaces = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SessionSpacesPayload spaces.
                     * @member {Array.<engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload>} spaces
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @instance
                     */
                    SessionSpacesPayload.prototype.spaces = $util.emptyArray;

                    /**
                     * Creates a new SessionSpacesPayload instance using the specified properties.
                     * @function create
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionSpacesPayload=} [properties] Properties to set
                     * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload} SessionSpacesPayload instance
                     */
                    SessionSpacesPayload.create = function create(properties) {
                        return new SessionSpacesPayload(properties);
                    };

                    /**
                     * Encodes the specified SessionSpacesPayload message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionSpacesPayload.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionSpacesPayload} message SessionSpacesPayload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionSpacesPayload.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.spaces != null && message.spaces.length)
                            for (var i = 0; i < message.spaces.length; ++i)
                                $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.encode(message.spaces[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionSpacesPayload message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionSpacesPayload.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionSpacesPayload} message SessionSpacesPayload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionSpacesPayload.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionSpacesPayload message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload} SessionSpacesPayload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionSpacesPayload.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionSpacesPayload();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    if (!(message.spaces && message.spaces.length))
                                        message.spaces = [];
                                    message.spaces.push($root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.decode(reader, reader.uint32()));
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionSpacesPayload message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload} SessionSpacesPayload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionSpacesPayload.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionSpacesPayload message.
                     * @function verify
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionSpacesPayload.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.spaces != null && message.hasOwnProperty("spaces")) {
                            if (!Array.isArray(message.spaces))
                                return "spaces: array expected";
                            for (var i = 0; i < message.spaces.length; ++i) {
                                var error = $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.verify(message.spaces[i]);
                                if (error)
                                    return "spaces." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SessionSpacesPayload message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload} SessionSpacesPayload
                     */
                    SessionSpacesPayload.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.sessionStore.payloads.SessionSpacesPayload)
                            return object;
                        var message = new $root.engine.network.sessionStore.payloads.SessionSpacesPayload();
                        if (object.spaces) {
                            if (!Array.isArray(object.spaces))
                                throw TypeError(".engine.network.sessionStore.payloads.SessionSpacesPayload.spaces: array expected");
                            message.spaces = [];
                            for (var i = 0; i < object.spaces.length; ++i) {
                                if (typeof object.spaces[i] !== "object")
                                    throw TypeError(".engine.network.sessionStore.payloads.SessionSpacesPayload.spaces: object expected");
                                message.spaces[i] = $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.fromObject(object.spaces[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SessionSpacesPayload message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {engine.network.sessionStore.payloads.SessionSpacesPayload} message SessionSpacesPayload
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionSpacesPayload.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.spaces = [];
                        if (message.spaces && message.spaces.length) {
                            object.spaces = [];
                            for (var j = 0; j < message.spaces.length; ++j)
                                object.spaces[j] = $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.toObject(message.spaces[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SessionSpacesPayload to JSON.
                     * @function toJSON
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionSpacesPayload.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for SessionSpacesPayload
                     * @function getTypeUrl
                     * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    SessionSpacesPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionSpacesPayload";
                    };

                    SessionSpacesPayload.SessionSpacePayload = (function() {

                        /**
                         * Properties of a SessionSpacePayload.
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                         * @interface ISessionSpacePayload
                         * @property {string|null} [spaceName] SessionSpacePayload spaceName
                         * @property {number|null} [roomId] SessionSpacePayload roomId
                         */

                        /**
                         * Constructs a new SessionSpacePayload.
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload
                         * @classdesc Represents a SessionSpacePayload.
                         * @implements ISessionSpacePayload
                         * @constructor
                         * @param {engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload=} [properties] Properties to set
                         */
                        function SessionSpacePayload(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SessionSpacePayload spaceName.
                         * @member {string} spaceName
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @instance
                         */
                        SessionSpacePayload.prototype.spaceName = "";

                        /**
                         * SessionSpacePayload roomId.
                         * @member {number} roomId
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @instance
                         */
                        SessionSpacePayload.prototype.roomId = 0;

                        /**
                         * Creates a new SessionSpacePayload instance using the specified properties.
                         * @function create
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload=} [properties] Properties to set
                         * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload} SessionSpacePayload instance
                         */
                        SessionSpacePayload.create = function create(properties) {
                            return new SessionSpacePayload(properties);
                        };

                        /**
                         * Encodes the specified SessionSpacePayload message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.verify|verify} messages.
                         * @function encode
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload} message SessionSpacePayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionSpacePayload.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.spaceName != null && Object.hasOwnProperty.call(message, "spaceName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.spaceName);
                            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.roomId);
                            return writer;
                        };

                        /**
                         * Encodes the specified SessionSpacePayload message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionSpacesPayload.ISessionSpacePayload} message SessionSpacePayload message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        SessionSpacePayload.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a SessionSpacePayload message from the specified reader or buffer.
                         * @function decode
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload} SessionSpacePayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionSpacePayload.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.spaceName = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.roomId = reader.uint32();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a SessionSpacePayload message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload} SessionSpacePayload
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        SessionSpacePayload.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a SessionSpacePayload message.
                         * @function verify
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        SessionSpacePayload.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.spaceName != null && message.hasOwnProperty("spaceName"))
                                if (!$util.isString(message.spaceName))
                                    return "spaceName: string expected";
                            if (message.roomId != null && message.hasOwnProperty("roomId"))
                                if (!$util.isInteger(message.roomId))
                                    return "roomId: integer expected";
                            return null;
                        };

                        /**
                         * Creates a SessionSpacePayload message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload} SessionSpacePayload
                         */
                        SessionSpacePayload.fromObject = function fromObject(object) {
                            if (object instanceof $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload)
                                return object;
                            var message = new $root.engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload();
                            if (object.spaceName != null)
                                message.spaceName = String(object.spaceName);
                            if (object.roomId != null)
                                message.roomId = object.roomId >>> 0;
                            return message;
                        };

                        /**
                         * Creates a plain object from a SessionSpacePayload message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload} message SessionSpacePayload
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        SessionSpacePayload.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.spaceName = "";
                                object.roomId = 0;
                            }
                            if (message.spaceName != null && message.hasOwnProperty("spaceName"))
                                object.spaceName = message.spaceName;
                            if (message.roomId != null && message.hasOwnProperty("roomId"))
                                object.roomId = message.roomId;
                            return object;
                        };

                        /**
                         * Converts this SessionSpacePayload to JSON.
                         * @function toJSON
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        SessionSpacePayload.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for SessionSpacePayload
                         * @function getTypeUrl
                         * @memberof engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        SessionSpacePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionSpacesPayload.SessionSpacePayload";
                        };

                        return SessionSpacePayload;
                    })();

                    return SessionSpacesPayload;
                })();

                payloads.SessionRequestSpaceEvent = (function() {

                    /**
                     * Properties of a SessionRequestSpaceEvent.
                     * @memberof engine.network.sessionStore.payloads
                     * @interface ISessionRequestSpaceEvent
                     * @property {string|null} [spaceName] SessionRequestSpaceEvent spaceName
                     */

                    /**
                     * Constructs a new SessionRequestSpaceEvent.
                     * @memberof engine.network.sessionStore.payloads
                     * @classdesc Represents a SessionRequestSpaceEvent.
                     * @implements ISessionRequestSpaceEvent
                     * @constructor
                     * @param {engine.network.sessionStore.payloads.ISessionRequestSpaceEvent=} [properties] Properties to set
                     */
                    function SessionRequestSpaceEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SessionRequestSpaceEvent spaceName.
                     * @member {string} spaceName
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @instance
                     */
                    SessionRequestSpaceEvent.prototype.spaceName = "";

                    /**
                     * Creates a new SessionRequestSpaceEvent instance using the specified properties.
                     * @function create
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionRequestSpaceEvent=} [properties] Properties to set
                     * @returns {engine.network.sessionStore.payloads.SessionRequestSpaceEvent} SessionRequestSpaceEvent instance
                     */
                    SessionRequestSpaceEvent.create = function create(properties) {
                        return new SessionRequestSpaceEvent(properties);
                    };

                    /**
                     * Encodes the specified SessionRequestSpaceEvent message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionRequestSpaceEvent.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionRequestSpaceEvent} message SessionRequestSpaceEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionRequestSpaceEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.spaceName != null && Object.hasOwnProperty.call(message, "spaceName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.spaceName);
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionRequestSpaceEvent message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionRequestSpaceEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionRequestSpaceEvent} message SessionRequestSpaceEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionRequestSpaceEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionRequestSpaceEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.sessionStore.payloads.SessionRequestSpaceEvent} SessionRequestSpaceEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionRequestSpaceEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionRequestSpaceEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.spaceName = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionRequestSpaceEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.sessionStore.payloads.SessionRequestSpaceEvent} SessionRequestSpaceEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionRequestSpaceEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionRequestSpaceEvent message.
                     * @function verify
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionRequestSpaceEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.spaceName != null && message.hasOwnProperty("spaceName"))
                            if (!$util.isString(message.spaceName))
                                return "spaceName: string expected";
                        return null;
                    };

                    /**
                     * Creates a SessionRequestSpaceEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.sessionStore.payloads.SessionRequestSpaceEvent} SessionRequestSpaceEvent
                     */
                    SessionRequestSpaceEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.sessionStore.payloads.SessionRequestSpaceEvent)
                            return object;
                        var message = new $root.engine.network.sessionStore.payloads.SessionRequestSpaceEvent();
                        if (object.spaceName != null)
                            message.spaceName = String(object.spaceName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SessionRequestSpaceEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.SessionRequestSpaceEvent} message SessionRequestSpaceEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionRequestSpaceEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.spaceName = "";
                        if (message.spaceName != null && message.hasOwnProperty("spaceName"))
                            object.spaceName = message.spaceName;
                        return object;
                    };

                    /**
                     * Converts this SessionRequestSpaceEvent to JSON.
                     * @function toJSON
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionRequestSpaceEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for SessionRequestSpaceEvent
                     * @function getTypeUrl
                     * @memberof engine.network.sessionStore.payloads.SessionRequestSpaceEvent
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    SessionRequestSpaceEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionRequestSpaceEvent";
                    };

                    return SessionRequestSpaceEvent;
                })();

                payloads.SessionResponseSpaceEvent = (function() {

                    /**
                     * Properties of a SessionResponseSpaceEvent.
                     * @memberof engine.network.sessionStore.payloads
                     * @interface ISessionResponseSpaceEvent
                     * @property {number|null} [roomId] SessionResponseSpaceEvent roomId
                     */

                    /**
                     * Constructs a new SessionResponseSpaceEvent.
                     * @memberof engine.network.sessionStore.payloads
                     * @classdesc Represents a SessionResponseSpaceEvent.
                     * @implements ISessionResponseSpaceEvent
                     * @constructor
                     * @param {engine.network.sessionStore.payloads.ISessionResponseSpaceEvent=} [properties] Properties to set
                     */
                    function SessionResponseSpaceEvent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SessionResponseSpaceEvent roomId.
                     * @member {number} roomId
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @instance
                     */
                    SessionResponseSpaceEvent.prototype.roomId = 0;

                    /**
                     * Creates a new SessionResponseSpaceEvent instance using the specified properties.
                     * @function create
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionResponseSpaceEvent=} [properties] Properties to set
                     * @returns {engine.network.sessionStore.payloads.SessionResponseSpaceEvent} SessionResponseSpaceEvent instance
                     */
                    SessionResponseSpaceEvent.create = function create(properties) {
                        return new SessionResponseSpaceEvent(properties);
                    };

                    /**
                     * Encodes the specified SessionResponseSpaceEvent message. Does not implicitly {@link engine.network.sessionStore.payloads.SessionResponseSpaceEvent.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionResponseSpaceEvent} message SessionResponseSpaceEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionResponseSpaceEvent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.roomId);
                        return writer;
                    };

                    /**
                     * Encodes the specified SessionResponseSpaceEvent message, length delimited. Does not implicitly {@link engine.network.sessionStore.payloads.SessionResponseSpaceEvent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.ISessionResponseSpaceEvent} message SessionResponseSpaceEvent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SessionResponseSpaceEvent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SessionResponseSpaceEvent message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.sessionStore.payloads.SessionResponseSpaceEvent} SessionResponseSpaceEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionResponseSpaceEvent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.sessionStore.payloads.SessionResponseSpaceEvent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.roomId = reader.uint32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SessionResponseSpaceEvent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.sessionStore.payloads.SessionResponseSpaceEvent} SessionResponseSpaceEvent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SessionResponseSpaceEvent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SessionResponseSpaceEvent message.
                     * @function verify
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SessionResponseSpaceEvent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.roomId != null && message.hasOwnProperty("roomId"))
                            if (!$util.isInteger(message.roomId))
                                return "roomId: integer expected";
                        return null;
                    };

                    /**
                     * Creates a SessionResponseSpaceEvent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.sessionStore.payloads.SessionResponseSpaceEvent} SessionResponseSpaceEvent
                     */
                    SessionResponseSpaceEvent.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.sessionStore.payloads.SessionResponseSpaceEvent)
                            return object;
                        var message = new $root.engine.network.sessionStore.payloads.SessionResponseSpaceEvent();
                        if (object.roomId != null)
                            message.roomId = object.roomId >>> 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a SessionResponseSpaceEvent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {engine.network.sessionStore.payloads.SessionResponseSpaceEvent} message SessionResponseSpaceEvent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SessionResponseSpaceEvent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.roomId = 0;
                        if (message.roomId != null && message.hasOwnProperty("roomId"))
                            object.roomId = message.roomId;
                        return object;
                    };

                    /**
                     * Converts this SessionResponseSpaceEvent to JSON.
                     * @function toJSON
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SessionResponseSpaceEvent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for SessionResponseSpaceEvent
                     * @function getTypeUrl
                     * @memberof engine.network.sessionStore.payloads.SessionResponseSpaceEvent
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    SessionResponseSpaceEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.sessionStore.payloads.SessionResponseSpaceEvent";
                    };

                    return SessionResponseSpaceEvent;
                })();

                return payloads;
            })();

            return sessionStore;
        })();

        network.payloads = (function() {

            /**
             * Namespace payloads.
             * @memberof engine.network
             * @namespace
             */
            var payloads = {};

            payloads.ObjectReceivedPayload = (function() {

                /**
                 * Properties of an ObjectReceivedPayload.
                 * @memberof engine.network.payloads
                 * @interface IObjectReceivedPayload
                 * @property {number|null} [objectId] ObjectReceivedPayload objectId
                 * @property {number|null} [clientId] ObjectReceivedPayload clientId
                 */

                /**
                 * Constructs a new ObjectReceivedPayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents an ObjectReceivedPayload.
                 * @implements IObjectReceivedPayload
                 * @constructor
                 * @param {engine.network.payloads.IObjectReceivedPayload=} [properties] Properties to set
                 */
                function ObjectReceivedPayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ObjectReceivedPayload objectId.
                 * @member {number} objectId
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @instance
                 */
                ObjectReceivedPayload.prototype.objectId = 0;

                /**
                 * ObjectReceivedPayload clientId.
                 * @member {number} clientId
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @instance
                 */
                ObjectReceivedPayload.prototype.clientId = 0;

                /**
                 * Creates a new ObjectReceivedPayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {engine.network.payloads.IObjectReceivedPayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.ObjectReceivedPayload} ObjectReceivedPayload instance
                 */
                ObjectReceivedPayload.create = function create(properties) {
                    return new ObjectReceivedPayload(properties);
                };

                /**
                 * Encodes the specified ObjectReceivedPayload message. Does not implicitly {@link engine.network.payloads.ObjectReceivedPayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {engine.network.payloads.IObjectReceivedPayload} message ObjectReceivedPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ObjectReceivedPayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.objectId);
                    if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.clientId);
                    return writer;
                };

                /**
                 * Encodes the specified ObjectReceivedPayload message, length delimited. Does not implicitly {@link engine.network.payloads.ObjectReceivedPayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {engine.network.payloads.IObjectReceivedPayload} message ObjectReceivedPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ObjectReceivedPayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ObjectReceivedPayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.ObjectReceivedPayload} ObjectReceivedPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ObjectReceivedPayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.ObjectReceivedPayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.objectId = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.clientId = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ObjectReceivedPayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.ObjectReceivedPayload} ObjectReceivedPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ObjectReceivedPayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ObjectReceivedPayload message.
                 * @function verify
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ObjectReceivedPayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.objectId != null && message.hasOwnProperty("objectId"))
                        if (!$util.isInteger(message.objectId))
                            return "objectId: integer expected";
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        if (!$util.isInteger(message.clientId))
                            return "clientId: integer expected";
                    return null;
                };

                /**
                 * Creates an ObjectReceivedPayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.ObjectReceivedPayload} ObjectReceivedPayload
                 */
                ObjectReceivedPayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.ObjectReceivedPayload)
                        return object;
                    var message = new $root.engine.network.payloads.ObjectReceivedPayload();
                    if (object.objectId != null)
                        message.objectId = object.objectId >>> 0;
                    if (object.clientId != null)
                        message.clientId = object.clientId >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ObjectReceivedPayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {engine.network.payloads.ObjectReceivedPayload} message ObjectReceivedPayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ObjectReceivedPayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.objectId = 0;
                        object.clientId = 0;
                    }
                    if (message.objectId != null && message.hasOwnProperty("objectId"))
                        object.objectId = message.objectId;
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        object.clientId = message.clientId;
                    return object;
                };

                /**
                 * Converts this ObjectReceivedPayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ObjectReceivedPayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ObjectReceivedPayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.ObjectReceivedPayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ObjectReceivedPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.ObjectReceivedPayload";
                };

                return ObjectReceivedPayload;
            })();

            payloads.RequestForObjectsPayload = (function() {

                /**
                 * Properties of a RequestForObjectsPayload.
                 * @memberof engine.network.payloads
                 * @interface IRequestForObjectsPayload
                 * @property {number|null} [clientId] RequestForObjectsPayload clientId
                 * @property {number|null} [roomId] RequestForObjectsPayload roomId
                 */

                /**
                 * Constructs a new RequestForObjectsPayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents a RequestForObjectsPayload.
                 * @implements IRequestForObjectsPayload
                 * @constructor
                 * @param {engine.network.payloads.IRequestForObjectsPayload=} [properties] Properties to set
                 */
                function RequestForObjectsPayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestForObjectsPayload clientId.
                 * @member {number} clientId
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @instance
                 */
                RequestForObjectsPayload.prototype.clientId = 0;

                /**
                 * RequestForObjectsPayload roomId.
                 * @member {number} roomId
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @instance
                 */
                RequestForObjectsPayload.prototype.roomId = 0;

                /**
                 * Creates a new RequestForObjectsPayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {engine.network.payloads.IRequestForObjectsPayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.RequestForObjectsPayload} RequestForObjectsPayload instance
                 */
                RequestForObjectsPayload.create = function create(properties) {
                    return new RequestForObjectsPayload(properties);
                };

                /**
                 * Encodes the specified RequestForObjectsPayload message. Does not implicitly {@link engine.network.payloads.RequestForObjectsPayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {engine.network.payloads.IRequestForObjectsPayload} message RequestForObjectsPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestForObjectsPayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.clientId);
                    if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.roomId);
                    return writer;
                };

                /**
                 * Encodes the specified RequestForObjectsPayload message, length delimited. Does not implicitly {@link engine.network.payloads.RequestForObjectsPayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {engine.network.payloads.IRequestForObjectsPayload} message RequestForObjectsPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestForObjectsPayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestForObjectsPayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.RequestForObjectsPayload} RequestForObjectsPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestForObjectsPayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.RequestForObjectsPayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.clientId = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.roomId = reader.uint32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestForObjectsPayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.RequestForObjectsPayload} RequestForObjectsPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestForObjectsPayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestForObjectsPayload message.
                 * @function verify
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestForObjectsPayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        if (!$util.isInteger(message.clientId))
                            return "clientId: integer expected";
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        if (!$util.isInteger(message.roomId))
                            return "roomId: integer expected";
                    return null;
                };

                /**
                 * Creates a RequestForObjectsPayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.RequestForObjectsPayload} RequestForObjectsPayload
                 */
                RequestForObjectsPayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.RequestForObjectsPayload)
                        return object;
                    var message = new $root.engine.network.payloads.RequestForObjectsPayload();
                    if (object.clientId != null)
                        message.clientId = object.clientId >>> 0;
                    if (object.roomId != null)
                        message.roomId = object.roomId >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a RequestForObjectsPayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {engine.network.payloads.RequestForObjectsPayload} message RequestForObjectsPayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestForObjectsPayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientId = 0;
                        object.roomId = 0;
                    }
                    if (message.clientId != null && message.hasOwnProperty("clientId"))
                        object.clientId = message.clientId;
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        object.roomId = message.roomId;
                    return object;
                };

                /**
                 * Converts this RequestForObjectsPayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestForObjectsPayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RequestForObjectsPayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.RequestForObjectsPayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RequestForObjectsPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.RequestForObjectsPayload";
                };

                return RequestForObjectsPayload;
            })();

            payloads.VariablePayload = (function() {

                /**
                 * Properties of a VariablePayload.
                 * @memberof engine.network.payloads
                 * @interface IVariablePayload
                 * @property {number|null} [id] VariablePayload id
                 * @property {string|null} [type] VariablePayload type
                 * @property {string|null} [name] VariablePayload name
                 * @property {number|null} [netObjectId] VariablePayload netObjectId
                 * @property {boolean|null} [interpolated] VariablePayload interpolated
                 * @property {number|null} [writerId] VariablePayload writerId
                 * @property {number|null} [roomId] VariablePayload roomId
                 * @property {google.protobuf.IAny|null} [payload] VariablePayload payload
                 */

                /**
                 * Constructs a new VariablePayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents a VariablePayload.
                 * @implements IVariablePayload
                 * @constructor
                 * @param {engine.network.payloads.IVariablePayload=} [properties] Properties to set
                 */
                function VariablePayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VariablePayload id.
                 * @member {number} id
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.id = 0;

                /**
                 * VariablePayload type.
                 * @member {string} type
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.type = "";

                /**
                 * VariablePayload name.
                 * @member {string} name
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.name = "";

                /**
                 * VariablePayload netObjectId.
                 * @member {number} netObjectId
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.netObjectId = 0;

                /**
                 * VariablePayload interpolated.
                 * @member {boolean} interpolated
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.interpolated = false;

                /**
                 * VariablePayload writerId.
                 * @member {number} writerId
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.writerId = 0;

                /**
                 * VariablePayload roomId.
                 * @member {number} roomId
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.roomId = 0;

                /**
                 * VariablePayload payload.
                 * @member {google.protobuf.IAny|null|undefined} payload
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 */
                VariablePayload.prototype.payload = null;

                /**
                 * Creates a new VariablePayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {engine.network.payloads.IVariablePayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.VariablePayload} VariablePayload instance
                 */
                VariablePayload.create = function create(properties) {
                    return new VariablePayload(properties);
                };

                /**
                 * Encodes the specified VariablePayload message. Does not implicitly {@link engine.network.payloads.VariablePayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {engine.network.payloads.IVariablePayload} message VariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VariablePayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.netObjectId != null && Object.hasOwnProperty.call(message, "netObjectId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.netObjectId);
                    if (message.interpolated != null && Object.hasOwnProperty.call(message, "interpolated"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.interpolated);
                    if (message.writerId != null && Object.hasOwnProperty.call(message, "writerId"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.writerId);
                    if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                        $root.google.protobuf.Any.encode(message.payload, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                        writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.roomId);
                    return writer;
                };

                /**
                 * Encodes the specified VariablePayload message, length delimited. Does not implicitly {@link engine.network.payloads.VariablePayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {engine.network.payloads.IVariablePayload} message VariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VariablePayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VariablePayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.VariablePayload} VariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VariablePayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.VariablePayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.type = reader.string();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.netObjectId = reader.uint32();
                                break;
                            }
                        case 5: {
                                message.interpolated = reader.bool();
                                break;
                            }
                        case 6: {
                                message.writerId = reader.uint32();
                                break;
                            }
                        case 8: {
                                message.roomId = reader.uint32();
                                break;
                            }
                        case 7: {
                                message.payload = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VariablePayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.VariablePayload} VariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VariablePayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VariablePayload message.
                 * @function verify
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VariablePayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.netObjectId != null && message.hasOwnProperty("netObjectId"))
                        if (!$util.isInteger(message.netObjectId))
                            return "netObjectId: integer expected";
                    if (message.interpolated != null && message.hasOwnProperty("interpolated"))
                        if (typeof message.interpolated !== "boolean")
                            return "interpolated: boolean expected";
                    if (message.writerId != null && message.hasOwnProperty("writerId"))
                        if (!$util.isInteger(message.writerId))
                            return "writerId: integer expected";
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        if (!$util.isInteger(message.roomId))
                            return "roomId: integer expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.google.protobuf.Any.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    return null;
                };

                /**
                 * Creates a VariablePayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.VariablePayload} VariablePayload
                 */
                VariablePayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.VariablePayload)
                        return object;
                    var message = new $root.engine.network.payloads.VariablePayload();
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.netObjectId != null)
                        message.netObjectId = object.netObjectId >>> 0;
                    if (object.interpolated != null)
                        message.interpolated = Boolean(object.interpolated);
                    if (object.writerId != null)
                        message.writerId = object.writerId >>> 0;
                    if (object.roomId != null)
                        message.roomId = object.roomId >>> 0;
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".engine.network.payloads.VariablePayload.payload: object expected");
                        message.payload = $root.google.protobuf.Any.fromObject(object.payload);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VariablePayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {engine.network.payloads.VariablePayload} message VariablePayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VariablePayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.type = "";
                        object.name = "";
                        object.netObjectId = 0;
                        object.interpolated = false;
                        object.writerId = 0;
                        object.payload = null;
                        object.roomId = 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.netObjectId != null && message.hasOwnProperty("netObjectId"))
                        object.netObjectId = message.netObjectId;
                    if (message.interpolated != null && message.hasOwnProperty("interpolated"))
                        object.interpolated = message.interpolated;
                    if (message.writerId != null && message.hasOwnProperty("writerId"))
                        object.writerId = message.writerId;
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.google.protobuf.Any.toObject(message.payload, options);
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        object.roomId = message.roomId;
                    return object;
                };

                /**
                 * Converts this VariablePayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.VariablePayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VariablePayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VariablePayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.VariablePayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VariablePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.VariablePayload";
                };

                return VariablePayload;
            })();

            payloads.AnimatorVariablePayload = (function() {

                /**
                 * Properties of an AnimatorVariablePayload.
                 * @memberof engine.network.payloads
                 * @interface IAnimatorVariablePayload
                 * @property {string|null} [actionName] AnimatorVariablePayload actionName
                 * @property {Array.<number>|null} [parameters] AnimatorVariablePayload parameters
                 */

                /**
                 * Constructs a new AnimatorVariablePayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents an AnimatorVariablePayload.
                 * @implements IAnimatorVariablePayload
                 * @constructor
                 * @param {engine.network.payloads.IAnimatorVariablePayload=} [properties] Properties to set
                 */
                function AnimatorVariablePayload(properties) {
                    this.parameters = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AnimatorVariablePayload actionName.
                 * @member {string} actionName
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @instance
                 */
                AnimatorVariablePayload.prototype.actionName = "";

                /**
                 * AnimatorVariablePayload parameters.
                 * @member {Array.<number>} parameters
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @instance
                 */
                AnimatorVariablePayload.prototype.parameters = $util.emptyArray;

                /**
                 * Creates a new AnimatorVariablePayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {engine.network.payloads.IAnimatorVariablePayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.AnimatorVariablePayload} AnimatorVariablePayload instance
                 */
                AnimatorVariablePayload.create = function create(properties) {
                    return new AnimatorVariablePayload(properties);
                };

                /**
                 * Encodes the specified AnimatorVariablePayload message. Does not implicitly {@link engine.network.payloads.AnimatorVariablePayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {engine.network.payloads.IAnimatorVariablePayload} message AnimatorVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AnimatorVariablePayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.actionName != null && Object.hasOwnProperty.call(message, "actionName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.actionName);
                    if (message.parameters != null && message.parameters.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.parameters.length; ++i)
                            writer.float(message.parameters[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified AnimatorVariablePayload message, length delimited. Does not implicitly {@link engine.network.payloads.AnimatorVariablePayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {engine.network.payloads.IAnimatorVariablePayload} message AnimatorVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AnimatorVariablePayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AnimatorVariablePayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.AnimatorVariablePayload} AnimatorVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AnimatorVariablePayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.AnimatorVariablePayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.actionName = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.parameters && message.parameters.length))
                                    message.parameters = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.parameters.push(reader.float());
                                } else
                                    message.parameters.push(reader.float());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AnimatorVariablePayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.AnimatorVariablePayload} AnimatorVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AnimatorVariablePayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AnimatorVariablePayload message.
                 * @function verify
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AnimatorVariablePayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        if (!$util.isString(message.actionName))
                            return "actionName: string expected";
                    if (message.parameters != null && message.hasOwnProperty("parameters")) {
                        if (!Array.isArray(message.parameters))
                            return "parameters: array expected";
                        for (var i = 0; i < message.parameters.length; ++i)
                            if (typeof message.parameters[i] !== "number")
                                return "parameters: number[] expected";
                    }
                    return null;
                };

                /**
                 * Creates an AnimatorVariablePayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.AnimatorVariablePayload} AnimatorVariablePayload
                 */
                AnimatorVariablePayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.AnimatorVariablePayload)
                        return object;
                    var message = new $root.engine.network.payloads.AnimatorVariablePayload();
                    if (object.actionName != null)
                        message.actionName = String(object.actionName);
                    if (object.parameters) {
                        if (!Array.isArray(object.parameters))
                            throw TypeError(".engine.network.payloads.AnimatorVariablePayload.parameters: array expected");
                        message.parameters = [];
                        for (var i = 0; i < object.parameters.length; ++i)
                            message.parameters[i] = Number(object.parameters[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AnimatorVariablePayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {engine.network.payloads.AnimatorVariablePayload} message AnimatorVariablePayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AnimatorVariablePayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.parameters = [];
                    if (options.defaults)
                        object.actionName = "";
                    if (message.actionName != null && message.hasOwnProperty("actionName"))
                        object.actionName = message.actionName;
                    if (message.parameters && message.parameters.length) {
                        object.parameters = [];
                        for (var j = 0; j < message.parameters.length; ++j)
                            object.parameters[j] = options.json && !isFinite(message.parameters[j]) ? String(message.parameters[j]) : message.parameters[j];
                    }
                    return object;
                };

                /**
                 * Converts this AnimatorVariablePayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AnimatorVariablePayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AnimatorVariablePayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.AnimatorVariablePayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AnimatorVariablePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.AnimatorVariablePayload";
                };

                return AnimatorVariablePayload;
            })();

            payloads.TransformVariablePayload = (function() {

                /**
                 * Properties of a TransformVariablePayload.
                 * @memberof engine.network.payloads
                 * @interface ITransformVariablePayload
                 * @property {engine.network.payloads.TransformVariablePayload.IPosition|null} [position] TransformVariablePayload position
                 * @property {engine.network.payloads.TransformVariablePayload.IQuaternion|null} [quaternion] TransformVariablePayload quaternion
                 */

                /**
                 * Constructs a new TransformVariablePayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents a TransformVariablePayload.
                 * @implements ITransformVariablePayload
                 * @constructor
                 * @param {engine.network.payloads.ITransformVariablePayload=} [properties] Properties to set
                 */
                function TransformVariablePayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TransformVariablePayload position.
                 * @member {engine.network.payloads.TransformVariablePayload.IPosition|null|undefined} position
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @instance
                 */
                TransformVariablePayload.prototype.position = null;

                /**
                 * TransformVariablePayload quaternion.
                 * @member {engine.network.payloads.TransformVariablePayload.IQuaternion|null|undefined} quaternion
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @instance
                 */
                TransformVariablePayload.prototype.quaternion = null;

                /**
                 * Creates a new TransformVariablePayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {engine.network.payloads.ITransformVariablePayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.TransformVariablePayload} TransformVariablePayload instance
                 */
                TransformVariablePayload.create = function create(properties) {
                    return new TransformVariablePayload(properties);
                };

                /**
                 * Encodes the specified TransformVariablePayload message. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {engine.network.payloads.ITransformVariablePayload} message TransformVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransformVariablePayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                        $root.engine.network.payloads.TransformVariablePayload.Position.encode(message.position, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.quaternion != null && Object.hasOwnProperty.call(message, "quaternion"))
                        $root.engine.network.payloads.TransformVariablePayload.Quaternion.encode(message.quaternion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TransformVariablePayload message, length delimited. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {engine.network.payloads.ITransformVariablePayload} message TransformVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransformVariablePayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TransformVariablePayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.TransformVariablePayload} TransformVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransformVariablePayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.TransformVariablePayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.position = $root.engine.network.payloads.TransformVariablePayload.Position.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.quaternion = $root.engine.network.payloads.TransformVariablePayload.Quaternion.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TransformVariablePayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.TransformVariablePayload} TransformVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransformVariablePayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TransformVariablePayload message.
                 * @function verify
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TransformVariablePayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.position != null && message.hasOwnProperty("position")) {
                        var error = $root.engine.network.payloads.TransformVariablePayload.Position.verify(message.position);
                        if (error)
                            return "position." + error;
                    }
                    if (message.quaternion != null && message.hasOwnProperty("quaternion")) {
                        var error = $root.engine.network.payloads.TransformVariablePayload.Quaternion.verify(message.quaternion);
                        if (error)
                            return "quaternion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TransformVariablePayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.TransformVariablePayload} TransformVariablePayload
                 */
                TransformVariablePayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.TransformVariablePayload)
                        return object;
                    var message = new $root.engine.network.payloads.TransformVariablePayload();
                    if (object.position != null) {
                        if (typeof object.position !== "object")
                            throw TypeError(".engine.network.payloads.TransformVariablePayload.position: object expected");
                        message.position = $root.engine.network.payloads.TransformVariablePayload.Position.fromObject(object.position);
                    }
                    if (object.quaternion != null) {
                        if (typeof object.quaternion !== "object")
                            throw TypeError(".engine.network.payloads.TransformVariablePayload.quaternion: object expected");
                        message.quaternion = $root.engine.network.payloads.TransformVariablePayload.Quaternion.fromObject(object.quaternion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TransformVariablePayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {engine.network.payloads.TransformVariablePayload} message TransformVariablePayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TransformVariablePayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.position = null;
                        object.quaternion = null;
                    }
                    if (message.position != null && message.hasOwnProperty("position"))
                        object.position = $root.engine.network.payloads.TransformVariablePayload.Position.toObject(message.position, options);
                    if (message.quaternion != null && message.hasOwnProperty("quaternion"))
                        object.quaternion = $root.engine.network.payloads.TransformVariablePayload.Quaternion.toObject(message.quaternion, options);
                    return object;
                };

                /**
                 * Converts this TransformVariablePayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TransformVariablePayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TransformVariablePayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.TransformVariablePayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TransformVariablePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.TransformVariablePayload";
                };

                TransformVariablePayload.Position = (function() {

                    /**
                     * Properties of a Position.
                     * @memberof engine.network.payloads.TransformVariablePayload
                     * @interface IPosition
                     * @property {number|null} [x] Position x
                     * @property {number|null} [y] Position y
                     * @property {number|null} [z] Position z
                     */

                    /**
                     * Constructs a new Position.
                     * @memberof engine.network.payloads.TransformVariablePayload
                     * @classdesc Represents a Position.
                     * @implements IPosition
                     * @constructor
                     * @param {engine.network.payloads.TransformVariablePayload.IPosition=} [properties] Properties to set
                     */
                    function Position(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Position x.
                     * @member {number} x
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @instance
                     */
                    Position.prototype.x = 0;

                    /**
                     * Position y.
                     * @member {number} y
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @instance
                     */
                    Position.prototype.y = 0;

                    /**
                     * Position z.
                     * @member {number} z
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @instance
                     */
                    Position.prototype.z = 0;

                    /**
                     * Creates a new Position instance using the specified properties.
                     * @function create
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IPosition=} [properties] Properties to set
                     * @returns {engine.network.payloads.TransformVariablePayload.Position} Position instance
                     */
                    Position.create = function create(properties) {
                        return new Position(properties);
                    };

                    /**
                     * Encodes the specified Position message. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.Position.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IPosition} message Position message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Position.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
                        if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
                        if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.z);
                        return writer;
                    };

                    /**
                     * Encodes the specified Position message, length delimited. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.Position.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IPosition} message Position message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Position.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Position message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.payloads.TransformVariablePayload.Position} Position
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Position.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.TransformVariablePayload.Position();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.x = reader.float();
                                    break;
                                }
                            case 2: {
                                    message.y = reader.float();
                                    break;
                                }
                            case 3: {
                                    message.z = reader.float();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Position message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.payloads.TransformVariablePayload.Position} Position
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Position.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Position message.
                     * @function verify
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Position.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.x != null && message.hasOwnProperty("x"))
                            if (typeof message.x !== "number")
                                return "x: number expected";
                        if (message.y != null && message.hasOwnProperty("y"))
                            if (typeof message.y !== "number")
                                return "y: number expected";
                        if (message.z != null && message.hasOwnProperty("z"))
                            if (typeof message.z !== "number")
                                return "z: number expected";
                        return null;
                    };

                    /**
                     * Creates a Position message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.payloads.TransformVariablePayload.Position} Position
                     */
                    Position.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.payloads.TransformVariablePayload.Position)
                            return object;
                        var message = new $root.engine.network.payloads.TransformVariablePayload.Position();
                        if (object.x != null)
                            message.x = Number(object.x);
                        if (object.y != null)
                            message.y = Number(object.y);
                        if (object.z != null)
                            message.z = Number(object.z);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Position message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.Position} message Position
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Position.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.x = 0;
                            object.y = 0;
                            object.z = 0;
                        }
                        if (message.x != null && message.hasOwnProperty("x"))
                            object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
                        if (message.y != null && message.hasOwnProperty("y"))
                            object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
                        if (message.z != null && message.hasOwnProperty("z"))
                            object.z = options.json && !isFinite(message.z) ? String(message.z) : message.z;
                        return object;
                    };

                    /**
                     * Converts this Position to JSON.
                     * @function toJSON
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Position.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Position
                     * @function getTypeUrl
                     * @memberof engine.network.payloads.TransformVariablePayload.Position
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Position.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.payloads.TransformVariablePayload.Position";
                    };

                    return Position;
                })();

                TransformVariablePayload.Quaternion = (function() {

                    /**
                     * Properties of a Quaternion.
                     * @memberof engine.network.payloads.TransformVariablePayload
                     * @interface IQuaternion
                     * @property {number|null} [x] Quaternion x
                     * @property {number|null} [y] Quaternion y
                     * @property {number|null} [z] Quaternion z
                     * @property {number|null} [w] Quaternion w
                     */

                    /**
                     * Constructs a new Quaternion.
                     * @memberof engine.network.payloads.TransformVariablePayload
                     * @classdesc Represents a Quaternion.
                     * @implements IQuaternion
                     * @constructor
                     * @param {engine.network.payloads.TransformVariablePayload.IQuaternion=} [properties] Properties to set
                     */
                    function Quaternion(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Quaternion x.
                     * @member {number} x
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @instance
                     */
                    Quaternion.prototype.x = 0;

                    /**
                     * Quaternion y.
                     * @member {number} y
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @instance
                     */
                    Quaternion.prototype.y = 0;

                    /**
                     * Quaternion z.
                     * @member {number} z
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @instance
                     */
                    Quaternion.prototype.z = 0;

                    /**
                     * Quaternion w.
                     * @member {number} w
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @instance
                     */
                    Quaternion.prototype.w = 0;

                    /**
                     * Creates a new Quaternion instance using the specified properties.
                     * @function create
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IQuaternion=} [properties] Properties to set
                     * @returns {engine.network.payloads.TransformVariablePayload.Quaternion} Quaternion instance
                     */
                    Quaternion.create = function create(properties) {
                        return new Quaternion(properties);
                    };

                    /**
                     * Encodes the specified Quaternion message. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.Quaternion.verify|verify} messages.
                     * @function encode
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IQuaternion} message Quaternion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Quaternion.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                            writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
                        if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                            writer.uint32(/* id 2, wireType 5 =*/21).float(message.y);
                        if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                            writer.uint32(/* id 3, wireType 5 =*/29).float(message.z);
                        if (message.w != null && Object.hasOwnProperty.call(message, "w"))
                            writer.uint32(/* id 4, wireType 5 =*/37).float(message.w);
                        return writer;
                    };

                    /**
                     * Encodes the specified Quaternion message, length delimited. Does not implicitly {@link engine.network.payloads.TransformVariablePayload.Quaternion.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.IQuaternion} message Quaternion message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Quaternion.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Quaternion message from the specified reader or buffer.
                     * @function decode
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {engine.network.payloads.TransformVariablePayload.Quaternion} Quaternion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Quaternion.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.TransformVariablePayload.Quaternion();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.x = reader.float();
                                    break;
                                }
                            case 2: {
                                    message.y = reader.float();
                                    break;
                                }
                            case 3: {
                                    message.z = reader.float();
                                    break;
                                }
                            case 4: {
                                    message.w = reader.float();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Quaternion message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {engine.network.payloads.TransformVariablePayload.Quaternion} Quaternion
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Quaternion.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Quaternion message.
                     * @function verify
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Quaternion.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.x != null && message.hasOwnProperty("x"))
                            if (typeof message.x !== "number")
                                return "x: number expected";
                        if (message.y != null && message.hasOwnProperty("y"))
                            if (typeof message.y !== "number")
                                return "y: number expected";
                        if (message.z != null && message.hasOwnProperty("z"))
                            if (typeof message.z !== "number")
                                return "z: number expected";
                        if (message.w != null && message.hasOwnProperty("w"))
                            if (typeof message.w !== "number")
                                return "w: number expected";
                        return null;
                    };

                    /**
                     * Creates a Quaternion message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {engine.network.payloads.TransformVariablePayload.Quaternion} Quaternion
                     */
                    Quaternion.fromObject = function fromObject(object) {
                        if (object instanceof $root.engine.network.payloads.TransformVariablePayload.Quaternion)
                            return object;
                        var message = new $root.engine.network.payloads.TransformVariablePayload.Quaternion();
                        if (object.x != null)
                            message.x = Number(object.x);
                        if (object.y != null)
                            message.y = Number(object.y);
                        if (object.z != null)
                            message.z = Number(object.z);
                        if (object.w != null)
                            message.w = Number(object.w);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Quaternion message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {engine.network.payloads.TransformVariablePayload.Quaternion} message Quaternion
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Quaternion.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.x = 0;
                            object.y = 0;
                            object.z = 0;
                            object.w = 0;
                        }
                        if (message.x != null && message.hasOwnProperty("x"))
                            object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
                        if (message.y != null && message.hasOwnProperty("y"))
                            object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
                        if (message.z != null && message.hasOwnProperty("z"))
                            object.z = options.json && !isFinite(message.z) ? String(message.z) : message.z;
                        if (message.w != null && message.hasOwnProperty("w"))
                            object.w = options.json && !isFinite(message.w) ? String(message.w) : message.w;
                        return object;
                    };

                    /**
                     * Converts this Quaternion to JSON.
                     * @function toJSON
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Quaternion.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Quaternion
                     * @function getTypeUrl
                     * @memberof engine.network.payloads.TransformVariablePayload.Quaternion
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Quaternion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/engine.network.payloads.TransformVariablePayload.Quaternion";
                    };

                    return Quaternion;
                })();

                return TransformVariablePayload;
            })();

            payloads.ObjectPayload = (function() {

                /**
                 * Properties of an ObjectPayload.
                 * @memberof engine.network.payloads
                 * @interface IObjectPayload
                 * @property {string|null} [type] ObjectPayload type
                 * @property {number|null} [id] ObjectPayload id
                 * @property {number|null} [ownerId] ObjectPayload ownerId
                 * @property {engine.network.payloads.ObjectPayload.NetworkObjectStatus|null} [status] ObjectPayload status
                 * @property {engine.network.payloads.ObjectPayload.NetworkObjectLifeTimeTypes|null} [lifeTimeType] ObjectPayload lifeTimeType
                 * @property {string|null} [code] ObjectPayload code
                 * @property {boolean|null} [isNeedSpawn] ObjectPayload isNeedSpawn
                 * @property {number|null} [roomId] ObjectPayload roomId
                 * @property {boolean|null} [permanent] ObjectPayload permanent
                 * @property {google.protobuf.IAny|null} [payload] ObjectPayload payload
                 */

                /**
                 * Constructs a new ObjectPayload.
                 * @memberof engine.network.payloads
                 * @classdesc Represents an ObjectPayload.
                 * @implements IObjectPayload
                 * @constructor
                 * @param {engine.network.payloads.IObjectPayload=} [properties] Properties to set
                 */
                function ObjectPayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ObjectPayload type.
                 * @member {string} type
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.type = "";

                /**
                 * ObjectPayload id.
                 * @member {number} id
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.id = 0;

                /**
                 * ObjectPayload ownerId.
                 * @member {number} ownerId
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.ownerId = 0;

                /**
                 * ObjectPayload status.
                 * @member {engine.network.payloads.ObjectPayload.NetworkObjectStatus} status
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.status = 0;

                /**
                 * ObjectPayload lifeTimeType.
                 * @member {engine.network.payloads.ObjectPayload.NetworkObjectLifeTimeTypes} lifeTimeType
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.lifeTimeType = 0;

                /**
                 * ObjectPayload code.
                 * @member {string} code
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.code = "";

                /**
                 * ObjectPayload isNeedSpawn.
                 * @member {boolean} isNeedSpawn
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.isNeedSpawn = false;

                /**
                 * ObjectPayload roomId.
                 * @member {number} roomId
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.roomId = 0;

                /**
                 * ObjectPayload permanent.
                 * @member {boolean} permanent
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.permanent = false;

                /**
                 * ObjectPayload payload.
                 * @member {google.protobuf.IAny|null|undefined} payload
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 */
                ObjectPayload.prototype.payload = null;

                /**
                 * Creates a new ObjectPayload instance using the specified properties.
                 * @function create
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {engine.network.payloads.IObjectPayload=} [properties] Properties to set
                 * @returns {engine.network.payloads.ObjectPayload} ObjectPayload instance
                 */
                ObjectPayload.create = function create(properties) {
                    return new ObjectPayload(properties);
                };

                /**
                 * Encodes the specified ObjectPayload message. Does not implicitly {@link engine.network.payloads.ObjectPayload.verify|verify} messages.
                 * @function encode
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {engine.network.payloads.IObjectPayload} message ObjectPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ObjectPayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
                    if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.ownerId);
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
                    if (message.lifeTimeType != null && Object.hasOwnProperty.call(message, "lifeTimeType"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lifeTimeType);
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.code);
                    if (message.isNeedSpawn != null && Object.hasOwnProperty.call(message, "isNeedSpawn"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNeedSpawn);
                    if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                        $root.google.protobuf.Any.encode(message.payload, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                        writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.roomId);
                    if (message.permanent != null && Object.hasOwnProperty.call(message, "permanent"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.permanent);
                    return writer;
                };

                /**
                 * Encodes the specified ObjectPayload message, length delimited. Does not implicitly {@link engine.network.payloads.ObjectPayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {engine.network.payloads.IObjectPayload} message ObjectPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ObjectPayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ObjectPayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {engine.network.payloads.ObjectPayload} ObjectPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ObjectPayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.payloads.ObjectPayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.type = reader.string();
                                break;
                            }
                        case 2: {
                                message.id = reader.uint32();
                                break;
                            }
                        case 3: {
                                message.ownerId = reader.uint32();
                                break;
                            }
                        case 4: {
                                message.status = reader.int32();
                                break;
                            }
                        case 5: {
                                message.lifeTimeType = reader.int32();
                                break;
                            }
                        case 6: {
                                message.code = reader.string();
                                break;
                            }
                        case 7: {
                                message.isNeedSpawn = reader.bool();
                                break;
                            }
                        case 9: {
                                message.roomId = reader.uint32();
                                break;
                            }
                        case 10: {
                                message.permanent = reader.bool();
                                break;
                            }
                        case 8: {
                                message.payload = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ObjectPayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {engine.network.payloads.ObjectPayload} ObjectPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ObjectPayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ObjectPayload message.
                 * @function verify
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ObjectPayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        if (!$util.isString(message.type))
                            return "type: string expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        if (!$util.isInteger(message.ownerId))
                            return "ownerId: integer expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.lifeTimeType != null && message.hasOwnProperty("lifeTimeType"))
                        switch (message.lifeTimeType) {
                        default:
                            return "lifeTimeType: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    if (message.isNeedSpawn != null && message.hasOwnProperty("isNeedSpawn"))
                        if (typeof message.isNeedSpawn !== "boolean")
                            return "isNeedSpawn: boolean expected";
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        if (!$util.isInteger(message.roomId))
                            return "roomId: integer expected";
                    if (message.permanent != null && message.hasOwnProperty("permanent"))
                        if (typeof message.permanent !== "boolean")
                            return "permanent: boolean expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.google.protobuf.Any.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ObjectPayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {engine.network.payloads.ObjectPayload} ObjectPayload
                 */
                ObjectPayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.engine.network.payloads.ObjectPayload)
                        return object;
                    var message = new $root.engine.network.payloads.ObjectPayload();
                    if (object.type != null)
                        message.type = String(object.type);
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    if (object.ownerId != null)
                        message.ownerId = object.ownerId >>> 0;
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "Active":
                    case 0:
                        message.status = 0;
                        break;
                    case "Removed":
                    case 1:
                        message.status = 1;
                        break;
                    }
                    switch (object.lifeTimeType) {
                    default:
                        if (typeof object.lifeTimeType === "number") {
                            message.lifeTimeType = object.lifeTimeType;
                            break;
                        }
                        break;
                    case "Owner":
                    case 0:
                        message.lifeTimeType = 0;
                        break;
                    case "Shared":
                    case 1:
                        message.lifeTimeType = 1;
                        break;
                    }
                    if (object.code != null)
                        message.code = String(object.code);
                    if (object.isNeedSpawn != null)
                        message.isNeedSpawn = Boolean(object.isNeedSpawn);
                    if (object.roomId != null)
                        message.roomId = object.roomId >>> 0;
                    if (object.permanent != null)
                        message.permanent = Boolean(object.permanent);
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".engine.network.payloads.ObjectPayload.payload: object expected");
                        message.payload = $root.google.protobuf.Any.fromObject(object.payload);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ObjectPayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {engine.network.payloads.ObjectPayload} message ObjectPayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ObjectPayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = "";
                        object.id = 0;
                        object.ownerId = 0;
                        object.status = options.enums === String ? "Active" : 0;
                        object.lifeTimeType = options.enums === String ? "Owner" : 0;
                        object.code = "";
                        object.isNeedSpawn = false;
                        object.payload = null;
                        object.roomId = 0;
                        object.permanent = false;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = message.type;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                        object.ownerId = message.ownerId;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.engine.network.payloads.ObjectPayload.NetworkObjectStatus[message.status] === undefined ? message.status : $root.engine.network.payloads.ObjectPayload.NetworkObjectStatus[message.status] : message.status;
                    if (message.lifeTimeType != null && message.hasOwnProperty("lifeTimeType"))
                        object.lifeTimeType = options.enums === String ? $root.engine.network.payloads.ObjectPayload.NetworkObjectLifeTimeTypes[message.lifeTimeType] === undefined ? message.lifeTimeType : $root.engine.network.payloads.ObjectPayload.NetworkObjectLifeTimeTypes[message.lifeTimeType] : message.lifeTimeType;
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    if (message.isNeedSpawn != null && message.hasOwnProperty("isNeedSpawn"))
                        object.isNeedSpawn = message.isNeedSpawn;
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.google.protobuf.Any.toObject(message.payload, options);
                    if (message.roomId != null && message.hasOwnProperty("roomId"))
                        object.roomId = message.roomId;
                    if (message.permanent != null && message.hasOwnProperty("permanent"))
                        object.permanent = message.permanent;
                    return object;
                };

                /**
                 * Converts this ObjectPayload to JSON.
                 * @function toJSON
                 * @memberof engine.network.payloads.ObjectPayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ObjectPayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ObjectPayload
                 * @function getTypeUrl
                 * @memberof engine.network.payloads.ObjectPayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ObjectPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/engine.network.payloads.ObjectPayload";
                };

                /**
                 * NetworkObjectStatus enum.
                 * @name engine.network.payloads.ObjectPayload.NetworkObjectStatus
                 * @enum {number}
                 * @property {number} Active=0 Active value
                 * @property {number} Removed=1 Removed value
                 */
                ObjectPayload.NetworkObjectStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "Active"] = 0;
                    values[valuesById[1] = "Removed"] = 1;
                    return values;
                })();

                /**
                 * NetworkObjectLifeTimeTypes enum.
                 * @name engine.network.payloads.ObjectPayload.NetworkObjectLifeTimeTypes
                 * @enum {number}
                 * @property {number} Owner=0 Owner value
                 * @property {number} Shared=1 Shared value
                 */
                ObjectPayload.NetworkObjectLifeTimeTypes = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "Owner"] = 0;
                    values[valuesById[1] = "Shared"] = 1;
                    return values;
                })();

                return ObjectPayload;
            })();

            return payloads;
        })();

        network.NetworkEvent = (function() {

            /**
             * Properties of a NetworkEvent.
             * @memberof engine.network
             * @interface INetworkEvent
             * @property {string|null} [eventName] NetworkEvent eventName
             * @property {number|null} [objectId] NetworkEvent objectId
             * @property {google.protobuf.IAny|null} [payload] NetworkEvent payload
             */

            /**
             * Constructs a new NetworkEvent.
             * @memberof engine.network
             * @classdesc Represents a NetworkEvent.
             * @implements INetworkEvent
             * @constructor
             * @param {engine.network.INetworkEvent=} [properties] Properties to set
             */
            function NetworkEvent(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NetworkEvent eventName.
             * @member {string} eventName
             * @memberof engine.network.NetworkEvent
             * @instance
             */
            NetworkEvent.prototype.eventName = "";

            /**
             * NetworkEvent objectId.
             * @member {number} objectId
             * @memberof engine.network.NetworkEvent
             * @instance
             */
            NetworkEvent.prototype.objectId = 0;

            /**
             * NetworkEvent payload.
             * @member {google.protobuf.IAny|null|undefined} payload
             * @memberof engine.network.NetworkEvent
             * @instance
             */
            NetworkEvent.prototype.payload = null;

            /**
             * Creates a new NetworkEvent instance using the specified properties.
             * @function create
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {engine.network.INetworkEvent=} [properties] Properties to set
             * @returns {engine.network.NetworkEvent} NetworkEvent instance
             */
            NetworkEvent.create = function create(properties) {
                return new NetworkEvent(properties);
            };

            /**
             * Encodes the specified NetworkEvent message. Does not implicitly {@link engine.network.NetworkEvent.verify|verify} messages.
             * @function encode
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {engine.network.INetworkEvent} message NetworkEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.eventName != null && Object.hasOwnProperty.call(message, "eventName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.eventName);
                if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.objectId);
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    $root.google.protobuf.Any.encode(message.payload, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified NetworkEvent message, length delimited. Does not implicitly {@link engine.network.NetworkEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {engine.network.INetworkEvent} message NetworkEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NetworkEvent message from the specified reader or buffer.
             * @function decode
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {engine.network.NetworkEvent} NetworkEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.engine.network.NetworkEvent();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.eventName = reader.string();
                            break;
                        }
                    case 2: {
                            message.objectId = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.payload = $root.google.protobuf.Any.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NetworkEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {engine.network.NetworkEvent} NetworkEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NetworkEvent message.
             * @function verify
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NetworkEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.eventName != null && message.hasOwnProperty("eventName"))
                    if (!$util.isString(message.eventName))
                        return "eventName: string expected";
                if (message.objectId != null && message.hasOwnProperty("objectId"))
                    if (!$util.isInteger(message.objectId))
                        return "objectId: integer expected";
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    var error = $root.google.protobuf.Any.verify(message.payload);
                    if (error)
                        return "payload." + error;
                }
                return null;
            };

            /**
             * Creates a NetworkEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {engine.network.NetworkEvent} NetworkEvent
             */
            NetworkEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.engine.network.NetworkEvent)
                    return object;
                var message = new $root.engine.network.NetworkEvent();
                if (object.eventName != null)
                    message.eventName = String(object.eventName);
                if (object.objectId != null)
                    message.objectId = object.objectId >>> 0;
                if (object.payload != null) {
                    if (typeof object.payload !== "object")
                        throw TypeError(".engine.network.NetworkEvent.payload: object expected");
                    message.payload = $root.google.protobuf.Any.fromObject(object.payload);
                }
                return message;
            };

            /**
             * Creates a plain object from a NetworkEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {engine.network.NetworkEvent} message NetworkEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NetworkEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.eventName = "";
                    object.objectId = 0;
                    object.payload = null;
                }
                if (message.eventName != null && message.hasOwnProperty("eventName"))
                    object.eventName = message.eventName;
                if (message.objectId != null && message.hasOwnProperty("objectId"))
                    object.objectId = message.objectId;
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = $root.google.protobuf.Any.toObject(message.payload, options);
                return object;
            };

            /**
             * Converts this NetworkEvent to JSON.
             * @function toJSON
             * @memberof engine.network.NetworkEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NetworkEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NetworkEvent
             * @function getTypeUrl
             * @memberof engine.network.NetworkEvent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NetworkEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/engine.network.NetworkEvent";
            };

            return NetworkEvent;
        })();

        return network;
    })();

    return engine;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && Object.hasOwnProperty.call(message, "type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type_url = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                var message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Any
             * @function getTypeUrl
             * @memberof google.protobuf.Any
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Any";
            };

            return Any;
        })();

        return protobuf;
    })();

    return google;
})();

$root.domain = (function() {

    /**
     * Namespace domain.
     * @exports domain
     * @namespace
     */
    var domain = {};

    domain.network = (function() {

        /**
         * Namespace network.
         * @memberof domain
         * @namespace
         */
        var network = {};

        network.payloads = (function() {

            /**
             * Namespace payloads.
             * @memberof domain.network
             * @namespace
             */
            var payloads = {};

            payloads.CharacterObjectPayload = (function() {

                /**
                 * Properties of a CharacterObjectPayload.
                 * @memberof domain.network.payloads
                 * @interface ICharacterObjectPayload
                 * @property {string|null} [avatarName] CharacterObjectPayload avatarName
                 */

                /**
                 * Constructs a new CharacterObjectPayload.
                 * @memberof domain.network.payloads
                 * @classdesc Represents a CharacterObjectPayload.
                 * @implements ICharacterObjectPayload
                 * @constructor
                 * @param {domain.network.payloads.ICharacterObjectPayload=} [properties] Properties to set
                 */
                function CharacterObjectPayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CharacterObjectPayload avatarName.
                 * @member {string} avatarName
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @instance
                 */
                CharacterObjectPayload.prototype.avatarName = "";

                /**
                 * Creates a new CharacterObjectPayload instance using the specified properties.
                 * @function create
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {domain.network.payloads.ICharacterObjectPayload=} [properties] Properties to set
                 * @returns {domain.network.payloads.CharacterObjectPayload} CharacterObjectPayload instance
                 */
                CharacterObjectPayload.create = function create(properties) {
                    return new CharacterObjectPayload(properties);
                };

                /**
                 * Encodes the specified CharacterObjectPayload message. Does not implicitly {@link domain.network.payloads.CharacterObjectPayload.verify|verify} messages.
                 * @function encode
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {domain.network.payloads.ICharacterObjectPayload} message CharacterObjectPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CharacterObjectPayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.avatarName != null && Object.hasOwnProperty.call(message, "avatarName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatarName);
                    return writer;
                };

                /**
                 * Encodes the specified CharacterObjectPayload message, length delimited. Does not implicitly {@link domain.network.payloads.CharacterObjectPayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {domain.network.payloads.ICharacterObjectPayload} message CharacterObjectPayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CharacterObjectPayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CharacterObjectPayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {domain.network.payloads.CharacterObjectPayload} CharacterObjectPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CharacterObjectPayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.domain.network.payloads.CharacterObjectPayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.avatarName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CharacterObjectPayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {domain.network.payloads.CharacterObjectPayload} CharacterObjectPayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CharacterObjectPayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CharacterObjectPayload message.
                 * @function verify
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CharacterObjectPayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.avatarName != null && message.hasOwnProperty("avatarName"))
                        if (!$util.isString(message.avatarName))
                            return "avatarName: string expected";
                    return null;
                };

                /**
                 * Creates a CharacterObjectPayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {domain.network.payloads.CharacterObjectPayload} CharacterObjectPayload
                 */
                CharacterObjectPayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.domain.network.payloads.CharacterObjectPayload)
                        return object;
                    var message = new $root.domain.network.payloads.CharacterObjectPayload();
                    if (object.avatarName != null)
                        message.avatarName = String(object.avatarName);
                    return message;
                };

                /**
                 * Creates a plain object from a CharacterObjectPayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {domain.network.payloads.CharacterObjectPayload} message CharacterObjectPayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CharacterObjectPayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.avatarName = "";
                    if (message.avatarName != null && message.hasOwnProperty("avatarName"))
                        object.avatarName = message.avatarName;
                    return object;
                };

                /**
                 * Converts this CharacterObjectPayload to JSON.
                 * @function toJSON
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CharacterObjectPayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CharacterObjectPayload
                 * @function getTypeUrl
                 * @memberof domain.network.payloads.CharacterObjectPayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CharacterObjectPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/domain.network.payloads.CharacterObjectPayload";
                };

                return CharacterObjectPayload;
            })();

            payloads.AudioVariablePayload = (function() {

                /**
                 * Properties of an AudioVariablePayload.
                 * @memberof domain.network.payloads
                 * @interface IAudioVariablePayload
                 * @property {number|Long|null} [localTime] AudioVariablePayload localTime
                 * @property {number|null} [audioTime] AudioVariablePayload audioTime
                 * @property {boolean|null} [isPlaying] AudioVariablePayload isPlaying
                 * @property {domain.network.payloads.AudioVariablePayload.IAudioSource|null} [source] AudioVariablePayload source
                 */

                /**
                 * Constructs a new AudioVariablePayload.
                 * @memberof domain.network.payloads
                 * @classdesc Represents an AudioVariablePayload.
                 * @implements IAudioVariablePayload
                 * @constructor
                 * @param {domain.network.payloads.IAudioVariablePayload=} [properties] Properties to set
                 */
                function AudioVariablePayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AudioVariablePayload localTime.
                 * @member {number|Long} localTime
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 */
                AudioVariablePayload.prototype.localTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * AudioVariablePayload audioTime.
                 * @member {number} audioTime
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 */
                AudioVariablePayload.prototype.audioTime = 0;

                /**
                 * AudioVariablePayload isPlaying.
                 * @member {boolean} isPlaying
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 */
                AudioVariablePayload.prototype.isPlaying = false;

                /**
                 * AudioVariablePayload source.
                 * @member {domain.network.payloads.AudioVariablePayload.IAudioSource|null|undefined} source
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 */
                AudioVariablePayload.prototype.source = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * AudioVariablePayload _source.
                 * @member {"source"|undefined} _source
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 */
                Object.defineProperty(AudioVariablePayload.prototype, "_source", {
                    get: $util.oneOfGetter($oneOfFields = ["source"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new AudioVariablePayload instance using the specified properties.
                 * @function create
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {domain.network.payloads.IAudioVariablePayload=} [properties] Properties to set
                 * @returns {domain.network.payloads.AudioVariablePayload} AudioVariablePayload instance
                 */
                AudioVariablePayload.create = function create(properties) {
                    return new AudioVariablePayload(properties);
                };

                /**
                 * Encodes the specified AudioVariablePayload message. Does not implicitly {@link domain.network.payloads.AudioVariablePayload.verify|verify} messages.
                 * @function encode
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {domain.network.payloads.IAudioVariablePayload} message AudioVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AudioVariablePayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.localTime != null && Object.hasOwnProperty.call(message, "localTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.localTime);
                    if (message.audioTime != null && Object.hasOwnProperty.call(message, "audioTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.audioTime);
                    if (message.isPlaying != null && Object.hasOwnProperty.call(message, "isPlaying"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isPlaying);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        $root.domain.network.payloads.AudioVariablePayload.AudioSource.encode(message.source, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AudioVariablePayload message, length delimited. Does not implicitly {@link domain.network.payloads.AudioVariablePayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {domain.network.payloads.IAudioVariablePayload} message AudioVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AudioVariablePayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AudioVariablePayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {domain.network.payloads.AudioVariablePayload} AudioVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AudioVariablePayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.domain.network.payloads.AudioVariablePayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.localTime = reader.uint64();
                                break;
                            }
                        case 2: {
                                message.audioTime = reader.uint32();
                                break;
                            }
                        case 3: {
                                message.isPlaying = reader.bool();
                                break;
                            }
                        case 4: {
                                message.source = $root.domain.network.payloads.AudioVariablePayload.AudioSource.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AudioVariablePayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {domain.network.payloads.AudioVariablePayload} AudioVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AudioVariablePayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AudioVariablePayload message.
                 * @function verify
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AudioVariablePayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.localTime != null && message.hasOwnProperty("localTime"))
                        if (!$util.isInteger(message.localTime) && !(message.localTime && $util.isInteger(message.localTime.low) && $util.isInteger(message.localTime.high)))
                            return "localTime: integer|Long expected";
                    if (message.audioTime != null && message.hasOwnProperty("audioTime"))
                        if (!$util.isInteger(message.audioTime))
                            return "audioTime: integer expected";
                    if (message.isPlaying != null && message.hasOwnProperty("isPlaying"))
                        if (typeof message.isPlaying !== "boolean")
                            return "isPlaying: boolean expected";
                    if (message.source != null && message.hasOwnProperty("source")) {
                        properties._source = 1;
                        {
                            var error = $root.domain.network.payloads.AudioVariablePayload.AudioSource.verify(message.source);
                            if (error)
                                return "source." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AudioVariablePayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {domain.network.payloads.AudioVariablePayload} AudioVariablePayload
                 */
                AudioVariablePayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.domain.network.payloads.AudioVariablePayload)
                        return object;
                    var message = new $root.domain.network.payloads.AudioVariablePayload();
                    if (object.localTime != null)
                        if ($util.Long)
                            (message.localTime = $util.Long.fromValue(object.localTime)).unsigned = true;
                        else if (typeof object.localTime === "string")
                            message.localTime = parseInt(object.localTime, 10);
                        else if (typeof object.localTime === "number")
                            message.localTime = object.localTime;
                        else if (typeof object.localTime === "object")
                            message.localTime = new $util.LongBits(object.localTime.low >>> 0, object.localTime.high >>> 0).toNumber(true);
                    if (object.audioTime != null)
                        message.audioTime = object.audioTime >>> 0;
                    if (object.isPlaying != null)
                        message.isPlaying = Boolean(object.isPlaying);
                    if (object.source != null) {
                        if (typeof object.source !== "object")
                            throw TypeError(".domain.network.payloads.AudioVariablePayload.source: object expected");
                        message.source = $root.domain.network.payloads.AudioVariablePayload.AudioSource.fromObject(object.source);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AudioVariablePayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {domain.network.payloads.AudioVariablePayload} message AudioVariablePayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AudioVariablePayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.localTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.localTime = options.longs === String ? "0" : 0;
                        object.audioTime = 0;
                        object.isPlaying = false;
                    }
                    if (message.localTime != null && message.hasOwnProperty("localTime"))
                        if (typeof message.localTime === "number")
                            object.localTime = options.longs === String ? String(message.localTime) : message.localTime;
                        else
                            object.localTime = options.longs === String ? $util.Long.prototype.toString.call(message.localTime) : options.longs === Number ? new $util.LongBits(message.localTime.low >>> 0, message.localTime.high >>> 0).toNumber(true) : message.localTime;
                    if (message.audioTime != null && message.hasOwnProperty("audioTime"))
                        object.audioTime = message.audioTime;
                    if (message.isPlaying != null && message.hasOwnProperty("isPlaying"))
                        object.isPlaying = message.isPlaying;
                    if (message.source != null && message.hasOwnProperty("source")) {
                        object.source = $root.domain.network.payloads.AudioVariablePayload.AudioSource.toObject(message.source, options);
                        if (options.oneofs)
                            object._source = "source";
                    }
                    return object;
                };

                /**
                 * Converts this AudioVariablePayload to JSON.
                 * @function toJSON
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AudioVariablePayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AudioVariablePayload
                 * @function getTypeUrl
                 * @memberof domain.network.payloads.AudioVariablePayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AudioVariablePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/domain.network.payloads.AudioVariablePayload";
                };

                AudioVariablePayload.AudioSource = (function() {

                    /**
                     * Properties of an AudioSource.
                     * @memberof domain.network.payloads.AudioVariablePayload
                     * @interface IAudioSource
                     * @property {string|null} [mp3] AudioSource mp3
                     */

                    /**
                     * Constructs a new AudioSource.
                     * @memberof domain.network.payloads.AudioVariablePayload
                     * @classdesc Represents an AudioSource.
                     * @implements IAudioSource
                     * @constructor
                     * @param {domain.network.payloads.AudioVariablePayload.IAudioSource=} [properties] Properties to set
                     */
                    function AudioSource(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AudioSource mp3.
                     * @member {string|null|undefined} mp3
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @instance
                     */
                    AudioSource.prototype.mp3 = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * AudioSource _mp3.
                     * @member {"mp3"|undefined} _mp3
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @instance
                     */
                    Object.defineProperty(AudioSource.prototype, "_mp3", {
                        get: $util.oneOfGetter($oneOfFields = ["mp3"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new AudioSource instance using the specified properties.
                     * @function create
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {domain.network.payloads.AudioVariablePayload.IAudioSource=} [properties] Properties to set
                     * @returns {domain.network.payloads.AudioVariablePayload.AudioSource} AudioSource instance
                     */
                    AudioSource.create = function create(properties) {
                        return new AudioSource(properties);
                    };

                    /**
                     * Encodes the specified AudioSource message. Does not implicitly {@link domain.network.payloads.AudioVariablePayload.AudioSource.verify|verify} messages.
                     * @function encode
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {domain.network.payloads.AudioVariablePayload.IAudioSource} message AudioSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AudioSource.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mp3 != null && Object.hasOwnProperty.call(message, "mp3"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.mp3);
                        return writer;
                    };

                    /**
                     * Encodes the specified AudioSource message, length delimited. Does not implicitly {@link domain.network.payloads.AudioVariablePayload.AudioSource.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {domain.network.payloads.AudioVariablePayload.IAudioSource} message AudioSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AudioSource.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AudioSource message from the specified reader or buffer.
                     * @function decode
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {domain.network.payloads.AudioVariablePayload.AudioSource} AudioSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AudioSource.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.domain.network.payloads.AudioVariablePayload.AudioSource();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.mp3 = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AudioSource message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {domain.network.payloads.AudioVariablePayload.AudioSource} AudioSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AudioSource.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AudioSource message.
                     * @function verify
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AudioSource.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.mp3 != null && message.hasOwnProperty("mp3")) {
                            properties._mp3 = 1;
                            if (!$util.isString(message.mp3))
                                return "mp3: string expected";
                        }
                        return null;
                    };

                    /**
                     * Creates an AudioSource message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {domain.network.payloads.AudioVariablePayload.AudioSource} AudioSource
                     */
                    AudioSource.fromObject = function fromObject(object) {
                        if (object instanceof $root.domain.network.payloads.AudioVariablePayload.AudioSource)
                            return object;
                        var message = new $root.domain.network.payloads.AudioVariablePayload.AudioSource();
                        if (object.mp3 != null)
                            message.mp3 = String(object.mp3);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AudioSource message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {domain.network.payloads.AudioVariablePayload.AudioSource} message AudioSource
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AudioSource.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.mp3 != null && message.hasOwnProperty("mp3")) {
                            object.mp3 = message.mp3;
                            if (options.oneofs)
                                object._mp3 = "mp3";
                        }
                        return object;
                    };

                    /**
                     * Converts this AudioSource to JSON.
                     * @function toJSON
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AudioSource.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for AudioSource
                     * @function getTypeUrl
                     * @memberof domain.network.payloads.AudioVariablePayload.AudioSource
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    AudioSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/domain.network.payloads.AudioVariablePayload.AudioSource";
                    };

                    return AudioSource;
                })();

                return AudioVariablePayload;
            })();

            payloads.VideoVariablePayload = (function() {

                /**
                 * Properties of a VideoVariablePayload.
                 * @memberof domain.network.payloads
                 * @interface IVideoVariablePayload
                 * @property {number|Long|null} [localTime] VideoVariablePayload localTime
                 * @property {number|null} [videoTime] VideoVariablePayload videoTime
                 * @property {boolean|null} [isPlaying] VideoVariablePayload isPlaying
                 * @property {number|null} [shareScreenUserId] VideoVariablePayload shareScreenUserId
                 * @property {domain.network.payloads.VideoVariablePayload.IVideoSource|null} [source] VideoVariablePayload source
                 */

                /**
                 * Constructs a new VideoVariablePayload.
                 * @memberof domain.network.payloads
                 * @classdesc Represents a VideoVariablePayload.
                 * @implements IVideoVariablePayload
                 * @constructor
                 * @param {domain.network.payloads.IVideoVariablePayload=} [properties] Properties to set
                 */
                function VideoVariablePayload(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VideoVariablePayload localTime.
                 * @member {number|Long} localTime
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 */
                VideoVariablePayload.prototype.localTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * VideoVariablePayload videoTime.
                 * @member {number} videoTime
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 */
                VideoVariablePayload.prototype.videoTime = 0;

                /**
                 * VideoVariablePayload isPlaying.
                 * @member {boolean} isPlaying
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 */
                VideoVariablePayload.prototype.isPlaying = false;

                /**
                 * VideoVariablePayload shareScreenUserId.
                 * @member {number} shareScreenUserId
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 */
                VideoVariablePayload.prototype.shareScreenUserId = 0;

                /**
                 * VideoVariablePayload source.
                 * @member {domain.network.payloads.VideoVariablePayload.IVideoSource|null|undefined} source
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 */
                VideoVariablePayload.prototype.source = null;

                /**
                 * Creates a new VideoVariablePayload instance using the specified properties.
                 * @function create
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {domain.network.payloads.IVideoVariablePayload=} [properties] Properties to set
                 * @returns {domain.network.payloads.VideoVariablePayload} VideoVariablePayload instance
                 */
                VideoVariablePayload.create = function create(properties) {
                    return new VideoVariablePayload(properties);
                };

                /**
                 * Encodes the specified VideoVariablePayload message. Does not implicitly {@link domain.network.payloads.VideoVariablePayload.verify|verify} messages.
                 * @function encode
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {domain.network.payloads.IVideoVariablePayload} message VideoVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VideoVariablePayload.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.localTime != null && Object.hasOwnProperty.call(message, "localTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.localTime);
                    if (message.videoTime != null && Object.hasOwnProperty.call(message, "videoTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.videoTime);
                    if (message.isPlaying != null && Object.hasOwnProperty.call(message, "isPlaying"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isPlaying);
                    if (message.shareScreenUserId != null && Object.hasOwnProperty.call(message, "shareScreenUserId"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.shareScreenUserId);
                    if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                        $root.domain.network.payloads.VideoVariablePayload.VideoSource.encode(message.source, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified VideoVariablePayload message, length delimited. Does not implicitly {@link domain.network.payloads.VideoVariablePayload.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {domain.network.payloads.IVideoVariablePayload} message VideoVariablePayload message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VideoVariablePayload.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VideoVariablePayload message from the specified reader or buffer.
                 * @function decode
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {domain.network.payloads.VideoVariablePayload} VideoVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VideoVariablePayload.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.domain.network.payloads.VideoVariablePayload();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.localTime = reader.uint64();
                                break;
                            }
                        case 2: {
                                message.videoTime = reader.uint32();
                                break;
                            }
                        case 3: {
                                message.isPlaying = reader.bool();
                                break;
                            }
                        case 4: {
                                message.shareScreenUserId = reader.uint32();
                                break;
                            }
                        case 5: {
                                message.source = $root.domain.network.payloads.VideoVariablePayload.VideoSource.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VideoVariablePayload message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {domain.network.payloads.VideoVariablePayload} VideoVariablePayload
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VideoVariablePayload.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VideoVariablePayload message.
                 * @function verify
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VideoVariablePayload.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.localTime != null && message.hasOwnProperty("localTime"))
                        if (!$util.isInteger(message.localTime) && !(message.localTime && $util.isInteger(message.localTime.low) && $util.isInteger(message.localTime.high)))
                            return "localTime: integer|Long expected";
                    if (message.videoTime != null && message.hasOwnProperty("videoTime"))
                        if (!$util.isInteger(message.videoTime))
                            return "videoTime: integer expected";
                    if (message.isPlaying != null && message.hasOwnProperty("isPlaying"))
                        if (typeof message.isPlaying !== "boolean")
                            return "isPlaying: boolean expected";
                    if (message.shareScreenUserId != null && message.hasOwnProperty("shareScreenUserId"))
                        if (!$util.isInteger(message.shareScreenUserId))
                            return "shareScreenUserId: integer expected";
                    if (message.source != null && message.hasOwnProperty("source")) {
                        var error = $root.domain.network.payloads.VideoVariablePayload.VideoSource.verify(message.source);
                        if (error)
                            return "source." + error;
                    }
                    return null;
                };

                /**
                 * Creates a VideoVariablePayload message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {domain.network.payloads.VideoVariablePayload} VideoVariablePayload
                 */
                VideoVariablePayload.fromObject = function fromObject(object) {
                    if (object instanceof $root.domain.network.payloads.VideoVariablePayload)
                        return object;
                    var message = new $root.domain.network.payloads.VideoVariablePayload();
                    if (object.localTime != null)
                        if ($util.Long)
                            (message.localTime = $util.Long.fromValue(object.localTime)).unsigned = true;
                        else if (typeof object.localTime === "string")
                            message.localTime = parseInt(object.localTime, 10);
                        else if (typeof object.localTime === "number")
                            message.localTime = object.localTime;
                        else if (typeof object.localTime === "object")
                            message.localTime = new $util.LongBits(object.localTime.low >>> 0, object.localTime.high >>> 0).toNumber(true);
                    if (object.videoTime != null)
                        message.videoTime = object.videoTime >>> 0;
                    if (object.isPlaying != null)
                        message.isPlaying = Boolean(object.isPlaying);
                    if (object.shareScreenUserId != null)
                        message.shareScreenUserId = object.shareScreenUserId >>> 0;
                    if (object.source != null) {
                        if (typeof object.source !== "object")
                            throw TypeError(".domain.network.payloads.VideoVariablePayload.source: object expected");
                        message.source = $root.domain.network.payloads.VideoVariablePayload.VideoSource.fromObject(object.source);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VideoVariablePayload message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {domain.network.payloads.VideoVariablePayload} message VideoVariablePayload
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VideoVariablePayload.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.localTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.localTime = options.longs === String ? "0" : 0;
                        object.videoTime = 0;
                        object.isPlaying = false;
                        object.shareScreenUserId = 0;
                        object.source = null;
                    }
                    if (message.localTime != null && message.hasOwnProperty("localTime"))
                        if (typeof message.localTime === "number")
                            object.localTime = options.longs === String ? String(message.localTime) : message.localTime;
                        else
                            object.localTime = options.longs === String ? $util.Long.prototype.toString.call(message.localTime) : options.longs === Number ? new $util.LongBits(message.localTime.low >>> 0, message.localTime.high >>> 0).toNumber(true) : message.localTime;
                    if (message.videoTime != null && message.hasOwnProperty("videoTime"))
                        object.videoTime = message.videoTime;
                    if (message.isPlaying != null && message.hasOwnProperty("isPlaying"))
                        object.isPlaying = message.isPlaying;
                    if (message.shareScreenUserId != null && message.hasOwnProperty("shareScreenUserId"))
                        object.shareScreenUserId = message.shareScreenUserId;
                    if (message.source != null && message.hasOwnProperty("source"))
                        object.source = $root.domain.network.payloads.VideoVariablePayload.VideoSource.toObject(message.source, options);
                    return object;
                };

                /**
                 * Converts this VideoVariablePayload to JSON.
                 * @function toJSON
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VideoVariablePayload.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VideoVariablePayload
                 * @function getTypeUrl
                 * @memberof domain.network.payloads.VideoVariablePayload
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VideoVariablePayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/domain.network.payloads.VideoVariablePayload";
                };

                VideoVariablePayload.VideoSource = (function() {

                    /**
                     * Properties of a VideoSource.
                     * @memberof domain.network.payloads.VideoVariablePayload
                     * @interface IVideoSource
                     * @property {string|null} [mp4] VideoSource mp4
                     * @property {string|null} [ogv] VideoSource ogv
                     */

                    /**
                     * Constructs a new VideoSource.
                     * @memberof domain.network.payloads.VideoVariablePayload
                     * @classdesc Represents a VideoSource.
                     * @implements IVideoSource
                     * @constructor
                     * @param {domain.network.payloads.VideoVariablePayload.IVideoSource=} [properties] Properties to set
                     */
                    function VideoSource(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * VideoSource mp4.
                     * @member {string|null|undefined} mp4
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @instance
                     */
                    VideoSource.prototype.mp4 = null;

                    /**
                     * VideoSource ogv.
                     * @member {string|null|undefined} ogv
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @instance
                     */
                    VideoSource.prototype.ogv = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * VideoSource _mp4.
                     * @member {"mp4"|undefined} _mp4
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @instance
                     */
                    Object.defineProperty(VideoSource.prototype, "_mp4", {
                        get: $util.oneOfGetter($oneOfFields = ["mp4"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * VideoSource _ogv.
                     * @member {"ogv"|undefined} _ogv
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @instance
                     */
                    Object.defineProperty(VideoSource.prototype, "_ogv", {
                        get: $util.oneOfGetter($oneOfFields = ["ogv"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new VideoSource instance using the specified properties.
                     * @function create
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {domain.network.payloads.VideoVariablePayload.IVideoSource=} [properties] Properties to set
                     * @returns {domain.network.payloads.VideoVariablePayload.VideoSource} VideoSource instance
                     */
                    VideoSource.create = function create(properties) {
                        return new VideoSource(properties);
                    };

                    /**
                     * Encodes the specified VideoSource message. Does not implicitly {@link domain.network.payloads.VideoVariablePayload.VideoSource.verify|verify} messages.
                     * @function encode
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {domain.network.payloads.VideoVariablePayload.IVideoSource} message VideoSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoSource.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mp4 != null && Object.hasOwnProperty.call(message, "mp4"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.mp4);
                        if (message.ogv != null && Object.hasOwnProperty.call(message, "ogv"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ogv);
                        return writer;
                    };

                    /**
                     * Encodes the specified VideoSource message, length delimited. Does not implicitly {@link domain.network.payloads.VideoVariablePayload.VideoSource.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {domain.network.payloads.VideoVariablePayload.IVideoSource} message VideoSource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VideoSource.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VideoSource message from the specified reader or buffer.
                     * @function decode
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {domain.network.payloads.VideoVariablePayload.VideoSource} VideoSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoSource.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.domain.network.payloads.VideoVariablePayload.VideoSource();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.mp4 = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.ogv = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VideoSource message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {domain.network.payloads.VideoVariablePayload.VideoSource} VideoSource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VideoSource.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VideoSource message.
                     * @function verify
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VideoSource.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.mp4 != null && message.hasOwnProperty("mp4")) {
                            properties._mp4 = 1;
                            if (!$util.isString(message.mp4))
                                return "mp4: string expected";
                        }
                        if (message.ogv != null && message.hasOwnProperty("ogv")) {
                            properties._ogv = 1;
                            if (!$util.isString(message.ogv))
                                return "ogv: string expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a VideoSource message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {domain.network.payloads.VideoVariablePayload.VideoSource} VideoSource
                     */
                    VideoSource.fromObject = function fromObject(object) {
                        if (object instanceof $root.domain.network.payloads.VideoVariablePayload.VideoSource)
                            return object;
                        var message = new $root.domain.network.payloads.VideoVariablePayload.VideoSource();
                        if (object.mp4 != null)
                            message.mp4 = String(object.mp4);
                        if (object.ogv != null)
                            message.ogv = String(object.ogv);
                        return message;
                    };

                    /**
                     * Creates a plain object from a VideoSource message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {domain.network.payloads.VideoVariablePayload.VideoSource} message VideoSource
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VideoSource.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (message.mp4 != null && message.hasOwnProperty("mp4")) {
                            object.mp4 = message.mp4;
                            if (options.oneofs)
                                object._mp4 = "mp4";
                        }
                        if (message.ogv != null && message.hasOwnProperty("ogv")) {
                            object.ogv = message.ogv;
                            if (options.oneofs)
                                object._ogv = "ogv";
                        }
                        return object;
                    };

                    /**
                     * Converts this VideoSource to JSON.
                     * @function toJSON
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VideoSource.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for VideoSource
                     * @function getTypeUrl
                     * @memberof domain.network.payloads.VideoVariablePayload.VideoSource
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    VideoSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/domain.network.payloads.VideoVariablePayload.VideoSource";
                    };

                    return VideoSource;
                })();

                return VideoVariablePayload;
            })();

            return payloads;
        })();

        return network;
    })();

    return domain;
})();

module.exports = $root;
