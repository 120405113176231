import { FC } from 'react';
import { IconProps } from './iconTypes';
import Camera from './Camera';
import SlashCamera from './SlashCamera';
import Microphone from './Microphone';
import SlashMicrophone from './SlashMicrophone';
import Sound from './Sound';
import SlashSound from './SlashSound';
import Bullit from './Bullit';
import { ChatSound } from './ChatSound';
import ChatSoundOff from './ChatSoundOff';
import Close from './Close';
import Send from './Send';
import ChatMic from './ChatMic';

const iconsDict: Record<string, FC<IconProps>> = {
  CameraIcon: Camera,
  SlashCameraIcon: SlashCamera,
  MicrophoneIcon: Microphone,
  SlashMicrophoneIcon: SlashMicrophone,
  SoundIcon: Sound,
  SlashSoundIcon: SlashSound,
  BullitIcon: Bullit,
  ChatSoundIcon: ChatSound,
  ChatSoundOffIcon: ChatSoundOff,
  CloseIcon: Close,
  SendIcon: Send,
  ChatMicIcon: ChatMic,
};

export default iconsDict;
