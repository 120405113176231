import _ from 'lodash';
import * as ThreeMeshUI from 'three-mesh-ui';
import PlaceMenuItemComponent from '../../../components/UI/PlaceMenuItem.component';
import { PlaceMenuRow } from './PlaceMenuRow';
import { MainMenuAdaptive } from '../../adaptive';

export type PlaceMenuContentParams = {
  items: PlaceMenuItemComponent[];
  adaptive: MainMenuAdaptive;
};

export function PlaceMenuContent({ items, adaptive }: PlaceMenuContentParams): ThreeMeshUI.Block[] {
  const rows = _.chunk(items.sort((a, b) => a.sortOrder - b.sortOrder), 2);
  const padding = rows.length <= adaptive.placeMenuMinRows ? 0 : undefined;
  return rows.map((rowItems, index) => {
    return PlaceMenuRow({
      id: index,
      adaptive,
      padding,
      items: rowItems.map(({ placeId, text, imageUrl }) => ({
        placeId,
        text,
        imgUrl: imageUrl,
        width: adaptive.placeElementWidth,
        height: adaptive.placeElementHeight,
        fontSize: adaptive.tabsFontSize,
      })),
    });
  });
}
