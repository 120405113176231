export const enDictionary = {
  components: {
    poweredOwn: {
      powered: 'Powered',
      by: 'by',
      ownverse: 'OWNverse',
    },
  },
  containers: {
    enterScreen: {
      spaceTitle: 'bowmo/OWNverse Space',
      spaceDefaultTitle: 'OWNverse',
      nameForm: {
        title: 'Give yourself a name',
        inputPlaceholder: 'Name',
        inputCharTypeRule: 'English characters, alphanumerics, hyphens, underscores, and tildes',
        inputCharLengthRule: 'At least 3 characters, no more than 8',
      },
      continueButtonLabel: 'Continue',
      enterButtonLabel: 'Enter',
      welcomeMessage: 'Welcome to OWNverse',
    },
  },
  canvas: {
    welcomeScreen: {
      notTouchDevice: {
        mouseHelp: 'Use your mouse to look around',
        keyboardHelp: 'Move around by pressing WASD',
        switchViewHelp: 'Switch first and third person view',
        runHelp: 'Switch to run',
        danceHelp: 'Click to dance',
      },
      touchDevice: {
        dragHelp: 'Drag your finger around the screen to look around',
        navigateHelp: 'Navigate around the place by using  control',
        switchViewHelp: 'Switch first and third person view',
        runHelp: 'Switch to run',
        danceHelp: 'Click to dance',
      },
      seatHelp: 'Click on the button near with benches to seat and click on the Esc to stand up',
      videoHelp: 'Click interaction buttons and customise options',
      controls: 'Controls',
      startButtonLabel: 'Start',
    },
    shopifyMenu: {
      openInShopify: 'Open in Shopify',
      openInShopify2: 'Open in Shopify',
      defaultTitle: 'Hp racchetta padlle legend in carboniohp',
      defaultContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus tellus id congue semper cras at tempus. Enim etiam accumsan volutpat eget pretium ut velit, augue nibh. Nunc, sed magna et eu sed elit malesuada hendrerit. Aenean scelerisque in massa blandit lectus et urna. ',
    },
    presentationMenu: {
      text: 'Text',
    },
    playerMenu: {
      text: 'Text',
      libraryButtonLabel: 'Library',
      libraryButtonLabel2: 'Library',
    },
    mainMenu: {
      switchTo: 'Switch to:',
      titleFirstPart: 'OW',
      titleSecondPart: 'NSpace',
      tabAvatars: 'Avatars',
      tabPlaces: 'Places',
      tabPlayers: 'Players',
      tabInvite: 'Invite',
      shareLinkDescription: 'You can share link to invite friends',
      shareSocialMedia: 'Share in social media',
      poweredByFirstPart: 'Powered by',
      poweredBySecondPart: ' OWNverse',
    },
    avatarVideo: {
      waitingMessage: 'Waiting...',
    },
    avatarName: {
      waitingMessage: 'Waiting...',
    },
  },
};
