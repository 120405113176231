import * as Three from 'three';
import { Adaptive } from './Adaptive';

export class ActionsMenuAdaptive extends Adaptive {
  get isMobile() {
    return Adaptive.isTouchDevice() || this.elementSize.width < 650;
  }

  get width() {
    return this.isMobile ? 3 * this.buttonSize : 417;
  }

  get height() {
    return this.isMobile ? 5 * this.buttonSize : 192;
  }

  get scale() {
    const base = 0.0004;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    return kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get padding() {
    return this.isMobile ? 6 : 32;
  }

  get space() {
    return this.isMobile ? 2 : 30;
  }

  get borderRadius(): [number, number, number, number] {
    return this.isMobile ? [0, 16, 0, 0] : [16, 0, 0, 0];
  }

  get columnContentDirection() {
    return this.isMobile ? 'column' : 'row';
  }

  get contentDirection() {
    return this.isMobile ? 'row-reverse' : 'column';
  }

  get columnWidth() {
    const width = this.width - 2 * this.padding;
    return this.isMobile ? width / 2 : width;
  }

  get columnHeight() {
    const height = this.height - 2 * this.padding;
    return this.isMobile ? height : height / 2;
  }

  get tooltipWidth() {
    return this.isMobile ? this.buttonSize : 64;
  }

  get tooltipHeight() {
    return this.isMobile ? this.buttonSize : 48;
  }

  get buttonSize() {
    return this.isMobile ? 48 : 64;
  }

  get position() {
    return new Three.Vector3(
      this.isMobile ? this.left(this.width / 2) : this.right(this.width / 2),
      this.bottom(this.height / 2),
      -this.z,
    );
  }
}
