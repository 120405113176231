import { FC, memo } from 'react';
import { IJoystickUpdateEvent, Joystick } from 'react-joystick-component/build/lib/Joystick';
import classnames from 'classnames';
import classNames from './JoystickPanel.module.css';
import { Adaptive } from '../../../domain/ui/adaptive';

export interface ControlsPanelProps {
  onJoystickMove: (event: IJoystickUpdateEvent) => void;
  onJoystickStop: (event: IJoystickUpdateEvent) => void;
  show: boolean;
}

const JoystickPanel: FC<ControlsPanelProps> = memo((props) => {
  const {
    onJoystickMove = () => undefined,
    onJoystickStop = () => undefined,
    show = false,
  } = props;

  const device = Adaptive.isTouchDevice() ? 'mobile' : 'desktop';

  return (
    <div
      className={classnames(classNames[`root_${device}`])}
      style={{ display: show ? 'block' : 'none' }}
    >
      <div className={classNames.joystick}>
        <Joystick
          size={140}
          baseColor="rgba(255, 255, 255, 0.6)"
          stickColor="#2586FF"
          move={onJoystickMove}
          start={onJoystickMove}
          stop={onJoystickStop}
        />
      </div>
    </div>
  );
});

export default JoystickPanel;
