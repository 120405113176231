import { FC, memo } from 'react';
import { IconProps } from './iconTypes';

const SlashMicrophone: FC<IconProps> = memo((props) => {
  const {
    width = 24,
    height = 24,
    fill = '#FFFFFF',
    className,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2301 5.2213C18.2413 3.16745 16.1403 1.75 13.7083 1.75C10.3255 1.75 7.5833 4.49224 7.5833 7.87499V12.9791C7.58457 14.096 7.88437 15.1428 8.40718 16.0443L6.90443 17.547C6.17716 16.4796 5.69426 15.231 5.53482 13.8804C5.46876 13.3204 4.96125 12.9201 4.40134 12.9862C3.8414 13.0522 3.44107 13.5597 3.50713 14.1197C3.72293 15.941 4.40944 17.6121 5.44142 19.01L3.90113 20.5503C3.42461 21.0268 3.42461 21.7994 3.90113 22.276C4.37766 22.7525 5.15025 22.7525 5.62678 22.276L23.5233 4.37946C23.9998 3.90294 23.9998 3.13034 23.5233 2.65382C23.0467 2.1773 22.2741 2.1773 21.7976 2.65382L19.2301 5.2213ZM17.6492 6.80219C17.2719 5.41306 16.1752 4.2934 14.7292 3.92079C12.5468 3.35843 10.3218 4.67174 9.7594 6.85415H11.6667C12.2305 6.85415 12.6875 7.31118 12.6875 7.87499C12.6875 8.4388 12.2305 8.89583 11.6667 8.89583H9.62497V10.9375H11.6667C12.1694 10.9375 12.5874 11.301 12.6719 11.7795L17.6492 6.80219ZM11.4723 12.9791H9.62497C9.62497 13.5259 9.73243 14.0475 9.92737 14.5241L11.4723 12.9791Z"
        fill={fill}
      />
      <path
        d="M19.8333 12.9792C19.8294 16.3603 17.0894 19.1003 13.7083 19.1042C12.9446 19.1033 12.2137 18.9629 11.5396 18.707L13.2138 17.0328C13.3759 17.0523 13.541 17.0624 13.7084 17.0624C15.9635 17.0624 17.7917 15.2342 17.7917 12.9791V12.4549L19.8333 10.4133V12.9792Z"
        fill={fill}
      />
      <path
        d="M8.48689 21.7596C9.73993 22.5 11.1645 22.9822 12.6876 23.1347V25.2292C12.6876 25.7929 13.1447 26.25 13.7084 26.25C14.2723 26.25 14.7293 25.793 14.7293 25.2292V23.1347C19.525 22.6547 23.3427 18.9059 23.9098 14.1197C23.9759 13.5597 23.5755 13.0522 23.0156 12.9862C22.4556 12.9201 21.9482 13.3204 21.8821 13.8804C21.3492 18.3945 17.2577 21.622 12.7435 21.0891C11.761 20.9731 10.8325 20.686 9.98841 20.2581L8.48689 21.7596Z"
        fill={fill}
      />
    </svg>
  );
});

export default SlashMicrophone;
