import * as Three from 'three';
import { SyncVariable } from '../SyncVariable';
import PositionBuffer from '../interpolation/PositionBuffer';
import config from '../config';
import { TransformVariablePayload, TransformVariableType } from '../payloads/TransformVariablePayload';
import { SyncVariableSerialized, VariablePayload } from '../payloads/VariablePayload';

export default class TransformVariable extends SyncVariable<TransformVariableType> {
  public static type = 'transform';

  public interpolated = true;

  public buffer: PositionBuffer = new PositionBuffer();

  public static payloadType: typeof VariablePayload<TransformVariableType> = TransformVariablePayload;

  constructor(name = 'transform') {
    super(name);
  }

  public get position() {
    if (!this.value || !this.value.position) return null;
    const pos = this.value.position;
    return new Three.Vector3(pos.x, pos.y, pos.z);
  }

  public get quaternion() {
    if (!this.value) return null;
    const q = this.value.quaternion;
    return new Three.Quaternion(q.x, q.y, q.z, q.w);
  }

  saveValueFromNetwork(data: SyncVariableSerialized<TransformVariableType>, sendTime: number, receiveTime: number) {
    super.saveValueFromNetwork(data, sendTime, receiveTime);
    if (this.position) {
      this.buffer.setValue(this.position, receiveTime);
    }
  }

  getInterpolatePosition(t: number, ts: number, position: Three.Vector3): Three.Vector3 {
    if (!this.interpolated && this.position) return this.position;
    // TODO: interpolate from position from prev frame to network position
    return this.buffer.getValue(t - config.interpolate - ts);
    // return this.buffer.getPosition(t, ts, position);
  }

  public isChanged(prevValue: TransformVariableType | null, newValue: TransformVariableType) {
    // return true;
    if (!prevValue || !prevValue.position) return true;
    const eps = 0.03;
    const prevPosition = new Three.Vector3(prevValue.position.x, prevValue.position.y, prevValue.position.z);
    const newPosition = new Three.Vector3(newValue.position.x, newValue.position.y, newValue.position.z);
    if (prevPosition.distanceTo(newPosition) > eps) return true;

    const prevQuat = new Three.Quaternion(prevValue.quaternion.x, prevValue.quaternion.y,
      prevValue.quaternion.z, prevValue.quaternion.w);
    const newQuat = new Three.Quaternion(newValue.quaternion.x, newValue.quaternion.y,
      newValue.quaternion.z, newValue.quaternion.w);

    if (!prevQuat.equals(newQuat)) return true;

    return false;
  }
}
