import * as Three from 'three';
import { Entity } from '../../../engine/Entity';
import { Adaptive } from './Adaptive';
import { Application } from '../../../engine/Application';
import AvatarVideoComponent from '../../components/UI/AvatarVideo.component';

export class AvatarVideoAdaptive extends Adaptive {
  protected component: AvatarVideoComponent;

  constructor(app: Application, component: AvatarVideoComponent) {
    super(app);
    this.component = component;
  }

  public get parent() {
    return this.component.getParentEntity() as Entity;
  }

  get isMobile() {
    return this.elementSize.width < 868;
  }

  get padding() {
    return this.isMobile && this.pinned ? 6 : 8;
  }

  get videoWidth() {
    return this.isMobile ? 160 : 320 * (this.pinned ? 1 : 0.6);
  }

  get videoHeight() {
    return this.isMobile ? 120 : 240 * (this.pinned ? 1 : 0.6);
  }

  get buttonsWidth() {
    return this.buttonSize + this.padding;
  }

  get buttonSize() {
    return this.isMobile && this.pinned ? 40 : 40;
  }

  get fontSize() {
    return this.isMobile && this.pinned ? 18 : 24;
  }

  get width() {
    return this.videoWidth + 2 * this.padding + this.buttonsWidth;
  }

  get nameHeight() {
    return this.fontSize + this.padding;
  }

  get height() {
    return this.videoHeight + 3 * this.padding + this.nameHeight; // + (!this.pinned ? this.nameHeight : 0);
  }

  get pinned() {
    return this.component.pinned;
  }

  get borderRadius() {
    return 12;
  }

  get positionOffset() {
    return new Three.Vector3(this.pinned ? 0 : (this.buttonsWidth * this.scale) / 2, 0, 0);
  }

  get scale() {
    const base = this.component.pinned ? 0.0004 : 0.003;
    const kf = Adaptive.isTouchDevice() && (this.elementSize.width > this.elementSize.height) ? 2.2 : 1;
    return kf * (this.isMobile ? this.getPixelRatioScale(base) : base);
  }

  get position() {
    const margin = this.isMobile ? 0.04 : 0;
    return this.component.pinned ? new Three.Vector3(
      this.left(this.width / 2),
      (this.top(this.height / 2 + this.component.pinIndex * (this.height - this.nameHeight - 2 * this.padding))) - margin,
      -this.z,
    ) : new Three.Vector3(0, 2.5, 0);
  }
}
